import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

const TabsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
`;

const Tab = styled.div`
  padding: 5px 15px;
  cursor: pointer;
  position: relative;
  font-family: 'Texta';
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: ${props => (props.active ? '#4B5166' : '#939aab4d')};
  font-weight: ${props => (props.active ? 'bold' : 'normal')};
  ${props => !props.isLast && 'border-right: 1px solid #939aab4d;'}
  &:last-child {
    border-right: none;
  }

  &:hover {
    color: #4b5166;
  }
`;

const Indicator = styled.div`
  position: absolute;
  bottom: 0;
  height: 3px;
  background-color: ${({ theme }) => theme.palette.brand.secondary.natural};
  transition: 0.3s ease;
`;

const tabsDates = [
  { value: 'day', label: 'D' },
  { value: 'week', label: 'S' },
  { value: 'month', label: 'M' },
];

const Tabs = ({ tab = null, tabs = tabsDates, handleApplay = () => {} }) => {
  const [activeTab, setActiveTab] = useState(
    tab ? tabs.findIndex(item => item.value === tab) : 0,
  );
  const [indicatorStyle, setIndicatorStyle] = useState({ width: 0, left: 0 });
  const tabRefs = useRef([]);

  useEffect(() => {
    const currentTab = tabRefs.current[activeTab];
    if (currentTab) {
      const textWidth = currentTab.querySelector('span').offsetWidth;
      setIndicatorStyle({
        width: textWidth,
        left: currentTab.offsetLeft + (currentTab.offsetWidth - textWidth) / 2,
      });
    }
  }, [activeTab, tabs]);

  useEffect(() => {
    setTimeout(() => {
      const currentTab = tabRefs.current[activeTab];
      if (currentTab) {
        const textWidth = currentTab.querySelector('span').offsetWidth;
        setIndicatorStyle({
          width: textWidth,
          left:
            currentTab.offsetLeft + (currentTab.offsetWidth - textWidth) / 2,
        });
      }
    }, 0);
  }, []);

  return (
    <>
      <TabsContainer>
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            isLast={index === tabs.length - 1}
            ref={el => (tabRefs.current[index] = el)}
            active={index === activeTab}
            onClick={() => {
              handleApplay(tab.value);
              setActiveTab(index);
            }}
          >
            <span>{tab.label}</span>
          </Tab>
        ))}
        <Indicator
          style={{
            width: `${indicatorStyle.width}px`,
            left: `${indicatorStyle.left}px`,
          }}
        />
      </TabsContainer>
    </>
  );
};

export default Tabs;
