import styled from 'styled-components';
import colors from 'styles/colors';

export const Container = styled.div`
  .boxLoading {
    position: relative;
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }
  .boxBody {
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom: none;

    .formatTextCheck {
      display: flex;
      align-items: center;
      font-weight: 900;
      flex-direction: row;
      font-size: 18px;
      letter-spacing: 0px;
      color: #4b5166;
      .rotulo {
        border-radius: 12px;
        margin-left: 30px;
        font-weight: bold;
        font-size: 16px;
        padding-left: 30px;
        padding-right: 30px;
      }
    }
    .notifyItem {
      color: #4b5166;
      font-weight: 600;
      border-bottom: 1px solid #939aab4d;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0px;
      .notifyText {
        text-align: left;
        color: #4b5166;
        font-size: 14px;
      }
    }
    .notifyControls {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
    }
    .notifyItem:last-child {
      border-bottom: none;
    }
    .space {
      margin-top: 10px;
    }
    .subtitle {
      text-align: left;
      font: normal normal 900 16px/30px Texta;
      letter-spacing: 0px;
      color: #4b5166;
      opacity: 1;
    }
  }
  .tags-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 10px;
    gap: 10px;
  }
  .tags {
    background-color: #2e3240;
    color: #fff;
    font-family: Texta;
    font-style: noraml;
    font-size: 14px;
    margin-right: 10px;
    padding: 0px 10px 0px 10px;
    border-radius: 20px;
    user-select: none;
  }
  .tags > span {
    font-size: 16px;
    font-weight: 900;
    margin-left: 5px;
  }
`;

export const Action = styled.div`
  margin-top: 1.5rem;
`;

export const NotifyBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  .boxHeader {
    display: flex;

    .headerItem {
      background-color: #fff;
      color: ${colors.greyTitle};
      opacity: 0.8;
      font-weight: 600;
      font-size: 16px;
      padding: 10px 40px;
      border: 1px solid #939aab4d;
      border-bottom: none;
      border-right: none;
      transition: all ease 0.5s;
      cursor: pointer;
      box-shadow: 0px -1px 10px #939aab4d;
    }

    .headerItem:last-child {
      border-right: 1px solid #939aab4d;
      border-top-right-radius: 5px;
    }

    .headerItem:first-child {
      border-top-left-radius: 5px;
    }

    .active {
      background-color: #efefef;
    }
  }
`;
