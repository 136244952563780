import RotogramaProvider from 'pages/Provider/Rotograma';
import RotogramaHistoricoProvider from 'pages/Provider/Rotograma/Historico';

export default {
  '/rotograma': {
    title: 'Rotograma',
    page: <RotogramaProvider />,
  },

  '/rotograma/historico/:id': {
    back: -1,
    id: true,
    defaults: [],
    title: 'Rotograma',
    page: <RotogramaHistoricoProvider />,
  },
};
