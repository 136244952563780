import React, { useCallback } from 'react';
import { format } from 'date-fns';

import { Divider } from '@mui/material';
import Tabs from 'components/Tabs';
import InfoCard from 'components/Cards/InfoCard';
import Header from './components/Header';
import Cards from './components/Cards';
import { DefaultTable } from 'components/_Table/templates/default';
import { FilterTable } from 'components/_Table/templates/filter';
import { DoubleInputTable } from 'components/_Table/templates/doubleInput';

const ListPage = ({
  title,
  headerActions,
  headerTabs = null,
  infoMessage = null,
  cards = null,
  selectedCard = null,
  disableCards = false,
  loadingCards = false,
  handleClickCard = null,
  tableProps = {},
  tableTemplate = 'default',
  customComponent,
  lastUpdated = null,
}) => {
  const renderTable = useCallback((tableTemplate, tableProps) => {
    switch (tableTemplate) {
      case 'filter':
        return <FilterTable {...tableProps} />;
      case 'double':
        return <DoubleInputTable {...tableProps} />;
      default:
        return <DefaultTable {...tableProps} />;
    }
  }, []);

  return (
    <>
      <Header title={title} actions={headerActions} />

      {customComponent}

      {headerTabs && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Tabs {...headerTabs} />
          <Divider />
          {lastUpdated && (
            <div
              style={{
                fontFamily: 'Texta',
                fontStyle: 'normal',
                fontWeight: '500',
                fontSize: 14,
                color: '#939aab',
                whiteSpace: 'nowrap',
              }}
            >
              Atualizado ás {format(lastUpdated, "HH'h'mm")}
            </div>
          )}
        </div>
      )}

      {infoMessage && <InfoCard message={infoMessage} key="info" />}

      {!!cards && (
        <Cards
          cards={cards}
          selectedCard={selectedCard}
          disableCards={disableCards}
          loadingCards={loadingCards}
          handleClickCard={handleClickCard}
        />
      )}

      {renderTable(tableTemplate, tableProps)}
    </>
  );
};

export default ListPage;
