import React from 'react';
import { Modal } from '@mui/material';
import GhostButton from 'components/Buttons/Ghost';
import { ReactComponent as WarningIco } from 'images/icons/telemetria/warning.svg';
import { useTheme } from 'styled-components';
import * as S from './styled';
import { useDispatch } from 'react-redux';
import {
  toggleAlertModalClose,
  toggleSnackbarOpen,
} from 'store/modules/header/actions';

const AlertModal = ({
  handleClose,
  open,
  title,
  subtitle,
  buttonText,
  backButtonText,
  onClick,
  loading,
  route,
  query,
  color,
  isBigFile,
  isError,
  isManualDownload,
}) => {
  const dispatch = useDispatch();

  const theme = useTheme();
  const body = (
    <S.Paper>
      <S.Header>
        <WarningIco
          width="48px"
          color={theme.palette.semantics.feedback.attention.natural}
        />
      </S.Header>
      <S.Header>
        <S.Title>
          <h1>{title}</h1>
        </S.Title>
      </S.Header>

      <br />

      <S.Main>{subtitle || ''}</S.Main>

      <br />

      <S.Footer>
        {backButtonText && (
          <GhostButton onClick={handleClose}>{backButtonText}</GhostButton>
        )}
        {isBigFile ? (
          <GhostButton
            onClick={() => {
              dispatch(
                toggleSnackbarOpen({
                  url: route,
                  query,
                  isBigFile,
                  isError,
                  isManualDownload,
                }),
              );
              dispatch(toggleAlertModalClose());
            }}
            loading={loading}
            customcolor={
              color || theme.palette.semantics.feedback.attention.natural
            }
          >
            {buttonText}
          </GhostButton>
        ) : (
          <GhostButton
            onClick={onClick}
            loading={loading}
            customcolor={
              color || theme.palette.semantics.feedback.attention.natural
            }
          >
            {buttonText}
          </GhostButton>
        )}
      </S.Footer>
    </S.Paper>
  );

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        {body}
      </Modal>
    </div>
  );
};

export default AlertModal;
