import styled, { css } from 'styled-components';
import Button from '@mui/material/Button';

export const StyledButton = styled(Button)`
  ${({ theme, ...props }) => css`
    &.MuiButton-root {
      min-width: 120px;
      width: ${props.width || ''};
      cursor: ${props.loading === 'true' ? 'default' : 'pointer'};

      background: ${props.loading === 'true'
        ? theme.palette.brand.primary.background
        : theme.palette.system.transparent};
      border: 1px solid;
      border-color: ${props.customcolor || theme.palette.brand.primary.natural};
      border-radius: 4px;
      text-transform: none;
      color: ${props.customcolor || theme.palette.brand.primary.natural};

      &:hover {
        background: ${props.customcolor
          ? `${props.customcolor}10`
          : `${theme.palette.brand.primary.natural}10`};
        border: 1px solid;
        border-color: ${props.customcolor || theme.palette.brand.primary.light};
      }

      &:active {
        background: ${`${props.customcolor}10` ||
        `${theme.palette.brand.primary.natural}10`};
        box-shadow: 0px 3px 15px
          ${props.customcolor || theme.palette.brand.primary.natural};
      }
    }

    &.MuiButton-sizeSmall {
      height: 24px;
      font: normal normal 500 14px/24px Texta;
    }

    &.MuiButton-sizeMedium {
      height: 32px;
      font: normal normal 500 16px/18px Texta;
    }

    &.MuiButton-sizeLarge {
      height: 40px;
      font: normal normal 500 18px/24px Texta;
    }

    &&.Mui-disabled {
      background: ${theme.palette.system.disabled};
      color: ${theme.palette.words.disabled.natural};
    }
  `}
`;
