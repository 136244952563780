// Styles
import { useTheme } from 'styled-components';

// React
import React, { useState } from 'react';

// Components
import Button from 'components/Buttons/Default';
import ButtonGhost from 'components/Buttons/Ghost';
import ConfirmModal from 'components/ConfirmModal';
import { DefaultTable } from 'components/_Table/templates/default';

// Components MUI
import { Divider } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';

// Utils
import DefaultButton from 'components/Buttons/Default';
import { columns } from '../columns';
import ViewTurma from './viewTurma';
import * as S from '../style';

const ListarTurmas = ({
  behaviorCloseModal,
  openModal,
  data,
  setSelectedTurmas,
  fetchTurma,
}) => {
  const theme = useTheme();
  // Tabela
  const [selectedRow, setSelectedRow] = useState([]);

  const [openModalTurma, setOpenModalTurma] = useState(false);
  const [openNewTab, setOpenNewTab] = useState(false);
  const [idTurma, setIdTurma] = useState(null);

  const handleAddAluno = () => {
    const result = data.filter(item => selectedRow.includes(item.id));
    setSelectedTurmas(result);
    behaviorCloseModal();
  };

  const redirectToCreationPage = () => {
    setOpenNewTab(false);
    window.open('/fiscalizacao/turmas/criar');
    window.onfocus = () => {
      fetchTurma();
    };
  };

  return (
    <S.ModalTurmas
      open={openModal}
      onClose={() => {
        behaviorCloseModal();
        window.onfocus = () => null;
      }}
      disableEscapeKeyDown={false}
    >
      <S.ContainerModal>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: 10,
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <PersonAddAltOutlinedIcon
              htmlColor={theme.palette.brand.secondary.natural}
            />
            <h1 style={{ marginLeft: '10px' }}>Adicionar Turmas</h1>
          </div>
          <IconButton
            onClick={() => {
              behaviorCloseModal();
              window.onfocus = () => null;
            }}
          >
            <CloseOutlinedIcon />
          </IconButton>
        </div>
        <Divider />
        <S.TableContainer>
          <DefaultTable
            data={data || []}
            columns={columns}
            setSelectedRows={setSelectedRow}
            transparent
            actions={[
              {
                title: 'Ver turma',
                function: id => {
                  Promise.all([setIdTurma(id)]).then(() =>
                    setOpenModalTurma(true),
                  );
                },
              },
            ]}
            fixedItems={4}
            bulk={[]}
            sortBy={{ id: 'nome', order: 'DESC' }}
            searchKeys={['nome', 'tipoUser']}
            empty={{
              title: 'Ops! Você não tem nenhum turma.',
              description: 'Verifique os filtros selecionados.',
            }}
          />

          {data.length <= 0 && (
            <S.AbsoluteButton>
              <DefaultButton
                startIcon={<AddCircleOutlineIcon />}
                children="Criar nova turma"
                onClick={() => setOpenNewTab(true)}
              />
            </S.AbsoluteButton>
          )}
        </S.TableContainer>
        <div
          style={{ display: 'flex', justifyContent: 'flex-end', padding: 15 }}
        >
          <ButtonGhost
            onClick={() => {
              behaviorCloseModal();
              window.onfocus = () => null;
            }}
            customcolor={
              theme?.palette?.semantics?.feedback?.attention?.natural
            }
          >
            Cancelar
          </ButtonGhost>
          <div style={{ marginLeft: 5 }} />
          <ButtonGhost onClick={handleAddAluno}>Adicionar</ButtonGhost>
        </div>

        {idTurma && (
          <ViewTurma
            openModal={openModalTurma}
            idturma={idTurma}
            behaviorCloseModal={() => {
              setOpenModalTurma(false);
              setIdTurma(null);
              fetchTurma();
            }}
          />
        )}

        <ConfirmModal
          open={openNewTab}
          handleClose={() => setOpenNewTab(false)}
          title="Deseja continuar?"
          titleIcon={
            <NoteAddOutlinedIcon
              sx={{ color: theme.palette.brand.secondary.natural }}
              fontSize="medium"
            />
          }
          subtitle="Você será direcionado para a criação de turmas"
          buttonText="Ir para criação"
          onClick={() => redirectToCreationPage()}
        />
      </S.ContainerModal>
    </S.ModalTurmas>
  );
};

export default ListarTurmas;
