// React
import React, { useState, useEffect, useMemo } from 'react';
import { useTheme } from 'styled-components';

// mui ui components
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

// components custom
import CustomCard from '../../FatorRisco/Cards/Card';

// styles
import * as S from './styled';

// icons
import { ReactComponent as CamIco } from 'images/icons/camIndisp2.svg';
import { ReactComponent as DesvioCion } from 'images/icons/desvio125432.svg';

const Cards = ({ data }) => {
  const theme = useTheme();

  return (
    <div>
      <S.Container>
        <div className="main-card">
          <div className="title">{data?.cards01[0]?.name || ''}</div>
          <div className="middle">{data?.cards01[0]?.value || '0'}</div>
          <div className="bottom">Últimos 30 dias</div>
        </div>
        {data?.cards01.slice(1).map(item => (
          <S.Cards>
            <div>
              <div className="container-info">
                <div>
                  <div
                    className={`container-icon ${
                      item?.icon === 'camIndisp.svg'
                        ? 'icon-color1'
                        : 'icon-color2'
                    }`}
                  >
                    {item?.icon === 'camIndisp.svg' && (
                      <CamIco fontSize="medium" />
                    )}
                    {item?.icon !== 'camIndisp.svg' && (
                      <DesvioCion fontSize="medium" />
                    )}
                  </div>
                  <div className="title-card">{item?.value || '0'}</div>
                </div>
                <div>
                  <KeyboardArrowRightIcon />
                </div>
              </div>
              <div className="title-bottom">
                {item?.text || item?.name || ''}
              </div>
            </div>
          </S.Cards>
        ))}
      </S.Container>
      <div style={{ marginTop: 20 }}>
        {data?.cards02 && (
          <CustomCard
            title={data?.cards02?.text}
            percentage={
              <span style={{ color: '#f64e60' }}>{data?.cards02?.value}</span>
            }
            titleFooter={
              <div>
                <div>Meta:{data?.cards02?.meta}</div>
                <div>Últimos 30 dias</div>
              </div>
            }
          />
        )}
        {data?.cards03 && (
          <CustomCard
            title={data?.cards03?.text}
            percentage={
              <span style={{ color: '#1BC5BD' }}>{data?.cards03?.value}</span>
            }
            titleFooter={
              <div>
                <div>Meta:{data?.cards03?.meta}</div>
                <div>Últimos 30 dias</div>
              </div>
            }
          />
        )}
      </div>
    </div>
  );
};

export default Cards;
