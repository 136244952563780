import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    font-size: 24px;
    color: ${theme.palette.brand.primary.natural};
    margin-top: -15px;
    margin-bottom: 5px;
    font-weight: 900;
    letter-spacing: 1.1px;
  `}
`;

export const Content = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: 3fr 2fr;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

export const TableContainer = styled.div``;

export const GraphContainer = styled.div``;
