import styled, { css } from 'styled-components';

const optionModifier = {
  hover: ({ theme }) => css`
    color: ${theme.palette.brand.secondary.natural};
  `,
  disabled: () => css`
    cursor: not-allowed;
    opacity: 0.5;
  `,
};

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 5px;
`;

export const Option = styled.button`
  ${({ theme, disabled }) => css`
    padding: 0px 10px;
    color: ${theme.palette.words.subtitle.natural};
    font-weight: 600;
    font-size: 14px;
    border: none;
    background: transparent;
    transition: color 0.2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;

    &::first-letter {
      text-transform: uppercase;
    }

    &:hover {
      ${!disabled && optionModifier.hover}
    }

    &:disabled {
      ${optionModifier.disabled}
    }

    &.onlyRead {
      cursor: default !important;
      color: ${theme.palette.words.title.natural} !important;
    }

    @media (max-width: 868px) {
      &.onlyRead {
        display: none !important;
      }
    }
  `}
`;

export const Button = styled.button`
  ${({ theme, variant, disabled }) => css`
    padding: 4px 8px;
    color: ${variant
      ? theme.palette.semantics.feedback[variant].natural
      : theme.palette.words.subtitle.natural};
    font-weight: 600;
    font-size: 14px;
    border: 1px solid
      ${variant
        ? theme.palette.semantics.feedback[variant].natural
        : theme.palette.words.subtitle.natural};
    border-radius: 4px;
    background: transparent;
    transition: color 0.2s ease-in-out;
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: center;

    &::first-letter {
      text-transform: uppercase;
    }

    &:hover {
      ${!disabled &&
      css`
        background-color: ${
          variant
            ? `${theme.palette.semantics.feedback[variant].natural}20`
            : theme.palette.system.border
        }};
        `}
    }

    &:disabled {
      ${optionModifier.disabled}
    }

    &.onlyRead {
      cursor: default !important;
      color: ${theme.palette.words.title.natural} !important;
    }

    @media (max-width: 868px) {
      &.onlyRead {
        display: none !important;
      }
    }
  `}
`;
