import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  min-width: 100%;
  padding-left: 50px;
  flex-direction: column;

  .select-header {
    display: flex;
    align-items: center;
    gap: 16px;
    margin: 16px 0;
    .title-header {
      font: normal normal bold 16px/20px Texta;
      letter-spacing: 0px;
      color: #4b5166;
      white-space: nowrap;
    }
  }
  .label-hilight {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    -ms-user-select: none;
    user-select: none;
    font: normal normal medium 14px/20px Texta;
    letter-spacing: 0px;
    color: #ffffff;
    background: #2e3240;
    border-radius: 12px;
  }
  .label-hilight > span {
    margin-left: 8px;
    font-weight: bold;
  }
  .label-hilight > span:hover {
    cursor: pointer;
    opacity: 0.2;
    border-radius: 4px;
  }
  .input-title {
    border: none;
    background: transparent;
    font-size: 32px;
    color: #4b5166;
    width: 70%;
    opacity: 0.4;
  }
  .container-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .new-list {
    display: flex;
    gap: 1rem;
    align-items: center;
    flex-direction: row;

    span {
      white-space: nowrap;
      color: ${({ theme }) =>
        theme.palette.semantics.feedback.attention.natural};
    }
  }

  .container-step {
    display: flex;
    align-items: start;
    padding: 0 20px;
    margin-top: 20px;
  }

  .label-prazo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 50px;
    text-align: center;
    color: #4b5166;
    margin-bottom: 16px;
  }

  .select-time {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;

    span {
      margin-left: 1rem;
      white-space: nowrap;
      color: ${({ theme }) =>
        theme.palette.semantics.feedback.attention.natural};
    }
  }
  .container-btn-time {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .btn-time {
    width: 50px;
    margin: 2px;
    height: 23px;
    background: #fff;
    border: 1px solid #939aab4d;
    border-radius: 4px;
    color: #4b5166;
  }

  .btn-time:hover {
  }

  .btn-time.disabled {
    opacity: 0.3;
  }
  .display-time::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  .display-time {
    appearance: textfield;
    -moz-appearance: textfield;
    width: 197px;
    height: 50px;
    border: 1px solid #939aab4d;
    border-radius: 4px;
    text-align: center;
    text-align: center;
    font-size: 32px;
    letter-spacing: 0px;
    color: #4b5166;
    opacity: 0.4;
  }
  .error {
    opacity: 0.8;
    border: 2px solid
      ${({ theme }) => theme.palette.semantics.feedback.attention.natural};
  }
`;

export const ListEmpresas = styled.div`
  width: 100%;
  display: flex;
  color: #656e8c;
  max-height: 350px;
  overflow: auto;

  .item {
    width: 100%;

    p {
      font-size: 24px;
      line-height: 48px;
    }
  }

  .icons {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 4px;
    font: normal normal 700 16px/18px Texta;
  }

  .list {
    width: 100%;
    margin: 8px 0;
  }

  .list > .icons {
    margin: 8px 0;
    font-size: 14px;
  }
`;
