import React, { useState, useEffect, useRef } from 'react';
import { Icon, IconButton, Modal, Grid, SvgIcon } from '@mui/material';
import GhostButton from 'components/Buttons/Ghost';
import SelectMultiple from 'components/Inputs/SelectMultiple';

import * as S from './styled';
import { useTheme } from 'styled-components';
import { ReactComponent as FilterIco } from 'images/icons/filter2.svg';

const DrawerFilters = ({
  open,
  handleClose,
  dataSelect,
  onSelected,
  onClean,
}) => {
  const theme = useTheme();

  const [selectedItems, setSelectedItems] = useState({});

  // Ajuste aqui: Evita a reinicialização dos estados ao reabrir sem limpar explicitamente
  useEffect(() => {
    const initialSelections = dataSelect.reduce((acc, item) => {
      // Mantém o item selecionado se já existir ou inicializa se não existir
      acc[item.name] = acc[item.name] || [];
      return acc;
    }, selectedItems || {}); // Usa selectedItems existentes como base
    setSelectedItems(initialSelections);
  }, [dataSelect]);

  const handleSelectItems = (name, value) => {
    let newValue = typeof value === 'string' ? value.split(',') : value;
    if (newValue.length === 1 && newValue[0] === '') {
      newValue = [];
    }
    const dependent = dataSelect.find(item => item.dependency === name);
    if (dependent) {
      setSelectedItems(prev => ({ ...prev, [dependent.name]: [] }));
    }

    setSelectedItems(prev => ({ ...prev, [name]: newValue }));
  };

  const handleApply = () => {
    if (onSelected instanceof Function) onSelected(selectedItems);
    handleClose();
  };

  const handleClear = () => {
    // Ajuste aqui: Define explicitamente o estado para vazio
    const clearedSelections = dataSelect.reduce((acc, item) => {
      acc[item.name] = [];
      return acc;
    }, {});
    setSelectedItems(clearedSelections);
    if (onClean instanceof Function) onClean();
  };

  return (
    <>
      <Modal open={open} onClose={handleClose} disableEscapeKeyDown={false}>
        <S.Container>
          <S.Header>
            <div>
              <SvgIcon component={FilterIco} />
              <span>Filtros</span>
            </div>

            <IconButton size="small" onClick={handleClose}>
              <Icon sx={{ color: theme.palette.words.subtitle.natural }}>
                close
              </Icon>
            </IconButton>
          </S.Header>

          <S.Main>
            <S.ClearButton onClick={handleClear}>Limpar filtros</S.ClearButton>
            {dataSelect.map(item => {
              if (item.dependency) {
                if (
                  selectedItems[item.dependency] &&
                  selectedItems[item.dependency]?.length === 1
                ) {
                  const dependent = dataSelect.find(
                    data => data.name === item.dependency,
                  );
                  return (
                    <Grid item xs={12} paddingBottom={2} key={item.name}>
                      <SelectMultiple
                        value={selectedItems[item.name]}
                        handleChange={value =>
                          handleSelectItems(item.name, value)
                        }
                        fieldName={item.name}
                        placeholder={item.placeholder}
                        data={
                          item.getData instanceof Function
                            ? item.getData(
                                selectedItems[item.dependency][0],
                                dependent.data,
                              )
                            : item.data
                        }
                        selectAll
                      />
                    </Grid>
                  );
                }
                return null;
              }
              return (
                <Grid item xs={12} paddingBottom={2} key={item.name}>
                  <SelectMultiple
                    value={selectedItems[item.name]}
                    handleChange={value => handleSelectItems(item.name, value)}
                    fieldName={item.name}
                    placeholder={item.placeholder}
                    data={item.data}
                    selectAll
                  />
                </Grid>
              );
            })}
          </S.Main>

          <S.Footer>
            <div>
              <GhostButton
                customcolor={
                  theme?.palette?.semantics?.feedback?.attention?.natural
                }
                onClick={handleClose}
                size="medium"
              >
                Cancelar
              </GhostButton>

              <GhostButton
                size="medium"
                sx={{ marginLeft: '20px' }}
                onClick={handleApply}
              >
                Aplicar
              </GhostButton>
            </div>
          </S.Footer>
        </S.Container>
      </Modal>
    </>
  );
};

export default DrawerFilters;
