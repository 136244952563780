import React, { Fragment, useEffect, useMemo } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { Grid, Tooltip, IconButton } from '@mui/material';
import GhostButton from 'components/Buttons/Ghost';
import Select from 'components/Inputs/Select';
import TextInput from 'components/Inputs/TextField';
import { Switch } from 'components/Inputs/Switch';
import AddIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import HelpIcon from '@mui/icons-material/HelpOutlineOutlined';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Loading from 'components/Loading';
import { useTheme } from 'styled-components';
import * as S from './styled';
import * as services from '../../services';
import Footer from 'components/ActionFooter';
import { Tag, TagContainer, TagLabel } from 'components/Tags';
import { DashedButton } from 'components/Buttons/Dashed';

const EditVelocidade = ({ novo }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const params = useParams();
  const { user } = useSelector(state => state.auth);
  const isProvider = user?.user?.provider;

  const selects = useSelector(state => {
    return state.selects;
  });

  const { data: _tiposVeiculos = [] } = useQuery(
    ['tipos-veiculos-telemetria'],
    () => services.getTiposVeiculos(),
    { staleTime: Infinity },
  );

  const tiposVeiculos = [
    { value: null, name: 'Todos' },
    ..._tiposVeiculos.map(tipo => ({
      name: tipo.tipo,
      value: tipo.id,
    })),
  ];

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    getValues,
    setValue,
  } = useForm({
    defaultValues: {
      id: null,
      titulo: '',
      descricao: '',
      categoria: 'VELOCIDADE',
      pista_molhada: null,
      carregado: null,
      ativo: true,
      id_tipo_veiculo: null,
      id_distribuidora: null,
      violacoes: [
        {
          pontos: '',
          intervalo_tempo: '',
          limiar: '',
          velocidade_final: 230,
          plano_de_acao: '',
          id_tipo_padrao: 1,
          ativo: true,
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: 'violacoes',
    control,
  });

  useEffect(() => {
    const subscription = watch((values, { name, type }) => {
      if (name?.includes('limiar')) {
        const index = parseInt(name.substring(10));
        if (index > 0) {
          const value = getValues(name);
          setValue(
            `violacoes[${index - 1}].velocidade_final`,
            value ? value - 1 : '',
          );
        }
      }
      if (name === 'violacoes' && values.violacoes.length > 0) {
        setValue(
          `violacoes[${values.violacoes.length - 1}].velocidade_final`,
          230,
        );
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const { data, isLoading, refetch } = useQuery(
    ['desvio-telemetria', params.id],
    () => services.getDesvio(params.id),
    {
      enabled: false,
      staleTime: Infinity,
    },
  );

  const clients = [
    { id: 0, value: null, name: isProvider ? '' : 'Todos' },
    ...selects.clients
      ?.filter(
        i => (data && data.id_distribuidora == i.id) || i.status !== 'ATIVO',
      ) // Não pode atribuir parametro para embarcadora ativa
      .map(i => ({ name: i.nome, value: i.id })),
  ] || [{ id: 0, value: null, name: 'Todos' }];

  useEffect(() => {
    if (!novo) refetch();
  }, [novo]);

  useEffect(() => {
    if (data) {
      const _data = { ...data };
      delete _data.empresa;
      delete _data.distribuidora;
      delete _data.tipo_veiculo;

      const violacoes = data.violacoes.map((violacao, index) => {
        const proximo_limiar = data.violacoes[index + 1]?.limiar;
        return {
          ...violacao,
          velocidade_final: proximo_limiar ? proximo_limiar - 1 : 230,
        };
      });

      reset({ ..._data, violacoes });
    }
  }, [data]);

  const onSubmit = async data => {
    try {
      const req = await services.registerDesvio(data);

      if (req.success) {
        toast.success(req.message);
        navigate(-1);
      } else toast.error(req.message);
    } catch (error) {
      toast.error('Falha ao salvar.');
      console.warn('Erro ao salvar.', error);
    }
  };

  const disableForm = useMemo(() => {
    const clientesAtivos = selects.clients
      .filter(i => i.status == 'ATIVO')
      .map(i => i.id);
    return (
      !isProvider &&
      data &&
      (!data.id_empresa || clientesAtivos.includes(data.id_distribuidora))
    );
  }, [data, isProvider]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit, e =>
        toast.error('Preencha todos os campos obrigatórios!'),
      )}
    >
      <S.Container>
        <S.Title>
          <h1>{`${novo ? 'Adicionar' : 'Editar'} Velocidade`}</h1>
        </S.Title>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <S.Section>Atributos</S.Section>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Controller
              rules={{
                required: { value: true, message: 'Campo obrigatório.' },
              }}
              control={control}
              name="titulo"
              render={({ field }) => (
                <TextInput
                  required
                  disabled={disableForm}
                  error={!!errors.titulo}
                  message={errors.titulo?.message}
                  label="Título"
                  placeholder="Insira um título"
                  {...field}
                />
              )}
            />
          </Grid>
          {!isProvider && (
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                name="id_distribuidora"
                render={({ field: { onChange, name, ref, value } }) => (
                  <Select
                    disabled={disableForm}
                    ref={ref}
                    name={name}
                    value={value}
                    handleChange={onChange}
                    error={!!errors.id_distribuidora}
                    data={clients}
                    label="Cliente"
                  />
                )}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={isProvider ? 12 : 6}>
            <Controller
              control={control}
              name="id_tipo_veiculo"
              render={({ field: { onChange, name, ref, value } }) => (
                <Select
                  ref={ref}
                  disabled={disableForm}
                  name={name}
                  value={value}
                  handleChange={onChange}
                  error={!!errors.id_tipo_veiculo}
                  data={tiposVeiculos}
                  label="Tipo de veículo"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TagLabel>Condições</TagLabel>
            <TagContainer>
              <Controller
                control={control}
                name="pista_molhada"
                render={({ field: { onChange, value } }) => (
                  <>
                    <Tag
                      selected={value === false}
                      onClick={() =>
                        !disableForm && onChange(value === false ? null : false)
                      }
                    >
                      Pista Seca
                    </Tag>
                    <Tag
                      selected={value === true}
                      onClick={() =>
                        !disableForm && onChange(value === true ? null : true)
                      }
                    >
                      Pista Molhada
                    </Tag>
                  </>
                )}
              />
              <Controller
                control={control}
                name="carregado"
                render={({ field: { onChange, value } }) => (
                  <>
                    <Tag
                      selected={value === false}
                      onClick={() =>
                        !disableForm && onChange(value === false ? null : false)
                      }
                    >
                      Veículo Vazio
                    </Tag>
                    <Tag
                      selected={value === true}
                      onClick={() =>
                        !disableForm && onChange(value === true ? null : true)
                      }
                    >
                      Veículo Carregado
                    </Tag>
                  </>
                )}
              />
            </TagContainer>
          </Grid>

          {fields.map((field, index) => {
            const canRemove = !field.createdAt && fields.length > 1;
            const velocidadeMinima =
              index === 0 ? 0 : getValues(`violacoes[${index - 1}].limiar`);
            return (
              <Fragment key={field.id}>
                <Grid item xs={canRemove ? 11 : 12} sm={canRemove ? 11 : 12}>
                  <S.Section>Faixa de Velocidade</S.Section>
                </Grid>
                {!disableForm && canRemove && (
                  <Grid item xs={1} sm={1}>
                    <Tooltip title="Excluir faixa">
                      <IconButton size="small" onClick={() => remove(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                )}

                <Grid item xs={3} sm={1.5}>
                  <Controller
                    name={`violacoes[${index}].ativo`}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <div
                        style={{
                          display: 'flex',
                          alignContent: 'center',
                          paddingTop: '2.5rem',
                        }}
                      >
                        <Switch
                          disabled={disableForm}
                          check={value}
                          setCheckbox={() => {
                            onChange(!value);
                          }}
                        />
                      </div>
                    )}
                  />
                </Grid>

                <Grid item xs={9} sm={4.5}>
                  <Controller
                    control={control}
                    rules={{
                      required: { value: true, message: 'Campo obrigatório.' },
                      min: {
                        value: velocidadeMinima,
                        message: 'O valor deve ser maior que a faixa anterior',
                      },
                      max: {
                        value: 230,
                        message: 'O valor deve ser inferior à 230',
                      },
                    }}
                    name={`violacoes[${index}].limiar`}
                    render={({ field }) => (
                      <TextInput
                        required
                        disabled={disableForm}
                        type="number"
                        inputProps={{ min: 0 }}
                        error={!!errors.violacoes?.[index]?.limiar}
                        message={errors.violacoes?.[index]?.limiar?.message}
                        label="Velocidade inicial"
                        placeholder="Insira a velocidade"
                        {...field}
                        onChange={({ target }) =>
                          field.onChange(
                            target.value ? parseInt(target.value) : '',
                          )
                        }
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={6} sm={6}>
                  <Controller
                    control={control}
                    name={`violacoes[${index}].velocidade_final`}
                    render={({ field }) => (
                      <Tooltip title="Valor baseado no inicial da próxima faixa.">
                        <TextInput
                          readOnly
                          disabled={disableForm}
                          label="Velocidade final"
                          placeholder=""
                          {...field}
                          onChange={() => {}}
                        />
                      </Tooltip>
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Controller
                    rules={{
                      required: { value: true, message: 'Campo obrigatório.' },
                    }}
                    control={control}
                    name={`violacoes[${index}].intervalo_tempo`}
                    render={({ field }) => (
                      <TextInput
                        required
                        disabled={disableForm}
                        type="number"
                        inputProps={{ min: 0 }}
                        error={!!errors.violacoes?.[index]?.intervalo_tempo}
                        message={
                          errors.violacoes?.[index]?.intervalo_tempo?.message
                        }
                        label={
                          <span>
                            Intervalo de tempo (min.){' '}
                            <Tooltip
                              title="Tempo de tolerância antes que o desvio comece a ser contabilizado."
                              arrow
                            >
                              <HelpIcon sx={{ fontSize: '1rem' }} />
                            </Tooltip>
                          </span>
                        }
                        placeholder="Insira o intervalo de tempo"
                        {...field}
                        onChange={({ target }) =>
                          field.onChange(
                            target.value ? parseInt(target.value) : '',
                          )
                        }
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Controller
                    rules={{
                      required: { value: true, message: 'Campo obrigatório.' },
                    }}
                    control={control}
                    name={`violacoes[${index}].pontos`}
                    render={({ field }) => (
                      <TextInput
                        required
                        disabled={disableForm}
                        type="number"
                        inputProps={{ min: 0 }}
                        error={!!errors.violacoes?.[index]?.pontos}
                        message={errors.violacoes?.[index]?.pontos?.message}
                        label="Pontuação"
                        placeholder="Insira a pontuação"
                        {...field}
                        onChange={({ target }) =>
                          field.onChange(
                            target.value ? parseInt(target.value) : '',
                          )
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TagLabel>
                    Criticidade
                    <span
                      style={{
                        fontSize: '20px',
                        fontWeight: 500,
                        color: theme.palette.semantics.feedback.attention.dark,
                      }}
                    >
                      *
                    </span>
                  </TagLabel>
                  <TagContainer>
                    <Controller
                      rules={{
                        required: {
                          value: true,
                          message: 'Campo obrigatório.',
                        },
                      }}
                      control={control}
                      name={`violacoes[${index}].criticidade`}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <Tag
                            variant="information"
                            selected={value === 'PRIMÁRIA'}
                            onClick={() => !disableForm && onChange('PRIMÁRIA')}
                          >
                            Primária
                          </Tag>
                          <Tag
                            variant="warning"
                            selected={value === 'MODERADA'}
                            onClick={() => !disableForm && onChange('MODERADA')}
                          >
                            Moderada
                          </Tag>
                          <Tag
                            variant="attention"
                            selected={value === 'GRAVE'}
                            onClick={() => !disableForm && onChange('GRAVE')}
                          >
                            Grave
                          </Tag>
                          <Tag
                            variant="attention"
                            selected={value === 'GRAVÍSSIMA'}
                            onClick={() =>
                              !disableForm && onChange('GRAVÍSSIMA')
                            }
                          >
                            Gravíssima
                          </Tag>
                        </>
                      )}
                    />
                  </TagContainer>
                  {errors.violacoes?.[index]?.criticidade?.message && (
                    <span
                      style={{
                        color: theme.palette.semantics.feedback.attention.dark,
                        margin: '10px 0',
                      }}
                    >
                      {errors.violacoes?.[index]?.criticidade?.message}
                    </span>
                  )}
                </Grid>
              </Fragment>
            );
          })}
          <Grid item xs={12} sm={12}>
            <DashedButton
              type="button"
              disabled={disableForm}
              onClick={() =>
                append({ ativo: true, plano_de_acao: '', id_tipo_padrao: 1 })
              }
            >
              <AddIcon />
              ADICIONAR FAIXA DE VELOCIDADE
            </DashedButton>
          </Grid>
        </Grid>
      </S.Container>
      <Footer
        title="Informações Atualizadas"
        actions={
          <>
            <GhostButton
              customcolor={
                theme?.palette?.semantics?.feedback?.attention?.natural
              }
              onClick={() => navigate(-1)}
            >
              Voltar
            </GhostButton>
            <GhostButton disabled={disableForm} type="submit">
              Salvar
            </GhostButton>
          </>
        }
      />
    </form>
  );
};

export default EditVelocidade;
