import styled from 'styled-components';

export const Container = styled.div`
  width: 25%;
`;

export const Quantidade = styled.span`
  font-size: 40px;
  line-height: 40px;
  font-weight: 900;
  font-family: Texta;
  color: #4b5166;
`;

export const Title = styled.div`
  font-size: 20px;
  line-height: 20px;
  font-weight: medium;
  font-family: Texta;
  color: #4b5166;
`;

export const Subtitle = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: medium;
  font-family: Texta;
  color: #939aab;
`;

export const FlexBar = styled.div`
  display: flex;
  width: 100%;
  margin: 1rem 0;
  border-radius: 4px;
  overflow: hidden;

  div {
    height: 0.75rem;
  }

  .gravissimo {
    background: #f64e60;
  }
  .grave {
    background: #ffa801;
  }
  .moderado {
    background: #0c12f2;
  }
  .primario {
    background: #1bc5bd;
  }
`;

export const Flex = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
  font-size: 14px;
  line-height: 14px;
  font-weight: medium;
  font-family: Texta;
  color: #4b5166;

  .bold {
    font-weight: 900;
  }
  .gravissimo {
    color: #f64e60;
  }
  .grave {
    color: #ffa801;
  }
  .moderado {
    color: #0c12f2;
  }
  .primario {
    color: #1bc5bd;
  }
`;
