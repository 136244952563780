// Pen
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
// Clock
import AccessAlarmOutlinedIcon from '@mui/icons-material/AccessAlarmOutlined';
// Warning
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
// Todo
import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';
// Cloud
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
// No
import DoDisturbOffOutlinedIcon from '@mui/icons-material/DoDisturbOffOutlined';
// Loading
import SyncProblemOutlinedIcon from '@mui/icons-material/SyncProblemOutlined';

import { formatNewDate } from 'utils/dates';

export const formatIcon = (status, theme) => {
  switch (status) {
    case 'INDISPONIVEL':
      return (
        <ErrorOutlineOutlinedIcon
          htmlColor={theme?.palette.semantics.feedback.attention.natural}
        />
      );
    case 'PENDENTE':
      return (
        <AccessAlarmOutlinedIcon
          htmlColor={theme?.palette.semantics.feedback.warning.natural}
        />
      );
    case 'PREENCHIDA':
      return (
        <PlaylistAddCheckOutlinedIcon
          htmlColor={theme?.palette.brand.secondary.natural}
        />
      );
    case 'ARQUIVADA':
      return (
        <CloudDownloadOutlinedIcon
          htmlColor={theme?.palette.brand.primary.light}
        />
      );
    case 'ASSINADA':
      return (
        <CreateOutlinedIcon
          htmlColor={theme?.palette.semantics.feedback.success.natural}
        />
      );
    case 'PROCESSAMENTO':
      return (
        <SyncProblemOutlinedIcon
          htmlColor={theme?.palette.words.title.natural}
        />
      );
    default:
      return (
        <DoDisturbOffOutlinedIcon
          htmlColor={theme?.palette.semantics.feedback.unknown.natural}
        />
      );
  }
};

export const formtStatus = (status, theme) => {
  switch (status) {
    case 'INDISPONIVEL':
      return {
        color: theme.palette.semantics.feedback.attention.natural,
        bg: theme.palette.semantics.feedback.attention.light,
      };
    case 'PENDENTE':
      return {
        color: theme.palette.semantics.feedback.warning.natural,
        bg: theme.palette.semantics.feedback.warning.light,
      };
    case 'PREENCHIDA':
      return {
        color: theme.palette.brand.secondary.natural,
        bg: theme.palette.brand.secondary.background,
      };
    case 'ARQUIVADA':
      return {
        color: theme.palette.brand.primary.light,
        bg: theme.palette.brand.primary.background,
      };
    case 'ASSINADA':
      return {
        color: theme.palette.semantics.feedback.success.natural,
        bg: theme.palette.semantics.feedback.success.light,
      };
    case 'PROCESSAMENTO':
      return {
        color: theme.palette.words.title.natural,
      };
  }
};

export const formatText = ev => {
  switch (ev.status) {
    case 'INDISPONIVEL':
      return (
        <div className="infos">
          <div className="week">Semana: {ev.semana || '-'}</div>
          <div className="data">
            Criada em: {formatNewDate(ev.createdAt) || '-'}
          </div>
        </div>
      );
    case 'PENDENTE':
      return (
        <div className="infos">
          <div className="week">Semana: {ev.semana || '-'}</div>
          <div className="data">
            Avaliado em: {formatNewDate(ev.data_avaliacao) || '-'}
          </div>
          <div className="data">
            Data pendente: {formatNewDate(ev.data_pendente) || '-'}
          </div>
        </div>
      );
    case 'PREENCHIDA':
      return (
        <div className="infos">
          <div className="week">Semana: {ev.semana || '-'}</div>
          <div className="data">
            Avaliado em: {formatNewDate(ev.data_avaliacao) || '-'}
          </div>
          <div className="data">
            Preenchida em: {formatNewDate(ev.data_finalizacao) || '-'}
          </div>
        </div>
      );
    case 'ARQUIVADA':
      return (
        <div className="infos">
          <div className="week">Semana: {ev.semana || '-'}</div>
          <div className="data">
            Avaliado em: {formatNewDate(ev.data_avaliacao) || '-'}
          </div>
        </div>
      );
    case 'ASSINADA':
      return (
        <div className="infos">
          <div className="week">Semana: {ev.semana || '-'}</div>
          <div className="data">
            Avaliado em: {formatNewDate(ev.data_avaliacao) || '-'}
          </div>
          <div className="data">
            Assinada em: {formatNewDate(ev.assinada_em) || '-'}
          </div>
        </div>
      );
    case 'PROCESSAMENTO':
      return (
        <div className="infos">
          <div className="week">Semana: {ev.semana || '-'}</div>
          <div className="data">
            Avaliado em: {formatNewDate(ev.data_avaliacao) || '-'}
          </div>
          <div className="data">
            Início do processamento{' '}
            {formatNewDate(ev.data_processamento) || '-'}
          </div>
        </div>
      );
    default:
  }
};

export const limitName = name => {
  if (name.length > 25) {
    return `${name.slice(0, 22)}...`;
  }
  return name;
};

export const reolutions = {
  three: [
    { margin: 1100, start: -3 },
    { margin: 1000, start: 6, end: -3 },
    { margin: 900, start: 3, end: -6 },
    { margin: 800, start: 0, end: 3 },
  ],
  two: [
    { margin: 1100, start: -2 },
    { margin: 1000, start: 8, end: -2 },
    { margin: 900, start: 6, end: -4 },
    { margin: 800, start: 4, end: -6 },
    { margin: 700, start: 2, end: -8 },
    { margin: 600, start: 0, end: 2 },
  ],
  one: [
    { margin: 1100, start: -1 },
    { margin: 1000, start: -2, end: -1 },
    { margin: 900, start: -3, end: -2 },
    { margin: 800, start: -4, end: -3 },
    { margin: 700, start: -5, end: -4 },
    { margin: 600, start: -6, end: -5 },
    { margin: 500, start: -7, end: -6 },
    { margin: 400, start: -8, end: -7 },
    { margin: 300, start: -9, end: -8 },
    { margin: 200, start: -10, end: -9 },
    { margin: 100, start: -11, end: -10 },
    { margin: 0, start: -12, end: -11 },
  ],
};

export const legends = [
  {
    text: 'Assinada',
    color: '#1BC5BD',
    Icon: <CreateOutlinedIcon htmlColor="#1BC5BD" fontSize="10px" />,
  },
  {
    text: 'Preenchida',
    color: '#ff8040',
    Icon: <PlaylistAddCheckOutlinedIcon htmlColor="#ff8040" fontSize="10px" />,
  },
  {
    text: 'Indisponível',
    color: '#f64e60',
    Icon: <ErrorOutlineOutlinedIcon htmlColor="#f64e60" fontSize="10px" />,
  },
  {
    text: 'Pendente',
    color: '#FFA801',
    Icon: <AccessAlarmOutlinedIcon htmlColor="#FFA801" fontSize="10px" />,
  },
  {
    text: 'Processamento',
    color: '#4B5166',
    Icon: <SyncProblemOutlinedIcon htmlColor="#4B5166" fontSize="10px" />,
  },
  {
    text: 'Arquivada',
    color: '#939aab',
    Icon: <CloudDownloadOutlinedIcon htmlColor="#939aab" fontSize="10px" />,
  },
  {
    text: 'Não realizada',
    color: '#CAD4E4',
    Icon: <DoDisturbOffOutlinedIcon htmlColor="#CAD4E4" fontSize="10px" />,
  },
];

export const tabs = [
  {
    value: 0,
    label: 'Direção',
  },
  {
    value: 1,
    label: 'Descarga',
  },
];
