import React, { useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Rectangle,
} from 'recharts';
import Skeleton from '@mui/material/Skeleton';
import * as S from './styled';

const CustomBarShape = props => {
  const { fill, x, y, width, height, index, orderId } = props;

  return (
    <Rectangle
      x={x}
      y={y}
      width={width}
      height={height}
      radius={[2, 2, 2, 2]}
      fill={orderId !== null ? (index === 0 ? fill : `${fill}80`) : fill}
    />
  );
};

const CustomLabel = props => {
  const { x, y, width, height, value, percentage, name, colorText } = props;
  const valueText = `${value}`;
  const percentageText = `  (${percentage}%)`;
  const valueLengthEstimate = valueText.length * 9;

  return (
    <g>
      <text
        x={x}
        y={y - 10}
        fill={colorText || '#4B5166'}
        textAnchor="start"
        dominantBaseline="middle"
      >
        {name}
      </text>
      <text
        x={x + width + 5}
        y={y + height / 2}
        fill="#4B5166"
        textAnchor="start"
        dominantBaseline="middle"
      >
        {`${value}`}
      </text>
      <text
        x={x + width + 5 + valueLengthEstimate}
        y={y + height / 2}
        fill="#939aab"
        textAnchor="start"
        dominantBaseline="middle"
      >
        {percentageText}
      </text>
    </g>
  );
};

const BarChartVertical = ({
  title = '',
  data = [],
  dataKey1,
  orderId = null,
  isLoading = false,
}) => {
  return (
    <div style={{ alignItems: 'flex-end' }}>
      <S.Title>{title}</S.Title>
      {!isLoading ? (
        <ResponsiveContainer width="100%" height={240}>
          <BarChart
            data={data.map(item => {
              return {
                ...item,
                porcentagem: parseFloat(item?.porcentagem),
              };
            })}
            layout="vertical"
            margin={{ right: 100, top: 10 }}
          >
            <XAxis dataKey="porcentagem" type="number" hide />
            <YAxis
              dataKey="period"
              type="category"
              axisLine={false}
              tickLine={false}
              tick={{ fontSize: 14 }}
              hide
            />
            <Bar
              dataKey="porcentagem"
              fill="#939aab"
              barSize={20}
              shape={<CustomBarShape orderId={orderId} />}
              label={({ x, y, width, height, index }) => (
                <CustomLabel
                  x={x}
                  index={index}
                  y={y}
                  width={width}
                  height={height}
                  value={data[index].total}
                  percentage={parseFloat(data[index].porcentagem)}
                  colorText={data[index].colorText}
                  name={data[index][dataKey1]}
                />
              )}
            />
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <>
          {[1, 2, 3, 4, 6].map((_, k) => (
            <Skeleton key={k} height={50} />
          ))}
        </>
      )}
    </div>
  );
};

export default BarChartVertical;
