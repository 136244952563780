import * as React from 'react';
import * as S from './styled';
import DefaultButton from 'components/Buttons/DefaultPopover';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Divider from '@mui/material/Divider';
import { CountDown } from './CountDown';
import { Icon } from 'components/IconsOnisys';
import { viewAlert, ignoreAlert } from '../services';
import Bar from 'components/CriticalityBar';

const renderInfractionsLayout = data => (
  <S.Content bakground={getColorContent('', '1A')}>
    <S.ContentPart>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          paddingLeft: 30,
          paddingRight: 30,
          flexWrap: 'wrap',
        }}
      >
        {data.desvios?.length &&
          (data.desvios?.length > 1 ? (
            data.desvios.map((item, index) => (
              <div className="desvio-sec">
                <span>{index + 1}</span>
                {item.titulo}
                {index < data.desvios.length - 1 && (
                  <KeyboardArrowRightIcon sx={{ color: '#4B5166' }} />
                )}
              </div>
            ))
          ) : (
            <div className="single-desvio">
              <div
                style={{
                  color: getColorContent(data.desvios?.[0].criticidade),
                }}
              >
                <Icon
                  name={`PIN_${data.desvios?.[0].criticidade}`}
                  style={{ height: '20px' }}
                />
                <span>{data.desvios?.[0].criticidade}</span>
              </div>
              <div>
                <span>{data.desvios?.[0].titulo}</span>
              </div>
            </div>
          ))}
      </div>
      <Divider sx={{ marginRight: 5, marginLeft: 5 }} />
      <div className="driver-info">
        <div className="title-driver">
          Motorista: <span>{data?.nome_motorista}</span>
        </div>
        <div style={{ marginTop: 10 }}>
          <span>{data?.data_acontecimento}</span>
        </div>
        <div>
          <span>{data?.endereco}</span>
        </div>
      </div>
    </S.ContentPart>
  </S.Content>
);

const renderDefaultLayout = data => (
  <S.Content bakground={getColorContent(data.risco, '1A')}>
    <S.DefaultContent riscoColor={getColorContent(data.risco, '')}>
      <div className="driver-info">
        <div className="title-driver">
          <span>{data?.nome_motorista || 'Motorista não identificado'}</span>
        </div>
        Nesse deslocamento, o motorista já somou{' '}
        <span>{data.pontos ?? 0} pontos</span>
      </div>
      <div>{data?.endereco}</div>
      <br />

      <div className="flex justify-between">
        <div>
          Risco da viagem
          <div className="flex risco">{data?.risco}</div>
        </div>
        <div>
          Data do acontecimento
          <div className="flex dtviagem">{data?.data_acontecimento}</div>
        </div>
        <div>
          Pts/Km
          <div className="flex dtviagem">
            {data?.pontos_km ? data?.pontos_km.toFixed(2) : 0}
          </div>
        </div>
      </div>
      <br />

      <div className="flex gap1">
        <div className="desvios">
          <span>{data.desvios?.length ?? 0}</span> Desvios cometidos
        </div>
        <div>
          <Bar
            quantities={{
              qtde_GRAVE: data?.qtde_GRAVE || 0,
              qtde_GRAVISSIMA: data?.qtde_GRAVISSIMA || 0,
              qtde_MODERADA: data?.qtde_MODERADA || 0,
              qtde_PRIMARIA: data?.qtde_PRIMARIA || 0,
            }}
          />
        </div>
      </div>
      <div className="flex left gap1">
        {data?.desvios.map(desvio => (
          <Icon name={desvio.icone ?? 'ic_velocidade'} />
        ))}
      </div>
    </S.DefaultContent>
  </S.Content>
);

const getColorContent = (option, opacity = '') => {
  switch (option) {
    case 'Altíssimo':
    case 'GRAVÍSSIMA':
      return `#f64e60${opacity}`;
    case 'GRAVE':
    case 'Alto':
      return `#FFA801${opacity}`;
    case 'MODERADA':
    case 'Perigo':
      return `#0C12F2${opacity}`;
    case 'Baixo':
      return `#1BC5BD${opacity}`;
    default:
      return `#939aab${opacity}`;
  }
};

export const CardAlert = ({ data, modal = false, onIgnore }) => {
  const handleResolve = async () => {
    viewAlert(data.id_notificacao);
    window.open(`/desvios/deslocamentos/${data.id_viagem}`);
  };

  const handleIgnore = async () => {
    ignoreAlert(data.id_notificacao);
    if (onIgnore instanceof Function) onIgnore();
  };

  return data ? (
    <S.Container
      borderColor={getColorContent(data.risco)}
      titleColor={data.is_default ? getColorContent(data.risco) : null}
    >
      <div className="title">{data.titulo}</div>

      {data.is_default
        ? renderDefaultLayout(data)
        : renderInfractionsLayout(data)}

      <div className="footer">
        <DefaultButton width={306} onClick={handleResolve}>
          RESOLVER
        </DefaultButton>
        {!!modal && (
          <div className="btn-ignore" onClick={handleIgnore}>
            Ignorar
          </div>
        )}
        {!!data.data_escalation && (
          <div className="text-footer">
            Caso não seja resolvido, esse alerta irá escalar{' '}
            <CountDown endDate={new Date(data.data_escalation)} />
          </div>
        )}
      </div>
    </S.Container>
  ) : null;
};
