import React, { useState, useEffect, useRef } from 'react';

// Components
import { Icon, IconButton, Modal, Grid, Button } from '@mui/material';
import { EditOutlined } from '@mui/icons-material';
import { toast } from 'react-toastify';
import Input from 'components/Inputs/TextField';
import SelectInput from 'components/Inputs/Select';
import GhostButton from 'components/Buttons/Ghost';
import ConfirmModal from 'components/ConfirmModal';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

// Styles
import * as S from './styled';
import { fildsRequired, fildsNotRequired } from './constans';
import { useForm, Controller } from 'react-hook-form';
import { useTheme } from 'styled-components';

// Service
import { changeDriverStatus, updateDriver, storeDriver } from '../services';
import { firestorage } from 'utils/firebase';

// utils
import { format } from 'date-fns';
import InputMask from 'react-input-mask';

const ModalAddMotorista = ({
  open,
  dataEdit,
  handleClose,
  setTriggerUpdate,
  disabledEdit = false,
}) => {
  const theme = useTheme();
  const inputFile = useRef(null);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState(false);
  const [foto, setFoto] = useState('');

  const [openConfirmUpdate, setOpenConfirmUpdate] = useState(false);
  const [openConfirmChangeStatus, setOpenConfirmChangeStatus] = useState(false);

  const updateDriverImage = async () => {
    const file = inputFile.current.files[0];
    if (file) {
      try {
        setLoading(true);
        const dh = new Date().toDateString();
        if (!file) return toast.error('Não foi possível enviar sua imagem');

        const uploadTask = firestorage
          .ref(`/motoristas/provider/${dh}_${file.name}`)
          .put(file);
        uploadTask.on(
          'state_changed',
          err => {
            console.log('Err', err);
          },
          () => {
            firestorage
              .ref(`/motoristas/provider/`)
              .child(`${dh}_${file.name}`)
              .getDownloadURL()
              .then(fireBaseUrl => {
                setFoto(fireBaseUrl);
              });
          },
        );
      } catch (error) {
        toast.error('Não foi possível enviar sua imagem');
      }
      setLoading(false);
    }
  };

  const handleDesativarAtivar = async truckId => {
    setLoading(true);

    const res = await changeDriverStatus(truckId);
    if (res && res.data?.success) {
      toast.success(res.data.message);
      setTriggerUpdate(true);
      handleClose();
    } else if (res && res.data?.message) toast.error(res.data.message);
    else
      toast.error(
        'Erro ao ativar/desativar motorista, tente mais tarde, caso persista procure o nosso time de suporte',
      );

    setLoading(false);
    setOpenConfirmChangeStatus(false);
  };

  useEffect(() => {
    reset();
    if (dataEdit) {
      Object.keys(dataEdit).forEach(campo => {
        if (
          campo === 'data_de_admissao' ||
          campo === 'data_de_nascimento' ||
          campo === 'validade_cnh' ||
          campo === 'primeira_cnh'
        ) {
          const formattedDate = dataEdit[campo]
            ? format(new Date(dataEdit[campo]), 'dd-MM-yyyy')
            : null;
          setValue(campo, formattedDate);
        } else {
          setValue(campo, dataEdit[campo]);
        }
      });
    }
  }, [dataEdit, setValue]);

  const onSubmit = async values => {
    try {
      values.foto = foto;
      if (dataEdit) {
        const res = await updateDriver(values);

        if (res.data.success) {
          toast.success('Motorista editado com sucesso!');
          handleClose();
          setTriggerUpdate(true);
        } else {
          // toast.error(
          //   res?.data?.message || 'Erro ao editar motorista, tente novamente mais tarde',
          // );
        }
      } else {
        const res = storeDriver(values);
        if (res.data.success) {
          toast.success('Motorista criado com sucesso!');
          handleClose();
          setTriggerUpdate(true);
        } else {
          // toast.error(
          //   res?.data?.message ||
          //   'Erro ao editar motorista, tente novamente mais tarde',
          // );
        }
        toast.success('Motorista cadastrado com  sucesso!');
      }
    } catch (error) {
      toast.error('Erro ao salvar motorista, tente novamente mais tarde');
      console.log(error);
    }
  };

  return (
    <>
      <Modal open={open} onClose={handleClose} disableEscapeKeyDown={false}>
        <S.Container>
          <form onSubmit={handleSubmit(onSubmit)}>
            <S.Header>
              <div style={{ alignItems: 'center' }}>
                {dataEdit ? (
                  <>
                    <EditOutlined
                      sx={{ color: theme.palette.brand.secondary.natural }}
                      fontSize="medium"
                    />
                    <h2>Editar Motorista</h2>
                  </>
                ) : (
                  <>
                    <Icon
                      sx={{ color: theme.palette.brand.secondary.natural }}
                      fontSize="medium"
                    >
                      add_circle_outline
                    </Icon>
                    <h2>Adicionar Novo Motorista</h2>
                  </>
                )}
              </div>

              <IconButton size="small" onClick={handleClose}>
                <Icon sx={{ color: theme.palette.words.subtitle.natural }}>
                  close
                </Icon>
              </IconButton>
            </S.Header>

            <S.Main>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <S.Section>INFORMAÇÕES DE PERFIL</S.Section>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <label>
                    <input
                      type="file"
                      name="foto"
                      multiple={false}
                      ref={inputFile}
                      onChange={updateDriverImage}
                      disabled={loading || disabledEdit}
                    />
                    <div className="imgArea">
                      {foto && (
                        <span className="borderImage">
                          <img src={foto} />
                        </span>
                      )}

                      {!foto && <span className="empty" />}
                    </div>
                    <div className="textArea">
                      <EditOutlinedIcon
                        htmlColor={theme.palette.words.subtitle.natural}
                      />
                      <span>Alterar Foto</span>
                    </div>
                  </label>
                </Grid>
                {fildsRequired.map((item, key) => {
                  const renderInput = fieldProps => (
                    <Input
                      {...fieldProps}
                      required={item.required}
                      label={item.label}
                      placeholder={item.placeholder}
                    />
                  );

                  if (item.type === 'input' && item.mask) {
                    return (
                      <Grid item xs={item.xs} sm={item.sm} key={key}>
                        <Controller
                          name={item.name}
                          control={control}
                          defaultValue=""
                          rules={item.rules}
                          render={({ field }) => (
                            <>
                              <InputMask
                                mask={item.mask}
                                value={field.value}
                                onChange={e => field.onChange(e.target.value)}
                              >
                                {fieldProps => renderInput(fieldProps)}
                              </InputMask>
                              {errors[item.name] && (
                                <div className="error">
                                  <p>{errors[item.name].message}</p>
                                </div>
                              )}
                            </>
                          )}
                        />
                      </Grid>
                    );
                  }
                  if (item.type === 'input') {
                    return (
                      <Grid item xs={item.xs} sm={item.sm} key={key}>
                        <Controller
                          name={item.name}
                          control={control}
                          disabled={disabledEdit}
                          defaultValue=""
                          rules={item.rules}
                          render={({ field }) => renderInput(field)}
                        />
                        {errors[item.name] && (
                          <div className="error">
                            <p>{errors[item.name].message}</p>
                          </div>
                        )}
                      </Grid>
                    );
                  }

                  if (item.type === 'select') {
                    return (
                      <Grid item xs={item.xs} sm={item.sm} key={key}>
                        <Controller
                          name={item.name}
                          control={control}
                          defaultValue=""
                          disabled={disabledEdit}
                          rules={item.rules}
                          render={({ field }) => {
                            return (
                              <>
                                <SelectInput
                                  {...field}
                                  required={item.required}
                                  label={item.label}
                                  placeholder={item.placeholder}
                                  data={item.data || []}
                                />
                                {errors[item.name] && (
                                  <div className="error">
                                    <p>{errors[item.name].message}</p>
                                  </div>
                                )}
                              </>
                            );
                          }}
                        />
                      </Grid>
                    );
                  }
                })}
                <Grid item xs={12} sm={12}>
                  <S.Section>INFORMAÇÕES EXTRAS (Não obrigatórias)</S.Section>
                </Grid>

                {fildsNotRequired.map((item, key) => {
                  const renderInput = fieldProps => (
                    <Input
                      {...fieldProps}
                      required={item.required}
                      disabled={disabledEdit}
                      label={item.label}
                      placeholder={item.placeholder}
                    />
                  );

                  if (item.type === 'input' && item.mask) {
                    return (
                      <Grid item xs={item.xs} sm={item.sm} key={key}>
                        <Controller
                          name={item.name}
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <InputMask
                              mask={item.mask}
                              disabled={disabledEdit}
                              value={field.value}
                              onChange={e => field.onChange(e.target.value)}
                            >
                              {inputProps => renderInput(inputProps)}
                            </InputMask>
                          )}
                        />
                      </Grid>
                    );
                  }
                  if (item.type === 'input') {
                    return (
                      <Grid item xs={item.xs} sm={item.sm} key={key}>
                        <Controller
                          name={item.name}
                          control={control}
                          disabled={disabledEdit}
                          defaultValue=""
                          render={({ field }) => renderInput(field)}
                        />
                      </Grid>
                    );
                  }
                  if (item.type === 'select') {
                    return (
                      <Grid item xs={item.xs} sm={item.sm} key={key}>
                        <Controller
                          name={item.name}
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <SelectInput
                              {...field}
                              required={item.required}
                              label={item.label}
                              placeholder={item.placeholder}
                              data={item.data || []}
                              disabled={disabledEdit}
                            />
                          )}
                        />
                      </Grid>
                    );
                  }
                })}
              </Grid>
            </S.Main>
            <S.Footer>
              {dataEdit ? (
                <Button
                  onClick={() => setOpenConfirmChangeStatus(true)}
                  size="medium"
                  disabled={disabledEdit}
                  variant="contained"
                  style={{
                    color:
                      dataEdit.status === 'ATIVO'
                        ? theme.palette.semantics.feedback.attention.natural
                        : theme.palette.brand.secondary.natural,
                    background: theme.palette.system.overlay,
                    border:
                      dataEdit.status === 'ATIVO'
                        ? `1px solid ${theme.palette.semantics.feedback.attention.natural}`
                        : `1px solid ${theme.palette.brand.secondary.natural}`,
                    font: 'normal normal 900 14px/18px Texta',
                    height: '40px',
                  }}
                >
                  {dataEdit.status === 'ATIVO'
                    ? 'DESATIVAR MOTORISTA'
                    : 'REATIVAR MOTORISTA'}
                </Button>
              ) : (
                <div />
              )}
              <div>
                <GhostButton
                  customcolor={
                    theme?.palette?.semantics?.feedback?.attention?.natural
                  }
                  onClick={handleClose}
                  size="medium"
                >
                  Cancelar
                </GhostButton>

                <GhostButton
                  disabled={disabledEdit}
                  type="submit"
                  size="medium"
                  sx={{ marginLeft: '20px' }}
                >
                  Salvar
                </GhostButton>
              </div>
            </S.Footer>
          </form>
        </S.Container>
      </Modal>

      {/* Confirmação de edição */}
      <ConfirmModal
        open={openConfirmUpdate}
        handleClose={() => setOpenConfirmUpdate(false)}
        title="Deseja editar motorista?"
        titleIcon={
          <EditOutlined
            sx={{ color: theme.palette.brand.secondary.natural }}
            fontSize="medium"
          />
        }
        subtitle="Atenção! Verifique todos os campos antes de confirmar a operação."
        buttonText="Confirmar"
        onClick={() => handleEditMotorista(data)}
        loading={loading}
      />

      {
        /* Confirmação de exclusão / reativação */
        dataEdit && (
          <ConfirmModal
            open={openConfirmChangeStatus}
            handleClose={() => setOpenConfirmChangeStatus(false)}
            title={
              dataEdit.status === 'ATIVO'
                ? 'Deseja desativar este motorista?'
                : 'Deseja reativar este motorista?'
            }
            titleIcon={
              <EditOutlined
                sx={{ color: theme.palette.brand.secondary.natural }}
                fontSize="medium"
              />
            }
            subtitle="Motoristas inativos não tem avaliações geradas independente de estarem rodando!"
            buttonText="Confirmar"
            onClick={() => handleDesativarAtivar(dataEdit.id)}
            loading={loading}
          />
        )
      }
    </>
  );
};

export default ModalAddMotorista;
