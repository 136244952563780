import { getResponsaveis, getCargas } from 'constants/_SERVICES/user';

// Pages
import Avaliacoes from 'pages/Avaliacoes';
import CriacaoAvaliacao from 'pages/Avaliacoes/Criacao';
import TemplateEvaluation from 'pages/Avaliacoes/Detalhe';
import AvaliacoesPdf from 'pages/Avaliacoes/Detalhe/ModelPdf';

export default {
  '/avaliacoes': {
    title: 'Avaliações',
    back: -1,
    page: <Avaliacoes />,
    // date: true,
    // pageFilterName: 'filterAvaliacoes',
    // defaults: ['filials', 'operations', 'clients'],
    // filters: [
    //   {
    //     name: 'responsavel',
    //     placeholder: 'Filtrar por Responsável',
    //     section: 'Avaliação',
    //     mode: 'multiple',
    //     data: async () => getResponsaveis(),
    //   },
    //   {
    //     name: 'carga',
    //     placeholder: 'Filtrar por Carga',
    //     section: 'Avaliação',
    //     mode: 'multiple',
    //     data: async () => getCargas(),
    //   },
    // ],
  },

  '/avaliacoes/:id': {
    back: -1,
    title: 'Avaliação Direção',
    id: true,
    status: true,
    defaults: [],
    page: <TemplateEvaluation />,
  },

  '/avaliacoes/criar/:id': {
    back: -1,
    title: 'Criar Avaliação Direção',
    defaults: [],
    page: <CriacaoAvaliacao />,
  },

  '/avaliacoes/pdf/:id': {
    back: -1,
    title: 'Avaliação Direção',
    id: true,
    defaults: [],
    page: <AvaliacoesPdf />,
  },
};
