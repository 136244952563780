import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin: 20px;
  .see-more {
    text-decoration: underline;
    color: #ff8040;
    text-align: center;
    cursor: pointer;
    font-size: 16px;
    font-style: mendium;
    letter-spacing: 0px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
  .see-more:hover {
    color: #d1d1d1;
  }
  .see-more:active {
    color: #d1d1d1;
  }
`;

export const ContainerItem = styled.div`
  display: flex;
  background: #f9fbfd;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 16px;
  padding-right: 16px;
  padding-left: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
  border: 1px solid #939aab4d;
  overflow: hidden;
  justify-content: center;
  flex-direction: column;
  .limit-chats {
    text-overflow: ellipsis;
  }
  .title-empresa {
    text-align: left;
    font: normal normal medium 14px/20px Texta;
    letter-spacing: 0px;
    color: #4b5166;
  }
  .torre-driver-name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    font: normal normal 900 20px/20px Texta;
    letter-spacing: 0px;
    color: #4b5166;
    margin-bottom: 10px;
  }
  .points-torre-driver {
    text-align: left;
    font-size: 14px;
    font-family: Texta;
    font-style: medium;
    font-weight: 500;
    letter-spacing: 0px;
    color: #4b5166;
  }
  .name {
    text-align: left;
    font-size: 18px;
    font-weight: 900;
    color: #4b5166;
  }
  .name > span {
    font-size: 14px;
    font-weight: 400;
    margin-left: 4px;
  }
  .divider {
    min-width: 100%;
    border-bottom: 1px solid #939aab;
    opacity: 0.3;
  }
  .container-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 3px;
    padding-bottom: 3px;
  }
  .criticidade {
    font-weight: 900;
    font-size: 14px;
  }
  .primary {
    color: #1bc5bd;
  }
  .moderado {
    color: #0c12f2;
  }
  .grave {
    color: #f5a623;
  }
  .gravissimo {
    color: #f64e60;
  }
  .primary > span,
  .moderado > span,
  .grave > span,
  .gravissimo > span {
    color: #4b5166;
    font-weight: 400;
  }
  .desvios {
    display: flex;
    justify-content: center;
    text-align: right;
    text-decoration: underline;
    font-size: 16px;
    font-style: mendium;
    letter-spacing: 0px;
    color: #939aab;
    cursor: pointer;
  }
  .desvios:hover {
    color: #1bc5bd;
  }
  .desvios:active {
    color: #0c12f2;
  }
`;

export const ContainerList = styled.div`
  overflow-y: scroll;
  height: 500px;
  padding-right: 16px;
`;

export const Title = styled.div`
  text-align: left;
  font-size: 24px;
  font-weight: 900;
  letter-spacing: 0px;
  color: #4b5166;
  margin-bottom: 16px;
`;
