/* eslint-disable import/order */

// Styles
import * as S from './styled';
import colors from 'styles/colors';
import { useTheme } from 'styled-components';

// React
import { useState } from 'react';

// Components
import GhostButton from 'components/Buttons/Ghost';
import { EvaluationView as Card } from '../../EvaluationView';

// Material UI
import OfflineBoltOutlinedIcon from '@mui/icons-material/OfflineBoltOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

export const EvaluationView = ({ item: questions, is_quiz }) => {
  const handleNext = () => {
    if (currentQuestion < questions?.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    }
  };

  const theme = useTheme();

  const handlePrev = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  const [currentQuestion, setCurrentQuestion] = useState(0);
  return (
    <S.Container>
      {questions && (
        <>
          <S.Title>
            {is_quiz ? (
              <OfflineBoltOutlinedIcon htmlColor={colors.greenMain} />
            ) : (
              <CheckCircleOutlineOutlinedIcon htmlColor={colors.greenMain} />
            )}
            <div className="type">
              {is_quiz ? 'Quiz' : 'Teste Online'} #
              {(currentQuestion + 1).toString().padStart(2, '0')}
            </div>
          </S.Title>
          <Card item={questions[currentQuestion]} noBorder />

          <S.Footer>
            <GhostButton
              children="Anterior"
              customcolor={
                theme?.palette?.semantics?.feedback?.attention?.natural
              }
              onClick={() => handlePrev()}
              disabled={currentQuestion === 0}
            />
            <GhostButton
              children="Próxima"
              onClick={() => handleNext()}
              disabled={currentQuestion === questions?.length - 1}
            />
          </S.Footer>
        </>
      )}
    </S.Container>
  );
};
