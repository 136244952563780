// React
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

// Components
import GhostButton from 'components/Buttons/Ghost';
import Select from 'components/Inputs/Select';
import SelectList from 'components/Inputs/SelectList';

// Components MUI
import List from '@mui/material/List';
import { Popover } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import ListItemText from '@mui/material/ListItemText';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ListItemButton from '@mui/material/ListItemButton';
import {
  getTiposVeiculos,
  getTiposPadrao,
} from 'pages/TelemetriaConfiguracoes/services';
import { requestRodovias } from '../services';
import * as S from './styled';

// Utils

const Filters = ({ id, open, onClose, anchorEl, filter, setFilter }) => {
  const [prevFilter, setPrevFilter] = useState({ ...filter });
  const [filiaisFilter, setFiliaisFilter] = useState([]);
  const [selectedEmpresas, setSelectedEmpresas] = useState(false);
  const [openCollapseDesvio, setOpenCollapseDesvio] = React.useState(false);
  const [openCollapseVeiculo, setOpenCollapseVeiculo] = React.useState(false);
  const [openCollapseCondicoes, setOpenCollapseCondicoes] =
    React.useState(false);
  const { user } = useSelector(state => state.auth.user);
  const userLevel = user?.nivel;
  const isProvider = user?.provider;

  const selects = useSelector(state => state.selects);

  useEffect(() => {
    setPrevFilter({ ...filter });
  }, [filter]);

  const { data: _tiposVeiculos = [] } = useQuery(
    ['tipos-veiculos-telemetria'],
    () => getTiposVeiculos(),
    { staleTime: Infinity },
  );

  const { data: tiposPadrao = [] } = useQuery(
    ['desvios-padrao-telemetria'],
    () => getTiposPadrao(),
    { staleTime: Infinity },
  );

  const { data: _rodovias = [] } = useQuery(
    ['rodovias'],
    () => requestRodovias(),
    { refetchOnWindowFocus: false },
  );

  const tiposVeiculos = _tiposVeiculos.map(tipo => ({
    value: tipo.tipo,
    id: tipo.id,
  }));

  const tiposDesvios = tiposPadrao.map(tipo => ({
    value: tipo.tipo,
    id: tipo.id,
  }));

  const motoristas = selects.drivers
    ?.filter(motorista => motorista.status === 'ATIVO')
    .map(motorista => ({
      value: motorista.nome,
      id: motorista.id,
    }));

  const rodovias = _rodovias.map(rodovia => ({
    value: rodovia,
    id: `${rodovia}`.replaceAll(',', ';;;'),
  }));

  const clientes =
    selects.clients?.map(i => {
      return { value: i.nome, id: i.id };
    }) || [];

  const filiais =
    selects.filials?.map(i => {
      return { value: i.nome, id: i.id };
    }) || [];

  useEffect(() => {
    if (isProvider) {
      if (prevFilter?.empresas) {
        const count = prevFilter?.empresas.split(',').length;

        if (count === 1) {
          setSelectedEmpresas(true);
          const empresa = prevFilter?.empresas;
          const newFiliais = selects.empresas
            .find(company => company.id === Number(empresa))
            .filiais?.map(i => ({ id: i.id, value: i.nome }));
          setFiliaisFilter(newFiliais);
        } else {
          setSelectedEmpresas(false);
          setPrevFilter(_prevFilter => ({ ..._prevFilter, filiais: '' }));
        }
      } else {
        setSelectedEmpresas(false);
        setPrevFilter(_prevFilter => ({ ..._prevFilter, filiais: '' }));
      }
    }
  }, [prevFilter?.empresas, selects.empresas]);

  const handleChange = e => {
    setPrevFilter(_prevFilter => {
      return { ..._prevFilter, [e.target.name]: e.target.value };
    });
  };

  const handleChangeMultiple = (e, key) => {
    setPrevFilter(_prevFilter => {
      return { ..._prevFilter, [key]: e };
    });
  };

  const aplyFilter = () => {
    const _prevFilter = { ...prevFilter };
    Object.keys(_prevFilter).forEach(key => {
      if (!_prevFilter[key]) delete _prevFilter[key];
    });
    setFilter({ ..._prevFilter });
    onClose();
  };

  const reset = () => {
    setPrevFilter({ ...filter });
  };

  // Limpa TODOS os valores do estado e foca no botao de aplicar
  const handleClear = () => {
    setPrevFilter({});
    const buttonApply = document.getElementById('aplicar');
    buttonApply?.focus();
  };

  const toggleCollapse = collapseId => {
    setOpenCollapseDesvio(state => !state && collapseId === 'desvio');
    setOpenCollapseVeiculo(state => !state && collapseId === 'veiculo');
    setOpenCollapseCondicoes(state => !state && collapseId === 'condicoes');
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <S.Main>
        <S.Header>
          <h2>Filtros</h2>
          <S.ClearButton onClick={handleClear}>Limpar filtros</S.ClearButton>
        </S.Header>

        <S.SelectsContainer>
          <List>
            <S.CollapseContent>
              {isProvider ? (
                <>
                  <SelectList
                    value={prevFilter?.empresas}
                    handleChange={handleChangeMultiple}
                    fieldName="empresas"
                    label="Filtrar por Empresas"
                    data={selects.empresas}
                    selectAll
                  />
                  {selectedEmpresas && (
                    <SelectList
                      value={prevFilter?.filiais}
                      handleChange={handleChangeMultiple}
                      fieldName="filiais"
                      label="Filtrar por Filial"
                      data={filiaisFilter}
                      selectAll
                    />
                  )}
                </>
              ) : (
                <>
                  <SelectList
                    value={prevFilter?.filiais}
                    handleChange={handleChangeMultiple}
                    fieldName="filiais"
                    label="Filtrar por Filial"
                    disabled={userLevel > 1}
                    data={filiais}
                    selectAll
                  />
                  <SelectList
                    value={prevFilter?.client}
                    handleChange={handleChangeMultiple}
                    fieldName="client"
                    label="Filtrar por Cliente"
                    data={clientes}
                    selectAll
                  />
                </>
              )}
            </S.CollapseContent>
            {!isProvider ? (
              <>
                <ListItemButton onClick={() => toggleCollapse('desvio')}>
                  {openCollapseDesvio ? <ExpandLess /> : <ExpandMore />}
                  <ListItemText primary="Filtrar por Motorista e Desvio" />
                </ListItemButton>
                <Collapse in={openCollapseDesvio} timeout="auto" unmountOnExit>
                  <S.CollapseContent>
                    <SelectList
                      value={prevFilter?.idMotorista}
                      handleChange={handleChangeMultiple}
                      fieldName="idMotorista"
                      label="Filtrar por Motorista"
                      data={motoristas}
                      selectAll
                    />
                    <SelectList
                      data={tiposDesvios}
                      value={prevFilter?.idDesvio}
                      label="Filtrar por Tipo de Desvio"
                      fieldName="idDesvio"
                      handleChange={handleChangeMultiple}
                      selectAll
                    />
                  </S.CollapseContent>
                </Collapse>
              </>
            ) : (
              <S.CollapseContent style={{ marginTop: '1rem' }}>
                <SelectList
                  data={tiposDesvios}
                  value={prevFilter?.idDesvio}
                  label="Filtrar por Tipo de Desvio"
                  fieldName="idDesvio"
                  handleChange={handleChangeMultiple}
                  selectAll
                />
              </S.CollapseContent>
            )}
            <>
              <ListItemButton onClick={() => toggleCollapse('veiculo')}>
                {openCollapseVeiculo ? <ExpandLess /> : <ExpandMore />}
                <ListItemText primary="Filtrar por Rodovia e Veículo" />
              </ListItemButton>
              <Collapse in={openCollapseVeiculo} timeout="auto" unmountOnExit>
                <S.CollapseContent>
                  <SelectList
                    data={rodovias}
                    value={prevFilter?.rodovia}
                    label="Filtrar por Rodovia"
                    fieldName="rodovia"
                    handleChange={handleChangeMultiple}
                    selectAll
                  />
                  <SelectList
                    value={prevFilter?.idVeiculo}
                    handleChange={handleChangeMultiple}
                    fieldName="idVeiculo"
                    label="Filtrar por Tipo de Veiculo"
                    data={tiposVeiculos}
                    selectAll
                  />
                </S.CollapseContent>
              </Collapse>
            </>

            <ListItemButton onClick={() => toggleCollapse('condicoes')}>
              {openCollapseCondicoes ? <ExpandLess /> : <ExpandMore />}
              <ListItemText primary="Filtrar por Condições" />
            </ListItemButton>
            <Collapse in={openCollapseCondicoes} timeout="auto" unmountOnExit>
              <S.CollapseContent>
                <Select
                  value={prevFilter?.pista_molhada ?? ''}
                  handleChange={handleChange}
                  name="pista_molhada"
                  placeholder="Filtrar por Condições da Pista"
                  clearLabel="Limpar filtro"
                  data={[
                    { value: '1', name: 'Pista Molhada' },
                    { value: '0', name: 'Pista Seca' },
                  ]}
                />
                <Select
                  value={prevFilter?.carregado ?? ''}
                  handleChange={handleChange}
                  name="carregado"
                  placeholder="Filtrar por Condições de Carregamento"
                  clearLabel="Limpar filtro"
                  data={[
                    { value: '1', name: 'Carregado' },
                    { value: '0', name: 'Vazio' },
                    { value: '-1', name: 'Não Informado' },
                  ]}
                />
              </S.CollapseContent>
            </Collapse>
          </List>
        </S.SelectsContainer>
      </S.Main>
      <S.Footer>
        <GhostButton
          onClick={() => {
            reset();
            onClose();
          }}
          size="medium"
        >
          Cancelar
        </GhostButton>

        <GhostButton
          onClick={aplyFilter}
          size="medium"
          sx={{ marginLeft: '20px' }}
          id="aplicar"
        >
          Aplicar
        </GhostButton>
      </S.Footer>
    </Popover>
  );
};

export default Filters;
