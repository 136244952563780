import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const CardContainer = styled.div`
  display: flex;
  border: 2px solid #939aab4d;
  background: #ffffff;
  align-items: center;
  padding: 1.5rem;
  margin-top: 1rem;
  width: 100%;
`;

export const CardSubContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  width: 100%;
`;

export const CardTitle = styled.span`
  font-size: 20px;
  font-weight: 900;
  color: #4b5166;
`;

export const CardContent = styled.span`
  font-size: 18px;
  color: #656e8c;
  margin-right: 6.625rem;
  margin-top: 0.5rem;
`;

export const Paragraph = styled.p`
  ${({ theme, ...props }) => css`
    font-size: ${props.fontsize ? props.fontsize : '16px'};
    color: ${props.color ? props.color : theme.palette.brand.primary.light};
    font-weight: ${props.fontweight ? props.fontweight : 400};
    min-width: ${props.minwidth};
  `}
`;

export const ColDriver = styled.div`
  display: flex;
  flex-direction: column;
  text-transform: capitalize;
`;
export const Label = styled.label`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 380px;
    width: 100%;
    color: ${theme.palette.words.subtitle.natural};
    font-size: 14px;
    font-weight: 600;
    opacity: 0.9;
    position: relative;
    margin-top: 20px;

    .error {
      border: 2px solid ${theme.palette.semantics.feedback.attention.natural};
    }

    .error::placeholder {
      color: ${theme.palette.semantics.feedback.attention.natural};
    }
  `}
`;

export const TextField = styled.input`
  ${({ theme }) => css`
    height: 50px;
    border-radius: 5px;
    border: 2px solid ${theme.palette.brand.primary.light};
    padding: 5px 15px;
    margin-top: 8px;
    font-size: 16px;

    &:disabled {
      border: none;
    }

    &::placeholder {
      color: ${theme.palette.words.text.light};
    }
  `}
`;
