import { useQueries, useQueryClient } from 'react-query';
import {
  getResponsaveis,
  getTiposDesvio,
  getCriticidade,
  getTecnologias,
  getClients,
  getVeiculos,
  getCargas,
  getStatusItem,
  getPropriedade,
  getDepartamento,
  getStatus,
  getTurmas,
  getEmpresasLayout,
  getExecutores,
  getAprovadores,
  getBases,
  getTamanhos,
  getStates,
} from 'constants/_SERVICES/user';
import {
  getDesviosTiposPadrao,
  getResponsaveis as getResponsaveisProvider,
  getExecutores as getExecutoresProvider,
} from 'constants/_SERVICES/provider';
import { usePlans } from 'hooks/usePlans';
import { useSelector } from 'react-redux';

export const useFetchMultipleWithCache = () => {
  const { isProvider } = usePlans();

  const { filials } = useSelector(state => {
    return state.selects;
  });

  const queryClient = useQueryClient();

  const queriesConfig = [
    {
      queryKey: 'responsaveis',
      queryFn: isProvider ? getResponsaveisProvider : getResponsaveis,
      staleTime: 1000 * 60 * 60,
      cacheTime: 1000 * 60 * 60 * 24,
    },
    {
      queryKey: 'clients',
      queryFn: getClients,
      staleTime: 1000 * 60 * 60,
      cacheTime: 1000 * 60 * 60 * 24,
    },
    {
      queryKey: 'tecnologias',
      queryFn: getTecnologias,
      staleTime: 1000 * 60 * 60,
      cacheTime: 1000 * 60 * 60 * 24,
    },
    {
      queryKey: 'criticidade',
      queryFn: getCriticidade,
      staleTime: 1000 * 60 * 60,
      cacheTime: 1000 * 60 * 60 * 24,
    },
    {
      queryKey: 'tiposDesvios',
      queryFn: getTiposDesvio,
      staleTime: 1000 * 60 * 60,
      cacheTime: 1000 * 60 * 60 * 24,
    },
    {
      queryKey: 'veiculos',
      queryFn: getVeiculos,
      staleTime: 1000 * 60 * 60,
      cacheTime: 1000 * 60 * 60 * 24,
    },
    {
      queryKey: 'desviosTiposPadrao',
      queryFn: getDesviosTiposPadrao,
      staleTime: 1000 * 60 * 60,
      cacheTime: 1000 * 60 * 60 * 24,
    },
    {
      queryKey: 'cargas',
      queryFn: getCargas,
      staleTime: 1000 * 60 * 60,
      cacheTime: 1000 * 60 * 60 * 24,
    },

    {
      queryKey: 'status',
      queryFn: getStatusItem,
      staleTime: 1000 * 60 * 60,
      cacheTime: 1000 * 60 * 60 * 24,
    },
    {
      queryKey: 'propriedade',
      queryFn: getPropriedade,
      staleTime: 1000 * 60 * 60,
      cacheTime: 1000 * 60 * 60 * 24,
    },
    {
      queryKey: 'departamento',
      queryFn: getDepartamento,
      staleTime: 1000 * 60 * 60,
      cacheTime: 1000 * 60 * 60 * 24,
    },
    {
      queryKey: 'turmaStatus',
      queryFn: getStatus,
      staleTime: 1000 * 60 * 60,
      cacheTime: 1000 * 60 * 60 * 24,
    },
    {
      queryKey: 'turmas',
      queryFn: getTurmas,
      staleTime: 1000 * 60 * 60,
      cacheTime: 1000 * 60 * 60 * 24,
    },
    {
      queryKey: 'layoutEmpresas',
      queryFn: getEmpresasLayout,
      staleTime: 1000 * 60 * 60,
      cacheTime: 1000 * 60 * 60 * 24,
    },
    {
      queryKey: 'layoutExecutores',
      queryFn: getExecutores,
      staleTime: 1000 * 60 * 60,
      cacheTime: 1000 * 60 * 60 * 24,
    },
    {
      queryKey: 'layoutExecutores',
      queryFn: getExecutoresProvider,
      staleTime: 1000 * 60 * 60,
      cacheTime: 1000 * 60 * 60 * 24,
    },
    {
      queryKey: 'layoutAprovadores',
      queryFn: getAprovadores,
      staleTime: 1000 * 60 * 60,
      cacheTime: 1000 * 60 * 60 * 24,
    },
    {
      queryKey: 'layoutBases',
      queryFn: getBases,
      staleTime: 1000 * 60 * 60,
      cacheTime: 1000 * 60 * 60 * 24,
    },
    {
      queryKey: 'layoutTamanhos',
      queryFn: getTamanhos,
      staleTime: 1000 * 60 * 60,
      cacheTime: 1000 * 60 * 60 * 24,
    },
    {
      queryKey: 'layoutEstados',
      queryFn: getStates,
      staleTime: 1000 * 60 * 60,
      cacheTime: 1000 * 60 * 60 * 24,
    },
  ];

  const queries = useQueries(queriesConfig);

  const handleInvalidateCache = (_keys = []) => {
    // Invalida todas as queries
    const keys = _keys.length
      ? _keys
      : queriesConfig.map(item => item.queryKey);
    keys.forEach(key => queryClient.invalidateQueries(key));
  };

  const handleRemoveCache = (_keys = []) => {
    // Remove completamente as queries do cache (os dados são excluídos)
    const keys = _keys.length
      ? _keys
      : queriesConfig.map(item => item.queryKey);
    keys.forEach(key => queryClient.removeQueries(key));
  };

  return {
    responsaveis: queries[0],
    clients: queries[1],
    tecnologias: queries[2],
    criticidade: queries[3],
    tiposDesvios: queries[4],
    veiculos: queries[5],
    desviosTiposPadrao: queries[6],
    cargas: queries[7],
    filiais: filials.map(f => ({ value: f.id, label: f.nome })),
    motoristasTipos: [
      { label: 'Agregados', value: 'AGREGADOS' },
      { label: 'Próprios', value: 'PROPRIOS' },
      { label: 'Não Informado', value: '-1' },
    ],
    operacao: [
      { label: 'Primária', value: 1 },
      { label: 'Secundária', value: 2 },
      { label: 'Açucar', value: 3 },
    ],
    origin: [
      { label: 'Manual', value: 'MANUAL' },
      { label: 'Velocidade', value: 'VELOCIDADE' },
      { label: 'Cerca', value: 'CERCA' },
      { label: 'Telemetria', value: 'TELEMETRIA' },
      { label: 'Fadiga', value: 'FADIGA' },
      { label: 'Cabine', value: 'CABINE' },
      { label: 'Externa', value: 'EXTERNA' },
      { label: 'Jornada', value: 'JORNADA' },
    ],
    status: queries[8],
    propriedade: queries[9],
    departamento: queries[10],
    getTurmasStatus: queries[11],
    getTurmas: queries[12],
    getEmpresasLayout: queries[13],
    getExecutores: queries[14],
    getExecutoresProvider: queries[15],
    getAprovadores: queries[16],
    getBases: queries[17],
    getTamanhos: queries[18],
    getEstados: queries[19],
    handleInvalidateCache,
    handleRemoveCache,
  };
};
