// Styles
import { useTheme } from 'styled-components';

// React
import { useQuery } from 'react-query';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setFilter } from 'store/modules/filterMotoristaCronograma/actions';

// Components
import Tabs from 'components/Tabs';
import Icon from 'components/Icons';
import Loading from 'components/Loading';
import Select from 'components/Inputs/Select';
import DefaultButtonPopover from 'components/Buttons/DefaultPopover';
import FiltersGlobal from 'components/FiltersGlobal';

// Components MUI
import SearchIcon from '@mui/icons-material/Search';
import { AddCircleOutline } from '@mui/icons-material';
import { Popover, Tooltip, Stack, Divider, Grid } from '@mui/material';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import { trackEvent } from 'utils/mixpanel';
import ModalAddMotorista from '../Motoristas/ModalAddMotorista';
import * as S from './styled';

// Utils
import { useFetchMultipleWithCache } from 'hooks/fetchFilters';
import * as services from './services';
import {
  formatIcon,
  formatText,
  formtStatus,
  limitName,
  reolutions,
  legends,
  tabs,
} from './actions';
import GhostButton from 'components/Buttons/Ghost';

// Timer
let timer = null;
let timerResize = null;

const MotoristasCronogramas = () => {
  const theme = useTheme();
  const user = useSelector(state => {
    return state.auth?.user?.user;
  });

  // Slide States
  const heightLine = '70px';
  const [months, setMonths] = useState([]);
  const [margin, setMargin] = useState(1100);
  const [minMargin, setMinMargin] = useState(null);
  const [currentMonths, setCurrentMonths] = useState([]);

  // Table States
  const [data, setData] = useState([]);
  const [input, setInput] = useState('');
  const [search, setSearch] = useState('');
  const img = require(`images/empty/frota.png`);

  // Popup States
  const [anchorTask, setAnchorTask] = useState(false);
  const [clickedItem, setClickedItem] = useState(null);
  const [currentEvaluantions, setCurrentEvaluantions] = useState(null);

  // Modal States
  const [openAddMotorista, setOpenAddMotorista] = useState(false);
  const [triggerUpdate, setTriggerUpdate] = useState(false);

  // Redux
  const dispatch = useDispatch();
  const filter = useSelector(state => state.filterMotoristaCronograma);
  // const filterGl = useSelector(state => state.filter);
  const filterGl = useSelector(state => {
    return state?.filter?.filters?.cronograma;
  });

  // Navigate
  const navigate = useNavigate();

  // Selects dos filtros
  const { filiais, operacao, clients, motoristasTipos } =
    useFetchMultipleWithCache();

  // ----------------------------------- REQUEST  ----------------------------------//
  const {
    data: res,
    isError,
    refetch,
    isFetching: loading,
  } = useQuery(
    ['driver-schedule-direction', 1],
    () => services.getDriversList({ ...filter, ...filterGl, search }),
    { refetchOnWindowFocus: false },
  );

  // Update Object
  useEffect(() => {
    if (loading && isError) {
      return null;
    }

    if (res) {
      setData(res.data);

      let mts = res?.data[0]?.cronograma?.map(
        item => `${item.mes} - ${item.ano}`,
      );
      setMonths(mts);

      dispatch(
        setFilter({
          ...filter,
          pageCount: res.total,
        }),
      );
    }
  }, [res]);

  // Update page
  useEffect(() => {
    refetch();
  }, [
    filter.pageIndex,
    filter.pageSize,
    filter.orderBy,
    search,
    filter.currentTab,
    filterGl,
  ]);

  // Update by Modal (after add new driver)
  useEffect(() => {
    if (triggerUpdate) {
      refetch();
    }
  }, [triggerUpdate]);

  // --------------------------------- PAGE EVENTS ----------------------------------//
  useEffect(() => {
    window.onresize = function () {
      clearTimeout(timerResize);
      timerResize = setTimeout(() => {
        setMargin(1100);
        calcTitle();
      }, 1000);
    };

    return function cleanUp() {
      window.onresize = function () {
        return null;
      };
    };
  }, []);

  useEffect(() => {
    calcTitle();
  }, [margin, months]);

  const calcTitle = () => {
    let mts = data[0]?.cronograma?.map(item => item.mes);

    let twoItems = window.innerWidth <= 1325;
    let oneItem = window.innerWidth <= 920;
    let currentResolution = null;

    if (oneItem) {
      currentResolution = reolutions.one;
    } else if (twoItems) {
      currentResolution = reolutions.two;
    } else {
      currentResolution = reolutions.three;
    }

    for (let i in currentResolution) {
      let cr = currentResolution[i];
      if (cr.margin == margin) {
        setCurrentMonths(mts?.slice(cr.start, cr?.end));
        return;
      }
    }
  };

  // ------------------------------- SLIDER CONTROLL ----------------------------------//
  const nextSlide = () => {
    let twoItems = window.innerWidth <= 1325;
    let oneItem = window.innerWidth <= 920;
    let currentResolution = null;

    if (oneItem) {
      currentResolution = reolutions.one;
    } else if (twoItems) {
      currentResolution = reolutions.two;
    } else {
      currentResolution = reolutions.three;
    }

    let max = currentResolution[0].margin;
    let min = currentResolution[currentResolution.length - 1].margin;
    setMinMargin(min);

    if (margin < max) {
      setMargin(margin + 100);
    }
  };

  const previousSlide = () => {
    let twoItems = window.innerWidth <= 1325;
    let oneItem = window.innerWidth <= 920;
    let currentResolution = null;

    if (oneItem) {
      currentResolution = reolutions.one;
    } else if (twoItems) {
      currentResolution = reolutions.two;
    } else {
      currentResolution = reolutions.three;
    }

    let min = currentResolution[currentResolution.length - 1].margin;
    setMinMargin(min);

    if (margin >= min) {
      setMargin(margin - 100);
    }
  };

  // ------------------------------- MONITORING EMPTY BOX -----------------------------//
  const lessThanFive = evaluations => {
    let completed = evaluations.length;
    let rest = 5 - completed;
    let itemsMap = [];

    if (rest > 0) {
      for (let i = 0; i < rest; i++) {
        itemsMap.push('*');
      }
    }

    return itemsMap;
  };

  // ----------------------------- OPEN NEW TAB -------------------------------//
  const openNew = (link, id) => {
    window.open(`${link}/${id}`);
  };

  // ----------------------------- FOOTER && FILTERS TABLE CONTROLL ---------------------//
  const handlePageChange = (e, tab) => {
    dispatch(
      setFilter({
        ...filter,
        currentTab: tab,
      }),
    );
  };

  const goToPage = page => {
    dispatch(
      setFilter({
        ...filter,
        pageIndex: +page,
      }),
    );
  };

  const nextPage = () => {
    let total = filter.pageCount / filter.pageSize;
    let page = +filter.pageIndex + 1;
    if (page < total) {
      dispatch(
        setFilter({
          ...filter,
          pageIndex: page,
        }),
      );
    }
  };

  const previousPage = () => {
    let page = +filter.pageIndex - 1;
    if (+filter.pageIndex > 0) {
      dispatch(
        setFilter({
          ...filter,
          pageIndex: page,
        }),
      );
    }
  };

  const togglePageSize = size => {
    dispatch(
      setFilter({
        ...filter,
        pageSize: +size,
      }),
    );
  };

  const toggleOrderBy = () => {
    dispatch(
      setFilter({
        ...filter,
        orderBy: filter.orderBy === 'ASC' ? 'DESC' : 'ASC',
        pageIndex: 0,
      }),
    );
  };

  // Automatic search after 2s
  useEffect(() => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      goToPage(0);
      setSearch(input);
      if (input.length > 3) searchTrackEvent();
    }, 2000);
  }, [input]);

  // Directly search when submit is called
  const directlySendSearch = e => {
    e.preventDefault();
    clearTimeout(timer);
    goToPage(0);
    setSearch(input);
    if (input.length > 3) searchTrackEvent();
  };

  const searchTrackEvent = () => {
    if (input.length > 3) {
      trackEvent(
        user,
        `Busca Cronograma de Motoristas ${
          filter.currentTab === 0 ? `Direção` : `Descarga`
        } `,
        null,
        input,
      );
    }
  };

  const filterList = [
    {
      filterName: 'filial',
      label: 'Filiais',
      options: filiais || [],
    },
    // {
    //   filterName: 'filial_veiculo',
    //   label: 'Filial Veículo',
    //   options: filiais || [],
    // },
    {
      filterName: 'operation',
      label: 'Operação',
      options: operacao,
    },
    {
      filterName: 'client',
      label: 'Clientes',
      options: clients.data || [],
    },
    {
      filterName: 'motoristasTipos',
      label: 'Tipo de motorista',
      options: motoristasTipos,
    },
  ];

  // ----------------------------- RENDER POPUP -------------------------------//

  const renderFilters = () => {
    return (
      <S.FilterContainer>
        <FiltersGlobal
          // hideRefleshButton
          // showHours
          persistDate
          hideExportButton
          hideDate
          refetch={refetch}
          isFetching={loading}
          // handleFilters={props =>
          //   setQuery(prev => {
          //     return {
          //       ...prev,
          //       ...props,
          //     };
          //   })
          // }
          // handleExport={() => { setOpenExcelModal(true);
          //   trackEvent(user, 'Exportar Planilha Avaliações'); }}
          customComponent={
            <div>
              <DefaultButtonPopover
                startIcon={<AddCircleOutline />}
                size="medium"
                popoverComponent={
                  <S.AdicinarDiv>
                    <button
                      onClick={() => setOpenAddMotorista(!openAddMotorista)}
                    >
                      Adicionar novo
                    </button>

                    <button
                      onClick={() => navigate('/motoristas/add-motoristas')}
                    >
                      Adicionar em massa
                    </button>
                  </S.AdicinarDiv>
                }
              >
                Adicionar motorista
              </DefaultButtonPopover>

              <GhostButton
                sx={{ marginLeft: '10px' }}
                size="medium"
                // loading={loading}
                onClick={() => navigate('/motoristas/ranking')}
              >
                Ver ranking atualizado
              </GhostButton>
            </div>
          }
          data={filterList}
        />
      </S.FilterContainer>
    );
  };

  // Render Card on click in task
  const clickOnBox = event => {
    setAnchorTask(event.currentTarget);
  };

  const renderTask = () => {
    return (
      <Popover
        id={anchorTask ? 'popover' : undefined}
        open={Boolean(anchorTask)}
        anchorEl={anchorTask}
        onClose={() => {
          setAnchorTask(null);
          setClickedItem(null);
        }}
        onClick={() => setAnchorTask(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        style={{
          marginTop: '10px',
        }}
      >
        <S.PopoverBox {...formtStatus(clickedItem?.status, theme)}>
          <div className="topArea">
            {formatIcon(clickedItem.status, theme)}
            <span className="evaluationId">Avaliação {clickedItem.id}</span>
          </div>

          <div className="medium">{formatText(clickedItem)}</div>

          <div className="bottomArea">
            <div className="statusBox">
              {clickedItem?.status?.toLowerCase()}
            </div>
            {clickedItem.status !== 'INDISPONIVEL' &&
              clickedItem.status !== 'ARQUIVADA' &&
              clickedItem.status !== 'PROCESSAMENTO' && (
                <div
                  className="viewBox"
                  onClick={() =>
                    openNew(
                      filter.currentTab === 0
                        ? '/avaliacoes'
                        : '/avaliacoes-descarga',
                      clickedItem.id,
                    )
                  }
                >
                  Ver avaliação
                </div>
              )}
          </div>
        </S.PopoverBox>
      </Popover>
    );
  };

  const renderLoopTask = () => {
    return (
      <Popover
        id={anchorTask ? 'popover' : undefined}
        open={Boolean(anchorTask)}
        anchorEl={anchorTask}
        onClose={() => {
          setAnchorTask(null);
          setCurrentEvaluantions(null);
        }}
        onClick={() => setCurrentEvaluantions(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        style={{
          marginTop: '10px',
        }}
      >
        <>
          {currentEvaluantions.map((evl, key) => {
            if (key >= 5) {
              return (
                <S.PopoverBox {...formtStatus(evl?.status, theme)} key={key}>
                  <div className="topArea">
                    {formatIcon(evl.status, theme)}
                    <span className="evaluationId">Avaliação {evl.id}</span>
                  </div>

                  <div className="medium">{formatText(evl)}</div>

                  <div className="bottomArea">
                    <div className="statusBox">
                      {evl?.status?.toLowerCase()}
                    </div>
                    {evl.status !== 'INDISPONIVEL' &&
                      evl.status !== 'ARQUIVADA' &&
                      evl.status !== 'PROCESSAMENTO' && (
                        <div
                          className="viewBox"
                          onClick={() => openNew('/avaliacoes', evl.id)}
                        >
                          Ver avaliação
                        </div>
                      )}
                  </div>
                </S.PopoverBox>
              );
            }
          })}
        </>
      </Popover>
    );
  };

  // ------------------------------ RENDER TABLE ----------------------------//
  return (
    <>
      <S.HeaderPage>
        <h1>Cronograma de Motoristas</h1>
        {renderFilters()}
      </S.HeaderPage>
      <Tabs
        value={filter.currentTab}
        items={tabs}
        onChange={handlePageChange}
        disabled={loading}
      />
      <Grid marginBottom={3} item md={12}>
        <Divider />
      </Grid>
      <S.Table>
        <S.TableLegends>
          <form className="inputArea" onSubmit={e => directlySendSearch(e)}>
            <SearchIcon
              className="icon"
              htmlColor={theme.palette.brand.primary.light}
            />
            <S.Input
              placeholder="Buscar Motoristas"
              value={input}
              onChange={e => setInput(e.target.value)}
            />
          </form>
          <div className="legendArea">
            {legends.map((legend, key) => (
              <S.LegendItem color={legend.color} key={key}>
                <span className="icon">{legend.Icon}</span>
                <div className="item">{legend.text}</div>
              </S.LegendItem>
            ))}
          </div>
        </S.TableLegends>
        <S.TableHeader style={{ pointerEvents: loading ? 'none' : 'all' }}>
          <div className="headerMotoristas">
            <span
              className="column"
              onClick={!loading ? () => toggleOrderBy() : undefined}
            >
              Nome
            </span>
            <span
              className="arrows"
              onClick={!loading ? () => toggleOrderBy() : undefined}
            >
              <ArrowDropUpOutlinedIcon
                className="top"
                style={{ opacity: filter.orderBy === 'ASC' ? 1 : 0.4 }}
              />
              <ArrowDropDownOutlinedIcon
                className="bottom"
                style={{ opacity: filter.orderBy === 'DESC' ? 1 : 0.4 }}
              />
            </span>
          </div>
          <div className="headerMes">
            <div
              className="circleArrow left"
              onClick={
                margin !== minMargin && !loading
                  ? () => previousSlide()
                  : undefined
              }
              style={{
                opacity: margin === minMargin ? 0.5 : 1,
                cursor: margin === minMargin ? 'not-allowed' : 'pointer',
              }}
            >
              <KeyboardArrowLeftIcon />
            </div>
            <div className="currentMonths">{currentMonths?.join('/')}</div>
            <div
              className="circleArrow right"
              onClick={!loading ? () => nextSlide() : undefined}
              style={{
                opacity: margin === 1100 ? 0.5 : 1,
                cursor: margin === 1100 ? 'not-allowed' : 'pointer',
              }}
            >
              <KeyboardArrowRightIcon />
            </div>
          </div>
          <div
            className={margin !== 1100 ? 'backCurrent visible' : 'backCurrent'}
            onClick={() => setMargin(1100)}
          >
            Ver período atual
          </div>
        </S.TableHeader>
        {!loading && (
          <>
            {data.length > 0 && (
              <S.MonthLines currentMargin={margin}>
                <div className="notUsed" />
                <div className="containerMonths">
                  <div className="monthsList">
                    {months?.map((month, key) => (
                      <div className="month" key={key}>
                        {month}
                      </div>
                    ))}
                  </div>
                </div>
              </S.MonthLines>
            )}
            <S.TableBody>
              {data.length > 0 && (
                <>
                  <S.Motoristas heightLine={heightLine}>
                    {data?.map((item, key) => (
                      <div className="motoristaItem" key={key}>
                        <div className="circleIdentifier">
                          {item?.foto && (
                            <img className="profile" src={item.foto} />
                          )}
                          {!item?.foto && (
                            <div className="firstLetter">{item.nome[0]}</div>
                          )}
                        </div>
                        <div className="motoristaName">
                          {limitName(item.nome)}
                        </div>
                      </div>
                    ))}
                  </S.Motoristas>
                  <S.Cronograma heightLine={heightLine} currentMargin={margin}>
                    {data?.map((motorista, index) => (
                      <div className="limitDriverBox" key={index}>
                        <>
                          {motorista.cronograma.map((item, key) => {
                            let avaliacoes = item.avaliacoes;
                            return (
                              <div className="evaluationsLine" key={key}>
                                {avaliacoes.map((ev, idx) => {
                                  if (idx < 5) {
                                    let sts =
                                      ev.status.charAt(0).toUpperCase() +
                                      ev.status.slice(1).toLowerCase();
                                    return (
                                      <div className="evaluation" key={idx}>
                                        <Tooltip title={sts}>
                                          <div
                                            className="iconContainer"
                                            onClick={e => {
                                              clickOnBox(e);
                                              setClickedItem(ev);
                                            }}
                                          >
                                            {formatIcon(ev.status, theme)}
                                          </div>
                                        </Tooltip>
                                      </div>
                                    );
                                  }
                                })}
                                {lessThanFive(avaliacoes).map((item, idx) => (
                                  <div className="evaluation" key={idx}>
                                    <Tooltip title="Não realizada">
                                      <div className="iconContainer opc">
                                        {formatIcon('', theme)}
                                      </div>
                                    </Tooltip>
                                  </div>
                                ))}
                                <div className="seeMore">
                                  <Tooltip
                                    title={
                                      avaliacoes.length > 5
                                        ? 'Ver mais'
                                        : 'Sem avaliações adicionais'
                                    }
                                    style={{
                                      cursor:
                                        avaliacoes.length > 5
                                          ? 'pointer'
                                          : 'not-allowed',
                                    }}
                                  >
                                    <MoreVertOutlinedIcon
                                      htmlColor={
                                        avaliacoes.length > 5
                                          ? theme.palette.words.subtitle.natural
                                          : theme.palette.semantics.feedback
                                              .unknown.natural
                                      }
                                      onClick={
                                        avaliacoes.length > 5
                                          ? e => {
                                              clickOnBox(e);
                                              setCurrentEvaluantions(
                                                avaliacoes,
                                              );
                                            }
                                          : null
                                      }
                                    />
                                  </Tooltip>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      </div>
                    ))}
                  </S.Cronograma>
                </>
              )}
              {data.length <= 0 && (
                <S.Loading>
                  <img src={img} />
                  <div className="isEmpty">
                    <h1>Nenhum motorista encontrado</h1>
                    <h4>
                      Adicione novos motoristas ou verifique os filtros
                      aplicados
                    </h4>
                  </div>
                </S.Loading>
              )}
            </S.TableBody>
            <S.TableFooter>
              <div className="counter">
                <span className="text">Exibindo</span>
                <Select
                  value={filter.pageSize}
                  variant="outlined"
                  margin="dense"
                  onChange={e => {
                    togglePageSize(Number(e.target.value));
                  }}
                  data={[
                    { name: '10', value: 10 },
                    { name: '20', value: 20 },
                    { name: '30', value: 30 },
                  ]}
                  style={{ height: '35px', width: '70px', margin: ' 0px 5px' }}
                />
                <span className="total">{filter.pageCount}</span>
              </div>
              <Stack spacing={2}>
                <S.FooterCol>
                  <S.Control onClick={() => goToPage(0)}>
                    <Icon name="chevron-left-outline" />
                    <p>Primeiro</p>
                  </S.Control>
                  <S.Control onClick={() => previousPage()}>
                    <Icon name="chevron-left-outline" />
                    <p>Anterior</p>
                  </S.Control>
                  <S.Pages
                    count={Math.ceil(filter.pageCount / filter.pageSize)}
                    page={
                      // Verifica se a pagina atual esta dentro do total
                      // Caso ele esteja na ultima pagna e ela nao exista mais
                      filter.pageIndex + 1 <=
                      Math.ceil(filter.pageCount / filter.pageSize)
                        ? filter.pageIndex + 1
                        : Math.ceil(filter.pageCount / filter.pageSize)
                    }
                    onChange={(e, value) => goToPage(value - 1)}
                    hidePrevButton
                    hideNextButton
                  />
                  <S.Control
                    onClick={() =>
                      filter.pageIndex + 1 <
                      Math.ceil(filter.pageCount / filter.pageSize)
                        ? nextPage()
                        : null
                    }
                  >
                    <p>Próximo</p>
                    <Icon name="chevron-right-outline" />
                  </S.Control>
                  <S.Control
                    onClick={() =>
                      goToPage(
                        Math.ceil(filter.pageCount / filter.pageSize) - 1,
                      )
                    }
                  >
                    <p>Último</p>
                    <Icon name="chevron-right-outline" />
                  </S.Control>
                </S.FooterCol>
              </Stack>
            </S.TableFooter>
          </>
        )}
        {loading && (
          <S.Loading className="hasBorders">
            <Loading />
          </S.Loading>
        )}
      </S.Table>
      {clickedItem && renderTask()}
      {currentEvaluantions && renderLoopTask()}
      <ModalAddMotorista
        open={openAddMotorista}
        handleClose={() => setOpenAddMotorista(false)}
        setTriggerUpdate={setTriggerUpdate}
      />
    </>
  );
};

export default MotoristasCronogramas;
