import styled from 'styled-components';
import colors from 'styles/colors';
import { Grid } from '@mui/material';

export const Container = styled.div`
  .boxLoading {
    position: relative;
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }
`;

export const NotifyBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  .boxHeader {
    display: flex;

    .headerItem {
      background-color: #fff;
      color: ${colors.greyTitle};
      opacity: 0.8;
      font-weight: 600;
      font-size: 16px;
      padding: 10px 40px;
      border: 1px solid #939aab4d;
      border-bottom: none;
      border-right: none;
      transition: all ease 0.5s;
      cursor: pointer;
      box-shadow: 0px -1px 10px #939aab4d;
    }

    .headerItem:last-child {
      border-right: 1px solid #939aab4d;
      border-top-right-radius: 5px;
    }

    .headerItem:first-child {
      border-top-left-radius: 5px;
    }

    .active {
      background-color: #efefef;
    }
  }

  .boxBody {
    // background-color: #fff;
    //border: 1px solid #939aab4d;
    /* border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px; */
    //box-shadow: 0px 5px 15px #939aab4d;

    .subtitle {
      text-align: left;
      font: normal normal 900 13px/14px Texta;
      letter-spacing: 0px;
      color: #656e8c;
      text-transform: uppercase;
      opacity: 1;
    }
  }
`;

export const Footer = styled.div`
  margin-top: 15px;
  margin-right: 25px;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: end;
`;

export const GridCustom = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #939aab4d;
  border-radius: 4px;
  padding: 23px;
`;

export const Criticidade = styled.div`
  width: 150px;
  padding: 4px;
  display: flex;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  background-color: ${props => `${props.color}33`};
  margin-bottom: 14px;
  p {
    color: ${props => props.color};
    font: normal normal bold 14px/14px Texta;
  }
`;
