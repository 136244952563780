import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import SvgIcon from '@mui/material/SvgIcon';
import Button from '@mui/material/Button';

import Calendar from 'components/Inputs/Calendar';
import Icon from 'components/Icons';
import Loading from 'components/Loading';

import { ReactComponent as info } from 'images/icons/toast/info.svg';
import GhostButton from 'components/Buttons/Ghost';
import SelectInput from 'components/Inputs/Select';
import { useTheme } from 'styled-components';
import * as S from './styled';

const SaveModal = ({
  handleClose,
  open,
  title,
  titleIcon,
  subtitle,
  onClick,
  disabled,
  disabledSave,
  status,
  data,
  value,
  handleChange,
  valueFolga,
  setFolga,
  selectLabel,
}) => {
  const theme = useTheme();
  const body = (
    <S.Paper>
      <S.Header>
        <S.Title>
          {titleIcon && (
            <SvgIcon
              style={{ margin: '0 10px 0 0' }}
              htmlColor={theme.palette.brand.secondary.natural}
              component={titleIcon}
            />
          )}{' '}
          {title}
        </S.Title>
        <S.RightHeader>
          <S.CloseButton
            startIcon={
              <Icon name="close" color={theme.palette.brand.primary.dark} />
            }
            onClick={handleClose}
            variant="inherit"
            style={{ backgroundColor: 'transparent' }}
          />
        </S.RightHeader>
      </S.Header>
      <S.Main>
        <S.StyledToast>
          <SvgIcon
            htmlColor={theme.palette.semantics.feedback.warning.natural}
            component={info}
          />
          <h1>{subtitle}</h1>
        </S.StyledToast>
        <div style={{ width: '100%', marginBottom: '25px' }}>
          <SelectInput
            value={value}
            data={data}
            handleChange={e => handleChange(e.target.value)}
            label={selectLabel}
            style={{ width: '100%' }}
          />
        </div>
        {value === 'Folga' && (
          <div style={{ width: '100%', marginBottom: '25px' }}>
            <Calendar
              value={valueFolga}
              onChange={setFolga}
              label="Data Folga"
            />
          </div>
        )}
      </S.Main>
      <S.Footer>
        <S.RightFooter>
          <GhostButton
            size="small"
            variant="contained"
            style={{
              width: '100px',
              height: '40px',
            }}
            customcolor={theme.palette.semantics.feedback.attention.dark}
            icon={null}
            onClick={handleClose}
          >
            Cancelar
          </GhostButton>
          <Button
            disabled={disabled}
            onClick={onClick}
            size="small"
            type="submit"
            color="primary"
            variant="contained"
            style={{
              color: theme.palette.system.highlight,
              marginLeft: '10px',
              width: '180px',
              height: '40px',
            }}
          >
            Confirmar Mudança
          </Button>
        </S.RightFooter>
      </S.Footer>
      <div style={{ left: 0, top: 0 }} />
    </S.Paper>
  );

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
      {disabled && <Loading />}
    </div>
  );
};

export default SaveModal;
