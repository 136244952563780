import styled, { css } from 'styled-components';

export const Paper = styled.div`
  ${({ theme }) => css`
    position: absolute;
    width: 590px;
    background-color: ${theme.palette.system.overlay};
    border: 0px 5px 20px ${theme.palette.system.divider};
    border-radius: 8px;
    padding: 25px 25px;
    margin: 0 auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `}
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    h1 {
      font-size: 24px;
      font-weight: 800;
      color: ${theme.palette.words.title.natural};
    }
  `}
`;

export const Main = styled.div`
  display: flex;
  width: 100%;
  min-height: 40px;
  flex-grow: 1;
  align-items: center;
  flex-direction: column;
  text-align: center;

  color: ${({ theme }) => theme.palette.words.title.natural};
  font-size: 18px;
  font-weight: 500;

  span {
    font-weight: 800;
    text-transform: capitalize;
    color: ${({ theme }) => theme.palette.brand.secondary.natural};
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 15px;
`;
