import React from 'react';

// components
import Divider from '@mui/material/Divider';
import { IconButton } from '@mui/material';
import { Switch } from 'components/Inputs/Switch';

// icons
import { ReactComponent as EditarIcon } from 'images/icons/configuracoes/icone-editar.svg';
import { ReactComponent as Pendente } from 'images/icons/configuracoes/icone-pendente.svg';
import { ReactComponent as Check } from 'images/icons/configuracoes/icone-check.svg';
import { DesvioItem, Item } from './styled';

import { getColor, formatArrayString } from '../services';
import { disableEscalation, enableEscalation } from '../../services';
import { toast } from 'react-toastify';

export const ConfigTorre = ({
  item,
  handleNavigate,
  desviosTiposPadroes,
  desviosTipos,
  refetch,
}) => {
  const handleSwitchChange = async (id, value) => {
    const res = value
      ? await enableEscalation(id)
      : await disableEscalation(id);
    if (res?.success) {
      toast.success(res.message);
    } else {
      toast.error('Falha ao atualizar lista.');
    }
    refetch();
  };

  return (
    <>
      <Item>
        <Item>
          <Switch
            check={item.status === 'ATIVO'}
            setCheckbox={value => handleSwitchChange(item.id, value)}
            textOn=""
            textOff=""
          />
          <div className="icons">
            <Check />
            <span style={{ color: '#1BC5BD' }}>
              {item.empresasConfiguraram?.length ?? 0}
            </span>
          </div>
          <div className="icons">
            <Pendente />
            <span style={{ color: '#FFA801' }}>
              {item.empresasNaoConfiguraram?.length ?? 0}
            </span>
          </div>
          <div className="description">
            {item?.is_default && 'Onisys - '}
            <span style={{ color: getColor(item?.risco) }}>
              {`${item?.titulo.slice(0, 50)}` || ''}
            </span>
          </div>
          <DesvioItem>
            {formatArrayString(
              item.is_default ? desviosTiposPadroes : desviosTipos,
              item?.ids_desvios,
            )}
          </DesvioItem>
        </Item>
        <div style={{ marginTop: 10 }}>
          <IconButton onClick={() => handleNavigate(item)}>
            <EditarIcon color="#939aab" />
          </IconButton>
        </div>
      </Item>
      <Divider style={{ marginTop: 15 }} />
    </>
  );
};
