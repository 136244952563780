export const formaterFields = (name, value) => {
  if (name === 'nome') {
    value = value.toLocaleUpperCase();
  } else if (name == 'cpf' && value) {
    value = value.replace(/\D/g, '');
    value = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    if (value && value.length > 14) {
      value = value.slice(0, 14);
    }
  } else if (
    (name == 'data_de_admissao' ||
      name == 'data_de_nascimento' ||
      name == 'validade_cnh' ||
      name == 'primeira_cnh') &&
    value
  ) {
    const numbersss = /^[0-9-]+$/;
    value = value
      .replace(/^(\d{2})(\d{2})(\d{4})*/, '$1-$2-$3')
      .match(numbersss);
    if (value && value.input.length > 10) {
      value = value?.input?.slice(0, 10);
    }
  } else if (name === 'funcao' || name === 'cnh') {
    value = value?.slice(0, 30);
  }
  return value;
};

export const validateFields = form => {
  let error = false;
  if (
    !form.nome ||
    !form.cpf ||
    !form.id_da_filial ||
    !form.matricula ||
    !form.genero ||
    !form.data_de_nascimento ||
    !form.data_de_admissao ||
    form.agregado === null
  ) {
    error = true;
  }

  return error;
};

export const formatInitialData = (name, value) => {
  if (
    (name == 'data_de_admissao' ||
      name == 'data_de_nascimento' ||
      name == 'validade_cnh' ||
      name == 'primeira_cnh') &&
    value
  ) {
    const yaer = value.slice(0, 4);
    const month = value.slice(5, 7);
    const day = value.slice(8, 10);
    value = `${day}-${month}-${yaer}`;
  }

  return value;
};
