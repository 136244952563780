import styled from 'styled-components';

export const ModalContent = styled.div`
  padding: 1rem 1rem 0rem 1rem;
`;

export const Button = styled.button`
  color: ${({ theme }) => theme.palette.brand.secondary.natural};
  border: none;
  font-size: 16px;
  font-weight: 500;
  text-decoration: underline;
  background: none;
  text-align: start;
  display: flex;
  align-items: center;

  :hover {
    font-weight: 600;
  }
`;
