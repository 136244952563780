// styles
import { useTheme } from 'styled-components';

// React
import React, { useState } from 'react';

// Components
import GhostButton from 'components/Buttons/Ghost';
import TextInput from 'components/Inputs/TextField';

// Components MUI
import BookIcon from '@mui/icons-material/Book';
import { Modal, IconButton, Icon, Grid } from '@mui/material';
import { toast } from 'react-toastify';
import * as S from './styled';

const ModalNewTag = ({ open, handleClose, categories, handleSave }) => {
  const theme = useTheme();
  const [tags, setTags] = useState('');

  const save = () => {
    // Tag name validation
    const nameTags = categories.map(item => item.name.trim().toLowerCase());
    if (nameTags.includes(tags.trim().toLowerCase())) {
      toast.error('Já existe uma tag com esse nome');
      return;
    }

    // Safe case is success
    const newTags = categories;
    newTags.push({ state: true, name: tags });
    handleSave(newTags);
    setTags('');
  };
  const body = (
    <S.Paper>
      <S.Header>
        <S.Title>
          <BookIcon htmlColor={theme.palette.brand.secondary.natural} />
          <h1>Criar Categoria</h1>
        </S.Title>

        <IconButton size="small" onClick={() => handleClose(false)}>
          <Icon sx={{ color: theme.palette.words.subtitle.natural }}>
            close
          </Icon>
        </IconButton>
      </S.Header>

      <S.Main>
        <Grid item xs={12} md={12}>
          <TextInput
            sx={{ width: '100%' }}
            label="Nome da categoria"
            placeholder="Nome"
            value={tags}
            onChange={e => setTags(e.target.value.slice(0, 20))}
            props={{ readOnly: true }}
            variant="outlined"
          />
          <div className="counter">{`${tags.length}/20`}</div>
        </Grid>
      </S.Main>
      <S.Footer>
        <GhostButton
          customcolor={theme?.palette?.semantics?.feedback?.attention?.natural}
          onClick={() => handleClose(false)}
        >
          Cancelar
        </GhostButton>
        <div style={{ marginLeft: 10 }} />
        <GhostButton onClick={() => save(tags)}>Criar</GhostButton>
      </S.Footer>
    </S.Paper>
  );

  return (
    <div>
      <Modal open={open} onClose={() => handleClose(false)}>
        {body}
      </Modal>
    </div>
  );
};

export default ModalNewTag;
