import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import Tabs from 'components/Tabs';
import { Divider } from '@mui/material';
import { SaveAlt } from '@mui/icons-material';

import Header from 'pages/_templates/ListPage/components/Header';
import ListaFaixas from '../Components/ListaFaixas';
import ExcelModal from 'components/ExcelModalNew';
import { getFaixas } from '../services';
import { trackEvent } from 'utils/mixpanel';
import { generateFileName } from 'utils/generateFileName';
import { fields } from '../constants';

const ConfigAcoesSuspensoesClientes = () => {
  const user = useSelector(state => state.auth?.user?.user);
  const clients = useSelector(state => state.selects.clients);

  const [tab, setTab] = useState(clients[0]?.id || 0);
  const [queryFaixas, setQueryFaixas] = useState({ client: clients[0]?.id });
  const [openExcelModal, setOpenExcelModal] = useState(false);

  const [faixaModal, setFaixaModal] = useState({
    open: false,
    data: null,
  });

  const {
    data: resFaixas,
    isFetching: fetchingFaixas,
    refetch: refetchFaixas,
  } = useQuery(
    ['acoes-suspensoes-faxas', queryFaixas],
    () => getFaixas(queryFaixas),
    {
      refetchOnWindowFocus: false,
    },
  );

  const headerTabs = {
    value: tab,
    items: clients.map(client => ({ value: client.id, label: client.nome })),
    onChange: (_, newValue) => setTab(newValue),
    disabled: fetchingFaixas,
  };

  useEffect(() => {
    setQueryFaixas({ client: tab });
  }, [tab]);

  const headerActions = [
    {
      title: 'Exportar',
      variant: 'ghost',
      icon: <SaveAlt />,
      function: () => {
        trackEvent(user, 'EXPORTAR PLANILHA');
        setOpenExcelModal(true);
      },
    },
  ];

  // -------------------------- EXCEL ------------------------------------------//

  // const handleRequestExcel = async () => {
  //   setLoadingExcel(true);
  //   const res = await requestExcelFaixas({
  //     client: tab,
  //     excelFields: fields,
  //   });
  //   if (res.link) {
  //     downloadFromLink(res.link);
  //     toast.success(res.message);
  //   } else if (res.message) toast.error(res.message);

  //   setLoadingExcel(false);
  // };

  return (
    <>
      <Header title="Configurações do Cliente" actions={headerActions} />

      {openExcelModal && (
        <ExcelModal
          open={openExcelModal}
          handleClose={() => setOpenExcelModal(false)}
          excelFields={fields}
          query={{
            client: tab,
          }}
          file_name={generateFileName(
            window.location.pathname.split('/').pop(),
          )}
          route="/excel/acao_suspensao_faixa"
        />
      )}

      <Tabs {...headerTabs} />
      <Divider />

      <ListaFaixas
        data={resFaixas?.data?.data || []}
        pageCount={resFaixas?.data?.total || 0}
        loading={fetchingFaixas}
        formModal={faixaModal}
        setFormModal={setFaixaModal}
        refetch={refetchFaixas}
        readOnly
        client
      />
    </>
  );
};

export default ConfigAcoesSuspensoesClientes;
