import styled from 'styled-components';
import colors from 'styles/colors';

import { Modal } from '@mui/material';

export const ModalArea = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 540px;
  height: 365px;
  background-color: #fff;
  padding: 10px 30px;
  transition: all ease 0.5s;
  border-radius: 5px;
  box-shadow: 0px 0px 15px #000;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 70px;
  border-bottom: 1px solid #939aab4d;

  div {
    display: flex;
    flex-direction: row;
    h2 {
      margin-left: 10px;
    }
  }
`;

export const Description = styled.div`
  font-size: 14px;
  color: ${colors.greySubtitle};
`;

export const Body = styled.div`
  display: flex;
`;

export const Box = styled.div`
  width: 227px;
  height: 170px;
  border: 1px solid ${colors.grey};
  margin: 15px;
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  span {
    margin-top: 5px;
    font-size: 15px;
    color: ${colors.greyTitle};
  }

  &:hover {
    background-color: ${colors.greyBackgroud};
  }
`;
