import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// components
import Divider from '@mui/material/Divider';
import Button from 'components/Buttons/Default';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { toast } from 'react-toastify';

// styles
import { useTheme } from 'styled-components';

// icons
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

// components
import CadastroGestor from 'pages/Configuracoes/Usuarios/ModaUsuarios';
import CadastroMotorista from 'pages/Motoristas/ModalAddMotorista';
import * as S from './style.js';
import { getMotoristas, inativateDriver, inativateGestor } from './services';
import { trackEvent } from 'utils/mixpanel.js';

// colums
import { columns } from './columns';
import { DefaultTable } from 'components/_Table/templates/default/index.jsx';

const Alunos = () => {
  const theme = useTheme();

  const [openModalAddGestor, setOpenModalAddGestor] = useState(false);
  const [openModalAddMotorista, setOpenModalAddMotorista] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isDriver, setIsDriver] = React.useState(true);

  // tabelas

  const [dataMotorista, setDataMotorista] = useState([]);
  const [dataUsuario, setDataUsuario] = useState([]);

  const [currentUserEditing, setActionsUserEditing] = useState({});
  const [loading, setLoading] = useState(false);

  const user = useSelector(state => {
    return state.auth?.user?.user;
  });

  let isConvidado = false;
  if (+user.nivel === 4) isConvidado = true;

  const handleConvidado = () => {
    toast.error('Você não tem permissão para executar essa ação!');
  };
  const fetchUsers = () => {
    setLoading(true);
    getMotoristas().then(res => {
      const motoristas = res.data.drivers;
      const { users } = res.data;
      motoristas.map(item => {
        Object.assign(item, {
          filial_name: item.filial.nome,
          turmas: item.turmas,
          conteudos: item.conteudos,
        });
        return item;
      });
      users.map(item => {
        Object.assign(item, {
          filial_name: item.filial.nome,
          turmas: item.turmas,
          conteudos: item.conteudos,
        });
        return item;
      });
      setDataMotorista(motoristas);
      setDataUsuario(users);
      setLoading(false);
    });
  };
  useEffect(() => {
    fetchUsers();
  }, []);

  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSetExcluir = async (id, status, type) => {
    if (type === 'GESTOR') {
      const res = await inativateGestor(id, { status });
      if (res.data.success) {
        toast.success(res.data.message);
        fetchUsers();
      } else {
        toast.error(res.data.message);
      }
    } else if (type === 'MOTORISTA') {
      const res = await inativateDriver(id);
      if (res.data.success) {
        toast.success(res.data.message);
        fetchUsers();
      } else {
        toast.error(res.data.message);
      }
    }
  };

  const actionsGestor = [
    {
      title: 'Editar',
      function: (_, item) => {
        const newObj = {};
        Object.assign(newObj, {
          id: item.id,
          nome: item.nome,
          email: item.email,
          telefone_de_contato: item.telefone_de_contato,
          status: item.status,
          funcao: item.funcao,
          filial: { id: item.id_da_filial },
          matricula: item.matricula,
          nivel: item.nivel,
          foto: item.foto,
        });
        setActionsUserEditing(newObj);
        setOpenModalAddGestor(true);
        trackEvent(user, 'Editar Aluno');
      },
    },
    {
      title: 'Ativar',
      function: (_, item) => {
        handleSetExcluir(item.id, !item.status ? 'ATIVO' : 'INATIVO', 'GESTOR');
        trackEvent(user, 'Desativar Aluno');
      },
      visible: item => item.status === 'INATIVO',
    },
    {
      title: 'Inativar',
      function: (_, item) => {
        handleSetExcluir(item.id, !item.status ? 'ATIVO' : 'INATIVO', 'GESTOR');
        trackEvent(user, 'Desativar Aluno');
      },
      visible: item => item.status === 'ATIVO',
    },
  ];

  const actionsMotoristas = [
    {
      title: 'Editar',
      function: (_, item) => {
        delete item.turmas;
        delete item.nomes_turmas;
        delete item.conteudos;
        delete item.clients;
        delete item.status;
        item.id_da_empresa = item.filial.id_da_empresa;
        delete item.filial;
        Object.assign(item, {
          agregado: item.agregado ? 'SIM' : 'NÃO',
        });
        setActionsUserEditing(item);
        setOpenModalAddMotorista(true);
        trackEvent(user, 'Editar Aluno');
      },
    },
    {
      title: 'Ativar',
      function: (_, item) => {
        handleSetExcluir(
          item.id,
          !item.status ? 'ATIVO' : 'INATIVO',
          'MOTORISTA',
        );
        trackEvent(user, 'Desativar Aluno');
      },
      visible: item => item.status === 'INATIVO',
    },
    {
      title: 'Inativar',
      function: (_, item) => {
        handleSetExcluir(
          item.id,
          !item.status ? 'ATIVO' : 'INATIVO',
          'MOTORISTA',
        );
        trackEvent(user, 'Desativar Aluno');
      },
      visible: item => item.status === 'ATIVO',
    },
  ];

  return (
    <S.Container>
      {/* usuario cadastro */}
      {openModalAddGestor && (
        <CadastroGestor
          open={openModalAddGestor}
          handleClose={() => setOpenModalAddGestor(false)}
          dataEdit={currentUserEditing}
          fetchData={() => fetchUsers()}
        />
      )}
      {openModalAddMotorista && (
        <CadastroMotorista
          open={openModalAddMotorista}
          handleClose={() => setOpenModalAddMotorista(false)}
          dataEdit={currentUserEditing}
          setTriggerUpdate={() => {}}
          fetch={() => {
            setOpenModalAddMotorista(false);
            fetchUsers();
          }}
        />
      )}

      <S.ButtonContianer>
        <div>
          <h1>Alunos</h1>
        </div>
        <Button
          startIcon={<AddCircleOutlineIcon />}
          onClick={e => (!isConvidado ? handleClick(e) : handleConvidado())}
        >
          <span style={{ fontSize: 14 }}>ADICIONAR ALUNOS</span>
        </Button>
        <Menu
          id="fade-menu"
          MenuListProps={{
            'aria-labelledby': 'fade-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          <MenuItem
            sx={{ width: 170 }}
            onClick={() => {
              setActionsUserEditing(null);
              setOpenModalAddGestor(true);
              trackEvent(user, 'Adicionar Aluno');
            }}
          >
            Adicionar Gestor
          </MenuItem>
          <MenuItem
            sx={{ width: 170 }}
            onClick={() => {
              setActionsUserEditing(null);
              setOpenModalAddMotorista(true);
            }}
          >
            Adicionar Motorista
          </MenuItem>
        </Menu>
      </S.ButtonContianer>
      <Divider light sx={{ marginTop: 2, marginBottom: 2 }} />
      <S.Controls>
        <div
          className="left"
          style={{
            background: isDriver
              ? theme.palette.system.disabled
              : theme.palette.system.overlay,
          }}
          onClick={() => {
            setIsDriver(true);
            trackEvent(user, 'Filtrar por Motorista');
          }}
        >
          Motorista
        </div>
        <div
          className="right"
          style={{ background: !isDriver ? '#EFEFEF' : 'white' }}
          onClick={() => {
            setIsDriver(false);
            trackEvent(user, 'Filtrar por Gestor');
          }}
        >
          Gestor
        </div>
      </S.Controls>
      <S.TableContainer>
        {isDriver && (
          <DefaultTable
            data={dataMotorista || []}
            columns={columns}
            searchKeys={['nome', 'filial_name']}
            loading={loading}
            placeholder="Buscar por aluno"
            sortBy={{ id: 'id', order: 'DESC' }}
            actions={!isConvidado ? actionsMotoristas : ''}
            searchEvent={search =>
              trackEvent(user, 'Busca Formulários Alunos', null, search)
            }
            empty={{
              title: 'Nenhuma aluno encontrado',
              description: `Seus alunos aparecerão aqui".`,
              image: 'frota.png',
            }}
          />
        )}
        {!isDriver && (
          <DefaultTable
            data={dataUsuario || []}
            columns={columns}
            searchKeys={['nome', 'filial_name']}
            loading={loading}
            placeholder="Buscar por aluno"
            sortBy={{ id: 'id', order: 'DESC' }}
            actions={!isConvidado ? actionsGestor : ''}
            searchEvent={search =>
              trackEvent(user, 'Busca Formulários Alunos', null, search)
            }
            empty={{
              title: 'Nenhuma aluno encontrado',
              description: `Seus alunos aparecerão aqui".`,
              image: 'frota.png',
            }}
          />
        )}
      </S.TableContainer>
    </S.Container>
  );
};

export default Alunos;
