export const getColor = op => {
  switch (op) {
    case 'ALTÍSSIMO':
      return '#f64e60';
    case 'ALTO':
      return '#FFA801';
  }
};

export const formatArrayString = (array, ids, join = ', ') => {
  let result = array.filter(item => ids && ids.includes(item.id));
  result = result.map(item => item.titulo);
  result = result.join(join);
  return `${result.slice(0, 60)}...`;
};
