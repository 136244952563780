import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { subDays } from 'date-fns';

import FiltersGlobal from 'components/FiltersGlobal';
import { DefaultTable } from 'components/_Table/templates/default';

import { usePlans } from 'hooks/usePlans';
import { useFetchMultipleWithCache } from 'hooks/fetchFilters';

import * as S from './styled';
import { requestDriversGeral } from './services';
import { columns } from './constants';
import { PerfilMotoristas } from './PerfilMotoristas';

export const ListaMotoristas = () => {
  const theme = useTheme();
  // Selects dos filtros
  const { filiais, clients } = useFetchMultipleWithCache();
  const selects = useSelector(state => state.selects);
  const empresasFilter =
    selects?.empresas?.map(i => {
      return { label: i.nome, value: i.id };
    }) || [];

  const [filtersPage, setFiltersPage] = useState({
    finalDate: new Date(),
    initialDate: subDays(new Date(), 30),
  });

  const [query, setQuery] = useState(null);
  const [resetTable, setResetTable] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const { planosAtivos, isProvider } = usePlans();

  const { isFetching, data: resData } = useQuery(
    ['motoristas-geral', query],
    () => query && requestDriversGeral(query),
    {
      refetchOnWindowFocus: false,
      onSettled: () => {
        resetTable && setResetTable(false);
      },
    },
  );

  const initial = useRef(true);
  useEffect(() => {
    if (initial.current) {
      initial.current = false;
      return;
    }

    setResetTable(true);
  }, [filtersPage]);

  // Filtros utilizados
  const filterList = [
    {
      filterName: 'filial',
      label: 'Filiais',
      options: filiais || [],
    },
    {
      filterName: 'client',
      label: 'Clientes',
      options: clients.data || [],
    },
  ];

  const filterListProvider = [
    {
      filterName: 'empresas',
      label: 'Empresas',
      options: empresasFilter || [],
    },
    {
      filterName: 'filial',
      label: 'Filiais',
      options: filiais || [],
    },
  ];

  return (
    <S.Container>
      <S.Header>
        <FiltersGlobal
          customComponent={<S.Title>Lista de motoristas</S.Title>}
          hideExportButton
          hideRefleshButton
          isFetching={isFetching}
          handleFilters={filter => {
            setFiltersPage(state => ({ ...state, ...filter }));
            setSelectedRow(null);
          }}
          defaultDate={{
            initialDate: filtersPage?.initialDate,
            finalDate: filtersPage?.finalDate,
          }}
          data={isProvider ? filterListProvider : filterList}
        />
      </S.Header>
      <S.Content>
        <S.TableContainer>
          <DefaultTable
            compactRow
            local={false}
            data={resData?.data?.data || []}
            pageCount={resData?.data?.total || 0}
            columns={columns.filter(
              col =>
                !col.plans ||
                planosAtivos.some(plan => col.plans.includes(plan.id_do_plano)),
            )}
            reset={resetTable}
            loading={isFetching}
            placeholder="Buscar Motorista"
            empty={{
              title: 'Nenhum motorista encontrado',
              description: '',
            }}
            sortBy={{ id: 'nome', order: 'ASC' }}
            setQuery={q =>
              setQuery({
                ...q,
                ...filtersPage,
              })
            }
            onClickRow={id => setSelectedRow(id)}
            disabledRows={[selectedRow]}
          />
        </S.TableContainer>
        <S.GraphContainer>
          <PerfilMotoristas
            id={selectedRow}
            motorista={
              selectedRow && resData?.data?.data
                ? resData.data.data.find(item => item.id === selectedRow)
                : null
            }
            filters={filtersPage}
          />
        </S.GraphContainer>
      </S.Content>
    </S.Container>
  );
};
