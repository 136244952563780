// Styles
import { useTheme } from 'styled-components';

// React
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import ConfirmModal from 'components/ConfirmModal';
import GhostButton from 'components/Buttons/Ghost';

// Components Material
import { Divider, SvgIcon } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

// Utils
import { DeleteOutline } from '@mui/icons-material';
import { setItems } from 'store/modules/formularios/actions';
import Checkbox from './Components/Checkbox';
import * as S from './styled';

const GlobalModal = ({
  open,
  onClose,
  resetFields,
  stepOne,
  stepTwo,
  validateStepOne,
  totalContent,
  hasData,
  obrigatorio,
  setObrigatorio,
  small = '95vh',
  currentElement,
  section,
  hasChanged,
  setHasChanged,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { currentSection, secoes, status } = useSelector(
    state => state.formularios,
  );

  const [widthBox, setWidthBox] = useState('200%');
  const [backModal, setBackModal] = useState(false);

  const confirmOrPass = () => {
    validateStepOne();
  };

  const tryBack = () => {
    if (hasChanged) {
      setBackModal(true);
    } else {
      onClose();
      resetFields();
      setBackModal(false);
    }
    return null;
  };

  // ----------------------Save Component-----------------------------//

  useEffect(() => {
    if (totalContent) {
      const cloneItems = JSON.parse(JSON.stringify(secoes));

      for (const i in cloneItems) {
        if (
          cloneItems[i].index === currentSection ||
          cloneItems[i].index === section
        ) {
          if (totalContent.index !== undefined) {
            // Need Edit
            const componentes = cloneItems[i].campos;
            for (const j in componentes) {
              if (componentes[j].index === totalContent.index) {
                componentes[j] = {
                  ...componentes[j],
                  ...totalContent,
                };
              }
            }
            cloneItems[i].campos = componentes;
          } else {
            // Create new
            cloneItems[i].campos.push({
              id_tipo_campo: currentElement.id_tipo_campo,
              nome: currentElement.nome,
              tipo: currentElement.tipo,
              ...totalContent,
              index: cloneItems[i].campos.length,
            });
          }
        }
      }

      dispatch(setItems(cloneItems));
      onClose();
    }
  }, [totalContent]);

  return (
    <S.ModalItems open={open} onClose={() => onClose()} disableEscapeKeyDown>
      <S.NullableBackground>
        <S.Container small={small}>
          <S.Header>
            <div>
              <SvgIcon component={currentElement.icone} />
              <span>{currentElement.nome}</span>
            </div>
            <CloseOutlinedIcon
              htmlColor={theme.palette.words.subtitle.natural}
              onClick={() => tryBack()}
              style={{ cursor: 'pointer' }}
            />
          </S.Header>
          <Divider />
          <S.Body widthBox={widthBox} step={1}>
            {stepOne && (
              <S.BoxStep>
                {stepOne(status === 'Ativo' || status === 'Finalizado')}
              </S.BoxStep>
            )}

            {stepTwo && <S.BoxStep>{stepTwo()}</S.BoxStep>}

            {/* This field never be visisble for user, it's
                    only to controll transition effect */}
            {!stepTwo && <S.BoxStep>No Content</S.BoxStep>}
          </S.Body>
          <S.Footer isFinal={currentElement.notRequired}>
            {!currentElement.notRequired && (
              <Checkbox
                value={obrigatorio}
                onChange={() => {
                  setHasChanged(true);
                  setObrigatorio(!obrigatorio);
                }}
                label="Obrigatório"
              />
            )}

            <div>
              <GhostButton
                customcolor={
                  theme?.palette?.semantics?.feedback?.attention?.natural
                }
                style={{ marginRight: '20px' }}
                onClick={() => tryBack()}
                children="Voltar"
              />
              <GhostButton onClick={() => confirmOrPass()} children="Salvar" />
            </div>
          </S.Footer>

          <ConfirmModal
            open={backModal}
            title="Tem certeza que deseja voltar?"
            subtitle={
              hasData
                ? 'Alterações feitas não serão salvas'
                : 'O campo não será adicionado ao formulário'
            }
            titleIcon={
              <DeleteOutline
                sx={{
                  color: theme.palette.semantics.feedback.attention.natural,
                }}
                fontSize="medium"
              />
            }
            buttonText="Confirmar"
            onClick={() => {
              onClose();
              resetFields();
              setBackModal(false);
              setHasChanged(false);
            }}
            handleClose={() => {
              setBackModal(false);
            }}
          />
        </S.Container>
      </S.NullableBackground>
    </S.ModalItems>
  );
};

export default GlobalModal;
