import styled, { css } from 'styled-components';
import { styled as stMuiUI } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import MuiDrawer from '@mui/material/Drawer';

export const Container = styled.div`
  ${({ theme, ...props }) => css`
    width: 60px;
    display: flex;
    font-family: 'Texta', sans-serif;
    flex-direction: column;
    flex-shrink: 0;
    overflow: visible !important;
    transition: none !important;
    height: 100%;
    box-shadow: 0px 3px 15px ${theme.palette.system.backdrop};
    position: fixed;
    z-index: 999;

    .headerArea {
      background-color: ${theme.palette.brand.secondary.dark};
      height: 130px;
      position: relative;

      .logoArea {
        display: flex;
        cursor: pointer;

        img {
          margin: 30px auto;
          width: 35px;
        }
      }

      .userArea--Ring {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        border: 1px solid ${theme.palette.brand.secondary.natural};
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: -20px;
        left: 13%;

        .userArea--Bg {
          height: 37px;
          width: 37px;
          border-radius: 50%;
          border: ${props.border};
          background-color: ${theme.palette.semantics.feedback.unknown.light};
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .navigationArea {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${theme.palette.system.overlay};

      .listArea {
        display: flex;
        flex-direction: column;
        width: 100%;

        .listItem {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          position: relative;
          padding: 12px;
          widht: 100%;
          margin-left: 5px;
          border-right: 5px solid ${theme.palette.system.divider};
          background-color: ${theme.palette.semantics.feedback.unknown.light};

          .listItem--Icon {
          }

          .subMenuBox {
            position: absolute;
            margin-left: 160px;

            .menuItem {
              display: ${props.display};
              opacity: ${props.opacity};
              transition: all ease 0.3s;
              margin-left: 20px;
              font-size: 16px;
              background-color: ${theme.palette.system.highlight};
              margin-bottom: 5px;
              border-radius: 5px;
              text-align: center;
              padding: 15px 25px;
              box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px,
                rgb(0 0 0 / 14%) 0px 8px 10px 1px,
                rgb(0 0 0 / 12%) 0px 3px 14px 2px;

              &:hover {
                background-color: ${theme.palette.system.disabled};
              }

              p {
                color: ${theme.palette.words.text.natural};
                font-size: 16px;
              }
            }
          }
        }

        .active {
          border-right: 5px solid ${theme.palette.brand.secondary.dark};
          background-color: ${theme.palette.brand.secondary.background};
        }
      }
    }
  `}
`;

export const Title = styled.label`
  padding-top: 2px;
  padding-bottom: 2px;
  margin-left: 11px;
  color: ${({ theme }) => theme.palette.brand.primary.natural};
  font-weight: 900;
  font-family: 'Texta', sans-serif;
  font-size: 20px;
  letter-spacing: 0px;
  .subtitle {
    text-align: left;
    font-size: 14px;
    font-family: 'Texta', sans-serif;
    font-weight: 500;
    color: ${({ theme }) => theme.palette.brand.primary.natural};
  }
`;

export const ContainerMenu = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.palette.system.overlay};
  &:hover {
    background-color: ${({ theme }) =>
      theme.palette.semantics.feedback.unknown.light};
  }
`;
export const ContainerIcon = styled.div`
  display: flex;
  width: ${p => (!p.isSelect ? '54px' : '100%')};
  height: 50px;
  justify-content: ${p => (p.isSelect ? 'flex-start' : 'center')};
  align-items: center;
`;
export const Menu = styled.div`
  display: ${p => (p.isSelect ? 'none' : 'flex')};
  width: 100%;
  height: 50px;
  background-color: ${({ theme }) =>
    theme.palette.semantics.feedback.unknown.light};
  justify-content: center;
  align-items: center;
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 80%;
`;

export const ListItemOne = styled.div`
  display: flex;
  width: ${p => (p.isSelected && p.sidebarState ? '30%' : '100%')};
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
`;

export const ListItemTwo = styled.div`
  display: flex;
  transform: translateY(-92px);
  min-height: 122%;
  flex-direction: column;
  border-top: ${p => (!p.isSelected ? '0px' : '12px')} solid
    ${({ theme }) => theme.palette.brand.secondary.natural};
  width: ${p => (p.isSelected ? '100%' : '0%')};
  background: #fafafa 0% 0% no-repeat padding-box;

  .btn-voltar {
    text-align: left;
    font: normal normal medium 14px/20px Texta;
    letter-spacing: 0px;
    color: #ff8040;
    margin-left: 15px;
    cursor: pointer;
    margin-top: 15px;
  }
  .title-section {
  }
`;

// material ui styling
export const DrawerHeader = stMuiUI('div')(({ theme, color, open }) => ({
  display: 'flex',
  padding: theme.spacing(0, 1),
  background: color,
  justifyContent: open ? 'flex-start' : 'center',
  borderTop: `6px solid ${theme.palette.brand.secondary.natural}`,
  alignItems: 'center',

  ...theme.mixins.toolbar,
}));

const drawerWidth = 280;

const openedMixin = theme => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = theme => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export const Drawer = stMuiUI(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export const HtmlTooltip = stMuiUI(({ className, ...props }) => (
  <Tooltip
    placement="left-end"
    arrow
    {...props}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
    '&::before': {
      backgroundColor: theme.palette.common.white,
      boxShadow: theme.shadows[1],
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));
