/* eslint-disable import/order */

// Styles
import * as S from './styled';
import { useTheme } from 'styled-components';
import useSound from 'use-sound';

// React
import { useFilter } from './Filters/use';
import { useQuery } from 'react-query';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

// Redux
import {
  closeAlert,
  showMsgInfo,
  hideMsgInfo,
} from 'store/modules/header/actions';
import { signOut } from 'store/modules/auth/saga';
import { useDispatch, useSelector } from 'react-redux';

// Components
import { Filter } from './Filters';
import DrawerNotifications from './Drawer';
import { HeaderGuide } from 'pages/Guia/Header';
import AlertsContent from './AlertsContent';
import NotificationsContent from './NotificationContent';
import { ShowAlert } from './Alertas/ShowAlert';
import LoadingAlert from './LoadingAlert';
import AlertModal from 'components/AlertModal';

// icons
import { ReactComponent as SinoIcon } from 'images/notifiations/sino.svg';
import { ReactComponent as AlertaIcon } from 'images/icons/alertas/alerta.svg';

// Components MUI
import Menu from '@mui/material/Menu';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { ArrowBackIos } from '@mui/icons-material';

// Utils
import { trackEvent } from 'utils/mixpanel';

import DateRangePicker from './DateRangePicker';

// Constants
import { motoristas } from './Filters/constants';

// Assets
import { ReactComponent as AttentionIcon } from 'images/icons/motoristas/perfil/sidebar/desvios.svg';
import { ReactComponent as BtnSeta } from 'images/icons/header/botao-seta.svg';
import _ from 'lodash';
import { uuidV4 } from './Filters/constants';

// Services
import { requestAlertas } from './services';
import { usePlans } from 'hooks/usePlans';

import audio from 'assets/audio/button-124476.mp3';

const Header = () => {
  const [play] = useSound(audio);

  // Hooks
  const theme = useTheme();
  const { id: _id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation().pathname;
  const { hasTorre, hasTorrePlus } = usePlans();

  // Selectors
  const header = useSelector(state => state.header);
  const selects = useSelector(state => state.selects);
  const user = useSelector(state => state.auth?.user?.user);
  const openSnackbar = useSelector(state => state.header.toggleSnackbar);
  const openAlertModal = useSelector(state => state.header.toggleAlertModal);
  const downloadAlertModal = useSelector(
    state => state.header.downloadAlertModal,
  );
  const downloadSnackbar = useSelector(state => state.header.downloadSnackbar);

  // Pages
  const { datas, sections, expanded, handleExpand, keys, keysDefault, page } =
    useFilter(location, { ...selects, motoristas }, user?.provider);

  // States
  const [isOldTab, setIsOldTab] = useState(true);
  const [anchorElConfig, setAnchorElConfig] = useState(null);
  const [openNotification, setOpenNotification] = useState(false);
  const [openAlertas, setOpenAlertas] = useState(false);

  navigator.serviceWorker.addEventListener('message', function (event) {
    if (event.data && event.data.type === 'play-sound') {
      play();
    }
  });

  // Alertas
  const { data: alertas = [], refetch } = useQuery(
    'alertas',
    () => requestAlertas(),
    {
      refetchOnWindowFocus: true,
      refetchIntervalInBackground: true,
      refetchInterval: 1000 * 60 * 1,
    },
  );

  // Handlers
  const handleNavigate = url => navigate(url);
  const handleCloseConfig = () => setAnchorElConfig(null);
  const handleMenuConfig = e => setAnchorElConfig(e.currentTarget);

  const avatarStyle = {
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  };

  useEffect(() => {
    const oldTab = window.history.state.idx;
    setIsOldTab(!!oldTab);

    if (
      !user.provider &&
      (location === '/' || location === '/home' || location === '/violacoes')
    ) {
      dispatch(showMsgInfo());
    } else {
      dispatch(hideMsgInfo());
    }
  }, [location]);

  const id = uuidV4.test(_id) ? _id.slice(0, 8) : _id;

  return (
    <>
      <AlertModal
        open={openAlertModal}
        title={downloadAlertModal?.title}
        subtitle={downloadAlertModal?.message}
        buttonText="Confirmar"
        route={downloadAlertModal?.url}
        query={downloadAlertModal?.query}
        color="#424449"
        isBigFile={downloadAlertModal?.isBigFile}
        isError={downloadAlertModal?.isError}
        isManualDownload={downloadAlertModal?.isManualDownload}
      />
      <LoadingAlert
        open={openSnackbar}
        isBigFile={downloadSnackbar?.isBigFile}
        route={downloadSnackbar?.url}
        query={downloadSnackbar?.query}
        isError={downloadSnackbar?.isError}
        isManualDownload={downloadSnackbar?.isManualDownload}
        isLocal={downloadSnackbar?.isLocal}
        localData={downloadSnackbar?.localData}
      />
      <S.Wrapper>
        <ShowAlert data={alertas} refetch={refetch} />
        <S.Alert show={header.alert.show}>
          <S.AlertText>
            <AttentionIcon width="1.5rem" />
            <p>{header.alert.message}</p>
          </S.AlertText>
          <IconButton size="small" onClick={() => dispatch(closeAlert())}>
            <CloseIcon sx={{ color: theme.palette.words.subtitle.natural }} />
          </IconButton>
        </S.Alert>

        <S.Container>
          <S.LeftContent>
            {
              // botão voltar e título da página definidos em content.back content.title.
              // Não exibir caso ambos undefined ou null
              (page?.back || page?.title) && (
                <S.TitleWrapper>
                  {page?.back && isOldTab && (
                    <S.BackButton onClick={() => navigate(page?.back || -1)}>
                      <ArrowBackIos
                        fontSize="20px"
                        htmlColor={theme.palette.words.subtitle.natural}
                      />
                      <h2>Voltar</h2>
                    </S.BackButton>
                  )}

                  {page?.subTitle && (
                    <span className="subTitle">{page.subTitle}</span>
                  )}

                  {page?.subTitle && <span className="subTitle">{`>`}</span>}

                  {page?.title && <h1>{page?.title}</h1>}

                  {page.id && (
                    <h2 style={{ marginLeft: '10px' }}>{id ? `#${id}` : ''}</h2>
                  )}

                  {page?.status && (
                    <S.Status
                      color={header?.color}
                      background={header?.background}
                    >
                      {header?.status?.toLowerCase()}
                    </S.Status>
                  )}
                </S.TitleWrapper>
              )
            }
            {
              // Não exibir <S.FiltersContent> caso nenhum filtro exista
              (page?.date ||
                (page?.filters && !!page?.filters?.length) ||
                (page?.defaults && !!page?.defaults?.length)) && (
                <S.FiltersContent
                  style={{
                    marginLeft:
                      (page?.title && page?.date) ||
                      (page?.title && (page?.filters || page?.pageFilterName))
                        ? '20px'
                        : '0px',
                  }}
                >
                  {page?.date && <DateRangePicker />}
                  {((page?.filters &&
                    !!page?.filters.length &&
                    page?.pageFilterName) ||
                    (page?.defaults && !!page?.defaults?.length) ||
                    page?.defaults === undefined) && (
                    <Filter
                      datas={datas}
                      sections={sections}
                      expanded={expanded}
                      handleExpand={handleExpand}
                      keys={keys}
                      keysDefault={keysDefault}
                      page={page}
                    />
                  )}
                </S.FiltersContent>
              )
            }
          </S.LeftContent>

          <S.RightContent>
            <HeaderGuide />
            <S.Space />
            {(hasTorre || hasTorrePlus) && (
              <>
                <IconButton onClick={() => setOpenAlertas(true)}>
                  <AlertaIcon style={{ color: '#939aab' }} />
                </IconButton>
                <S.Space />
              </>
            )}
            <IconButton onClick={() => setOpenNotification(true)}>
              <Badge
                sx={{
                  '& .MuiBadge-badge': {
                    minWidth: '14px',
                    height: '14px',
                    fontSize: '10px',
                    fontWeight: 'bold',
                    padding: '0 0px',
                  },
                }}
                badgeContent={header?.totalNotifications}
                color="error"
              >
                <SinoIcon />
              </Badge>
            </IconButton>
            <S.Space />

            <div>
              <IconButton onClick={handleMenuConfig}>
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  badgeContent={<BtnSeta />}
                >
                  {user?.filial?.empresa && (
                    <Avatar
                      alt="user"
                      style={avatarStyle}
                      src={user?.filial?.empresa?.logo_url || ''}
                    />
                  )}
                  {user?.distribuidora && (
                    <Avatar
                      alt="user"
                      style={avatarStyle}
                      src={user?.distribuidora?.logo_url || ''}
                    />
                  )}
                </Badge>
              </IconButton>

              <Menu
                keepMounted
                anchorEl={anchorElConfig}
                PaperProps={S.menu_St}
                transformOrigin={{
                  vertical: 0,
                  horizontal: 120,
                }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                open={Boolean(anchorElConfig)}
                MenuListProps={{ onMouseLeave: handleCloseConfig }}
                onClose={handleCloseConfig}
              >
                <MenuItem
                  onClick={() =>
                    handleNavigate(
                      !user.provider
                        ? '/configuracoes/perfil'
                        : '/configuracoes/seguranca',
                    )
                  }
                >
                  Configuração
                </MenuItem>
                <MenuItem
                  className="logout"
                  onClick={() => {
                    trackEvent(user, 'Logout');
                    dispatch({ type: '@DESTROY_SESSION' });
                    signOut();
                  }}
                >
                  Sair
                </MenuItem>
              </Menu>
            </div>
          </S.RightContent>
        </S.Container>
      </S.Wrapper>

      {header.alert.show && (
        <div style={{ display: 'flex', marginBottom: '40px' }} />
      )}

      <DrawerNotifications
        open={openNotification}
        onClose={() => setOpenNotification(false)}
        onOpen={() => setOpenNotification(true)}
      >
        <NotificationsContent
          onClose={() => {
            setOpenNotification(false);
          }}
        />
      </DrawerNotifications>

      <DrawerNotifications
        open={openAlertas}
        onClose={() => setOpenAlertas(false)}
        onOpen={() => setOpenAlertas(true)}
      >
        <AlertsContent
          data={alertas}
          refetch={refetch}
          onClose={() => {
            setOpenAlertas(false);
          }}
        />
      </DrawerNotifications>
    </>
  );
};

export default Header;
