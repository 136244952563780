import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useTheme } from 'styled-components';

import MuiModal from '@mui/material/Modal';
import { IconButton } from '@mui/material';
import { AddCircleOutlineOutlined, CloseOutlined } from '@mui/icons-material';

import { List } from '../../components/list';

import InfoCard from 'components/Cards/InfoCard';
import GhostButton from 'components/Buttons/Ghost';
import { ReactComponent as DriversIcon } from 'images/icons/sidebar/drivers.svg';
import * as S from './styled';
import { fetchDrivers } from '../services';
import { usePlans } from 'hooks/usePlans';

export const Modal = ({ open, handleClose, handleConfirm }) => {
  const theme = useTheme();
  const { isProvider } = usePlans();
  const [selected, setSelected] = useState(null);

  const { data: drivers } = useQuery(
    ['drivers'],
    () => fetchDrivers(isProvider),
    {
      refetchOnWindowFocus: true,
    },
  );

  return (
    <MuiModal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <S.ModalContent>
        <S.Header>
          <S.Flex>
            <DriversIcon style={{ height: '24px' }} />
            <h3>Atribuir motorista ao(s) desvio(s) selecionado(s).</h3>
          </S.Flex>
          <IconButton aria-label="close" size="small" onClick={handleClose}>
            <CloseOutlined />
          </IconButton>
        </S.Header>

        <InfoCard
          variant="success"
          message="O motorista escolhido será atribuído a TODOS os desvios selecionados."
        />

        <List
          title="Selecionar motorista"
          action={
            !isProvider
              ? {
                  action: {
                    title: 'Adicionar motorista',
                    icon: <AddCircleOutlineOutlined />,
                    function: () => window.open('/motoristas'),
                  },
                }
              : null
          }
          data={drivers}
          onChange={(_, item) => setSelected(item)}
          value={selected?.value}
        />

        <S.Footer>
          <GhostButton
            customcolor={theme.palette.semantics.feedback.attention.natural}
            onClick={handleClose}
          >
            Cancelar
          </GhostButton>
          <GhostButton
            customcolor={theme.palette.semantics.feedback.success.natural}
            onClick={() => handleConfirm(selected?.value || null, selected)}
          >
            Atribuir motorista
          </GhostButton>
        </S.Footer>
      </S.ModalContent>
    </MuiModal>
  );
};
