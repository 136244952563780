import AcoesSuspensoes from 'pages/AcoesSuspensoes/AcoesSuspensoes/ListAcoes';
import AcoesSuspensoesEmpresas from 'pages/AcoesSuspensoes/AcoesSuspensoes/Empresas';
import DetalheAcoesSuspensoes from 'pages/AcoesSuspensoes/AcoesSuspensoes/Detalhe';

// pages
import ConfiguracoesAcaoSuspensoes from 'pages/AcoesSuspensoes/Configuracoes';
import Historico from 'pages/Historico';

export default {
  '/acoes-suspensoes/configuracoes': {
    defaults: [],
    back: -1,
    title: 'Configurações',
    page: <ConfiguracoesAcaoSuspensoes />,
  },
  '/logs/acoes-suspensoes/configuracoes/:id': {
    back: -1,
    title: 'Histórico da Configuração da Faixa de Ação e Suspenção',
    id: true,
    defaults: [],
    page: (
      <Historico
        back={-1}
        url="/acoes-suspensoes/faixas/:id/logs"
        formatReturn={data =>
          data.map(log => ({
            ...log,
            data: log.data ?? log.createdAt,
            evento: log.evento?.evento ?? '',
          }))
        }
      />
    ),
  },
  '/acoes-suspensoes/empresas': {
    date: false,
    back: -1,
    title: 'Ações e Suspensões',
    defaults: [],
    page: <AcoesSuspensoesEmpresas viewEmpresas />,
  },
  '/acoes-suspensoes/empresas/:id': {
    back: '/acoes-suspensoes',
    title: 'Ações e Suspensões',
    date: false,
    defaults: [],
    page: <AcoesSuspensoes />,
  },
  '/acoes-suspensoes': {
    date: false,
    title: 'Ações e Suspensões',
    pageFilterName: 'filterAcoesSuspensoesProvider',
    defaults: [],
    page: <AcoesSuspensoes />,
  },
  '/acoes-suspensoes/:id': {
    back: '/acoes-suspensoes',
    title: 'Ações e Suspensões',
    defaults: [],
    page: <DetalheAcoesSuspensoes />,
  },
  '/logs/acoes-suspensoes/:id': {
    back: -1,
    title: 'Histórico da Ação e Suspenção',
    id: true,
    defaults: [],
    page: (
      <Historico
        back={-1}
        url="/acoes-suspensoes/:id/logs"
        formatReturn={data =>
          data.map(log => ({
            ...log,
            data: log.data ?? log.createdAt,
            evento: log.evento?.evento ?? '',
          }))
        }
      />
    ),
  },
};
