import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin-top: 1rem;
`;

export const Flex = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
`;

export const Cards = styled.div`
  width: 100%;
  max-width: 200px;
  overflow-y: auto;
`;

export const TableContainer = styled.div`
  width: 100%;

  h2 {
    line-height: 20px;
  }

  p {
    margin-bottom: 0.5rem;
  }
`;
