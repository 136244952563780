// React
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// Componentes
import ModalAdd from './ModalAdd';
import ConfirmModal from 'components/ConfirmModal';
import { DoubleInputTable } from 'components/_Table/templates/doubleInput';
import FiltersGlobal from 'components/FiltersGlobal';
import { DefaultPopover } from 'components/Buttons/DefaultPopoverNew';
import ExcelModal from 'components/ExcelModalNew';

// Componentes MUI
import Grid from '@mui/material/Grid';

// Utils
import { trackEvent } from 'utils/mixpanel';
import { generateFileName } from 'utils/generateFileName';

// Services
import * as request from './services';
import { useFetchMultipleWithCache } from 'hooks/fetchFilters';

// Constants
import { columns } from './columns';
import { fields, resetFields } from './constants';

// icons
import { ReactComponent as IconAdd } from 'images/icons/components/add-btn.svg';

const Rotograma = () => {
  // selects
  const { clients, operacao, responsaveis } = useFetchMultipleWithCache();

  // Navigate
  const navigate = useNavigate();

  // Refs
  const initialize = useRef(true);

  // Location
  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes('criar')) {
      setEditModal(true);
      window.history.replaceState({}, {}, '/rotograma');
    }
  }, [location]);

  // Redux
  const filter = useSelector(state => state.filter);
  const { user } = useSelector(state => state.auth.user);
  const filtersPersist = useSelector(state => {
    return state?.filter?.filters?.rotograma;
  });

  // General States
  const [query, setQuery] = useState(null);
  const [resetTable, setResetTable] = useState(false);
  const [selectedCard, setSelectedCards] = useState(null);
  const [loadingSelection, setLoadingSelection] = useState([]);
  const [selectedEvaluations, setSelectedEvaluations] = useState(null);
  const [openExcelModal, setOpenExcelModal] = useState(false);

  const [route, setRoute] = useState(null);
  const [editModal, setEditModal] = useState(false);
  const [dataEdit, setDataEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  // --------------------- REQUESTS -------------------//

  useEffect(() => {
    if (initialize.current) {
      initialize.current = false;
    }
    setResetTable(true);
  }, [filter]);

  const {
    refetch: fetchData,
    isLoading: loadingIndex,
    isFetching,
    data: resData,
  } = useQuery(
    ['rotograma-index', query],
    () => query && request.getRotogramas(query),
    {
      refetchOnWindowFocus: false,
      onSuccess: () => resetTable && setResetTable(false),
    },
  );

  // -------------------------- BULK MENU & ACTION COLUMN---------------------------//

  const handleDelete = async id => {
    const body = {
      event: 'deleteRoute',
      data: { ids: [route] },
      id,
    };

    setLoadingSelection([id]);
    const res = await request.deleteRotograma(body);
    setLoadingSelection([]);

    if (res.data.success) {
      fetchData();
      fetchCards();
      toast.success(res.data.message);
      setDeleteModal(false);
    } else {
      toast.error(res.data.message);
    }
    trackEvent(user, `rotograma deletar`);
  };

  const downloadRoute = async id => {
    const ids = selectedEvaluations.length > 0 ? selectedEvaluations : [id];

    if (ids.length > 1) trackEvent(user, `rotograma bulk_baixar`);
    else trackEvent(user, `rotograma baixar`);

    for (const i in ids) {
      const id = ids[i];
      const data = resData?.data || [];
      for (const i in data) {
        if (data[i].arquivo && data[i].id === Number(id)) {
          const req = new XMLHttpRequest();
          req.open('GET', data[i].arquivo, true);
          req.responseType = 'blob';

          let typeArchive = null;
          let mimiType = null;

          if (data[i].arquivo.includes('.pdf')) {
            typeArchive = 'application/pdf';
            mimiType = '.pdf';
          } else if (data[i].arquivo.includes('.docx')) {
            typeArchive = 'application/docx';
            mimiType = '.docx';
          } else if (data[i].arquivo.includes('.doc')) {
            typeArchive = 'application/doc';
            mimiType = '.doc';
          } else if (data[i].arquivo.includes('.png')) {
            typeArchive = 'image/png';
            mimiType = '.png';
          } else if (data[i].arquivo.includes('.jpeg')) {
            typeArchive = 'image/jpeg';
            mimiType = '.jpeg';
          } else if (data[i].arquivo.includes('.jpg')) {
            typeArchive = 'image/jpg';
            mimiType = '.jpg';
          } else if (data[i].arquivo.includes('.ppt')) {
            typeArchive = 'application/ppt';
            mimiType = '.ppt';
          }

          req.onload = async function () {
            const blob = new Blob([req.response], { type: typeArchive });
            const url = window.URL || window.webkitURL;
            const link = url.createObjectURL(blob);
            const a = document.createElement('a');
            a.setAttribute('download', `${data[i].origem}${mimiType}`);
            a.setAttribute('href', link);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          };

          req.send();
        }
      }
    }
  };

  const actions = [
    {
      title: 'Baixar',
      function: id => downloadRoute(id),
    },
    {
      title: 'Editar',
      function: id => {
        trackEvent(user, `rotograma botao_editar`);
        setRoute(id);
        setEditModal(true);
      },
      visible: item => item?.id_da_empresa === user.filial.id_da_empresa,
    },
    {
      title: 'Excluir',
      function: id => {
        setRoute(id);
        setDeleteModal(true);
      },
      visible: item => item?.id_da_empresa === user.filial.id_da_empresa,
    },
    {
      title: 'Histórico',
      function: id => navigate(`/rotograma/historico/${id}`),
    },
  ];

  const bulk = [
    {
      title: 'Baixar',
      function: () => downloadRoute(),
    },
  ];

  // ------------------------------------MODAL ADD & EDIT-----------------------------------//
  const renderModal = () => {
    return (
      editModal && (
        <ModalAdd
          open={editModal}
          handleClose={() => {
            setEditModal(false);
            setDataEdit(null);
            setRoute(null);
          }}
          dataEdit={dataEdit}
          setUpdated={() => {
            fetchData();
            fetchCards();
          }}
        />
      )
    );
  };

  // -------------------------------------FORMAT DATA FOR EDIT-------------------------------//
  useEffect(() => {
    if (route) {
      const data = resData?.data || [];
      for (const i in data) {
        if (data[i].id === Number(route)) {
          setDataEdit(data[i]);
        }
      }
    }
  }, [route]);

  // -------------------------- EXCEL ------------------------------------------//

  const [excelFields, setExcelFields] = useState(fields);

  // const handleRequestExcel = async () => {
  //   trackEvent(user, `rotograma exportar`);
  //   const newFields = excelFields.filter(item => item.selected === true);
  //   const formatedDate = formatNameDate(new Date());

  //   const newQuery = {
  //     ...query,
  //     excelFields: newFields,
  //     ids: selectedEvaluations,
  //   };

  //   const res = await request.requestExcel(newQuery);

  //   if (!res.data.success) {
  //     toast.error(res.data.message);
  //     return;
  //   }

  //   if (res.data && res.data.data.excel)
  //     ExportToExcel({
  //       excel: res.data.data.excel,
  //       name: `rotograma_${formatedDate}`,
  //     });
  //   setExcelFields(resetFields(fields));
  // };

  // -------------------------- FiltersTransporter ------------------------------------------//
  const filtersTransporter = [
    {
      filterName: 'client',
      label: 'Clientes',
      options: clients?.data || [],
    },
    {
      filterName: 'operation',
      label: 'Operação',
      options: operacao || [],
      multiple: false,
    },
    {
      filterName: 'responsavel',
      label: 'Responsáveis',
      options: responsaveis?.data || [],
    },
  ];

  // -----------------------------------------RENDER-----------------------------------------//
  return (
    <Grid container>
      <Grid container>
        <Grid marginBottom={3} item md={12}>
          {openExcelModal && (
            <ExcelModal
              open={openExcelModal}
              handleClose={() => setOpenExcelModal(false)}
              excelFields={excelFields}
              query={{
                ...query,
              }}
              file_name={generateFileName(
                window.location.pathname.split('/').pop(),
                filtersPersist,
              )}
              route="/excel/rotograma"
              filtersPersis={filtersPersist}
              collumns={filtersTransporter}
              isManualDownload
              ids={selectedEvaluations}
            />
          )}
          <FiltersGlobal
            data={filtersTransporter}
            persistDate
            handleFilters={q => {
              setQuery(val => {
                return {
                  ...val,
                  ...q,
                };
              });
            }}
            hideRefleshButton
            customComponent={
              <DefaultPopover
                startIcon={<IconAdd />}
                onClick={() => {
                  setEditModal(true);
                  trackEvent(user, `rotograma botao_adicionar`);
                }}
              >
                Adicionar rotograma
              </DefaultPopover>
            }
            handleExport={() => {
              trackEvent(user, 'Exportar Planilha rotograma');
              setOpenExcelModal(true);
            }}
          />
        </Grid>
      </Grid>

      <Grid item md={12}>
        <DoubleInputTable
          // persist="rotograma"
          data={resData?.data || []}
          columns={columns}
          setSelectedRows={setSelectedEvaluations}
          loading={loadingIndex}
          pageCount={resData?.count || 0}
          local={false}
          bulk={bulk}
          actions={actions}
          // keys={['origem', 'destino']}
          reset={resetTable}
          searchEvent={search =>
            trackEvent(user, 'Busca Rotograma', null, search)
          }
          loadingSelection={loadingSelection}
          setQuery={q =>
            setQuery({
              ...q,
              ...filter?.rotograma,
              // ...filterRotograma,
              card: selectedCard,
            })
          }
          sortBy={{ id: 'updatedAt', order: 'DESC' }}
          empty={{
            title: 'Seus rotogramas aparecerão aqui!.',
            description:
              'Clique no botão acima e digitalize o seu primeiro Rotograma!',
          }}
        />
      </Grid>

      <ConfirmModal
        handleClose={() => setDeleteModal(false)}
        open={deleteModal}
        title="Tem certeza que deseja excluir?"
        subtitle="Rotogramas excluídos não podem mais ser recuperados"
        buttonText="Confirmar"
        onClick={() => handleDelete()}
      />

      {renderModal()}
    </Grid>
  );
};

export default Rotograma;
