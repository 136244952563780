import { useEffect, useRef, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { usePlans } from 'hooks/usePlans';
import { SaveAlt } from '@mui/icons-material';

// Components
import { DefaultTable } from 'components/_Table/templates/default';
import Header from 'pages/_templates/ListPage/components/Header';
import ExcelModal from 'components/ExcelModalNew';

import { trackEvent } from 'utils/mixpanel';
import { generateFileName } from 'utils/generateFileName';
import { columns, columnsTorrePlus, fieldsEmpresas } from './constants';

import { getAcoesEmpresa, getAcoesSuspensoes } from './services';

const AcoesSuspensoesEmpresas = () => {
  // Redux e hooks
  const navigate = useNavigate();
  const user = useSelector(state => state.auth?.user?.user);
  const filter = useSelector(state => state.filterProvider);
  const { hasTorrePlus } = usePlans();

  const [query, setQuery] = useState(null);
  const [resetTable, setResetTable] = useState(false);
  const [loadingLines, setLoadingLines] = useState([]);
  const [openExcelModal, setOpenExcelModal] = useState(false);

  const initial = useRef(true);
  const { isLoading, data: resData } = useQuery(
    ['acoes-suspensoes-empresas', query],
    () => query && getAcoesEmpresa(query),
    {
      refetchOnWindowFocus: false,
      onSuccess: () => {
        setLoadingLines([]);
        resetTable && setResetTable(false);
      },
      enabled: !initial.current,
    },
  );

  const { isLoadingTorrePlus, data: resDataTorrePlus } = useQuery(
    ['acoes-suspensoes', query],
    () => query && getAcoesSuspensoes(query),
    {
      refetchOnWindowFocus: false,
      onSuccess: () => {
        setLoadingLines([]);
        resetTable && setResetTable(false);
      },
      enabled: !initial.current,
    },
  );

  useEffect(() => {
    if (initial.current) {
      initial.current = false;
      return;
    }

    setResetTable(true);
  }, [filter]);

  const actions = [
    {
      title: 'Ver detalhe',
      function: id =>
        hasTorrePlus
          ? navigate(`/acoes-suspensoes/${id}`)
          : navigate(`/acoes-suspensoes/empresas/${id}`),
    },
  ];

  // -------------------------- Açoes do Header ------------------------------------------//
  const headerActions = useMemo(
    () => [
      {
        title: 'Exportar',
        variant: 'ghost',
        icon: <SaveAlt />,
        function: () => {
          trackEvent(user, 'EXPORTAR PLANILHA');
          setOpenExcelModal(true);
        },
      },
    ],
    [user, setOpenExcelModal],
  );

  // -------------------------- EXCEL ------------------------------------------//

  // const handleRequestExcelEmpresas = async () => {
  //   setLoadingExcel(true);
  //   const newQuery = {
  //     ...filter,
  //     excelFields: fieldsEmpresas,
  //   };

  //   const res = await requestExcelEmpresas(newQuery);
  //   if (res.link) {
  //     downloadFromLink(res.link);
  //     toast.success(res.message);
  //   } else if (res.message) toast.error(res.message);

  //   setLoadingExcel(false);
  // };

  return (
    <>
      <Header title="Ações e Suspensões" actions={headerActions} />
      {openExcelModal && (
        <ExcelModal
          open={openExcelModal}
          handleClose={() => setOpenExcelModal(false)}
          excelFields={fieldsEmpresas}
          query={{
            ...filter,
          }}
          file_name={generateFileName(
            window.location.pathname.split('/').pop(),
          )}
          route="/excel/acao_suspensao_transportadoras"
        />
      )}
      <br />
      <DefaultTable
        data={
          hasTorrePlus
            ? resDataTorrePlus?.data?.data
            : resData?.data?.data || []
        }
        columns={hasTorrePlus ? columnsTorrePlus : columns}
        loading={isLoading || isLoadingTorrePlus}
        pageCount={
          hasTorrePlus
            ? resDataTorrePlus?.data?.total
            : resData?.data?.total || 0
        }
        local={false}
        actions={actions}
        reset={resetTable}
        loadingSelection={loadingLines}
        onClickRow={id =>
          hasTorrePlus
            ? navigate(`/acoes-suspensoes/${id}`)
            : navigate(`/acoes-suspensoes/empresas/${id}`)
        }
        setQuery={q => setQuery({ ...q, ...filter })}
        searchEvent={search =>
          trackEvent(user, 'Busca Ações e Suspensões de empresa', null, search)
        }
        sortBy={{ id: hasTorrePlus ? 'pontos' : 'titulo', order: 'DESC' }}
        placeholder="Buscar Empresa"
        empty={{
          title: 'Ops! Você não tem nenhuma ação ou suspensão disponível.',
          description: 'Verifique os filtros aplicados!',
        }}
      />
    </>
  );
};

export default AcoesSuspensoesEmpresas;
