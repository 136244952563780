import { formatNewHourClean } from 'utils/dates';
import { ReactComponent as Analise } from 'images/icons/tooltip/analise.svg';
import { ReactComponent as VideoPendente } from 'images/icons/tooltip/video_pendente.svg';
import { Icon } from '../../components/IconsOnisys';

export const getTipoDesvio = infraction =>
  infraction?.desvio_tipo?.desvio_tipo_padrao?.categoria;
export const getGrupoDesvio = infraction =>
  infraction?.desvio_tipo?.desvio_tipo_padrao?.grupo;

export const getStatusColor = (status, theme) => {
  switch (status) {
    case 'ABERTO':
      return theme?.palette?.semantics?.feedback?.warning?.natural;
    case 'PENDENTE':
      return theme?.palette?.semantics?.feedback?.warning?.natural;
    case 'FINALIZADO':
      return theme?.palette?.semantics?.feedback?.information?.natural;
    case 'DELETADO':
      return theme?.palette?.semantics?.feedback?.attention?.natural;
  }
};

export const getCriticidadeColor = (status, theme) => {
  switch (status) {
    case 'PRIMÁRIA':
      return theme?.palette?.semantics?.feedback?.success?.natural;
    case 'MODERADA':
      return theme?.palette?.semantics?.feedback?.information?.natural;
    case 'GRAVE':
    case 'GRAVÍSSIMA':
      return theme?.palette?.semantics?.feedback?.attention?.natural;
    default:
      theme?.palette?.semantics?.feedback?.unknown?.natural;
  }
};

export const statusTabs = [
  { value: 'ABERTO', label: 'Abertos' },
  { value: 'PENDENTE', label: 'Pendentes' },
  { value: 'FINALIZADO', label: 'Finalizados' },
  { value: 'DELETADO', label: 'Excluídos' },
];

export const iconsCards = {
  proximo_vencimento: 'schedule.svg',
  criticidade_alta: 'Grupo12754.svg',
  exclusao_revisao: 'warning.svg',
  desvios_analise: 'analise.svg',
};

export const formatArrFilters = filters => {
  const _filters = {};
  Object.keys(filters).forEach(key => {
    if (filters[key] && !['status', 'sortBy'].includes(key)) {
      // Converte números para string e divide por vírgula
      if (typeof filters[key] === 'number') {
        _filters[key] = [`${filters[key]}`];
      }
      // Divide strings por vírgula, se for possível
      else if (typeof filters[key] === 'string') {
        _filters[key] = filters[key].split(',');
      }
      // Ignora valores que não são strings ou números
      else {
        _filters[key] = [filters[key]];
      }
      return;
    }
    // Para 'status' e 'sortBy', mantém o valor original
    _filters[key] = filters[key];
  });

  return _filters;
};

export const hasReview = desvio => {
  const review = desvio.aprovado_distribuidora;
  let msg = '';

  // Revisão de exclusão
  if (desvio.data_exclusao) {
    if (review === 0) msg = 'Exclusão em revisão pelo cliente.';
    else if (review === 2) msg = 'Exclusão recusada pelo cliente.';
    else if (review === 3)
      msg = 'Cliente pediu mais informações para aprovar exclusão.';
  }

  // Revisão de troca de operação
  else if (desvio.data_alteracao_distribuidora) {
    if (!desvio.data_revisao_distribuidora)
      msg = 'Troca de operação em revisão pelo cliente';
    else msg = 'Troca de operação recusada pelo cliente';
  }

  return msg;
};

export const columns = [
  {
    header: 'Id',
    id: 'id',
    type: 'number',
    sort: true,
    width: 100,
  },
  {
    header: 'Motorista/Filial',
    id: 'motorista_nome',
    type: 'string',
    tooltip: true,
    sort: true,
    conditional: [
      {
        condition: (_, item) => !!item.id_motorista,
        style: theme => ({
          color: theme?.palette?.words.title.natural,
        }),
      },
      {
        condition: (_, item) => !item.id_motorista,
        style: theme => ({
          color: theme?.palette?.words.subtitle.light,
        }),
      },
    ],
    subRow: {
      children: (_, item) =>
        item.id_motorista ? (
          <span style={{ textTransform: 'none' }}>
            Filial: {item?.filial_nome}
          </span>
        ) : null,
    },
  },
  {
    header: 'Placa',
    id: 'placa',
    type: 'string',
    sort: true,
    width: 130,
    style: {
      textTransform: 'uppercase',
    },
    subRow: {
      prefix: 'Filial:',
      value: (_, item) => item?.filial_placa,
    },
  },
  {
    header: 'Desvio',
    id: 'titulo',
    type: 'string',
    sort: true,
    tooltip: true,
  },
  {
    header: 'Data Desvio',
    id: 'data_desvio',
    type: 'string',
    sort: true,
    value: (_, item) => formatNewHourClean(item?.data_desvio),
  },
  {
    header: 'Data Vencimento',
    id: 'data_vencimento',
    type: 'string',
    sort: true,
    value: (_, item) => formatNewHourClean(item?.data_vencimento),
  },
  {
    header: 'Responsável',
    id: 'responsavel_nome',
    type: 'string',
    sort: false,
    tooltip: true,
  },
  {
    header: 'Criticidade',
    id: 'criticidade',
    type: 'string',
    sort: true,
    width: 200,
    align: 'center',
    style: {
      textTransform: 'uppercase',
    },
    conditional: [
      {
        condition: value => value === 'PRIMÁRIA',
        style: theme => ({
          color: theme?.palette?.semantics?.feedback?.success?.natural,
          backgroundColor: theme?.palette?.semantics?.feedback?.success?.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => value === 'MODERADA',
        style: theme => ({
          color: theme?.palette?.semantics?.feedback?.information?.natural,
          backgroundColor:
            theme?.palette?.semantics?.feedback?.information?.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => value === 'GRAVE',
        style: theme => ({
          color: theme?.palette?.semantics?.feedback?.warning?.natural,
          backgroundColor: theme?.palette?.semantics?.feedback?.warning?.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => value === 'GRAVÍSSIMA',
        style: theme => ({
          color: theme?.palette?.semantics?.feedback?.attention?.natural,
          backgroundColor:
            theme?.palette?.semantics?.feedback?.attention?.light,
          textAlign: 'center',
        }),
      },
    ],
  },
  {
    header: 'Inf.',
    id: '',
    type: 'string',
    width: 70,
    align: 'center',
    attention: (_, item) => {
      if (item.em_analise)
        return {
          text:
            item.em_analise === 'ANALISE'
              ? 'Desvio em análise.'
              : 'Desvio com retorno de análise.',
          iconIn: <Analise />,
          iconOut: <Analise />,
        };
      const msg = hasReview(item);

      if (msg)
        return {
          text: msg,
        };

      if (!(item.imagem || item.video || item.arquivo_evidencia))
        return {
          iconIn: null,
          iconOut: <VideoPendente />,
          text: 'Sem mídia.',
        };
    },
  },
];

export const columnsEmbarcador = [
  columns[0],
  {
    header: 'Empresa',
    id: 'empresa_nome',
    type: 'string',
    tooltip: true,
    sort: true,
    subRow: {
      prefix: 'FIlial:',
      value: (_, item) => item?.filial_nome,
    },
  },
  ...columns.slice(2),
];

export const fields = [
  {
    label: 'ID',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 2,
  },
  {
    label: 'Responsáveis',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 2,
  },
  {
    label: 'Criticidade',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 2,
  },
  {
    label: 'Pontos',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 2,
  },
  {
    label: 'Motorista',
    selected: true,
    default: false,
    hasTorre: true,
    provider: 2,
  },
  {
    label: 'Matrícula',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 1,
  },
  {
    label: 'Plano de ação',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 2,
  },
  // {
  //   label: 'Observação',
  //   selected: true,
  //   default: false,
  //   hasTorre: false,
  //   provider: 2,
  // },
  {
    label: 'Motivo de exclusão',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 2,
  },
  {
    label: 'Observação de exclusão',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 2,
  },
  {
    label: 'Empresa',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 1,
  },
  {
    label: 'Tecnologia',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 2,
  },
  {
    label: 'Filial',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 2,
  },
  {
    label: 'Filial veículo',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 2,
  },
  {
    label: 'Placa',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 2,
  },
  {
    label: 'ID Frota',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 2,
  },
  {
    label: 'Próprio/Terceiro',
    selected: true,
    default: false,
    hasTorre: true,
    provider: 2,
  },
  {
    label: 'CPF',
    selected: true,
    default: false,
    hasTorre: true,
    provider: 2,
  },
  {
    label: 'Modelo',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 2,
  },
  {
    label: 'Desvio',
    selected: true,
    default: true,
    hasTorre: false,
    provider: 2,
  },
  {
    label: 'Status Embarcadora',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 1,
  },
  {
    label: 'Aprovado exclusão',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 1,
  },
  {
    label: 'Observações distribuidora',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 1,
  },
  {
    label: 'Velocidade lida',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 2,
  },
  {
    label: 'Velocidade limite',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 2,
  },
  {
    label: 'Data revisão distribuidora',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 1,
  },
  {
    label: 'Objeto',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 1,
  },
  {
    label: 'Comentário',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 1,
  },
  {
    label: 'Local',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 1,
  },
  {
    label: 'Usuário distribuidora',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 1,
  },
  {
    label: 'Criado por',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 1,
  },
  {
    label: 'Código viagem GR',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 2,
  },
  {
    label: 'Data hora desvio',
    selected: true,
    default: true,
    hasTorre: false,
    provider: 2,
  },
  {
    label: 'Data hora criação',
    selected: true,
    default: true,
    hasTorre: false,
    provider: 2,
  },
  {
    label: 'Data hora vencimento',
    selected: true,
    default: true,
    hasTorre: false,
    provider: 2,
  },
  {
    label: 'Data hora previsão',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 2,
  },
  {
    label: 'Data hora exclusão',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 2,
  },
  {
    label: 'Data vídeo',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 2,
  },
  {
    label: 'Data hora envio tecnologia',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 2,
  },
  {
    label: 'Data hora envio API',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 2,
  },
  {
    label: 'Data hora tratativa',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 2,
  },
  {
    label: 'Data hora conclusão',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 2,
  },
  {
    label: 'Status',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 2,
  },
  {
    label: 'Embarcador',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 0,
  },
];

export const cardsInfo = [
  {
    type: 'ALTISSIMO',
    value: '0',
    icon: <Icon name="icone_risco_altissmo" />,
    text: 'Risco altíssimo',
    loading: false,
  },
  {
    type: 'ALTO',
    value: '0',
    icon: <Icon name="icone_risco_alto" />,
    text: 'Risco alto',
    loading: false,
  },
  {
    type: 'PERIGO',
    value: '0',
    icon: <Icon name="icone_prox_vencimento" />,
    text: 'Deslocamentos perigosos',
    loading: false,
  },
];
