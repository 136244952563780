import styled, { css } from 'styled-components';
import { keyframes } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    position: fixed;
    z-index: 1;
    top: 0;
    width: 100vw;
    height: 5.25rem;
    background: ${theme.palette.system.overlay};
    box-shadow: 0px 3px 5px #0000320d;
  `}
`;

export const BackButton = styled.button`
  width: 80px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;
  border-radius: 20px;
  transition: all 0.2s;
  margin-right: 10px;

  :hover {
    background: ${({ theme }) => theme.palette.system.highlight};
  }

  :active {
    background: ${({ theme }) => theme.palette.brand.primary.background};
  }

  h2 {
    color: ${({ theme }) => theme.palette.words.subtitle.natural};
    font: normal normal 600 16px/18px Texta;
  }
`;

export const Content = styled.div`
  width: 100vw;
  height: 100%;
  max-width: 1200px;
  margin: auto;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LogoWrapper = styled.img`
  margin-left: 2rem;
  width: 150px;
`;

export const LinksWrapper = styled.div`
  ${({ theme }) => css`
    display: none;
    @media only screen and (min-width: 900px) {
      display: flex;
    }

    margin-right: 2rem;
    gap: 1rem;
    align-items: center;
    font: normal normal 600 16px/23px Texta;
    color: ${theme.palette.words.subtitle.natural};

    a {
      text-decoration: none;
      color: ${theme.palette.words.subtitle.natural};
    }
  `}
`;

export const ShowButton = styled.div`
  ${({ theme }) => css`
    display: block;
    @media only screen and (min-width: 900px) {
      display: none;
    }

    margin-right: 2rem;
    font: normal normal 600 16px/23px Texta;
    color: ${theme.palette.words.subtitle.natural};
  `}
`;

export const Nav = styled.nav`
  ${({ theme }) => css`
    display: none;
    @media only screen and (min-width: 900px) {
      display: flex;
    }

    margin-right: 1rem;
    justify-content: space-between;

    ul {
      display: flex;
      align-items: center;
      font: normal normal 600 16px/23px Texta;
      color: ${theme.palette.words.subtitle.natural};
      li {
        margin-right: 1rem;
      }
    }
    a {
      text-decoration: none;
      color: ${theme.palette.words.subtitle.natural};
    }
  `}
`;

const menuAnimation = keyframes`
  0% {
    transform: scaleY(0)
  }
  100% {
    transform: scaleY(1)
  }
`;

export const MobileMenu = styled.div`
  ${({ theme, active }) => css`
    background-color: ${theme.palette.system.border};
    display: ${active ? 'block' : 'none'};
    justify-content: center;
    align-items: center;
    padding-bottom: 1rem;
    animation: ${menuAnimation} 300ms ease-in-out forwards;
    transform-origin: top center;

    ul {
      font: normal normal 600 16px/23px Texta;
      color: ${theme.palette.words.subtitle.natural};
      li {
        padding: 1rem;
      }
    }
    a {
      text-decoration: none;
      color: ${theme.palette.words.subtitle.natural};
    }
  `}
`;
