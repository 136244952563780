import React, { useState } from 'react';
import { useQuery } from 'react-query';

import { Popover } from '@mui/material';
import {
  AddCircleOutlineOutlined,
  ArrowDropDown,
  ArrowDropUp,
} from '@mui/icons-material';

import { List } from '../../components/list';

import * as S from './styled';
import { fetchDrivers } from '../services';
import { usePlans } from 'hooks/usePlans';

export const Select = ({ onSelect }) => {
  const { isProvider } = usePlans();
  const [anchorEl, setAnchorEl] = useState(null);

  const { data: drivers } = useQuery(
    ['drivers'],
    () => fetchDrivers(isProvider),
    {
      refetchOnWindowFocus: true,
    },
  );

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (_, item) => {
    onSelect instanceof Function && onSelect(item);
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'select-motorista' : undefined;

  return (
    <>
      <S.Button aria-describedby={id} onClick={handleClick}>
        Atribuir motorista {open ? <ArrowDropUp /> : <ArrowDropDown />}
      </S.Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <S.ModalContent>
          <List
            title="Selecionar motorista"
            action={
              !isProvider
                ? {
                    action: {
                      title: 'Adicionar motorista',
                      icon: <AddCircleOutlineOutlined />,
                      function: () => window.open('/motoristas'),
                    },
                  }
                : null
            }
            data={drivers}
            onChange={handleSelect}
          />
        </S.ModalContent>
      </Popover>
    </>
  );
};
