import React, { Fragment, useEffect, useState } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { useTheme } from 'styled-components';
import { toast } from 'react-toastify';

import { Tooltip, SvgIcon, Grid, IconButton } from '@mui/material';
import {
  AddCircleOutlineOutlined,
  DeleteOutline,
  EditOutlined,
  VisibilityOutlined,
} from '@mui/icons-material';
import Icon from 'components/Icons';
import GhostButton from 'components/Buttons/Ghost';
import Switch from 'components/Inputs/_withController/Switch';
import TextField from 'components/Inputs/_withController/TextField';
import SelectCreatable from 'components/Inputs/_withController/SelectCreatable';

import { ReactComponent as add } from 'images/Grupo 12749.svg';
import { ReactComponent as CheckIcon } from 'images/icons/inputs/check.svg';
import { ReactComponent as shortCut } from 'images/icons/telemetria/navigate.svg';

import * as S from './styled';
import { usePlans } from 'hooks/usePlans';
import * as services from '../../../services';

const defaultValues = {
  id: null,
  ativo: false,
  cor: '#0c12f2',
  limiar: '',
  id_acao_disciplinar: null,
  id_conteudo: null,
  is_obrigatorio: 0,
  reincidencias: [],
};

const Form = React.forwardRef(
  (
    {
      setAcaoDisciplinarModal,
      handleClose,
      registro,
      modulosCapacitacao,
      acoesDisciplinares,
      acoes,
      refetch,
      disabled,
    },
    ref,
  ) => {
    const theme = useTheme();
    const [showReincidencia, setShowReincidencia] = useState(false);
    const [loading, setLoading] = useState(false);
    const { isProvider, isOpLogistico } = usePlans();
    const {
      control,
      handleSubmit,
      reset,
      formState: { errors },
      setValue,
      getValues,
      watch,
    } = useForm({ defaultValues });

    const editar = !!registro?.id && !registro?.is_sugestao;
    const link_capacitacao = `${window.location.origin}/capacitacao${
      !isProvider ? '/conteudos' : ''
    }`;

    const idTipoAcao = watch('id_acao_disciplinar');
    const { fields, append, remove } = useFieldArray({
      name: 'reincidencias',
      control,
    });

    useEffect(() => {
      if (idTipoAcao) {
        const acao = acoesDisciplinares?.find(a => a.id === idTipoAcao);
        const reincidencias = getValues('reincidencias');

        if (acao && acao.is_reincidencia) {
          setShowReincidencia(true);
          if (!reincidencias?.length) {
            setValue('reincidencias', [{ reincidencia: 1, dias: '' }]);
            setValue('id_conteudo', null);
          }
        } else {
          setShowReincidencia(false);
          setValue(
            'reincidencias',
            reincidencias.filter(item => item.id),
          );
        }
      }
    }, [idTipoAcao, acoesDisciplinares]);

    useEffect(() => {
      if (registro)
        reset({
          ...defaultValues,
          ...registro,
          limiar: registro.limiar ?? registro.min + 1,
          capacitacao: undefined,
          empresa: undefined,
          is_obrigatorio: registro.is_obrigatorio ? 1 : 0,
          reincidencias: registro.acao_suspensao_reincidencia ?? [],
        });
    }, [registro]);

    const handleSaveFaixa = async data => {
      try {
        setLoading(true);
        const req = await services.registerFaixa(data);
        if (req.success) {
          toast.success(req.message);
          refetch();
          handleClose();
        } else toast.error(req.message);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.warn('Erro ao salvar.', error);
      }
    };

    return (
      <form ref={ref} onSubmit={handleSubmit(handleSaveFaixa)}>
        <S.Container>
          <S.Header>
            <div style={{ alignItems: 'center' }}>
              <SvgIcon
                sx={{ color: theme.palette.brand.secondary.natural }}
                component={
                  disabled ? VisibilityOutlined : editar ? EditOutlined : add
                }
                fontSize="medium"
              />
              <h2>
                {disabled ? 'Visualizar' : editar ? 'Editar' : 'Adicionar'}{' '}
                Faixa Ações e Suspensões
              </h2>
            </div>

            <IconButton size="small" onClick={handleClose}>
              <Icon
                sx={{ color: theme.palette.words.subtitle.natural }}
                name="close"
              />
            </IconButton>
          </S.Header>
          <S.Main>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <S.Section>INFORMAÇÕES COMPLEMENTARES</S.Section>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Controller
                  control={control}
                  name="cor"
                  render={({ field: { value, onChange } }) => (
                    <S.ThemeSelector>
                      {[
                        theme.palette.semantics.feedback.information.natural,
                        theme.palette.semantics.feedback.warning.dark,
                        theme.palette.semantics.feedback.warning.natural,
                        theme.palette.semantics.feedback.attention.natural,
                        theme.palette.semantics.feedback.unknown.dark,
                      ].map(cor => (
                        <Fragment key={cor}>
                          <S.ThemeOption
                            color={cor}
                            onClick={() => !disabled && onChange(cor)}
                          >
                            {value === cor && <CheckIcon />}
                          </S.ThemeOption>
                        </Fragment>
                      ))}
                    </S.ThemeSelector>
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  required
                  rules={{
                    required: { value: true, message: 'Campo obrigatório.' },
                  }}
                  control={control}
                  disabled={disabled}
                  name="titulo"
                  errors={errors}
                  label="Título"
                  placeholder="Insira um título"
                />
              </Grid>

              <Grid item xs={6} sm={6}>
                <TextField
                  required
                  control={control}
                  disabled={disabled}
                  name="limiar"
                  type="number"
                  inputProps={{ min: 0 }}
                  errors={errors}
                  label="Pontuação inicial"
                  placeholder="Insira a pontuação"
                  rules={{
                    required: { value: true, message: 'Campo obrigatório.' },
                    min: {
                      value: registro?.min ? registro.min + 1 : 0,
                      message: registro?.min
                        ? 'Valor deve ser maior que a faixa anterior.'
                        : 'Valor não pode ser inferior à "0"',
                    },
                    max: registro?.limiar_final
                      ? {
                          value: registro.limiar_final,
                          message: 'Deve ser menor que a pontuação final.',
                        }
                      : undefined,
                    validate: value =>
                      !acoes.some(
                        acao => acao.limiar == value && acao.id != registro.id,
                      ) || 'Já existe configuração com essa pontuação.',
                  }}
                />
              </Grid>

              <Grid item xs={6} sm={6}>
                <Tooltip title="Numero de pontos baseado na proxima faixa.">
                  <TextField
                    control={control}
                    name="limiar_final"
                    disabled
                    type="number"
                    label="Pontuação final"
                  />
                </Tooltip>
              </Grid>

              <Grid item xs={12} sm={12}>
                <SelectCreatable
                  required
                  control={control}
                  disabled={disabled}
                  rules={{
                    required: { value: true, message: 'Campo obrigatório.' },
                  }}
                  name="id_acao_disciplinar"
                  errors={errors}
                  data={acoesDisciplinares}
                  label="Selecione a ação disciplinar"
                  searchPlaceHolder="Digite para buscar ou adicionar a Ação"
                  handleNewOption={text =>
                    setAcaoDisciplinarModal(state => ({
                      ...state,
                      create: text,
                    }))
                  }
                  handleRemoveOption={id =>
                    setAcaoDisciplinarModal(state => ({ ...state, remove: id }))
                  }
                />
              </Grid>
              {showReincidencia && (
                <>
                  {isProvider && !isOpLogistico && (
                    <Grid item xs={12} sm={12}>
                      <SelectCreatable
                        required
                        control={control}
                        disabled={disabled}
                        name="is_obrigatorio"
                        data={[
                          {
                            text: 'Sim',
                            value: 1,
                          },
                          {
                            text: 'Não',
                            value: 0,
                          },
                        ]}
                        label="Suspensão obrigatória"
                      />
                    </Grid>
                  )}
                  {fields.map((field, index) => {
                    const canRemove = !field.createdAt && !disabled;
                    if (index === 0)
                      return (
                        <Fragment key={field.id}>
                          <Grid key={field.id} item xs={12} sm={12}>
                            <TextField
                              required
                              rules={{
                                required: {
                                  value: true,
                                  message: 'Campo obrigatório.',
                                },
                              }}
                              control={control}
                              disabled={disabled}
                              name={`reincidencias[${index}].dias`}
                              errors={errors}
                              label="Dias de suspensão (1ª incidencia)"
                              placeholder="Insira os dias de suspensão"
                            />
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <SelectCreatable
                              control={control}
                              disabled={disabled}
                              name={`reincidencias[${index}].id_conteudo`}
                              data={modulosCapacitacao}
                              label="Selecione um módulo de capacitação"
                              link={{
                                text: 'IR PARA CAPACITAÇÃO',
                                icon: (
                                  <SvgIcon
                                    component={shortCut}
                                    fontSize="medium"
                                  />
                                ),
                                onClick: () => window.open(link_capacitacao),
                              }}
                            />
                          </Grid>
                        </Fragment>
                      );
                    return (
                      <Fragment key={field.id}>
                        <Grid
                          item
                          xs={canRemove ? 11 : 12}
                          sm={canRemove ? 11 : 12}
                        >
                          <S.Section>REINCIDÊNCIA DE SUSPENSÃO</S.Section>
                        </Grid>
                        {canRemove && (
                          <Grid item xs={1} sm={1}>
                            <Tooltip title="Excluir faixa">
                              <IconButton
                                size="small"
                                onClick={() => remove(index)}
                              >
                                <DeleteOutline />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        )}
                        <Grid item xs={12} sm={6}>
                          <TextField
                            required
                            rules={{
                              required: {
                                value: true,
                                message: 'Campo obrigatório.',
                              },
                            }}
                            control={control}
                            disabled={disabled}
                            name={`reincidencias[${index}].reincidencia`}
                            errors={errors}
                            label="Reincidência"
                            placeholder="Insira numero de incidências"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            required
                            rules={{
                              required: {
                                value: true,
                                message: 'Campo obrigatório.',
                              },
                            }}
                            control={control}
                            disabled={disabled}
                            name={`reincidencias[${index}].dias`}
                            errors={errors}
                            label="Dias de suspensão"
                            placeholder="Insira os dias de suspensão"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <SelectCreatable
                            control={control}
                            name={`reincidencias[${index}].id_conteudo`}
                            data={modulosCapacitacao}
                            disabled={disabled}
                            label="Selecione um módulo de capacitação"
                            link={{
                              text: 'IR PARA CAPACITAÇÃO',
                              icon: (
                                <SvgIcon
                                  component={shortCut}
                                  fontSize="medium"
                                />
                              ),
                              onClick: () => window.open(link_capacitacao),
                            }}
                          />
                        </Grid>
                      </Fragment>
                    );
                  })}
                  {!disabled && (
                    <Grid item xs={12} sm={12}>
                      <S.DashedButton
                        type="button"
                        onClick={() => append({ reincidencia: '', dias: '' })}
                      >
                        <AddCircleOutlineOutlined />
                        ADICIONAR REINCIDÊNCIA
                      </S.DashedButton>
                    </Grid>
                  )}
                </>
              )}
              {!showReincidencia && (
                <Grid item xs={12} sm={12}>
                  <SelectCreatable
                    control={control}
                    name="id_conteudo"
                    errors={errors}
                    data={modulosCapacitacao}
                    disabled={disabled}
                    label="Selecione um módulo de capacitação"
                    link={{
                      text: 'IR PARA CAPACITAÇÃO',
                      icon: <SvgIcon component={shortCut} fontSize="medium" />,
                      onClick: () => window.open(link_capacitacao),
                    }}
                  />
                </Grid>
              )}

              <Grid item xs={12} sm={12}>
                <Switch
                  name="ativo"
                  control={control}
                  textOn="Ativo"
                  textOff="Inativo"
                  disabled={disabled}
                />
              </Grid>
            </Grid>
          </S.Main>

          <S.Footer>
            <div>
              <GhostButton
                customcolor={
                  theme?.palette?.semantics?.feedback?.attention?.natural
                }
                onClick={handleClose}
                size="medium"
              >
                {disabled ? 'Voltar' : 'Cancelar'}
              </GhostButton>

              {!disabled && (
                <GhostButton
                  type="submit"
                  size="medium"
                  sx={{ marginLeft: '20px' }}
                  loading={loading}
                >
                  Salvar
                </GhostButton>
              )}
            </div>
          </S.Footer>
        </S.Container>
      </form>
    );
  },
);

export default Form;
