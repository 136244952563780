import { Table } from '../../components';

export const DoubleInputTable = ({
  data,
  bulk,
  empty,
  reset,
  sizes,
  sortBy,
  persist,
  columns,
  actions,
  loading,
  setQuery,
  pageCount,
  emptyFill,
  hideLines,
  onClickRow,
  fixedItems,
  searchKeys,
  searchEvent,
  visibleBulk,
  notClickable,
  actionsTitle,
  cachePerPage,
  local = true,
  visualizedKey,
  placeholderLeft,
  setSelectedRows,
  transparent,
  placeholderRight,
  loadingSelection,
  selectedByDefault,
  keys = ['origem', 'destino'],
}) => {
  return (
    <Table.Provider persist={persist}>
      <Table.Container transparent={transparent}>
        {!local && <Table.Dynamic setQuery={setQuery} keys={keys} />}
        <Table.Header transparent={transparent}>
          <Table.DoubleInput
            local={local}
            loading={loading}
            searchEvent={searchEvent}
            placeholderLeft={placeholderLeft}
            placeholderRight={placeholderRight}
            keys={keys}
          />
          <Table.Bulk options={bulk} disabled={loading} visible={visibleBulk} />
        </Table.Header>
        <Table.Body
          data={data}
          local={local}
          empty={empty}
          reset={reset}
          sortBy={sortBy}
          columns={columns}
          actions={actions}
          transparent={transparent}
          loading={loading}
          pageCount={pageCount}
          emptyFill={emptyFill}
          hideLines={hideLines}
          onClickRow={onClickRow}
          fixedItems={fixedItems}
          searchKeys={searchKeys}
          cachePerPage={cachePerPage}
          actionsTitle={actionsTitle}
          notClickable={notClickable}
          visualizedKey={visualizedKey}
          setSelectedRows={setSelectedRows}
          loadingSelection={loadingSelection}
          selectedByDefault={selectedByDefault}
        />
      </Table.Container>
      <Table.Footer>
        <Table.Counter
          sizes={sizes}
          fixedValue={fixedItems}
          disabled={loading}
        />
        <Table.Pagination disabled={loading} />
      </Table.Footer>
    </Table.Provider>
  );
};
