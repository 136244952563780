import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Ranking from 'pages/_templates/Ranking';
import { startOfDay, addDays } from 'date-fns';

import { trackEvent } from 'utils/mixpanel';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { usePlans } from 'hooks/usePlans';
import { useQuery } from 'react-query';

import { requestDriversRanking, requestDriversResumo } from './services';
import { categorias, columns, getExcelFields, fields } from './constants';
import { getRouterQueryParams } from 'utils/router-query-params';
import { useFetchMultipleWithCache } from 'hooks/fetchFilters';
import { generateFileName } from 'utils/generateFileName';

import ExcelModal from 'components/ExcelModalNew';

const RankingMotoristas = () => {
  const navigate = useNavigate();
  const { planosAtivos, isOpLogistico, isProvider, hasTorrePlus } = usePlans();
  const user = useSelector(state => state.auth?.user?.user);
  const filter = useSelector(state => state.filter);
  const selects = useSelector(state => state.selects);
  const filterRanking = useSelector(state =>
    isProvider ? state.filterRankingDriverProvider : state.filterRankingDriver,
  );
  const empresas =
    selects?.empresas?.map(i => {
      return { label: i.nome, value: i.id };
    }) || [];

  const [openExcelModal, setOpenExcelModal] = useState(false);

  const filtersPersist = useSelector(state => {
    return state?.filter?.filters?.rankings;
  });

  const {
    motoristasTipos,
    veiculos,
    filiais,
    clients,
    operacao,
    desviosTiposPadrao,
  } = useFetchMultipleWithCache();

  const fieldsTransporter = [
    {
      filterName: 'filial',
      label: 'filiais',
      filterName: 'filial',
      label: 'filiais',
      options: filiais,
    },
    {
      filterName: 'client',
      label: 'cliente',
      options: clients.data,
    },
    {
      filterName: 'desvio',
      label: 'Desvios',
      options: desviosTiposPadrao.data,
    },
    {
      filterName: 'motoristas',
      filterName: 'motoristas',
      label: 'Motorista',
      options: motoristasTipos,
    },
    {
      filterName: 'veiculos',
      label: 'Veículos',
      options: veiculos.data,
    },
    {
      filterName: 'operation',
      label: 'Operação',
      options: operacao,
    },
  ];

  let fieldsProvider = [
    {
      filterName: 'empresas',
      label: 'Empresas',
      options: empresas,
    },
    {
      filterName: 'desvio',
      label: 'Filtrar por desvios',
      options: desviosTiposPadrao.data,
    },
    {
      filterName: 'modorista',
      label: 'Motorista',
      options: motoristasTipos,
      multiple: false,
    },
    {
      filterName: 'veiculos',
      label: 'Veículos',
      options: veiculos.data,
      multiple: false,
    },
    {
      filterName: 'operation',
      label: 'Operação',
      options: operacao,
      multiple: false,
    },
  ];

  const [tab, setTab] = useState('geral');
  const [query, setQuery] = useState({
    initialDate: startOfDay(addDays(new Date(), -30)),
    finalDate: new Date(),
    client: '',
    filial: '',
    filial_veiculo: '',
    operation: '',
    dateLabel: 'Últimos 30 dias',
    tipo: 'geral',
    empresas: '',
    sort: '',
  });
  const [loadingTab, setLoadingTab] = useState(false);
  const [podium, setPodium] = useState([]);

  const order = getRouterQueryParams({ location: useLocation }).get('order');

  const {
    isFetching,
    isLoading,
    data: resData,
  } = useQuery(
    ['ranking', query, tab],
    () => query && requestDriversRanking({ ...query, tipo: tab }),
    {
      refetchOnWindowFocus: false,
      onSuccess: res => {
        const podium = res?.data?.podio || null;
        if (podium)
          setPodium(
            podium.map(item => ({
              nameOne: item.nome ?? item.nome_motorista,
              position: item.posicao,
              oldPosition: item.posicao_anterior
                ? item.posicao_anterior > item.posicao
                  ? 'UP'
                  : item.posicao_anterior < item.posicao
                  ? 'DOWN'
                  : 'EQUAL'
                : 'UP',
              logo: item.foto,
              value: item.valor ?? item.total_pontos,
            })),
          );
      },
      onSettled: () => {
        setLoadingTab(false);
      },
    },
  );

  const {
    isFetching: isFetchingResumo,
    isLoading: isLoadingResumo,
    data: resResumo,
  } = useQuery(
    ['ranking-resumo', query, tab],
    () => query && requestDriversResumo({ ...query, tipo: tab }),
    {
      refetchOnWindowFocus: false,
      onSettled: () => {
        setLoadingTab(false);
      },
    },
  );

  // Troca do tab (filterDesvios.status)
  const handleStatusTab = async (event, newValue) => {
    // setSelectedCard(null);
    setLoadingTab(true);
    setTab(newValue);
  };

  // Configura as tabs da tela
  const headerTabs = {
    value: tab,
    items: categorias.filter(
      item =>
        !item.plan ||
        planosAtivos.some(plano => plano.id_do_plano === item.plan),
    ),
    onChange: handleStatusTab,
    disabled: isLoading,
  };

  const initial = useRef(true);
  useEffect(() => {
    if (tab === 'geral') {
      setQuery({
        ...query,
        tipo: tab,
      });
      return;
    }
    if (initial.current) {
      initial.current = false;
    }
  }, [filterRanking, tab]);

  // const handleExportar = useCallback(
  //   async query => {
  //     trackEvent(user, 'EXPORTAR RANKING');
  //     setLoadingExcel(true);

  //     const newFields = getExcelFields(query.tipo).filter(
  //       item =>
  //         !item.plans ||
  //         planosAtivos.some(({ id_do_plano }) =>
  //           item.plans.includes(id_do_plano),
  //         ),
  //     );
  //     const formatedDate = formatNameDate(new Date());
  //     const newQuery = { ...query, excelFields: newFields };

  //     const res = await requestExcel(newQuery, isProvider);
  //     if (res.excel) {
  //       ExportToExcel({
  //         excel: res.excel,
  //         name: `ranking_motoristas_${formatedDate}`,
  //       });
  //       toast.success(res.message);
  //     } else if (res.message) toast.error(res.message);

  //     setLoadingExcel(false);
  //   },
  //   [planosAtivos],
  // );

  const formattedColumns = useMemo(
    () =>
      columns[tab]
        ?.filter(
          col =>
            !col.plans ||
            planosAtivos.some(({ id_do_plano }) =>
              col.plans.includes(id_do_plano),
            ),
        )
        .map(item => {
          if (item.id === 'nome_motorista') {
            if (hasTorrePlus)
              return {
                ...item,
                subRow: {
                  value: (_, item) => item?.nome_empresa,
                },
              };
            if (!isOpLogistico)
              return {
                ...item,
                subRow: {
                  children: (_, item) => (
                    <span style={{ textTransform: 'none' }}>
                      Filial: {item?.nome_filial}
                    </span>
                  ),
                },
              };
          }
          return item;
        }),
    [query, isOpLogistico],
  );

  const actions = [
    {
      title: 'Ver perfil',
      function: (id, item) => {
        if (item.id_motorista) navigate(`/motoristas/${item.id_motorista}`);
        else
          toast.error('Não foi possível acessar os detalhes deste motorista.');
      },
    },
  ];

  const formatResumo = useMemo(() => {
    return fields[tab]
      .filter(
        item =>
          !item.plans ||
          planosAtivos.some(({ id_do_plano }) =>
            item.plans.includes(id_do_plano),
          ),
      )
      .map(item => {
        const data = resResumo?.data?.data[item.id] || '';
        return {
          ...item,
          value: item.value ? item.value(data) : data,
        };
      });
  }, [resResumo, fields, tab]);

  return (
    <>
      {openExcelModal && (
        <ExcelModal
          open={openExcelModal}
          handleClose={() => setOpenExcelModal(false)}
          excelFields={getExcelFields(tab).filter(
            item =>
              !item.plans ||
              planosAtivos.some(({ id_do_plano }) =>
                item.plans.includes(id_do_plano),
              ),
          )}
          query={{
            ...query,
          }}
          file_name={generateFileName(
            window.location.pathname.split('/').pop(),
            filtersPersist,
          )}
          route={
            isProvider
              ? '/excel-provider/motoristas_ranking'
              : '/excel/motoristas_ranking'
          }
          filtersPersis={filtersPersist}
          collumns={isProvider ? fieldsProvider : fieldsTransporter}
          isManualDownload
        />
      )}
      <Ranking
        title="Ranking"
        headerInfo=""
        handleFilters={val => setQuery({ ...query, ...val })}
        // headerActions={() => handleExportar({ ...query, tipo: tab })}
        headerActions={() => {
          trackEvent(user, 'Exportar Planilha Ranking Motoristas');
          setOpenExcelModal(true);
        }}
        headerTabs={headerTabs}
        podium={podium}
        loading={isFetching}
        tableProps={{
          loading: isLoading || loadingTab,
          data: resData?.data?.data
            ? resData.data.data.map(item => ({
                ...item,
                media: resData.data.media,
              }))
            : [],
          columns: formattedColumns,
          actions,
          searchKeys: ['nome_motorista'],
          placeholder: 'Buscar por Motorista',
          sortBy: { id: 'posicao', order: order ?? 'ASC' },
          searchEvent: search =>
            trackEvent(user, 'Busca Ranking de Motoristas', null, search),
          empty: {
            image: 'motorista.png',
            title: 'Ops! Não foram encontrados motoristas para essa busca.',
            description:
              'Altere os filtros ou faça o cadastro do novo motorista!',
          },
        }}
        resumo={formatResumo}
      />
    </>
  );
};

export default RankingMotoristas;
