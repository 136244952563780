import styled from 'styled-components';

export const ChartContainer = styled.div``;

export const Title = styled.h3`
  margin-bottom: 5px;
  color: #333;
`;

export const Meta = styled.p`
  color: #ff8040;
  margin-bottom: 20px;
`;

export const BarChart = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ContainerBarChart = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.span`
  color: ${({ color }) => color};
  font-size: 14px;
  margin-bottom: 2px;
`;

export const BarContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Bar = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ color }) => `${color}22`};
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  height: 20px;
  flex: 1;

  &::before {
    content: '';
    background-color: ${({ color }) => color};
    width: ${({ value }) => value}%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 2px;
    transition: width 0.3s ease-in-out;
  }
`;

export const Value = styled.span`
  position: relative;
  padding-left: 5px;
  color: ${({ color }) => color};
`;
