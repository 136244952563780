const colors = {
  greenSucces: '#1BC5BD',
  greenMain: '#ff8040',
  greenLighter: '#FFDDD3',
  orangeAlert: '#FFA801',
  orangeLighter: '#FFA8011A',
  orangeStatus: '#FF9931',
  redDanger: '#f64e60',
  redDangerText: '#f64e601A',
  blueInfo: '#0c12f2',
  blueTitle: '#494F65',
  blueBackground: '#D7EBFF',
  blueHeaderBackground: '#373f54',
  whiteBackground: '#F9FBFD',
  greyTitle: '#4B5166',
  greySubtitle: '#656E8C',
  greyBorder: '#EDF2F9',
  greyBackgroud: '#EDF2F9CC',
  grey: '#939aab',
  greyTiny: '#CAD4E4',
  white: '#FFFFFF',
  black: '#000032',
  redBackground: '#FEE5E7',
  orangeBackground: '#FFF6E5',
  greenBackground: '#1BC5BD20',
  greenMainBackground: '#BFE74230',
};

const paletteColors = {
  default: [
    '#FFA801',
    '#0C12F2',
    '#1BC5BD',
    '#ff8040',
    '#f64e60',
    '#939aab',
    '#656E8C',
    '#4B5166',
    '#2E3240',
    '#70AED4',
    '#486F88',
    '#4AF20C',
    '#6D50C7',
    '#008F7A',
    '#3B4D8C',
    '#04004A',
    '#486F88',
    '#B0C6D4',
    '#9CA9D9',
  ],
  status: ['#2E3240', '#ff8040', '#656E8C'],
  critical: [
    colors.greenMain,
    colors.blueInfo,
    colors.orangeAlert,
    colors.redDanger,
  ],
  'critical-reverse': [
    colors.redDanger,
    colors.orangeAlert,
    colors.blueInfo,
    colors.greenMain,
  ],
  'critical-duo': [colors.redDanger, colors.greenMain],
  'critical-first': [
    colors.greenSucces,
    colors.greenMain,
    colors.orangeAlert,
    colors.redDanger,
  ],
  status: [colors.orangeAlert, colors.blueInfo, colors.greenMain],
  meta: ['#0C12F2', colors.redDanger],
  radar: ['#4b5166', '#f64e60'],
};

export { paletteColors, colors };

export default colors;
