import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Rectangle,
} from 'recharts';

const CustomBarShape = props => {
  const { fill, x, y, width, height } = props;

  return (
    <Rectangle
      x={x}
      y={y}
      width={width}
      height={height}
      radius={[2, 2, 2, 2]}
      fill={fill}
    />
  );
};

const BarChartVertical = ({ data = [] }) => {
  return (
    <ResponsiveContainer width="100%" height={80}>
      <BarChart data={data} layout="horizontal" margin={{ bottom: 5, top: 5 }}>
        <YAxis dataKey="value" type="number" hide />
        <XAxis
          dataKey="name"
          type="category"
          axisLine={false}
          tickLine={false}
          tick={{ fontSize: 12 }}
        />
        <Bar
          dataKey="value"
          fill="#939aab"
          barSize={18}
          shape={<CustomBarShape />}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartVertical;
