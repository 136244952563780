import {
  getStatusEmpresa,
  getVeiculos,
  getMotoristas,
  getDesviosTiposPadrao,
} from 'constants/_SERVICES/provider';

// PAGES
import Empresas from 'pages/Provider/Empresas';
import LogEmpresa from 'pages/Provider/Empresas/History';
import { PerfilEmpresa } from 'pages/Provider/Empresas/PerfilEmpresa';
import RankingEmpresas from 'pages/Provider/RankingEmpresas';
import { EmpresasEstatisticas } from 'pages/Estatisticas/Empresas';

export default {
  '/empresas': {
    title: 'Empresas',
    back: -1,
    date: false,
    defaults: [], // ['operations'],
    pageFilterName: 'filterEmpresaProvider',
    page: <Empresas />,
  },
  '/empresas/:id': {
    title: 'Perfil da Empresa',
    back: -1,
    defaults: [],
    page: <PerfilEmpresa />,
  },

  '/logs/empresas/:id': {
    back: -1,
    defaults: [],
    page: <LogEmpresa />,
  },

  '/empresas/ranking': {
    title: 'Ranking Empresas',
    back: -1,
    page: <RankingEmpresas />,
  },
  '/empresas/estatisticas': {
    title: 'Estatísticas',
    back: -1,
    defaults: [],
    page: <EmpresasEstatisticas />,
  },
};
