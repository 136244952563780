import styled, { css } from 'styled-components';
import { Button } from '@mui/material';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  .filter-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .main-header {
    font-family: 'Texta';
    font-style: normal;
    font-size: 24px;
    font-weight: 900;
    color: #4b5166;
  }
`;

const StyledButton = styled(Button)`
  justify-content: center;
  align-items: center;
  text-transform: none;
  color: ${props => props.textcolor};
  background: white;
  border: 1px solid #939aab4d;
  font-weight: 400;
  font-size: 16px;
  font-style: normal;
  height: 3.125rem;
  padding: 5px 15px;
  transition: opacity 300ms;
  width: 6.25rem;
`;

export const HeaderDesvios = styled.div`
  .headerText {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    padding-bottom: 0.5rem;
  }

  .sub-header {
    font-family: 'Texta';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #939aab;
    margin-left: 0.3rem;
  }
  .container {
    display: flex;
    width: 100%;
    align-items: center;
  }
  .subContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: 1.875rem;
  }
  .filters {
    flex: 1;
    flex-direction: column;
  }
  margin-bottom: 2.5rem;
`;

export const BoxGraphs = styled.div`
  border-radius: 4px;
  background-color: #ffffff;
  overflow: hidden;
  padding: 16px 32px 32px;
  margin-bottom: 56px;
  border: 1px solid rgba(149, 170, 201, 0.3);
`;
export { Container, StyledButton };
