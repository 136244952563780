import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { useTheme } from 'styled-components';
import { Divider, Grid } from '@mui/material';
import TextField from 'components/Inputs/_withController/TextField';
import TextInput from 'components/Inputs/TextField';
import DefaultButton from 'components/Buttons/Default';
import GhostButton from 'components/Buttons/Ghost';
import { Nivel } from './components/Nivel';
import { Header } from './components/Header';
import { SelectCheck } from './components/SelectCheck';
import { SelectSwitch } from './components/SelectSwitch';
import ConstantsUpdater from 'services/updateConstants';

import { validatePhone } from './constants';
import * as S from './styled';

// services
import { requestNiveis, requestUser, updateUser } from '../services';

const defaultValues = {
  nome: '',
  ativo: true,
};

const capitalizeFirstLetter = str => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const PerfilUsuario = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [original, setOriginal] = useState(null);
  const [sending, setSending] = useState(null);
  const [disabledFilials, setDisabledFilials] = useState(false);
  const theme = useTheme();
  const {
    control,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ defaultValues });

  const { isLoading } = useQuery(
    ['usuario', params.id],
    () => requestUser(params.id),
    {
      refetchOnWindowFocus: false,
      enabled: !!params.id,
      onSuccess: data => {
        const _data = { ...data, ativo: data.status === 'ATIVO' };
        setOriginal(_data);
        reset(_data);
      },
      onError: data => {
        if (data.code === 401) {
          toast.error(data.message);
          navigate('/configuracoes/usuarios');
        } else {
          toast.error('Falha ao buscar dados do usuário.');
        }
      },
    },
  );

  const { data: niveis = [] } = useQuery(
    ['niveis-usuario'],
    () => requestNiveis(),
    {
      refetchOnWindowFocus: false,
    },
  );

  // Filials e modulos redux
  const {
    filials,
    empresas: companies,
    modulos: modules,
  } = useSelector(state => {
    return state.selects;
  });

  const user = useSelector(state => state.auth?.user?.user);
  const isProvider = user.provider;

  const modulos =
    modules
      ?.filter(p => !p.sempre_disponivel)
      .map(p => ({ value: p.id, label: capitalizeFirstLetter(p.nome) })) ?? [];

  const filiais =
    filials?.map(fil => ({ value: fil.id, label: fil.nome })) ?? [];

  const empresas =
    companies?.map(com => ({ value: com.id, label: com.nome })) ?? [];

  const disabled = false;

  const nivel = watch('nivel');
  const foto = watch('foto');

  useEffect(() => {
    if (nivel === 1) {
      setValue('filiais', [...filiais.map(op => op.value)]);
      setValue('empresas', [...empresas.map(op => op.value)]);
      setValue('modulos', [...modulos.map(op => op.value)]);
      setDisabledFilials(true);
    } else {
      setDisabledFilials(false);
    }
  }, [nivel]);

  const onSubmit = async data => {
    const payload = { ...data, status: data.ativo ? 'ATIVO' : 'INATIVO' };

    try {
      setSending(true);
      const req = await updateUser(payload);
      if (req?.success) {
        toast.success(req?.message);
        refetch();
      } else toast.error(req?.message);
      setSending(false);
    } catch (error) {
      setSending(false);
      console.warn('Erro ao salvar.', error);
    }
  };

  return (
    <S.Container>
      <ConstantsUpdater names={['modulos']} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Header
          id={params.id}
          foto={foto}
          isLoading={isLoading}
          control={control}
          setValue={setValue}
          getValues={getValues}
          errors={errors}
        />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              required
              rules={{
                required: { value: true, message: 'Campo obrigatório.' },
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
                  message: 'Email inválido.',
                },
              }}
              control={control}
              disabled={disabled}
              name="email"
              errors={errors}
              label="E-mail"
              placeholder="Preencha o email"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              rules={{ validate: validatePhone }}
              control={control}
              name="telefone_de_contato"
              render={({ field: { ref, ...field } }) => (
                <InputMask
                  mask="(99) 9 9999-9999"
                  formatChars={{ 9: '[0-9]' }}
                  maskChar={null}
                  {...field}
                >
                  {() => (
                    <TextInput
                      required
                      ref={ref}
                      label="Telefone"
                      placeholder="Telefone"
                      error={!!errors.telefone_de_contato}
                      message={errors.telefone_de_contato?.message}
                    />
                  )}
                </InputMask>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              rules={{
                required: { value: true, message: 'Campo obrigatório.' },
              }}
              control={control}
              disabled={disabled}
              name="funcao"
              errors={errors}
              label="Função"
              placeholder="Preencha a função"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              rules={{
                required: { value: true, message: 'Campo obrigatório.' },
              }}
              control={control}
              disabled={disabled}
              name="matricula"
              errors={errors}
              label="Matrícula"
              placeholder="Preencha a matrícula"
            />
          </Grid>
          {errors?.nivel && (
            <Grid item xs={12}>
              <S.ErrorMessage>{errors.nivel.message}</S.ErrorMessage>
            </Grid>
          )}
          <Controller
            control={control}
            name="nivel"
            rules={{
              required: {
                value: true,
                message:
                  '*Selecione um dos perfis de acesso abaixo para continuar.',
              },
            }}
            render={({ field: { value, onChange } }) =>
              niveis.map(nivel => (
                <Grid item key={nivel.id} xs={6} md={3}>
                  <Nivel
                    nivel={nivel}
                    selected={nivel.id == value}
                    onClick={() => onChange(nivel.id)}
                  />
                </Grid>
              ))
            }
          />
        </Grid>
        <br />
        <Divider />
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <SelectSwitch
              name="modulos"
              control={control}
              disabled={disabled || disabledFilials}
              title="A quais módulos esse usuário terá acesso?"
              options={modulos}
              rules={{
                required: {
                  value: true,
                  message: '*Selecione um dos modulos abaixo para continuar.',
                },
              }}
              error={errors.modulos?.message ?? ''}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            {isProvider ? (
              <SelectCheck
                name="empresas"
                control={control}
                disabled={disabled || disabledFilials}
                title="Com quais empresas esse usuário irá trabalhar?"
                options={empresas}
                setValue={setValue}
                rules={{
                  required: {
                    value: true,
                    message:
                      '*Selecione uma das empresas abaixo para continuar.',
                  },
                }}
                error={errors.empresas?.message ?? ''}
              />
            ) : (
              <SelectCheck
                name="filiais"
                control={control}
                disabled={disabled || disabledFilials}
                title="Com quais filiais esse usuário irá trabalhar?"
                options={filiais}
                setValue={setValue}
                rules={{
                  required: {
                    value: true,
                    message:
                      '*Selecione uma das filiais abaixo para continuar.',
                  },
                }}
                error={errors.filiais?.message ?? ''}
              />
            )}
          </Grid>
        </Grid>
        <br />
        <S.Footer>
          <GhostButton
            customcolor={
              theme?.palette?.semantics?.feedback?.attention?.natural
            }
            onClick={() => reset(original ?? defaultValues)}
            size="medium"
          >
            CANCELAR
          </GhostButton>

          <GhostButton type="submit" size="medium" loading={sending}>
            {params.id ? 'SALVAR' : 'ADICIONAR USUÁRIO'}
          </GhostButton>
        </S.Footer>
      </form>
    </S.Container>
  );
};
