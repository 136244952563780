import React from 'react';
import * as S from './styled';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 12,
    padding: '1rem',
  },
}));

const Bar = ({ quantities, tooltip }) => {
  const total = Object.values(quantities).reduce(
    (sum, value) => sum + value,
    0,
  );

  const percentages = Object.entries(quantities).map(
    ([key, value], index, array) => {
      const isLeftmost = index === 0;
      const isRightmost = index === array.length - 1;
      return {
        key,
        value,
        percentage: total === 0 ? 0 : (value / total) * 100,
        color: getColor(key),
        isLeftmost,
        isRightmost,
      };
    },
  );

  function getColor(key) {
    switch (key) {
      case 'qtde_GRAVISSIMA':
        return '#f64e60';
      case 'qtde_GRAVE':
        return '#FFA801';
      case 'qtde_MODERADA':
        return '#0C12F2';
      case 'qtde_PRIMARIA':
        return '#1BC5BD';
      default:
        return '#000';
    }
  }
  return (
    <LightTooltip
      title={
        tooltip && (
          <div>
            {percentages.map(({ key, value, color }) => (
              <div
                key={key}
                style={{
                  color,
                }}
              >
                {key.substring(5)}: {value}
              </div>
            ))}
          </div>
        )
      }
    >
      <S.Container>
        {percentages.map(
          ({ key, percentage, color, isLeftmost, isRightmost }) => (
            <div
              key={key}
              className={`bar ${isLeftmost ? 'leftmost' : ''} ${
                isRightmost ? 'rightmost' : ''
              }`}
              style={{
                width: `${percentage}%`,
                backgroundColor: color,
              }}
            />
          ),
        )}
      </S.Container>
    </LightTooltip>
  );
};

export default Bar;
