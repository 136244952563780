import React, { useState } from 'react';
import { useTheme } from 'styled-components';
import {
  Icon,
  IconButton,
  Modal,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';

import GhostButton from 'components/Buttons/Ghost';
import TextField from 'components/Inputs/TextField';
import { ReactComponent as CamIco } from 'images/icons/camIndisp.svg';
import { ReactComponent as WarningIco } from 'images/icons/warning.svg';
import * as S from './styled';
import { statusJustificativa } from '../constants';

const ModalJustificativa = ({ open, handleClose, handleApply, loading }) => {
  const theme = useTheme();
  const [justify, setJustify] = useState(null);
  const [outro, setOutro] = useState('');

  const handleRadio = event => {
    const newValue = event.target.value;
    setJustify(newValue);
    setOutro('');
  };

  const handleOutro = event => {
    const newValue = event.target.value;
    if (newValue.trim() === '' && !outro) return;
    setOutro(newValue);
  };

  const handleSubmit = () => {
    if (outro) handleApply('OUTRO', outro);
    else handleApply(justify, null);
  };

  return (
    <>
      <Modal open={open} onClose={handleClose} disableEscapeKeyDown={false}>
        <S.Container>
          <S.Header>
            <div style={{ alignItems: 'center' }}>
              <CamIco fontSize="medium" />
              <h2>Justificar vídeo(s) indisponível</h2>
            </div>

            <IconButton size="small" onClick={handleClose}>
              <Icon sx={{ color: theme.palette.words.subtitle.natural }}>
                close
              </Icon>
            </IconButton>
          </S.Header>

          <S.Main>
            <h3>Selecionar motivo abaixo:</h3>

            <FormControl component="fieldset">
              <RadioGroup
                aria-label="Justificar"
                name="Justificar"
                value={justify}
                onChange={handleRadio}
              >
                {statusJustificativa.map(item => (
                  <FormControlLabel
                    value={item.value}
                    control={<Radio />}
                    label={item.label}
                    key={`radio_${item.value}`}
                  />
                ))}
              </RadioGroup>
            </FormControl>

            {justify === 'OUTRO' && (
              <TextField
                name="outro"
                value={outro}
                variant="outlined"
                label="Digite a justificativa"
                onChange={handleOutro}
                maxRows={1}
              />
            )}

            <S.StyledToast>
              <WarningIco
                strokeWidth="1.5"
                stroke={theme.palette.semantics.feedback.warning.natural}
                fill="none"
                fontSize="medium"
              />
              <h1>
                Atenção, você deverá justificar ao gestor a indisponibilidade.
              </h1>
            </S.StyledToast>
          </S.Main>

          <S.Footer>
            <GhostButton
              customcolor={
                theme?.palette?.semantics?.feedback?.attention?.natural
              }
              onClick={handleClose}
              size="medium"
            >
              Cancelar
            </GhostButton>

            <GhostButton
              onClick={handleSubmit}
              size="medium"
              sx={{ marginLeft: '20px' }}
              disabled={!justify || (justify === 'OUTRO' && outro.length < 2)}
              loading={loading}
            >
              Enviar justificativa
            </GhostButton>
          </S.Footer>
        </S.Container>
      </Modal>
    </>
  );
};

export default ModalJustificativa;
