import React, { useState } from 'react';

import DefaultButton from 'components/Buttons/Default';

import * as S from './styled';
import { Input } from '../input';

export const List = ({ title, action, data = [], value, onChange }) => {
  const [search, setSearch] = useState('');

  let listItens = search
    ? data.filter(item =>
        String(item.label).toLowerCase().includes(search.toLowerCase()),
      )
    : data;

  listItens.sort((a, b) => a.label.localeCompare(b.label));

  return (
    <S.Container>
      <S.Title>{title}</S.Title>
      {action && (
        <>
          <DefaultButton
            width="100%"
            onClick={action.function}
            icon={action.icon}
          >
            {action.title}
          </DefaultButton>
          <br />
          <br />
        </>
      )}
      <Input
        placeholder="Buscar..."
        value={search}
        onChange={({ target }) => setSearch(target.value)}
      />
      <S.ListWrapper>
        {listItens.length ? (
          listItens.slice(0, 300).map(item => (
            <S.ListItem
              key={item.value}
              className={item.value === value ? 'selected' : ''}
              onClick={() => onChange(item.value, item)}
            >
              {item.label}
            </S.ListItem>
          ))
        ) : (
          <span>Nenhum item encontrado...</span>
        )}
      </S.ListWrapper>
    </S.Container>
  );
};
