import React, { useState, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';

import { FormControl, Modal, IconButton, Icon, Grid } from '@mui/material';

import InfoCard from 'components/Cards/InfoCard';
import GhostButton from 'components/Buttons/Ghost';
import TextInput from 'components/Inputs/TextField';
import Select from 'components/Inputs/Select';
import InputAttachedFile from 'components/Inputs/InputAttachedFile2';
import RadioGroup from 'components/Inputs/RadioGroup';
import { toast } from 'react-toastify';
import * as S from './styled';

const FinishSuspensaoModal = ({
  ids,
  handleClose,
  handleConfirm,
  loading,
  isSuspensao,
}) => {
  const theme = useTheme();
  const selects = useSelector(state => {
    return state.selects;
  });
  const user = useSelector(state => {
    return state.auth?.user?.user;
  });

  const userLevel = user.nivel;
  const userFilials = user.usuario_filiais;

  const responsaveis = useMemo(() => {
    // Nivel 1 lista todos  || nivel > 1 apenas users da mesma filial
    const resps = selects.users?.filter(
      item => userLevel === 1 || userFilials.includes(item.id_da_filial),
    );
    return (
      resps.map(item => {
        return { value: item.id, name: item.nome };
      }) || []
    );
  }, [selects.users]);

  const title = useMemo(() => {
    if (isSuspensao)
      return ids.length > 1
        ? `Deseja finalizar as ações selecionados? (${ids.length})`
        : 'Deseja finalizar a ação selecionada?';
    return ids.length > 1
      ? `Deseja finalizar as suspensões selecionados? (${ids.length})`
      : 'Deseja finalizar a suspensão selecionada?';
  }, [ids, isSuspensao]);

  const subtitle = useMemo(() => {
    return ids.length > 1
      ? 'Os motoristas passarão para os status "Liberado". As alterações serão aplicadas em TODAS as ações selecionadas.'
      : 'O motorista passará para o status "Liberado".';
  }, [ids]);

  const responsavelInicial = useMemo(
    () => responsaveis.find(item => item.value === user.id),
    [responsaveis],
  );

  // Icone do header variável com titleIconName
  const titleIcon = (
    <Icon
      sx={{ color: theme.palette.brand.secondary.natural }}
      fontSize="medium"
    >
      check
    </Icon>
  );

  // Objeto de dados a serem preenchidos
  // Provável que tanha mais coisa depois
  const [errors, setErrors] = useState(null);
  const [data, setData] = useState({
    link_anexo: '',
    id_responsavel: responsavelInicial ? responsavelInicial.value : '',
  });

  const handleChange = (name, value) => {
    setData(prev => {
      return { ...prev, [name]: value };
    });
  };

  // Bloqueia botão de confirmar quando há item sem preencher
  const checkReady = () => {
    let ready = true;
    if (!data.id_responsavel) {
      ready = false;
      setErrors(_errors => ({
        ..._errors,
        id_responsavel: 'Campo obrigatório.',
      }));
    }
    if (!data.motivo_liberacao && isSuspensao) {
      ready = false;
      setErrors(_errors => ({
        ..._errors,
        motivo_liberacao: 'Campo obrigatório.',
      }));
    }

    if (!ready) {
      toast.error('Preencha os campos obrigatórios.');
    } else {
      setErrors(null);
    }
    return ready;
  };

  const handleClickConfirm = () => {
    if (checkReady()) {
      const req = {
        ...data,
        ids,
      };
      handleConfirm(req);
    }
  };

  const open = Boolean(ids);

  return (
    <Modal open={open}>
      <S.Paper>
        <S.Header>
          <S.Title>
            {titleIcon}
            <h1>{title}</h1>
          </S.Title>

          <IconButton size="small" onClick={handleClose}>
            <Icon sx={{ color: theme.palette.words.subtitle.natural }}>
              close
            </Icon>
          </IconButton>
        </S.Header>

        <InfoCard message={subtitle} />
        <Grid container rowSpacing={2} columnSpacing={2} marginY={1}>
          <Grid item xs={12} sm={12}>
            <Select
              required
              data={responsaveis}
              value={data.id_responsavel ?? ''}
              onChange={e => handleChange('id_responsavel', e.target.value)}
              name="id_responsavel"
              label="Responsável"
              error={!!errors?.id_responsavel}
            />
          </Grid>
          {isSuspensao && (
            <>
              <Grid item xs={12} sm={6}>
                <FormControl>
                  <p
                    style={{
                      color: theme.palette.words.subtitle.natural,
                      font: 'normal medium 14px/20px Texta',
                    }}
                  >
                    Motivo da liberação <span style={{ color: 'red' }}>*</span>
                  </p>
                  <RadioGroup
                    options={[
                      {
                        value: 'Treinamento já cumprido',
                        label: 'Treinamento já cumprido',
                      },
                      {
                        value: 'Suspensão já aplicada',
                        label: 'Suspensão já aplicada',
                      },
                      { value: 'Outro Motivo', label: 'Outro Motivo' },
                    ]}
                    value={data.tipo_motivo ?? ''}
                    onChange={e => {
                      handleChange('tipo_motivo', e.target.value);
                      if (e.target.value === 'Outro Motivo')
                        handleChange('motivo_liberacao', '');
                      else handleChange('motivo_liberacao', e.target.value);
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} display="flex" alignItems="end">
                <div style={{ width: '100%' }}>
                  <TextInput
                    disabled={data.tipo_motivo != 'Outro Motivo'}
                    label=""
                    placeholder="Insira o motivo"
                    value={data.motivo_liberacao ?? ''}
                    onChange={e =>
                      handleChange('motivo_liberacao', e.target.value)
                    }
                    name="motivo_liberacao"
                    error={!!errors?.motivo_liberacao}
                    message={errors?.motivo_liberacao}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12}>
                <InputAttachedFile
                  idInput="input_file_finish_desvio"
                  label="Arquivo Justificativa"
                  inputLabel="Anexar Evidência"
                  fileUrl={data.link_anexo}
                  setFileUrl={value => handleChange('link_anexo', value)}
                  fileDir={`/acoes/${ids[0]}`}
                  // Tá aqui uma gambiarra fruto do bulk action. ids[0] será a pasta do arquivo em comum.
                  // Mas como nunca é apagado não tem problema
                  // deleteFromStorage não apagar arquivo antigo
                />
              </Grid>
            </>
          )}
        </Grid>

        <S.Footer>
          <GhostButton
            customcolor={
              theme?.palette?.semantics?.feedback?.attention?.natural
            }
            size="medium"
            onClick={handleClose}
          >
            Cancelar
          </GhostButton>

          <GhostButton
            onClick={handleClickConfirm}
            size="medium"
            style={{ marginLeft: '10px' }}
            loading={loading}
          >
            Confirmar
          </GhostButton>
        </S.Footer>
      </S.Paper>
    </Modal>
  );
};

export default FinishSuspensaoModal;
