import Bar from 'components/CriticalityBar';

export const columnsPlaca = [
  {
    header: 'Placa',
    id: 'placa',
    type: 'string',
    sort: false,
  },
  {
    header: 'Desvios',
    id: 'total_desvios',
    type: 'string',
    sort: false,
    children: (_, item) => (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        {item.total_desvios}
        <Bar
          tooltip
          quantities={{
            qtde_GRAVE: Number(item?.total_grave) || 0,
            qtde_GRAVISSIMA: Number(item?.total_gravissima) || 0,
            qtde_MODERADA: Number(item?.total_moderada) || 0,
            qtde_PRIMARIA: Number(item?.total_primaria) || 0,
          }}
        />
      </div>
    ),
  },
];

export const columnsMotorista = [
  {
    header: 'Motorista',
    id: 'nome',
    type: 'string',
    sort: false,
  },
  {
    header: 'Desvios',
    id: 'total_desvios',
    type: 'string',
    sort: false,
    children: (_, item) => (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        {item.total_desvios}
        <Bar
          tooltip
          quantities={{
            qtde_GRAVE: Number(item?.total_grave) || 0,
            qtde_GRAVISSIMA: Number(item?.total_gravissima) || 0,
            qtde_MODERADA: Number(item?.total_moderada) || 0,
            qtde_PRIMARIA: Number(item?.total_primaria) || 0,
          }}
        />
      </div>
    ),
  },
];
