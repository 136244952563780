// Styles
import colors from 'styles/colors';
import { useTheme } from 'styled-components';

// React
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import React, { useState, useEffect, useRef } from 'react';

// Components
import Input from 'components/Inputs/TextField';
import { Switch } from 'components/Inputs/Switch';
import ConfirmModal from 'components/ConfirmModal';
import GhostButton from 'components/Buttons/Ghost';

// Components Material UI
import { EditOutlined } from '@mui/icons-material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Icon, IconButton, Modal, Grid } from '@mui/material';

// Utils
import { firestorage } from 'utils/firebase';
import { updateTurma, deleteTurma } from '../services';
import * as S from './styled';

const ModalAddUsuario = ({ open, dataEdit, handleClose, fetchData }) => {
  // General States
  const [loading, setLoading] = useState(false);

  // Modal Controll and States
  const [confirmAdd, setConfirmAdd] = useState(false);
  const [margin, setMargin] = useState('-550px');

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setMargin('0px');
      }, 10);
    }
  }, [open]);

  const behaviorCloseModal = () => {
    setMargin('-550px');
    setTimeout(() => {
      handleClose();
    }, 380);
  };

  // User fields
  const [nome, setNome] = useState('');
  const [status, setStatus] = useState(false);
  const [foto, setFoto] = useState(null);
  const [motoristaActive, setMotoristaActive] = useState(false);
  const [gestorActive, setGestorActive] = useState(false);
  const theme = useTheme();

  // Input file reference (for picture)
  const inputFile = useRef(null);

  // Filials redux
  const { filials } = useSelector(state => {
    return state.selects;
  });
  const [filiais, setFiliais] = useState([]);

  useEffect(() => {
    if (filials) {
      const formated = [];
      for (const i in filials) {
        formated.push({
          name: filials[i].nome,
          value: filials[i].id,
        });
      }
      setFiliais(formated);
    }
  }, [filials]);

  // Fill fields if action are edit
  useEffect(() => {
    if (!dataEdit) {
      return null;
    }
    setNome(dataEdit.nome ? dataEdit.nome : 'D');
    setFoto(dataEdit.foto);
    setStatus(dataEdit.status);
    setGestorActive(dataEdit.para_usuarios);
    setMotoristaActive(dataEdit.para_motoristas);
  }, [dataEdit]);

  // CONFIRM ACTION (FIELDS AND REQUESTS)
  const verifyFields = () => {
    if (!nome) {
      toast.error('Preencha um nome para o usuário');
      return;
    }
    setConfirmAdd(true);
  };

  const confirmCreateUser = async () => {
    setLoading(true);
    const res = await updateTurma(dataEdit.id, {
      id: dataEdit.id,
      nome,
      ativa: Number(status),
      foto,
      para_motoristas:
        motoristaActive === false && gestorActive === false
          ? true
          : motoristaActive,
      para_usuarios:
        motoristaActive === false && gestorActive === false
          ? true
          : gestorActive,
    });

    if (res.data.success) {
      behaviorCloseModal();
      setTimeout(() => {
        fetchData();
        toast.success(res.data.message);
      }, 600);
    } else {
      toast.error(res.data.message);
    }

    setConfirmAdd(false);
    setLoading(false);
  };

  // Change Photo
  // Atenção o upload da foto nao garante a finalização do usuario
  // Isso pode gerar upload desnecessario de imagens no firebase
  const updateUserImage = async () => {
    const file = inputFile.current.files[0];
    if (file) {
      try {
        setLoading(true);
        const dh = new Date().toDateString();
        if (!file) return toast.error('Não foi possível enviar sua imagem');

        const uploadTask = firestorage
          .ref(`/turmas/${dh}_${file.name}`)
          .put(file);
        uploadTask.on(
          'state_changed',
          snapShot => {},
          err => {
            console.log('Err', err);
          },
          () => {
            firestorage
              .ref(`/turmas/`)
              .child(`${dh}_${file.name}`)
              .getDownloadURL()
              .then(fireBaseUrl => {
                setFoto(fireBaseUrl);
              });
          },
        );
      } catch (error) {
        toast.error('Não foi possível enviar sua imagem');
      }
      setLoading(false);
    }
  };
  const handelDeleteTurma = () => {
    deleteTurma(dataEdit.id)
      .then(res => {
        toast.success(res.data.message);
        handleClose();
      })
      .catch(res => {
        toast.error(res.data.message);
      });
  };

  return (
    <>
      <Modal
        open={open}
        onClose={behaviorCloseModal}
        disableEscapeKeyDown={false}
      >
        <S.Container margin={margin}>
          <S.Header>
            <div style={{ alignItems: 'center' }}>
              <>
                <SettingsOutlinedIcon
                  sx={{ color: colors.greenMain }}
                  fontSize="medium"
                />
                <h2>Editar Turma</h2>
              </>
            </div>

            <IconButton size="small" onClick={behaviorCloseModal}>
              <Icon sx={{ color: colors.greySubtitle }}>close</Icon>
            </IconButton>
          </S.Header>

          <S.Main>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <S.Section>INFORMAÇÕES GERAIS</S.Section>
              </Grid>
              <Grid item xs={12} sm={12}>
                <label>
                  <input
                    type="file"
                    multiple={false}
                    ref={inputFile}
                    onChange={updateUserImage}
                    disabled={loading}
                  />
                  <div className="imgArea">
                    {foto && (
                      <span className="borderImage">
                        <img src={foto} />
                      </span>
                    )}

                    {!foto && (
                      <span className="empty">
                        {nome.length > 0 && nome[0]}
                      </span>
                    )}
                  </div>
                  <div className="textArea">
                    <EditOutlinedIcon htmlColor="#656E8C" />
                    <span>Alterar Foto</span>
                  </div>
                </label>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Input
                  required
                  label="Nome do Grupo"
                  placeholder=""
                  value={nome}
                  onChange={e => setNome(e.target.value)}
                  name="nome"
                  id="nome"
                />
              </Grid>
              <Grid container spacing={2} item xs={12} sm={12}>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  display="flex"
                  alignContent="center"
                  marginTop="25px"
                >
                  <Switch
                    check={status}
                    setCheckbox={setStatus}
                    textOn="Ativa"
                    textOff="Inativa"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} item xs={12} sm={12}>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  display="flex"
                  alignContent="center"
                  marginTop="25px"
                >
                  <Switch
                    check={motoristaActive}
                    setCheckbox={setMotoristaActive}
                    textOn="Para Motorista"
                    textOff="Para Motorista"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} item xs={12} sm={12}>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  display="flex"
                  alignContent="center"
                  marginTop="25px"
                >
                  <Switch
                    check={gestorActive}
                    setCheckbox={setGestorActive}
                    textOn="Para Gestor"
                    textOff="Para Gestor"
                  />
                </Grid>
              </Grid>
            </Grid>
          </S.Main>

          <S.Footer>
            <div>
              <GhostButton
                customcolor={
                  theme?.palette?.semantics?.feedback?.attention?.natural
                }
                onClick={behaviorCloseModal}
                size="medium"
              >
                Cancelar
              </GhostButton>

              <GhostButton
                onClick={verifyFields}
                size="medium"
                sx={{ marginLeft: '20px' }}
              >
                Salvar
              </GhostButton>
            </div>
          </S.Footer>
        </S.Container>
      </Modal>

      {/* Confirmação de adição */}
      <ConfirmModal
        open={confirmAdd}
        handleClose={() => setConfirmAdd(false)}
        title="Deseja confirmar a atualização da turma?"
        titleIcon={
          <EditOutlined sx={{ color: colors.greenMain }} fontSize="medium" />
        }
        subtitle="Atenção! Verifique todos os campos antes de confirmar a operação."
        buttonText="Confirmar"
        onClick={() => confirmCreateUser()}
        loading={loading}
      />
    </>
  );
};

export default ModalAddUsuario;
