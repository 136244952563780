import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  LabelList,
} from 'recharts';

import Tabs from 'components/FiltersGlobal/Tabs';
import Skeleton from '@mui/material/Skeleton';

import * as S from './styled';

const CustomTick = props => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="middle"
        fill="#939aab"
        transform="rotate(0)"
      >
        <tspan x={0} dy="20">
          {payload.value}
        </tspan>
      </text>
    </g>
  );
};
const _data = [
  { x: 'Jan', year: 300, meta: 400 },
  { x: 'Feb', year: 350, meta: 400 },
  { x: 'Mar', year: 200, meta: 400 },
  { x: 'Apr', year: 450, meta: 400 },
  { x: 'May', year: 500, meta: 400 },
  { x: 'Jun', year: 600, meta: 400 },
  { x: 'Jul', year: 700, meta: 400 },
  { x: 'Aug', year: 550, meta: 400 },
  { x: 'Sep', year: 400, meta: 400 },
  { x: 'Oct', year: 350, meta: 400 },
  { x: 'Nov', year: 300, meta: 400 },
  { x: 'Dec', year: 450, meta: 400 },
];

const CustomLineChart = ({
  title = '',
  data = [],
  tabs = [],
  loading = false,
  handleTabs = () => {},
  dataKey = 'CAEs',
}) => {
  const renderGraph = () => {
    return (
      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          height={300}
          data={data || []}
          margin={{ top: 20, right: 40, bottom: 0, left: 0 }}
        >
          {data?.length === 0 && (
            <text
              x="50%"
              y="50%"
              textAnchor="middle"
              dominantBaseline="middle"
              fontSize={22}
              fill="#999"
            >
              Nenhum dado encontrado, tente usar outros filtros.
            </text>
          )}
          <XAxis
            opacity={0.5}
            padding={{ left: 0, right: 0 }}
            dataKey="x"
            height={70}
            tick={<CustomTick />}
            tickLine={false}
            interval="auto"
          />

          {data?.length !== 0 && (
            <YAxis
              opacity={0.5}
              padding={{ left: 0, right: 0 }}
              tickLine={false}
              minTickGap={-20}
            />
          )}
          <Tooltip />
          <Line
            type="linear"
            dataKey={dataKey}
            name="Valor"
            stroke="#0C12F2"
            dot={{ r: 0 }}
            activeDot={{ r: 0 }}
          >
            <LabelList dataKey={dataKey} position="top" fontSize={12} />
          </Line>

          <Line
            type="linear"
            dataKey="meta"
            name="Meta"
            stroke="#f64e60"
            dot={{ r: 0 }}
            activeDot={{ r: 0 }}
          />
        </LineChart>
      </ResponsiveContainer>
    );
  };

  return (
    <S.StyledCard>
      <div>
        <h2>{title}</h2>
        <div>
          <Tabs tabs={tabs} handleApplay={handleTabs} />
        </div>
      </div>
      <div>{renderGraph()}</div>
    </S.StyledCard>
  );
};

export default CustomLineChart;
