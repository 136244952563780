import { Fragment, React, useEffect, useState } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { useTheme } from 'styled-components';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import GhostButton from 'components/Buttons/Ghost';
import {
  AddCircleOutlineOutlined,
  DeleteOutline,
  EditOutlined,
} from '@mui/icons-material';
import Icon from 'components/Icons';
import SelectCreatable from 'components/Inputs/SelectCreatable';
import { Switch } from 'components/Inputs/Switch';
import TextInput from 'components/Inputs/TextField';
import AlertModal from 'components/AlertModal';
import ConfirmModal from 'components/ConfirmModal';
import { Modal, Tooltip, SvgIcon, Grid, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { ReactComponent as add } from 'images/Grupo 12749.svg';
import { ReactComponent as shortCut } from 'images/icons/telemetria/navigate.svg';
import { ReactComponent as CheckIcon } from 'images/icons/inputs/check.svg';

import * as S from './styled';
import * as services from '../services';
import UpgradeModal from '../../components/UpgradeModal';

const PLANOS_CAPACITACAO = [3, 4];
const defaultValues = {
  id: null,
  ativo: false,
  cor: '#0c12f2',
  limiar: '',
  id_acao: null,
  id_capacitacao: null,
  reincidencias: [],
};

const AcoesSuspensaoModal = ({
  registro,
  acoes,
  handleClose,
  open,
  refetch,
}) => {
  const theme = useTheme();
  const { user } = useSelector(state => {
    return state.auth?.user;
  });
  const isProvider = user.provider;
  const contract = user?.provider
    ? user?.distribuidora?.contrato
    : user?.filial?.empresa?.contrato;
  const { plano_combinado } = contract ?? {};
  const { pageMap } = useSelector(state => {
    return state.pageMap;
  });
  const link_capacitacao = `${window.location.origin}/capacitacao${
    !isProvider ? '/conteudos' : ''
  }`;

  const [showReincidencia, setShowReincidencia] = useState(false);
  const [addDisciplinarModal, setAddDisciplinarModal] = useState(null);
  const [deleteDisciplinarModal, setDeleteDisciplinarModal] = useState(false);
  const [deleteDisciplinarErrorModal, setDeleteDisciplinarErrorModal] =
    useState(false);
  const [upgradeModal, setUpgradeModal] = useState(false);

  const { data: _acoesDisciplinares = [], refetch: refetchDisciplinares } =
    useQuery(['acoes-disciplinares'], () => services.getAcoesDisciplinares(), {
      staleTime: Infinity,
    });

  const { data: _modulosCapacitacao = [] } = useQuery(
    ['modulos_capacitacao'],
    () => services.getModulosCapacitacao(),
    { refetchOnWindowFocus: false },
  );

  const editar = !!registro?.id && !registro?.is_sugestao;

  const acoesDisciplinares = _acoesDisciplinares
    .filter(item => item.ativo)
    .map(item => ({
      text: item.nome,
      value: item.id,
      id: item.id,
      removable: !!item.is_editavel,
    }));

  const modulosCapacitacao = _modulosCapacitacao.map(modulo => ({
    id: modulo.id,
    text: modulo.titulo,
    value: modulo.id,
  }));

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = useForm({ defaultValues });

  const idTipoAcao = watch('id_acao');
  const { fields, append, remove } = useFieldArray({
    name: 'reincidencias',
    control,
  });

  useEffect(() => {
    if (idTipoAcao) {
      const acao = _acoesDisciplinares?.find(a => a.id === idTipoAcao);
      const reincidencias = getValues('reincidencias');

      if (acao && acao.is_reincidencia) {
        setShowReincidencia(true);
        if (!reincidencias.length) {
          setValue('reincidencias', [{ reincidencia: 1, dias: '' }]);
          setValue('id_capacitacao', null);
        }
      } else {
        setShowReincidencia(false);
        setValue(
          'reincidencias',
          reincidencias.filter(item => item.id),
        );
      }
    }
  }, [idTipoAcao, _acoesDisciplinares]);

  useEffect(() => {
    if (registro)
      reset({
        ...defaultValues,
        ...registro,
        limiar: registro.limiar ?? registro.min + 1,
        capacitacao: undefined,
        empresa: undefined,
      });
  }, [registro]);

  const onSubmit = async data => {
    try {
      const _data = {
        ...data,
        id: data.is_sugestao ? null : data.id,
        is_sugestao: false,
      };
      const req = await services.registerAcao(_data);

      if (req.success) {
        toast.success(req.message);
        refetch();
      } else toast.error(req.message);
      handleClose();
    } catch (error) {
      console.warn('Erro ao salvar.', error);
    }
  };

  const handleCapacitacao = ref => {
    if (isProvider) return true;

    let hasCapacitacao = true;

    if (!hasCapacitacao) {
      ref.target.blur();
      setUpgradeModal(true);
    }
  };

  const handleAddDisciplinar = async nome => {
    try {
      const req = await services.createAcaoDisciplinar(nome);
      if (req.success) {
        toast.success(req.message);
        refetchDisciplinares();
      } else toast.error(req.message);
      setAddDisciplinarModal(null);
    } catch (error) {
      console.warn('Erro ao salvar.', error);
    }
  };

  const handleDeleteDisciplinar = async id => {
    try {
      const req = await services.desactivateAcaoDisciplinar(id);

      if (req.success) {
        toast.success(req.message);
        refetchDisciplinares();
      } else if (req.status === 406) {
        setDeleteDisciplinarErrorModal(true);
      } else {
        toast.error(req.message);
      }
      setDeleteDisciplinarModal(null);
    } catch (error) {
      console.warn('Erro ao salvar.', error);
    }
  };

  const body = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <S.Container>
        <S.Header>
          <div style={{ alignItems: 'center' }}>
            <>
              {editar ? (
                <>
                  <EditOutlined
                    sx={{ color: theme.palette.brand.secondary.natural }}
                    fontSize="medium"
                  />
                  <h2>Editar Ação</h2>
                </>
              ) : (
                <>
                  <SvgIcon
                    sx={{ color: theme.palette.brand.secondary.natural }}
                    component={add}
                    fontSize="medium"
                  />
                  <h2>Adicionar Ação</h2>
                </>
              )}
            </>
          </div>

          <IconButton size="small" onClick={handleClose}>
            <Icon
              sx={{ color: theme.palette.words.subtitle.natural }}
              name="close"
            />
          </IconButton>
        </S.Header>
        <S.Main>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <S.Section>INFORMAÇÕES COMPLEMENTARES</S.Section>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Controller
                control={control}
                name="cor"
                render={({ field: { value, onChange } }) => (
                  <S.ThemeSelector>
                    {[
                      theme.palette.semantics.feedback.information.natural,
                      theme.palette.semantics.feedback.warning.dark,
                      theme.palette.semantics.feedback.warning.natural,
                      theme.palette.semantics.feedback.attention.natural,
                      theme.palette.semantics.feedback.unknown.dark,
                    ].map(cor => (
                      <Fragment key={cor}>
                        <S.ThemeOption
                          color={cor}
                          onClick={() => onChange(cor)}
                        >
                          {value === cor && <CheckIcon />}
                        </S.ThemeOption>
                      </Fragment>
                    ))}
                  </S.ThemeSelector>
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <Controller
                rules={{
                  required: { value: true, message: 'Campo obrigatório.' },
                }}
                control={control}
                name="titulo"
                render={({ field }) => (
                  <TextInput
                    required
                    error={!!errors.titulo}
                    message={errors.titulo?.message}
                    label="Título"
                    placeholder="Insira um título"
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6} sm={6}>
              <Controller
                rules={{
                  required: { value: true, message: 'Campo obrigatório.' },
                  min: {
                    value: registro?.min ? registro.min + 1 : 0,
                    message: registro?.min
                      ? 'Valor deve ser maior que a faixa anterior.'
                      : 'Valor não pode ser inferior à "0"',
                  },
                  max: registro?.valor_maximo
                    ? {
                        value: registro.valor_maximo,
                        message: 'Deve ser menor que a pontuação final.',
                      }
                    : undefined,
                  validate: value =>
                    !acoes.some(
                      acao => acao.limiar == value && acao.id != registro.id,
                    ) || 'Já existe configuração com essa pontuação.',
                }}
                control={control}
                name="limiar"
                render={({ field }) => (
                  <TextInput
                    required
                    type="number"
                    inputProps={{ min: 0 }}
                    error={!!errors.limiar}
                    message={errors.limiar?.message}
                    label="Pontuação inicial"
                    placeholder="Insira a pontuação"
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6} sm={6}>
              <Controller
                control={control}
                name="valor_maximo"
                render={({ field: { name, ref, value } }) => (
                  <Tooltip title="Numero de pontos baseado na proxima faixa.">
                    <TextInput
                      disabled
                      ref={ref}
                      name={name}
                      type="number"
                      label="Pontuação final"
                      value={value}
                    />
                  </Tooltip>
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <Controller
                control={control}
                rules={{
                  required: { value: true, message: 'Campo obrigatório.' },
                }}
                name="id_acao"
                render={({ field: { onChange, name, ref, value } }) => (
                  <SelectCreatable
                    required
                    ref={ref}
                    name={name}
                    value={value}
                    handleChange={onChange}
                    error={!!errors.id_acao}
                    message={errors.id_acao?.message}
                    data={acoesDisciplinares}
                    label="Selecione a ação disciplinar"
                    searchPlaceHolder="Digite para buscar ou adicionar a Ação"
                    handleNewOption={text => setAddDisciplinarModal(text)}
                    handleRemoveOption={id => setDeleteDisciplinarModal(id)}
                  />
                )}
              />
            </Grid>
            {showReincidencia && (
              <>
                {fields.map((field, index) => {
                  const canRemove = !field.createdAt;
                  if (index === 0)
                    return (
                      <Fragment key={field.id}>
                        <Grid key={field.id} item xs={12} sm={12}>
                          <Controller
                            rules={{
                              required: {
                                value: true,
                                message: 'Campo obrigatório.',
                              },
                            }}
                            control={control}
                            name={`reincidencias[${index}].dias`}
                            render={({ field }) => (
                              <TextInput
                                required
                                error={!!errors.reincidencias?.[index]?.dias}
                                message={
                                  errors.reincidencias?.[index]?.dias?.message
                                }
                                label="Dias de suspensão (1ª incidencia)"
                                placeholder="Insira os dias de suspensão"
                                {...field}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <Controller
                            control={control}
                            name={`reincidencias[${index}].id_capacitacao`}
                            render={({
                              field: { onChange, name, ref, value },
                            }) => (
                              <SelectCreatable
                                ref={ref}
                                name={name}
                                value={value}
                                handleChange={onChange}
                                data={modulosCapacitacao}
                                label="Selecione um módulo de capacitação"
                                onFocus={() => handleCapacitacao(ref)}
                                link={{
                                  text: 'IR PARA CAPACITAÇÃO',
                                  icon: (
                                    <SvgIcon
                                      component={shortCut}
                                      fontSize="medium"
                                    />
                                  ),
                                  onClick: () => window.open(link_capacitacao),
                                }}
                              />
                            )}
                          />
                        </Grid>
                      </Fragment>
                    );
                  return (
                    <Fragment key={field.id}>
                      <Grid
                        item
                        xs={canRemove ? 11 : 12}
                        sm={canRemove ? 11 : 12}
                      >
                        <S.Section>REINCIDÊNCIA DE SUSPENSÃO</S.Section>
                      </Grid>
                      {canRemove && (
                        <Grid item xs={1} sm={1}>
                          <Tooltip title="Excluir faixa">
                            <IconButton
                              size="small"
                              onClick={() => remove(index)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      )}
                      <Grid item xs={12} sm={6}>
                        <Controller
                          rules={{
                            required: {
                              value: true,
                              message: 'Campo obrigatório.',
                            },
                          }}
                          control={control}
                          name={`reincidencias[${index}].reincidencia`}
                          render={({ field }) => (
                            <TextInput
                              required
                              error={
                                !!errors.reincidencias?.[index]?.reincidencia
                              }
                              message={
                                errors.reincidencias?.[index]?.reincidencia
                                  ?.message
                              }
                              label="Reincidência"
                              placeholder="Insira numero de incidências"
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                      <Grid key={index} item xs={12} sm={6}>
                        <Controller
                          rules={{
                            required: {
                              value: true,
                              message: 'Campo obrigatório.',
                            },
                          }}
                          control={control}
                          name={`reincidencias[${index}].dias`}
                          render={({ field }) => (
                            <TextInput
                              required
                              error={!!errors.reincidencias?.[index]?.dias}
                              message={
                                errors.reincidencias?.[index]?.dias?.message
                              }
                              label="Dias de suspensão"
                              placeholder="Insira os dias de suspensão"
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Controller
                          control={control}
                          name={`reincidencias[${index}].id_capacitacao`}
                          render={({
                            field: { onChange, name, ref, value },
                          }) => (
                            <SelectCreatable
                              ref={ref}
                              name={name}
                              value={value}
                              handleChange={onChange}
                              data={modulosCapacitacao}
                              label="Selecione um módulo de capacitação"
                              onFocus={handleCapacitacao}
                              link={{
                                text: 'IR PARA CAPACITAÇÃO',
                                icon: (
                                  <SvgIcon
                                    component={shortCut}
                                    fontSize="medium"
                                  />
                                ),
                                onClick: () => window.open(link_capacitacao),
                              }}
                            />
                          )}
                        />
                      </Grid>
                    </Fragment>
                  );
                })}
                <Grid item xs={12} sm={12}>
                  <S.DashedButton
                    type="button"
                    onClick={() => append({ reincidencia: '', dias: '' })}
                  >
                    <AddCircleOutlineOutlined />
                    ADICIONAR REINCIDÊNCIA
                  </S.DashedButton>
                </Grid>
              </>
            )}
            {!showReincidencia && (
              <Grid item xs={12} sm={12}>
                <Controller
                  control={control}
                  name="id_capacitacao"
                  render={({ field: { onChange, name, ref, value } }) => (
                    <SelectCreatable
                      ref={ref}
                      name={name}
                      value={value}
                      handleChange={onChange}
                      error={!!errors.id_capacitacao}
                      data={modulosCapacitacao}
                      label="Selecione um módulo de capacitação"
                      onFocus={handleCapacitacao}
                      link={{
                        text: 'IR PARA CAPACITAÇÃO',
                        icon: (
                          <SvgIcon component={shortCut} fontSize="medium" />
                        ),
                        onClick: () => window.open(link_capacitacao),
                      }}
                    />
                  )}
                />
              </Grid>
            )}

            <Grid item xs={12} sm={12}>
              <Controller
                name="ativo"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Switch
                    textOn="Ativo"
                    textOff="Inativo"
                    check={value}
                    setCheckbox={() => {
                      onChange(!value);
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </S.Main>

        <S.Footer>
          <div>
            <GhostButton
              customcolor={
                theme?.palette?.semantics?.feedback?.attention?.natural
              }
              onClick={handleClose}
              size="medium"
            >
              Cancelar
            </GhostButton>

            <GhostButton
              type="submit"
              size="medium"
              sx={{ marginLeft: '20px' }}
            >
              Salvar
            </GhostButton>
          </div>
        </S.Footer>
      </S.Container>
    </form>
  );

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
      {upgradeModal && (
        <UpgradeModal
          open={upgradeModal}
          handleClose={() => {
            setUpgradeModal(false);
          }}
        />
      )}
      {!!addDisciplinarModal && (
        <ConfirmModal
          open={!!addDisciplinarModal}
          handleClose={() => setAddDisciplinarModal(null)}
          title={`Tem certeza que deseja salvar "${addDisciplinarModal}"?`}
          titleIcon={
            <AddCircleOutlineOutlined
              sx={{ color: theme.palette.semantics.feedback.success.natural }}
              fontSize="medium"
            />
          }
          buttonText="Confirmar"
          onClick={() => handleAddDisciplinar(addDisciplinarModal)}
        />
      )}
      {!!deleteDisciplinarModal && (
        <ConfirmModal
          open={!!deleteDisciplinarModal}
          handleClose={() => setDeleteDisciplinarModal(null)}
          title="Tem certeza que deseja desativar esse registro?"
          titleIcon={
            <DeleteOutline
              sx={{ color: theme.palette.semantics.feedback.attention.natural }}
              fontSize="medium"
            />
          }
          buttonText="Confirmar"
          onClick={() => handleDeleteDisciplinar(deleteDisciplinarModal)}
        />
      )}
      {!!deleteDisciplinarErrorModal && (
        <AlertModal
          open={!!deleteDisciplinarErrorModal}
          backButtonText="Cancelar"
          handleClose={() => setDeleteDisciplinarErrorModal(null)}
          title="Não é possível excluir esta ação"
          subtitle="Existe uma ou mais faixas que utilizam esta ação, desative a faixa para poder excluir a ação."
          buttonText="Entendi"
          onClick={() => setDeleteDisciplinarErrorModal(null)}
        />
      )}
    </div>
  );
};

export default AcoesSuspensaoModal;
