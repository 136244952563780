import styled from 'styled-components';

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
`;

const InvitationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  text-decoration: underline;
  font: normal normal 900 16px/24px Texta;
  letter-spacing: 0px;
  color: #ff8040;
  user-select: none;
  &:hover {
    opacity: 0.8;
  }
`;

const ContentDialogConfirm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  width: 100%;
  height: 100%;
  .title-dialog {
    text-align: left;
    font: normal normal 900 24px/30px Texta;
    letter-spacing: 0px;
    color: #494f65;
    opacity: 1;
  }
  .container-info-empresa {
    padding-left: 24px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    background: #edf2f9;
    width: 476px;
    height: 141px;
    border-radius: 4px;
    border-radius: 4px;
    .title-empresa {
      text-align: left;
      font: normal normal 900 32px/32px Texta;
      letter-spacing: 0px;
      color: #494f65;
      overflow: hidden;
    }
    .cnpj-empresa {
      font: normal normal medium 16px/32px Texta;
      letter-spacing: 0px;
      color: #494f65;
    }
  }
  .footer-text {
    text-align: left;
    font: normal normal medium 16px/24px Texta;
    letter-spacing: 0px;
    color: #494f65;
  }
`;
const ContentDialogReject = styled.div`
  padding: 43px;

  .title {
    text-align: center;
    font: normal normal 900 24px/30px Texta;
    letter-spacing: 0px;
    color: #494f65;
    margin-top: 14px;
    margin-bottom: 36px;
  }
  .content {
    text-align: center;
    font: normal normal medium 20px/24px Texta;
    letter-spacing: 0px;
    color: #494f65;
    margin-bottom: 36px;
  }
  .container-btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export {
  Main,
  TitleWrapper,
  InvitationContainer,
  ContentDialogConfirm,
  ContentDialogReject,
};
