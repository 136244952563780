import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { formatNewDate } from 'utils/dates';

// Columns

import * as S from './styled';
import { formatData } from '../Info/columns';
import { getStatus } from '../../ListAcoes/constants';

export const ExportToPdf = ({
  acaoSuspensao,
  isSuspensao,
  desvios,
  printRef,
}) => {
  const logo = require('images/onisys.png');

  const companyLogo =
    acaoSuspensao?.distribuidora?.logo_url ??
    acaoSuspensao?.empresa?.logo_url ??
    null;

  const conteudo =
    acaoSuspensao?.acao_suspensao_faixa?.conteudo ??
    acaoSuspensao?.acao_suspensao_reincidencia?.conteudo ??
    null;

  return (
    <S.ModalMUI open={false} onClose={() => {}} disableEscapeKeyDown>
      <S.Container ref={printRef}>
        <S.Infos>
          <div className="logoArea">
            <img src={logo} />
          </div>
          <div className="logoArea">
            <img src={companyLogo} />
          </div>
        </S.Infos>
        <S.Infos>
          <div className="formIdent">
            <div className="titleForm">
              <span>
                {`${isSuspensao ? 'Supensão' : 'Ação Disciplinar'} - ID`}{' '}
                {acaoSuspensao?.id.slice(0, 8)}
              </span>
            </div>
          </div>
        </S.Infos>
        <S.CardHeader>
          <h1>{`Informações da ${
            isSuspensao ? 'Supensão' : 'Ação Disciplinar'
          }`}</h1>
        </S.CardHeader>
        <S.Header>
          <div className="topInfos">
            <Grid container spacing={1}>
              <Grid item xs={6} md={6}>
                <div className="normalText">Motorista:</div>
                <S.InputToPDF>{acaoSuspensao?.motorista?.nome}</S.InputToPDF>
              </Grid>
              <Grid item xs={6} md={6}>
                <div className="normalText">Filial:</div>
                <S.InputToPDF>
                  {acaoSuspensao?.motorista?.filial?.nome}
                </S.InputToPDF>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="normalText">Ação Disciplinar:</div>
                <S.InputToPDF>
                  {acaoSuspensao?.acao_suspensao_faixa?.acao_disciplinar.nome}
                </S.InputToPDF>
              </Grid>
              <Grid item xs={6} md={3}>
                <div className="normalText">Pontuação:</div>
                <S.InputToPDF>
                  {` ${acaoSuspensao?.acao_suspensao_faixa?.limiar || '-'}`}
                  {'  Pontos'}
                </S.InputToPDF>
              </Grid>
              <Grid item xs={6} md={3}>
                <div className="normalText">Faixa:</div>
                <S.InputToPDF>
                  {acaoSuspensao?.acao_suspensao_faixa?.titulo || '-'}
                </S.InputToPDF>
              </Grid>
              {isSuspensao && (
                <>
                  <Grid item xs={6} md={3}>
                    <div className="normalText">Data da suspensão:</div>
                    <S.InputToPDF>
                      {formatNewDate(acaoSuspensao?.data_inicio) || '-'}
                    </S.InputToPDF>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <div className="normalText">Data da liberção:</div>
                    <S.InputToPDF>
                      {formatNewDate(acaoSuspensao?.data_fim) || '-'}
                    </S.InputToPDF>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <div className="normalText">Incidência/Reincidência:</div>
                    <S.InputToPDF>
                      {acaoSuspensao?.acao_suspensao_reincidencia
                        ?.reincidencia || '-'}
                    </S.InputToPDF>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <div className="normalText">Dias de suspensão:</div>
                    <S.InputToPDF>
                      {acaoSuspensao?.acao_suspensao_reincidencia?.dias || '-'}
                    </S.InputToPDF>
                  </Grid>
                </>
              )}
              <Grid item xs={12} md={6}>
                <div className="normalText">Capacitação:</div>
                <S.InputToPDF>{conteudo?.titulo || '-'}</S.InputToPDF>
              </Grid>
              {!isSuspensao && (
                <>
                  <Grid item xs={6} md={3}>
                    <div className="normalText">Data alcance de faixa:</div>
                    <S.InputToPDF>
                      {formatNewDate(acaoSuspensao?.data_inicio) || '-'}
                    </S.InputToPDF>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <div className="normalText">Data de finalização:</div>
                    <S.InputToPDF>
                      {formatNewDate(acaoSuspensao?.data_fim) || '-'}
                    </S.InputToPDF>
                  </Grid>
                </>
              )}
              <Grid item xs={12} md={6}>
                <div className="normalText">Status:</div>
                <S.InputToPDF>
                  {getStatus(acaoSuspensao?.status, isSuspensao) || '-'}
                </S.InputToPDF>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="normalText">Responsável:</div>
                <S.InputToPDF>
                  {acaoSuspensao?.responsavel?.nome || '-'}
                </S.InputToPDF>
              </Grid>
              {isSuspensao && (
                <Grid item xs={12} md={6}>
                  <div className="normalText">Motivo da liberação:</div>
                  <S.InputToPDF>
                    {acaoSuspensao?.motivo_liberacao || '-'}
                  </S.InputToPDF>
                </Grid>
              )}
              <Grid item xs={12} md={12}>
                <div className="normalText">Observações:</div>
                <S.InputToPDF>{acaoSuspensao?.observacao || '-'}</S.InputToPDF>
              </Grid>
            </Grid>
          </div>
        </S.Header>
        <S.CardHeader>
          <h1>Desvios Contabilizados</h1>
        </S.CardHeader>
        <S.Header style={{ padding: 0 }}>
          <S.TableArea>
            <table cellSpacing={0}>
              <thead>
                <td>Desvio</td>
                <td className="center">Data Desvio</td>
                <td className="center">Pontuação</td>
              </thead>
              <tbody>
                {desvios?.length ? (
                  formatData(desvios).map(desvio => (
                    <tr>
                      <td>{desvio.desvio}</td>
                      <td className="center">
                        {formatNewDate(desvio.data_desvio)}
                      </td>
                      <td className="center">{Number(desvio.pontos)}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                )}
              </tbody>
            </table>
          </S.TableArea>
        </S.Header>
        <S.CardHeader>
          <h1>Assinaturas</h1>
        </S.CardHeader>
        <S.Header>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <div className="containerSignatures">
                <div className="lineApprover" />
                <div className="containerSignaturesTipe">
                  Responsável: {acaoSuspensao?.responsavel?.nome.toUpperCase()}
                </div>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="containerSignatures">
                <div className="lineApprover" />
                <div className="containerSignaturesTipe">
                  Motorista: {acaoSuspensao?.motorista?.nome.toUpperCase()}
                </div>
              </div>
            </Grid>
          </Grid>
        </S.Header>
      </S.Container>
    </S.ModalMUI>
  );
};
