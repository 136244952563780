// Pages
import Frota from 'pages/Provider/Frota';
import LogFrota from 'pages/Provider/Frota/History';

export default {
  '/frota': {
    title: 'Frota',
    page: <Frota pageDefault="veiculos" />,
  },

  '/logs/frota/:id': {
    back: '/frota',
    title: 'Histórico de alterações - Frota',
    defaults: [],
    page: <LogFrota />,
  },
};
