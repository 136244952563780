export const defaultTheme = {
  palette: {
    brand: {
      primary: {
        light: '#ff99a4',
        natural: '#F14F4F',
        dark: '#C03F3F',
        background: '#FDF9F9',
      },
      secondary: {
        light: '#8AB8F8',
        natural: '#0C5F94',
        dark: '#0F77BA',
        background: '#EDF2F9',
      },
    },
    semantics: {
      feedback: {
        attention: {
          light: '#FFCCD1',
          natural: '#F64E60',
          dark: '#F64E60',
        },
        warning: {
          light: '#FFE7BB',
          natural: '#FFA801',
          dark: '#E3B40B',
        },
        success: {
          light: '#EAFFFE',
          natural: '#1BC5BD',
          dark: '#006d68',
        },
        information: {
          light: '#B2B3FF',
          natural: '#0C12F2',
          dark: '#0c12f2',
        },
        unknown: {
          light: '#F2F2F2',
          natural: '#CAD4E4',
          dark: '#4F4F4F',
        },
      },
    },
    words: {
      text: {
        light: '#939AAB',
        natural: '#424449',
        dark: '#000032',
        contrast: '#FFFFFF',
      },
      title: {
        light: '#C3C3C3',
        natural: '#4B5166',
        dark: '#3d465d',
        contrast: '#FFFFFF',
      },
      subtitle: {
        light: '#939AAB',
        natural: '#656E8C',
        dark: '#000032',
        contrast: '#EFEFEF',
      },
      caption: {
        light: '#C3C3C3',
        natural: '#424449',
        dark: '#000032',
        contrast: '#FFFFFF',
      },
      label: {
        light: '#C3C3C3',
        natural: '#424449',
        dark: '#000032',
        contrast: '#FFFFFF',
      },
      button: {
        light: '#C3C3C3',
        natural: '#424449',
        dark: '#000032',
        contrast: '#FFFFFF',
      },
      link: {
        light: '#C3C3C3',
        natural: '#424449',
        dark: '#000032',
        contrast: '#FFFFFF',
      },
      placeholder: {
        light: '#C3C3C3',
        natural: '#424449',
        dark: '#000032',
        contrast: '#FFFFFF',
      },
      chart: {
        natural: '#666',
      },
      disabled: {
        light: '#C3C3C3',
        natural: '#424449',
        dark: '#000032',
        contrast: '#FFFFFF',
      },
    },
    system: {
      transparent: 'transparent',
      background: '#F9FBFD',
      border: '#EDF2F9',
      overlay: '#FFFFFF',
      highlight: '#FFFFFF',
      disabled: '#F2F2F2',
      divider: '#939AAB4D',
      scrollbar: '#424449',
      backdrop: '#22222222',
      shadow: '#424449',
      white: '#FFFFFF',
      black: '#424449',
      light: '#EDF2F9',
      natural: '#939AAB',
      dark: '#424449',
    },
  },
};
