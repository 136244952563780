import React, { useState, useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { Grid } from '@mui/material';
import {
  AddCircleOutline,
  EditOutlined,
  DeleteOutline,
} from '@mui/icons-material';
import ConstantsUpdater from 'services/updateConstants';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTheme } from 'styled-components';
import { generateFileName } from 'utils/generateFileName';

import { ReactComponent as file } from 'images/icons/sidebar/file.svg';

// Components
import DefaultButtonPopover from 'components/Buttons/DefaultPopover';
import InfoCard from 'components/Cards/InfoCard';
import Card from 'components/Cards/Indicador';
import ConfirmModal from 'components/ConfirmModal';
import ExcelModal from 'components/ExcelModalNew';
import { DefaultTable } from 'components/_Table/templates/default';
import { trackEvent } from 'utils/mixpanel';
import UpgradeModal from './UpgradeModal';
import ModalAddMotorista from './ModalAddMotorista';
import SaveModal from '../Avaliacoes/SaveModal';
import MessageModal from './MessageModal';
import StorePhoneModal from './StorePhoneModal';

// Filters
import FiltersGlobal from 'components/FiltersGlobal';

import {
  requestFaixaContratada,
  changeDriverStatus,
  changeStatusMany,
  requestDriverCard,
  requestClients,
  changeClient,
  sendSms,
  sendMessage,
  storePhone,
} from './services';
import {
  infoMessage,
  cardsMotoristas,
  columnsMotoristas,
  fields,
} from './constants';
import * as S from './styled';
import { useQuery } from 'react-query';
import { requestDrivers } from 'pages/Configuracoes/Motoristas/services';

const Motoristas = () => {
  const theme = useTheme();
  // Verify route to open creation modal
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const createMode = searchParams.get('creation');
  useEffect(() => {
    if (createMode === 'true') {
      setOpenAddMotorista(true);
      window.history.replaceState({}, {}, `/motoristas`);
    }
  }, [location]);

  const navigate = useNavigate();
  const filter = useSelector(state => {
    return state.filter;
  });
  const filterDriver = useSelector(state => {
    return state.filterDriver;
  });
  const filterSelects = useSelector(state => state.selects);
  const { user } = useSelector(state => state?.auth?.user);
  let isConvidado = false;
  if (+user?.nivel === 4) isConvidado = true;

  const [data, setData] = useState([]);
  const [pageTab, setPageTab] = useState('motoristas');
  const [cardsMotors, setCardsMotors] = useState(cardsMotoristas);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [loading, setLoading] = useState(false);

  const [openExcelModal, setOpenExcelModal] = useState(false);
  const [excelFields, setExcelFields] = useState(fields);

  // Seleção de tabela
  const [selectedData, setSelectedData] = useState(null);
  const [idConfirmChangeStatus, setIdConfirmChangeStatus] = useState(false);
  const [idsConfirmDesativar, setIdsConfirmDesativar] = useState(false);
  const [idsConfirmAtivar, setIdsConfirmAtivar] = useState(false);
  const [idsConfirmAlterarCliente, setIdsConfirmAlterarCliente] =
    useState(false);
  const [totalError, setTotalError] = useState(0);
  const [idStoreCellPhone, setIdStoreCellPhone] = useState(null);
  const [clients, setClients] = useState(null);
  const [cliente, setCliente] = useState('');
  const [loadingCards, setLoadingCards] = useState(false);

  // Adicionar e Editar Motoristas
  const [openAddMotorista, setOpenAddMotorista] = useState(false);
  const [editDriver, setEditDriver] = useState(null);

  // Seleciona qual motorista receberá o SMS
  const [msgDriverId, setMsgDriverId] = useState(null);
  const [loadingMsg, setLoadingMsg] = useState(false);

  // Pop-up maximo motoristas
  const [maxFaixa, setMaxFaixa] = useState(false);
  const [ativosFaixa, setAtivosFaixa] = useState(false);
  const [upgradeModal, setUpgradeModal] = useState(false);

  // FiltersPersis
  const filtersPersist = useSelector(state => {
    return state?.filter?.filters?.motoristas;
  });

  // Query
  const [query, setQuery] = useState(null);

  const { data: _drivers = [], refetch } = useQuery(
    ['motoristas'],
    () => requestDrivers(),
    {
      refetchOnWindowFocus: false,
    },
  );

  const { data: dataCards } = useQuery(
    ['motoristas', filter, filterDriver],
    () =>
      requestDriverCard({
        ...filter,
        filial: filter.filial ? filter.filial.split(',') : undefined,
        client: filter.client ? filter.client.split(',') : undefined,
        ...filterDriver,
      }),
    {
      refetchOnWindowFocus: false,
    },
  );

  const drivers = _drivers?.data ?? [];
  const fetchFaixa = async () => {
    setLoadingCards(true);
    try {
      const faixaContratada = await requestFaixaContratada();
      if (faixaContratada.data.success) {
        const motAtivos =
          faixaContratada.data.ativos ??
          (drivers?.data?.filter(item => item.status === 'ATIVO').length || 0);
        const cardInit = cardsMotors.map(card => ({
          ...card,
          value:
            card.name === 'limite'
              ? `${motAtivos}/${faixaContratada.data.data.faixa.max}`
              : card.value,
        }));
        setAtivosFaixa(faixaContratada.data.ativos);
        setMaxFaixa(faixaContratada.data.data.faixa.max);
        setCardsMotors(cardInit);
      } else
        toast.error(
          faixaContratada.data.message || 'Erro ao buscar faixa contratada.',
        );
    } catch (error) {
      console.log('error', error);
      toast.error('Erro ao buscar faixa contratada.');
    } finally {
      setLoadingCards(false);
    }
  };

  const fetchClients = async () => {
    const res = await requestClients();
    if (res.data.success) {
      setClients(res.data.data);
    }
  };

  const filtersData = [
    {
      filterName: 'client',
      label: 'Cliente',
      options:
        filterSelects?.clients?.map(i => {
          return { label: i.nome, value: i.id };
        }) || [],
    },
    {
      filterName: 'filiais',
      label: 'Filiais',
      options:
        filterSelects?.filials?.map(i => {
          return { label: i.nome, value: i.id };
        }) || [],
    },
    {
      filterName: 'agregado',
      label: 'Agregado',
      options: [
        { label: 'Agregados', value: true },
        { label: 'Próprios', value: false },
      ],
    },
    {
      filterName: 'status',
      label: 'Status',
      options: [
        { label: 'Ativo', value: 'ATIVO' },
        { label: 'Inativo', value: 'INATIVO' },
      ],
    },
  ];

  useEffect(() => {
    if (idsConfirmAlterarCliente) {
      fetchClients();
    }
  }, [idsConfirmAlterarCliente]);

  useEffect(() => {
    fetchFaixa();
  }, []);

  const [lds, setLds] = useState(true);
  useEffect(async () => {
    setLds(true);
    if (query) {
      // Cria newData com drivers filtrado
      const newData = drivers.filter(driver => {
        const res =
          (!query?.filiais?.length ||
            query.filiais.some(f => f == driver.id_da_filial)) &&
          (!query?.client?.length ||
            query.client.some(c => c == driver.id_da_distribuidora)) &&
          (!query?.agregado?.length ||
            query.agregado.some(a => a === driver.agregado)) &&
          (!query?.status?.length ||
            query.status.some(s => s === driver.status));
        return res;
      });

      // Atualiza valores dos cards com base nos dados filtrados
      // Armazena dados de newData filtrados pelo card em dataCardSeleted caso algum esteja selecionado
      let dataCardSeleted;
      const newCardsMotors = cardsMotors.map(card => {
        if (card.name === 'limite') {
          const cardData = newData.filter(item => item.status === 'ATIVO');
          if (card.selected) dataCardSeleted = cardData;
        } else if (card.name === 'desvios') {
          const cardData = newData.filter(item =>
            dataCards?.data?.data.includes(item.id),
          );
          card.value = dataCards?.data?.data
            ? dataCards.data.data.length
            : newData.length;
          if (card.selected) dataCardSeleted = cardData;
        }
        return card;
      });
      setCardsMotors(newCardsMotors);

      // Atualiza data com newData ou dataCardSeleted se algum card for selecionado
      if (dataCardSeleted) setData(dataCardSeleted);
      else setData(newData);
    } else setData(drivers);
    setLds(false);
  }, [
    filter,
    filterDriver,
    dataCards,
    cardsMotors[0].selected,
    cardsMotors[1].selected,
    drivers,
    query,
  ]);

  const handleClickCards = name => {
    if (pageTab === 'motoristas') {
      const newCards = cardsMotors.map(card => {
        if (name === card.name) {
          if (card.selected !== undefined) {
            card.selected = !card.selected;
          }
        } else if (card.selected) card.selected = false;
        return card;
      });
      setCardsMotors(newCards);
    }
  };

  const handleVer = id => {
    trackEvent(user, 'MOTORISTA: VER PERFIL');
    if (id) navigate(`/motoristas/${id}`);
    else toast.error('Não foi possível acessar os detalhes deste motorista.');
  };

  const handleConvidado = id => {
    toast.error(
      'Você não tem permissão para acessar os detalhes deste motorista.',
    );
  };

  const handleEditar = id => {
    trackEvent(user, 'MOTORISTA: EDITAR');
    const driverEdit = drivers.find(driver => String(driver.id) === String(id));
    if (driverEdit) setEditDriver(driverEdit);
  };

  const handleHistorico = id => {
    trackEvent(user, 'MOTORISTA: visualizar histórico de alterações');
    navigate(`/logs/driver/${id}`);
  };

  const handleSetDesativarAtivar = driverId => {
    const driverEdit = drivers.find(
      driver => String(driver.id) === String(driverId),
    );
    if (!driverEdit.celular && driverEdit.status === 'INATIVO')
      setIdStoreCellPhone(driverId);
    else setIdConfirmChangeStatus({ id: driverId, status: driverEdit.status });
    trackEvent(
      user,
      `MOTORISTA:${
        driverEdit.status === 'ATIVO' ? 'desativar' : 'ativar'
      }  motorista`,
    );

    setIdConfirmChangeStatus({ id: driverId, status: driverEdit.status });
  };

  const handleDesativarAtivar = async driverId => {
    trackEvent(user, 'MOTORISTA: desativar motorista');
    const res = await changeDriverStatus(driverId);
    if (res && res.data?.success) {
      await fetchFaixa();
      toast.success(res.data.message);
      setTriggerUpdate(true);
    } else if (res && res.data?.message) toast.error(res.data.message);
    else
      toast.error(
        'Erro ao ativar/desativar motorista, tente mais tarde. Caso persista procure o nosso time de suporte',
      );
    setIdConfirmChangeStatus(null);
  };

  const handleSetSendMessage = id => {
    setMsgDriverId(id);
  };

  const handleSendMessage = async data => {
    setLoadingMsg(true);
    if (!data || !data.canal) toast.error('Erro ao selecionar canal');

    if (data.canal === 'SMS') {
      const res = await sendSms(data.id);
      if (res && res.data.success) {
        toast.success(res.data.message);
        setMsgDriverId(null);
        trackEvent(user, 'envio sms');
      } else if (res && !res.data.success) toast.error(res.data.message);
      else
        toast.error(
          'Erro ao enviar SMS, tente mais tarde, caso persista procure o nosso time de suporte',
        );
    }

    if (data.canal === 'WhatsApp') {
      const res = await sendMessage(data.id);
      if (res && res.data.success) {
        toast.success(res.data.message);
        setMsgDriverId(null);
        trackEvent(user, 'envio whatsapp');
      } else if (res && !res.data.success) toast.error(res.data.message);
      else
        toast.error(
          'Erro ao enviar mensagem, tente mais tarde, caso persista procure o nosso time de suporte',
        );
    }

    setLoadingMsg(false);
  };

  const handleStorePhone = async data => {
    setLoading(true);

    const res = await storePhone(data);
    if (res && res.data?.success) {
      toast.success(res.data.message);
      setTriggerUpdate(true);
    } else if (res && res.data?.message) toast.error(res.data.message);
    else
      toast.error(
        'Erro ao salvar celular do motorista, tente novamente mais tarde. Caso persista procure o nosso time de suporte',
      );

    setIdConfirmChangeStatus(null);
    setIdStoreCellPhone(null);
    setLoading(false);
  };

  const handleVerifyStatusDriver = async selectedData => {
    const selectedDrivers = drivers.filter(driver =>
      selectedData.includes(driver.id),
    );

    const withError = selectedDrivers.filter(
      driver => driver.status === 'INATIVO' && !driver.celular,
    );

    const withSuccess = selectedDrivers.filter(
      driver => driver.status === 'INATIVO' && driver.celular,
    );

    const idsSuccess = withSuccess.map(driver => driver.id);
    setIdsConfirmAtivar(idsSuccess);

    if (withError.length > 0) {
      setTotalError(withError.length);
      return;
    }
    setTotalError(0);
  };

  const handleDesativarAtivarMassa = async (newStatus, driverIds) => {
    setLoading(true);
    const data = { newStatus, driverIds };

    const res = await changeStatusMany(data);
    if (res.data?.success) {
      await fetchFaixa();
      toast.success(res.data.message);
      setTriggerUpdate(true);
    } else if (res.data?.message) toast.error(res.data.message);
    setLoading(false);
    setIdsConfirmDesativar(null);
    setIdsConfirmAtivar(null);
    setTotalError(0);
  };

  const handleCloseAlterarCliente = () => {
    setCliente('');
    setIdsConfirmAlterarCliente(false);
  };

  const handleAlterarCliente = async () => {
    let ids = [];
    if (idsConfirmAlterarCliente) ids = idsConfirmAlterarCliente;
    let client = null;
    if (cliente) client = cliente;

    if (client) {
      const data = {
        ids,
        client,
      };

      const res = await changeClient(data);

      if (res.data.success) {
        toast.success(res.data.message);
      } else toast.error(res.data.message);

      setTriggerUpdate(true);
      handleCloseAlterarCliente();
    }
  };

  // ------------------------------------- Excel ------------------------------------------//
  // const handleRequestExcel = async () => {
  //   setLoadingExcel(true);

  //   const newFields = excelFields.filter(item => item.selected === true);
  //   const formatedDate = formatNameDate(new Date());

  //   const newQuery = { ...filter, ...filterDriver, excelFields: newFields };

  //   const res = await requestExcel(newQuery);
  //   if (res.data && res.data?.data?.excel)
  //     ExportToExcel({
  //       excel: res.data.data.excel,
  //       name: `motoristas_${formatedDate}`,
  //     });
  //   else if (res.data.message) toast.error(res.data?.message);

  //   setExcelFields(resetExcelFields(fields));
  //   setLoadingExcel(false);
  //   setOpenExcelModal(false);
  // };

  // ------------------------------------- RENDER ------------------------------------------//
  const renderHeader = () => {
    const PopoverAdicionar = (
      <S.AdicinarDiv>
        <button
          onClick={() => {
            trackEvent(user, 'MOTORISTA:  Adicionar novo');
            setOpenAddMotorista(!openAddMotorista);
          }}
        >
          Adicionar novo
        </button>

        <button
          onClick={() => {
            trackEvent(user, 'MOTORISTA: Adicionar em massa');
            navigate('/motoristas/add-motoristas');
          }}
        >
          Adicionar em massa
        </button>
      </S.AdicinarDiv>
    );

    return (
      <>
        <S.TitleWrapper>
          <h1>Lista de Motoristas</h1>
        </S.TitleWrapper>

        <div style={{ marginTop: 16, marginBottom: 10 }}>
          <FiltersGlobal
            hideDate
            persistDate
            customComponent={
              maxFaixa === ativosFaixa ? (
                <S.ButtonApply
                  startIcon={<AddCircleOutline />}
                  size="medium"
                  onClick={() => setUpgradeModal(true)}
                  disabled={isConvidado || loadingCards}
                >
                  ADICIONAR MOTORISTA
                </S.ButtonApply>
              ) : (
                <DefaultButtonPopover
                  startIcon={<AddCircleOutline />}
                  size="medium"
                  sx={{ marginLeft: '10px' }}
                  popoverComponent={PopoverAdicionar}
                  disabled={isConvidado || loadingCards}
                >
                  ADICIONAR MOTORISTA
                </DefaultButtonPopover>
              )
            }
            refetch={() => refetch()}
            // isFetching={isFetching}
            handleFilters={props => setQuery({ ...query, ...props })}
            handleExport={() => {
              trackEvent(user, 'MOTORISTA: EXPORTAR');
              setOpenExcelModal(true);
            }}
            data={filtersData}
          />
        </div>
        <div style={{ padding: '15px 0px' }}>
          <InfoCard message={infoMessage[pageTab]} key={pageTab} />
        </div>
      </>
    );
  };

  const renderCards = () => {
    if (pageTab === 'motoristas') {
      const cards = cardsMotors;
      return (
        <Grid container spacing={2} marginBottom="25px">
          {cards?.map(card => {
            return (
              <Grid item key={card.name} xs={12} md={4} xl={4}>
                <Card
                  value={card.value}
                  icon={card.icon}
                  text={card.text}
                  handleClick={() => handleClickCards(card.name)}
                  selected={card.selected}
                  loading={loadingCards}
                />
              </Grid>
            );
          })}
        </Grid>
      );
    }
  };

  const renderModalAddDriver = () => {
    return (
      openAddMotorista && (
        <ModalAddMotorista
          open={openAddMotorista}
          handleClose={() => {
            setOpenAddMotorista(false);
            refetch();
          }}
          setTriggerUpdate={setTriggerUpdate}
        />
      )
    );
  };

  const renderModalEditDriver = () => {
    return (
      editDriver && (
        <ModalAddMotorista
          open={Boolean(editDriver)}
          dataEdit={editDriver}
          handleClose={() => {
            setEditDriver(null);
            refetch();
          }}
          setTriggerUpdate={setTriggerUpdate}
        />
      )
    );
  };

  const renderTableDrivers = () => {
    const actions = [
      { title: 'Ver perfil', function: handleVer },
      { title: 'Editar', function: handleEditar },
      {
        title: 'Enviar Mensagem',
        function: handleSetSendMessage,
      },
      {
        title: 'Histórico alterações',
        function: handleHistorico,
      },
      {
        title: 'Ativar/Desativar',
        function: handleSetDesativarAtivar,
      },
    ];

    const bulk = [
      {
        title: 'Desativar',
        function: () => {
          trackEvent(user, 'MOTORISTA: desativar motoristas em massa');
          setIdsConfirmDesativar(selectedData);
        },
      },
      {
        title: 'Ativar',
        function: () => {
          trackEvent(user, 'MOTORISTA: ativar motoristas em massa');

          // setIdsConfirmAtivar(selectedData);
          handleVerifyStatusDriver(selectedData);
        },
      },
      {
        title: 'Alterar cliente',
        function: () => setIdsConfirmAlterarCliente(selectedData),
      },
      {
        title: 'Alterar cliente',
        function: () => setIdsConfirmAlterarCliente(selectedData),
      },
    ];

    return (
      <DefaultTable
        data={data || []}
        columns={columnsMotoristas}
        setSelectedRows={!isConvidado ? setSelectedData : ''}
        actions={!isConvidado ? actions : ''}
        bulk={bulk}
        searchKeys={['nome', 'funcao', 'filial.nome']}
        loading={lds}
        onClickRow={!isConvidado ? handleVer : handleConvidado}
        placeholder="Buscar por nome, função ou filial"
        sortBy={{ id: 'status', order: 'ASC' }}
        searchEvent={search =>
          trackEvent(user, 'Busca Gestão de Motoristas', null, search)
        }
        empty={{
          title: 'Nenhum motorista encontrado',
          description: 'Você ainda não cadastrou nenhum motorista.',
          image: 'frota.png',
        }}
        setQuery={q => setQuery({ ...q, ...filter, ...filterDriver })}
      />
    );
  };

  return (
    <>
      <ConstantsUpdater
        names={['drivers']}
        trigger={triggerUpdate}
        setTrigger={setTriggerUpdate}
      />
      <S.Main>
        {renderHeader()}
        {renderCards()}

        {renderModalAddDriver()}
        {renderModalEditDriver()}

        {pageTab === 'motoristas' && renderTableDrivers()}
      </S.Main>

      {
        /* Confirmação de ativacao / desativacao */
        idConfirmChangeStatus && (
          <ConfirmModal
            open={Boolean(idConfirmChangeStatus)}
            handleClose={() => setIdConfirmChangeStatus(null)}
            title={
              idConfirmChangeStatus.status === 'ATIVO'
                ? 'Deseja desativar este motorista?'
                : 'Deseja reativar este motorista?'
            }
            titleIcon={
              idConfirmChangeStatus.status === 'ATIVO' ? (
                <DeleteOutline
                  sx={{
                    color: theme.palette.semantics.feedback.attention.natural,
                  }}
                  fontSize="medium"
                />
              ) : (
                <EditOutlined
                  sx={{ color: theme.palette.brand.secondary.natural }}
                  fontSize="medium"
                />
              )
            }
            subtitle="Motoristas inativos não tem novas avaliações geradas e serão removidos das turmas associadas."
            buttonText="Confirmar"
            onClick={() => handleDesativarAtivar(idConfirmChangeStatus.id)}
            loading={loading}
          />
        )
      }

      {
        /* Confirmação de ativacao em massa */
        idsConfirmAtivar && !totalError && (
          <ConfirmModal
            open={Boolean(idsConfirmAtivar)}
            handleClose={() => setIdsConfirmAtivar(null)}
            title={`Deseja reativar ${idsConfirmAtivar.length} motoristas?`}
            titleIcon={
              <EditOutlined
                sx={{ color: theme.palette.brand.secondary.natural }}
                fontSize="medium"
              />
            }
            subtitle="Todos os motoristas marcados serão reativados!"
            buttonText="Confirmar"
            onClick={() =>
              handleDesativarAtivarMassa('ATIVO', idsConfirmAtivar)
            }
            loading={loading}
          />
        )
      }

      {
        /* Confirmação de ids com error de telefone em massa */
        !!totalError && (
          <ConfirmModal
            open={Boolean(totalError)}
            handleClose={() => {
              setIdsConfirmAtivar(null);
              setTotalError(null);
            }}
            title={`Existem ${totalError} motorista(s) sem telefone cadastrado`}
            titleIcon={
              <WarningAmberRoundedIcon
                sx={{ color: colors.redDanger }}
                fontSize="medium"
              />
            }
            subtitle="Motoristas sem telefone não serão ativados! Para completar a ação é necessário ativar individualmente."
            buttonText="Ativar Motoristas com telefone"
            onClick={() =>
              handleDesativarAtivarMassa('ATIVO', idsConfirmAtivar)
            }
            loading={loading}
          />
        )
      }

      {
        /* Confirmação de desativacao em massa */
        idsConfirmDesativar && (
          <ConfirmModal
            open={Boolean(idsConfirmDesativar)}
            handleClose={() => setIdsConfirmDesativar(null)}
            title={`Deseja desativar ${idsConfirmDesativar.length} motoristas?`}
            titleIcon={
              <DeleteOutline
                sx={{
                  color: theme.palette.semantics.feedback.attention.natural,
                }}
                fontSize="medium"
              />
            }
            subtitle="Motoristas inativos não tem novas avaliações geradas e serão removidos das turmas associadas."
            buttonText="Confirmar"
            onClick={() =>
              handleDesativarAtivarMassa('INATIVO', idsConfirmDesativar)
            }
            loading={loading}
          />
        )
      }
      {
        /* Confirmação alteração de clientes em massa */
        clients && idsConfirmAlterarCliente && (
          <SaveModal
            onClick={() => handleAlterarCliente()}
            open={Boolean(clients)}
            handleClose={() => handleCloseAlterarCliente()}
            title={`Deseja alterar clientes para ${idsConfirmAlterarCliente.length} motoristas?`}
            titleIcon={file}
            subtitle="As avaliações destes motoristas serão geradas automaticamente o cliente selecionado."
            value={cliente}
            handleChange={setCliente}
            selectLabel="Cliente"
            data={clients.map(item => ({
              value: item.id,
              name: item.nome,
            }))}
          />
        )
      }
      {openExcelModal && (
        <ExcelModal
          open={openExcelModal}
          handleClose={() => setOpenExcelModal(false)}
          excelFields={excelFields}
          query={{
            ...filtersPersist,
          }}
          file_name={generateFileName(
            window.location.pathname.split('/').pop(),
            filtersPersist,
          )}
          route="/excel/driver"
          filtersPersis={filtersPersist}
          collumns={filtersData}
          isManualDownload
        />
      )}
      {msgDriverId && (
        <MessageModal
          id={msgDriverId}
          handleConfirm={handleSendMessage}
          handleClose={() => setMsgDriverId(null)}
          canais={[
            { value: 'SMS', name: 'SMS' },
            { value: 'WhatsApp', name: 'WhatsApp' },
          ]}
          loading={loadingMsg}
        />
      )}
      {idStoreCellPhone && (
        <StorePhoneModal
          id={idStoreCellPhone}
          handleConfirm={handleStorePhone}
          handleClose={() => {
            setIdConfirmChangeStatus(null);
            setIdStoreCellPhone(null);
          }}
          loading={loading}
        />
      )}
      {upgradeModal && (
        <UpgradeModal
          open={upgradeModal}
          handleClose={() => setUpgradeModal(false)}
        />
      )}
    </>
  );
};

export default Motoristas;
