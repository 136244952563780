import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { subHours } from 'date-fns';

// components custom
import { PeriodPicker } from 'pages/Estatisticas/Components/PeriodPicker';
import Tabs from 'components/Tabs';
// styles
import * as S from './styled';

const CustomTick = props => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="middle"
        fill="#939aab"
        transform="rotate(0)"
      >
        <tspan x={0} dy="20">
          {payload.value}
        </tspan>
      </text>
    </g>
  );
};

export const HistoryPoints = ({
  tab = 'ptsH',
  tabs = [],
  data = [],
  dates,
  handleDate,
  handleChangeTab = () => false,
  print = false,
}) => {
  const handleChange = value => {
    handleChangeTab(value);
  };

  return (
    <S.Container print={print}>
      <div className="container-filters">
        <div className="titletop">
          <div className="graphtitle">Histórico de pontos</div>
          <PeriodPicker
            finalDate={dates.finalDate}
            initialDate={dates.initialDate}
            period="lastYear"
            periodAdd="month"
            onChange={date => {
              handleDate({
                initialDate: date.initialDate,
                finalDate: subHours(date.finalDate, 3),
              });
            }}
          />
        </div>
        <div>
          <Tabs
            value={tab}
            onChange={(e, value) => handleChange(value)}
            items={tabs}
          />
        </div>
      </div>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          height={300}
          data={data}
          margin={{ top: 10, right: 40, left: 20, bottom: 5 }}
        >
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <XAxis
            dataKey="name"
            height={70}
            tick={<CustomTick />}
            interval={30}
          />
          <YAxis />
          <Tooltip />

          <Line
            type="linear"
            dataKey="x"
            name="Empresa"
            stroke="#f64e60"
            dot={false}
          />

          <Line
            type="linear"
            dataKey="factor2"
            name="Global"
            stroke="#656E8C"
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
      <div className="legend-container">
        <div className="legend">
          <div className="format-point">
            <div className="point1" />
            Empresa
          </div>
          <div className="format-point">
            <div className="point2" />
            Global
          </div>
        </div>
      </div>
    </S.Container>
  );
};
