import React, { useState } from 'react';

// Components
import { Divider } from '@mui/material';
import Tabs from 'components/Tabs';
import { Acidentes } from './Acidentes';
import { Multas } from './Multas';
import FiltersGlobal from 'components/FiltersGlobal';
import { useFetchMultipleWithCache } from 'hooks/fetchFilters';
import { startOfMonth, subMonths } from 'date-fns';

export const tabs = [
  { value: 'acidentes', label: 'Acidentes' },
  { value: 'multas', label: 'Multas' },
];

export const AcidentesMultas = ({ setOpenExport }) => {
  const [tab, setTab] = useState('acidentes');
  const { clients } = useFetchMultipleWithCache();
  const [filter, setFilter] = useState({
    finalDate: new Date(),
    initialDate: startOfMonth(subMonths(new Date(), 11)),
  });
  return (
    <>
      <FiltersGlobal
        hideRefleshButton
        customComponent={
          <>
            <Tabs
              value={tab}
              items={tabs}
              onChange={(_, value) => setTab(value)}
            />
            <Divider />
          </>
        }
        handleExport={() => setOpenExport(true)}
        handleFilters={filter => setFilter(state => ({ ...state, ...filter }))}
        defaultDate={filter}
        data={[
          {
            filterName: 'client',
            label: 'Clientes',
            options: clients.data || [],
          },
        ]}
      />

      <br />

      {tab === 'acidentes' && <Acidentes filter={filter} />}
      {tab === 'multas' && <Multas filter={filter} />}
    </>
  );
};
