export const columns = [
  {
    header: 'Nome',
    id: 'motorista',
    type: 'string',
    sort: true,
    width: 250,
  },
  {
    header: 'Km',
    id: 'kms',
    sort: true,
    type: 'number',
    align: 'center',
    plans: [100, 110],
  },
  {
    header: 'Horas',
    id: 'horas',
    sort: true,
    type: 'number',
    align: 'center',
    plans: [140],
  },
  {
    header: 'Desvios',
    id: 'desvios',
    sort: true,
    type: 'number',
    align: 'center',
  },
  {
    header: 'Pontos',
    id: 'pontos',
    sort: true,
    type: 'number',
    align: 'center',
  },
  {
    header: 'Suspensões',
    id: 'acoes_suspensoes',
    sort: true,
    type: 'number',
    align: 'center',
  },
];
