/* eslint-disable import/order */
import * as S from './styled';

// React
import { useQuery } from 'react-query';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  initializeData,
  setConteudo,
} from 'store/modules/provider/capacitacaoConteudo/actions';

// Components
import Tag from './components/Tag';
import Title from './components/Title';
import Search from './components/Search';
import Loading from './components/Loading';
import ModalAdd from './components/ModalAdd';
import Templates from './components/Templates';
import SliderTag from './components/SliderTag';
import PreviewModal from './components/ModalPreview';
import DefaultButton from 'components/Buttons/Default';
import ModalTemplates from './components/ModalTemplates';
import FiltersGlobal from 'components/FiltersGlobal';
import { useFetchMultipleWithCache } from 'hooks/fetchFilters';

// Componentes MUI
import { Divider } from '@mui/material';
import { AddCircleOutline } from '@mui/icons-material';

// Services
import * as services from './services';

// Timer
let timer = null;
let timerRoute = null;

const Capacitacao = () => {
  const { getTurmasStatus } = useFetchMultipleWithCache();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const filterForm = useSelector(state => state.filter);

  const [tags, setTags] = useState([]);
  const [firstCharge, setFirstCharge] = useState(true);

  const [templates, setTemplates] = useState(null);
  const [filteredTemplates, setFilteredTemplates] = useState(null);
  const [openPreview, setOpenPreview] = useState(false);

  const [modalAdd, setModalAdd] = useState(false);
  const [modalTemplate, setModalTemplate] = useState(false);
  const [inSearch, setInSearch] = useState(false);
  const [loadingCard, setLoadingCard] = useState(false);

  const [query, setQuery] = useState({
    card: null,
    tag: 'Todos',
    search: '',
  });

  // -------------------------------------PAGE CONTROLLER---------------------------------------//

  const {
    data: res,
    isError,
    isLoading: loading,
  } = useQuery(['embarcadora-conteudos', 1], () => services.GetContents(), {
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (loading) return null;

    if (!isError) {
      setTemplates(res.data.contents);
      setTags(res.data.tags);
    } else {
      toast.error(res.message);
    }
  }, [res]);

  const previewContent = async id => {
    setLoadingCard(true);
    let res = await services.GetConteudo(id);

    if (res.success) {
      const cont = res?.data?.content;

      const id = cont.id;

      const geral = {
        titulo: cont.titulo,
        descricao: cont.descricao,
        capa: cont.imagem_capa,
        data_inicio: cont.data_inicio,
        data_fim: cont.data_fim,
        tags: cont.tags,
        status: cont.status,
      };

      const midias = cont.midias.map(m => {
        return {
          id: m.id,
          titulo: m.titulo,
          descricao: m.descricao,
          link: m.link,
          draggableId: m.ordem,
          ordem: m.ordem,
          tipo: m.tipo,
        };
      });

      const avaliacao = cont.perguntas;

      dispatch(
        initializeData({
          id,
          geral,
          midias,
          avaliacao,
        }),
      );
      setOpenPreview(true);
      setInSearch(true);
    } else {
      toast.error(res.message);
    }
    setLoadingCard(false);
  };

  // Controll back arrow in window when modal is opened in same tab (BETA VERSION)
  useEffect(() => {
    timerRoute = setTimeout(() => {
      if (!openPreview && !firstCharge) {
        window.onpopstate = event => {
          event.preventDefault();
        };
      }
    }, 100);

    return function cleanup() {
      clearTimeout(timerRoute);
    };
  }, [openPreview, firstCharge]);

  // ------------------------------------FILTERS CONTROLLER-------------------------------------//
  const handleQuery = object => {
    setQuery({ ...query, ...object });
  };

  useEffect(() => {
    if (filterForm) setQuery({ ...query, ...filterForm?.filters?.capacitacao });
  }, [filterForm]);

  useEffect(() => {
    let hasFilter = [];
    const temps = templates;
    let status = null;

    if (!temps || firstCharge) {
      setFilteredTemplates(null);
      setFirstCharge(false);
      return;
    }

    // Filtro Status
    status = query?.status ? query.status : false;
    if (status.length) {
      hasFilter = temps.filter(temp => status.includes(temp?.status));
    } else {
      hasFilter = temps;
    }

    // Filtro Publico
    let turmas = query?.turma;
    if (turmas) {
      const newFilter = [];
      hasFilter.forEach(temp => {
        const turmasId = temp.turmas.map(item => item.id);
        turmasId.forEach(id => {
          turmas.forEach(turma => {
            if (+id === +turma) {
              newFilter.push(temp);
            }
          });
        });
      });

      hasFilter = newFilter;
    }

    if (query.search) {
      hasFilter = hasFilter.filter(temp => {
        const turmasName = temp?.turmas
          .map(item => item?.nome.toLowerCase())
          .join('*');
        if (
          temp.titulo
            .toLowerCase()
            .includes(query.search.toLocaleLowerCase()) ||
          temp.tags
            .toString()
            .toLocaleLowerCase()
            .includes(query.search.toLocaleLowerCase()) ||
          turmasName.includes(query.search.toLocaleLowerCase())
        ) {
          return temp;
        }
      });
    }

    if (query.tag !== 'Todos') {
      hasFilter = hasFilter.filter(temp => temp.tags.includes(query.tag));
    }

    setInSearch(true);
    clearTimeout(timer);

    timer = setTimeout(() => {
      if (!query.search && query.tag === 'Todos' && !status && !turmas) {
        setFilteredTemplates(null);
      } else {
        setFilteredTemplates(hasFilter);
      }
      setInSearch(false);
    }, 400);
  }, [query, templates]);

  return (
    <S.Container>
      <S.Header>
        <Title>Capacitação</Title>
        <div style={{ marginTop: 10 }} />
        <FiltersGlobal
          hideRefleshButton
          hideDate
          persistDate
          data={[
            {
              filterName: 'status',
              label: 'Status',
              options: getTurmasStatus?.data || [],
            },
          ]}
          customComponent={
            <span className="buttonArea">
              <DefaultButton
                children="Novo Conteúdo"
                onClick={() => setModalAdd(true)}
                startIcon={<AddCircleOutline />}
              />
            </span>
          }
          hideExportButton
        />
      </S.Header>

      <Divider style={{ marginTop: '10px' }} />

      <SliderTag width="72vw">
        {!loading &&
          tags.map((item, key) => (
            <Tag
              children={item}
              key={key}
              onClick={() => handleQuery({ tag: item })}
              active={item === query.tag}
              disabled={item === query.tag}
            />
          ))}
        {loading && <Tag loading />}
      </SliderTag>

      <S.SearchArea>
        <Search
          value={query.search}
          setValue={value => handleQuery({ search: value })}
          placeholder="Buscar Conteúdo"
        />
        <p>{query.tag}</p>
      </S.SearchArea>

      <S.Body inSearch={inSearch}>
        {!loading && templates && !openPreview && (
          <Templates
            templates={filteredTemplates || templates}
            conteudos={null}
            setModalAdd={setModalAdd}
            query={query}
            inSearch={inSearch}
            loadingScreen={loading || inSearch}
            loadingCard={loadingCard}
            onClickNavigateEdit={id => navigate(`/capacitacao/editar/${id}`)}
            onClickSketch={id => {
              dispatch(setConteudo(id, 'id'));
              navigate(`/capacitacao/criar?conteudo=${id}`);
            }}
            onClickView={id => previewContent(id)}
          />
        )}
        {loading && <Loading />}
      </S.Body>

      {/* Modal Area */}
      <ModalAdd
        open={modalAdd}
        onClose={() => setModalAdd(false)}
        setModalTemplate={setModalTemplate}
        title="Criar Conteúdo"
        textLeft="Criar conteúdo do zero"
        textRight="Utilizar template"
        description={
          'Você poderá criar um conteúdo clicando em "Criar conteúdo do zero" ou poderá copiar um conteúdo já existente e editar como preferir clicando em "Utilizar template".'
        }
        actionRight={() => setModalTemplate(true)}
        actionLeft={() => navigate('/capacitacao/criar')}
      />

      <ModalTemplates
        tags={tags}
        templates={templates}
        open={modalTemplate}
        onClose={() => setModalTemplate(false)}
      />

      {openPreview && (
        <PreviewModal
          open={openPreview}
          onClose={() => {
            setOpenPreview(false);
            setTimeout(() => {
              setInSearch(false);
            }, 500);
          }}
          backPath="/capacitacao"
        />
      )}
    </S.Container>
  );
};

export default Capacitacao;
