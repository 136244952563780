import styled from 'styled-components';
import colors from 'styles/colors';

export const Container = styled.main``;

export const Header = styled.div``;
export const TabsArea = styled.div``;

export const Cards = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  margin: 25px 0px;

  .cardItem {
    margin: 10px;
  }
`;

export const SearchArea = styled.div`
  margin-top: 23px;

  p {
    margin-left: 5px;
    margin-top: 10px;
    font-weight: 600;
    font-size: 18px;
    color: ${colors.greySubtitle};
  }
`;

export const Body = styled.section`
  margin-top: 20px;
  height: 100%;
  opacity: ${props => (props.inSearch ? 0.5 : 1)};
  pointer-events: ${props => (props.inSearch ? 'none' : 'all')};
`;
export const StatusArea = styled.div`
  display: flex;
  margin-right: 40px;

  span {
    margin-left: 10px;
    font-weight: 600;
  }

  &.header {
    transform: scale(0.9);
    margin-right: 10px;
  }

  &.user {
    border-radius: 30px;
    background-color: ${props => props.bg};
    padding: 0px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    margin: 0;

    span {
      margin: 0;
    }
  }
`;

export const PecentResponse = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px;

  .barArea {
    width: 80%;
    position: relative;
    display: flex;
    align-items: center;
    padding: 0px 5px;

    .out {
      background-color: ${colors.greyTiny};
      height: 5px;
      width: 100%;
      border-radius: 10px;
    }

    .in {
      position: absolute;
      width: ${props => props.percent}%;
      height: 5px;
      background-color: ${props => props.color};
      border-radius: 10px;
    }
  }

  .percent {
    margin-left: 20px;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    white-space: nowrap;
    color: ${props => props.color};
  }
`;
