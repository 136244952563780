import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { handleApplyFilter } from 'store/modules/filter/actions';
import { Icon } from 'components/IconsOnisys';
import { useTheme } from 'styled-components';
import { subDays } from 'date-fns';
import DateRangePicker from '../DateRangePicker2';
import SelectMultiple from 'components/Inputs/_SelectMultiple';
import {
  Container,
  ContainerSelects,
  ButtonApply,
  FiltersContainer,
  ButtonCustom,
  PopoverContaiener,
  ButtonIconCustom,
  ButtonExportar,
  ButtonCancelar,
  ButtonApplyAll,
} from './styled';

import Fade from '@mui/material/Fade';
import Popper from '@mui/material/Popper';
import { toast } from 'react-toastify';
import Tabs from './Tabs';
import { PeriodPicker } from './PeriodPicker';
import { usePlans } from 'hooks/usePlans';

import { ReactComponent as IconExportar } from '../../images/icons/components/exportar.svg';
import { ReactComponent as IconFilters } from '../../images/icons/components/filters.svg';
import { ReactComponent as IconArrows } from '../../images/icons/components/arrows.svg';
import { ReactComponent as IconClear } from '../../images/icons/components/clear.svg';
import { ReactComponent as IconReflesh } from '../../images/icons/components/reflesh.svg';
import { fill } from 'lodash';

const FiltersGlobal = ({
  data = [], // Renderiza os selects
  isFetching = false,
  handleExport = () => {},
  isLoadingExport = false,
  refetch = () => {},
  handleFilters = () => {},
  customComponent = <></>,
  hideExportButton = false,
  hideRefleshButton = false,
  hideDate = false,
  period = 'day',
  enablePeriodFilters = false,
  handlePeriodFilter = () => {},
  persistDate = false,
  selectedFilter = false,
  showHours = false,
  datePickerType = 'dynamic',
  defaultDate = {
    initialDate: subDays(new Date(), 30),
    finalDate: new Date(),
  },
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { isProvider } = usePlans();
  const location = useLocation();
  const path = location.pathname;
  const currentPageName = path.split('/').pop();

  const selectEmpresas = useSelector(state => state.selects.empresas);
  const persistedFilters = useSelector(state => {
    return state.filter.filters && state.filter.filters[currentPageName]
      ? state.filter.filters[currentPageName]
      : {};
  });

  console.log('Current Page Name', currentPageName);

  const tabsDates = [
    { value: 'day', label: 'D' },
    { value: 'week', label: 'S' },
    { value: 'month', label: 'M' },
  ];

  const [isFilterSelect, setIsFilterSelect] = useState(selectedFilter);
  const [filters, setFilters] = useState({ ...persistedFilters });

  const listRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState();
  const [clearFilters, setClearFilters] = useState(false);
  const [usedData, setUsedData] = useState(data);
  const [loadedInitialDate, setLoadedInitialDate] = useState(false);

  useEffect(() => {
    let tmp = { ...persistedFilters };

    if (Object.prototype.hasOwnProperty.call(tmp, 'initialDate')) {
      delete tmp.initialDate;
    }
    if (Object.prototype.hasOwnProperty.call(tmp, 'finalDate')) {
      delete tmp.finalDate;
    }

    if (Object.keys(tmp).length > 0) {
      setIsFilterSelect(true);
    }
  }, [persistedFilters]);

  useEffect(() => {
    setUsedData([...data]);
    const list = listRef.current;
    setIsOverflowing(list.scrollWidth > list.clientWidth);
    if (isProvider) {
      const empresas = filters?.empresas;
      if (empresas?.length) {
        const filteredEmpresas = selectEmpresas?.filter(item =>
          empresas.includes(item.id),
        );
        const newFiliais = filteredEmpresas.flatMap(item =>
          item?.filiais.map(filial => ({
            label:
              empresas?.length > 1
                ? `(${item?.nome}) ${filial?.nome}`
                : `${filial?.nome}`,
            value: filial?.id,
          })),
        );

        setUsedData(
          usedData.map(item => {
            if (
              item.filterName === 'filial' ||
              item.filterName === 'filial_veiculo'
            ) {
              item.options = newFiliais;
            }
            return { ...item };
          }),
        );
      }
    }

    // Ao inicializar data do componente sem passar data e nao existe ainda no redux
    if (
      !loadedInitialDate &&
      !data?.initialDate &&
      !persistedFilters?.initialDate
    ) {
      handleFilters(defaultDate);
      if (currentPageName && persistDate) {
        const combinedFilters = { ...filters, ...defaultDate };
        dispatch(handleApplyFilter(combinedFilters, currentPageName));
      }
      setLoadedInitialDate(true);
    }
  }, [filters, data]);

  const handleClick = newPlacement => event => {
    setAnchorEl(event.currentTarget);
    setOpen(prev => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const handleReflesh = () => {
    refetch();
  };

  const handleFilterChange = (filterName, ids) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterName]: ids,
    }));
  };

  const handleDateChange = date => {
    const newFilters = {
      ...filters,
      ...date,
    };

    handleFilters(newFilters);

    setFilters(prevFilters => ({
      ...prevFilters,
      ...newFilters,
    }));

    if (persistDate) {
      const combinedFilters = {
        ...persistedFilters,
        ...newFilters,
      };
      dispatch(handleApplyFilter(combinedFilters, currentPageName));
    }
  };

  const handleApplyFilters = (reset = false) => {
    if (reset) {
      Object.keys(filters).forEach(f => {
        if (!['initialDate', 'finalDate'].includes(f)) filters[f] = [];
      });
    }
    handleFilters(filters);

    if (persistDate) {
      const combinedFilters = {
        ...persistedFilters,
        ...filters,
      };
      dispatch(handleApplyFilter(combinedFilters, currentPageName));
    }
    if (!reset) {
      toast.success('Filtro de data aplicado com sucesso!');
    } else toast.success('Filtros resetados com sucesso!');
  };

  return (
    <Container>
      <Popper
        sx={{ zIndex: 1200 }}
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        transition
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 5],
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <PopoverContaiener>
              <div className="text">Deseja resetar todos os filtros?</div>
              <div className="container-btn">
                <ButtonCancelar onClick={() => setOpen(false)}>
                  Cancelar
                </ButtonCancelar>
                <ButtonApply
                  onClick={() => {
                    setClearFilters(true);
                    setTimeout(() => setClearFilters(false), 100);
                    setOpen(false);
                    handleApplyFilters(true);
                    handleReflesh();
                  }}
                >
                  Aplicar
                </ButtonApply>
              </div>
            </PopoverContaiener>
          </Fade>
        )}
      </Popper>
      <FiltersContainer>
        <div className="container-controls">
          {!hideRefleshButton && (
            <ButtonIconCustom
              opacity
              isRotating={isFetching}
              onClick={() => handleReflesh()}
            >
              <IconReflesh />
            </ButtonIconCustom>
          )}
          {customComponent}
        </div>

        <div className="btn-right-container">
          {enablePeriodFilters && (
            <Tabs
              tab={period}
              tabs={tabsDates}
              handleApplay={handlePeriodFilter}
            />
          )}
          {!!data.length && (
            <>
              <ButtonIconCustom opacity onClick={handleClick('bottom-end')}>
                <IconClear />
              </ButtonIconCustom>

              <ButtonCustom
                opacity
                endIcon={
                  <IconArrows
                    style={{
                      transform: isFilterSelect
                        ? 'rotate(180deg)'
                        : 'rotate(0deg)',
                      transition: 'transform 0.3s ease-in-out',
                    }}
                    fill={isFilterSelect ? '#fff' : '#939aab'}
                  />
                }
                selected={isFilterSelect}
                onClick={() => setIsFilterSelect(p => !p)}
              >
                <IconFilters fill={isFilterSelect ? '#fff' : '#939aab'} />
              </ButtonCustom>
            </>
          )}
          {!hideDate &&
            (datePickerType === 'fixed' ? (
              <PeriodPicker
                finalDate={
                  persistedFilters?.finalDate
                    ? persistedFilters?.finalDate
                    : defaultDate.finalDate
                }
                initialDate={
                  persistedFilters?.initialDate
                    ? persistedFilters?.initialDate
                    : defaultDate.initialDate
                }
                period="lastYear"
                periodAdd="month"
                onChange={handleDateChange}
              />
            ) : (
              <DateRangePicker
                showHours={showHours}
                dInt={
                  persistedFilters?.initialDate
                    ? persistedFilters?.initialDate
                    : defaultDate.initialDate
                }
                dfim={
                  persistedFilters?.finalDate
                    ? persistedFilters?.finalDate
                    : defaultDate.finalDate
                }
                onDate={handleDateChange}
                persistDate={persistDate}
              />
            ))}
          {!hideExportButton && (
            <ButtonExportar
              startIcon={<IconExportar />}
              onClick={e => handleExport(e)}
              disabled={isLoadingExport}
            >
              Exportar
            </ButtonExportar>
          )}
        </div>
      </FiltersContainer>
      <ContainerSelects isOpen={isFilterSelect}>
        <div className="filter-list-description">Filtrar por:</div>
        <div className="container-list" ref={listRef}>
          <div className="container-select">
            {usedData.map(item => (
              <div className="list-selects" key={item?.filterName}>
                <SelectMultiple
                  multiple={item?.multiple}
                  clearSelected={clearFilters}
                  data={item?.options || []}
                  filialSubTitle={`Filtrar por ${item?.label.toLowerCase()}`}
                  value={filters[item?.filterName] || []}
                  filialName={item?.label}
                  handleChange={val =>
                    handleFilterChange(item?.filterName, val)
                  }
                />
              </div>
            ))}
          </div>
          <ButtonApplyAll onClick={() => handleApplyFilters()}>
            <Icon
              name="icone_white_redondo_check"
              style={{
                width: '18.5px',
                height: '18.5px',
              }}
            />
            Aplicar
          </ButtonApplyAll>
        </div>
      </ContainerSelects>
    </Container>
  );
};

export default FiltersGlobal;
