import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Componentes
import Tabs from 'components/Tabs';
import FiltersGlobal from 'components/FiltersGlobal';
import { History } from '../../../components/Graphs/History';
import BarGraph2 from 'components/Graphs/NewBarGraph';
import { CustomizedSelect } from 'components/SelectCustom';

// Service
import { toast } from 'react-toastify/dist';
import { Grid } from '@mui/material';
import { trackEvent } from 'utils/mixpanel';
import { requestGraphData } from '../services';
import * as S from './styled';
import { useFetchMultipleWithCache } from 'hooks/fetchFilters';

const ptshTabs = [
  { value: 'tudo', label: 'Tudo' },
  { value: 'filial', label: 'Por filial' },
  { value: 'cliente', label: 'Por cliente' },
  { value: 'operacao', label: 'Por operação' },
];

const historicoTabs = [
  { value: 'status', label: 'Status' },
  { value: 'drivers', label: 'Motoristas avaliados' },
  { value: 'hours', label: 'Horas avaliadas' },
  { value: 'desvios', label: 'Zero desvios' },
  { value: 'atrasadas', label: 'Atrasadas' },
];

const top10Tabs = [
  { value: 'justificativas', label: 'Justificativas de exclusão' },
  { value: 'responsaveis', label: 'Responsáveis' },
  { value: 'indisponibilidade', label: 'Indisponibilidade' },
];

const GraficosAvalicoes = () => {
  // const filter = useSelector(state => {
  //   return state.filter;
  // });
  const user = useSelector(state => {
    return state.auth?.user?.user;
  });
  const filter = useSelector(state => {
    return state?.filter?.filters?.['estatisticas-avaliacoes'];
  });

  // const [query, setQuery] = useState({});

  const [ptsLoading, setPtsLoading] = useState(true);
  const [historicoLoading, setHistoricoLoading] = useState(true);
  const [tempLoading, setTempLoading] = useState(true);
  const [top10Loading, setTop10Loading] = useState(true);

  const [ptsHour, setPtsHour] = useState([]);
  const [ptsHourAxis, setPtsHourAxis] = useState([]);
  const [ptshTab, setPtshTab] = useState('tudo');

  const [historico, setHistorico] = useState([]);
  const [historicoAxis, setHistoricoAxis] = useState([]);
  const [historicoTab, setHistoricoTab] = useState('status');

  const [tempMedio, setTempMedio] = useState([]);
  const [tempMedioAxis, setTempMedioAxis] = useState([]);

  const [top10, setTop10] = useState([]);
  const [top10Tab, setTop10Tab] = useState('justificativas');

  // Selects dos filtros
  const { filiais, operacao, clients } = useFetchMultipleWithCache();

  const fetchPtsHour = async query => {
    setPtsLoading(true);
    const res = await requestGraphData('1', query);
    if (res.data?.data) {
      setPtsHour(res.data.data.data);
      setPtsHourAxis(res.data.data.series);
    } else if (res.data?.message) toast.error(res.data.message);
    setPtsLoading(false);
  };

  const fetchHistory = async query => {
    setHistoricoLoading(true);
    const res = await requestGraphData('2', query);
    if (res.data?.data) {
      setHistorico(res.data.data.historico);
      setHistoricoAxis(res.data.data.series);
    } else if (res.data?.message) toast.error(res.data.message);
    setHistoricoLoading(false);
  };

  const fetchTempMedio = async query => {
    setTempLoading(true);
    const res = await requestGraphData('3', query);
    if (res.data?.data) {
      setTempMedio(res.data.data.data);
      setTempMedioAxis(res.data.data.series);
    } else if (res.data?.message) toast.error(res.data.message);
    setTempLoading(false);
  };

  const fetchTop10 = async query => {
    setTop10Loading(true);
    const res = await requestGraphData('4', query);
    if (res.data?.data) {
      setTop10(res.data.data.data);
    } else if (res.data?.message) toast.error(res.data.message);
    setTop10Loading(false);
  };

  useEffect(() => {
    const query = { ...filter, tab_option: ptshTab };
    if (filter?.initialDate) fetchPtsHour(query);
  }, [filter, ptshTab]);

  useEffect(() => {
    const query = { ...filter, tab_option: historicoTab };
    if (filter?.initialDate) fetchHistory(query);
  }, [filter, historicoTab]);

  useEffect(() => {
    if (filter?.initialDate) fetchTempMedio(filter);
  }, [filter]);

  useEffect(() => {
    const query = { ...filter, tab_option: top10Tab };
    if (filter?.initialDate) fetchTop10(query);
  }, [filter, top10Tab]);

  const filterList = [
    // filiais, operacao, clients, responsaveis, cargas
    {
      filterName: 'filial',
      label: 'Filiais',
      options: filiais || [],
    },
    // {
    //   filterName: 'filial_veiculo',
    //   label: 'Filial Veículo',
    //   options: filiais || [],
    // },
    {
      filterName: 'client',
      label: 'Clientes',
      options: clients.data || [],
    },
    {
      filterName: 'operation',
      label: 'Operação',
      options: operacao,
    },
  ];

  const renderFilters = () => {
    return (
      <FiltersGlobal
        hideRefleshButton
        hideExportButton
        // showHours
        persistDate
        // refetch={fetchData}
        // isFetching={isFetching}
        // handleFilters={props =>
        //   setQuery(prev => {
        //     return {
        //       ...prev,
        //       ...props,
        //     };
        //   })
        // }
        data={filterList}
        customComponent={
          <S.Header>
            <h1>Estatísticas: Avaliações de direção</h1>
          </S.Header>
        }
      />
    );
  };

  const renderPhGraph = () => {
    const tabs = (
      <Tabs
        value={ptshTab}
        items={ptshTabs}
        onChange={(e, value) => {
          trackEvent(user, `GRÁFICO AVALIAÇÕES PTS/H: ${value}`);
          setPtshTab(value);
        }}
      />
    );

    return (
      <S.BoxGraphs>
        <History
          titulo="Histórico de ptos/hora"
          data={ptsHour}
          series={ptsHourAxis}
          series_divs={['pontos', 'horas']}
          loading={ptsLoading}
          leftHeaderComponent={tabs}
          initialDate={filter?.initialDate}
          finalDate={filter?.finalDate}
        />
      </S.BoxGraphs>
    );
  };

  const renderHistoryGraph = () => {
    const tabs = (
      <CustomizedSelect
        size="small"
        items={historicoTabs}
        selected={historicoTab}
        handelSelect={value => {
          trackEvent(user, `GRÁFICO AVALIAÇÕES HISTÓRICO: ${value}`);
          setHistoricoTab(value);
        }}
      />
    );

    return (
      <S.BoxGraphs>
        <History
          titulo="Histórico"
          data={historico}
          series={historicoAxis}
          calcDistinct={
            historicoTab === 'drivers' &&
            historicoAxis.includes('Motoristas avaliados')
          }
          loading={historicoLoading}
          leftHeaderComponent={tabs}
          initialDate={filter?.initialDate}
          finalDate={filter?.finalDate}
        />
      </S.BoxGraphs>
    );
  };

  const renderTempMedioGraph = () => {
    return (
      <S.BoxGraphs>
        <History
          titulo="Tempo médio (dias)"
          data={tempMedio}
          series={tempMedioAxis}
          loading={tempLoading}
          // leftHeaderComponent={tabs}
          initialDate={filter?.initialDate}
          finalDate={filter?.finalDate}
          hidePeriods
          initialPeriod="month"
        />
      </S.BoxGraphs>
    );
  };

  const renderTop10 = () => {
    const tabs = (
      <Tabs
        value={top10Tab}
        items={top10Tabs}
        onChange={(e, value) => {
          trackEvent(user, `GRÁFICO AVALIAÇÕES TOP 10: ${value}`);
          setTop10Tab(value);
        }}
      />
    );

    return (
      <S.BoxGraphs>
        <BarGraph2
          title="Top 10"
          leftHeaderComponent={tabs}
          currentTab={top10Tab}
          data={top10}
          tabsItems={top10Tabs}
          isLoading={top10Loading}
          handleTabClick={newValue => setTop10Tab(newValue)}
        />
      </S.BoxGraphs>
    );
  };

  const renderMain = () => (
    <>
      <S.Container>
        <S.Header>{renderFilters()}</S.Header>
        <S.Main>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} xl={12}>
              {renderPhGraph()}
            </Grid>

            <Grid item xs={12} md={12} xl={12}>
              {renderHistoryGraph()}
            </Grid>

            <Grid item xs={12} md={12} xl={12}>
              {renderTempMedioGraph()}
            </Grid>

            <Grid item xs={12} md={12} xl={12}>
              {renderTop10()}
            </Grid>
          </Grid>
        </S.Main>
      </S.Container>
    </>
  );

  return renderMain();
};

export default GraficosAvalicoes;
