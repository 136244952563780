/* eslint-disable import/order */

// Styles
import * as S from './styled';
import * as C from '../styled';

// React
import { useEffect, useState } from 'react';

// Components
import Select from 'components/Inputs/Select';
import TextInput from 'components/Inputs/TextField';
import DefaultButton from 'components/Buttons/Default';

// Components MUI
import { Tooltip, Grid } from '@mui/material';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

// Utils
import { cepMask } from 'utils/masks';
import { brStates } from 'utils/brazilianStates';

export const Perfil = ({
  title,
  tooltip,
  button,
  setChanged,
  setCurrentData,
  errors,
  data,
  preview,
}) => {
  const [disabled, setDisabled] = useState(false);

  const [form, setForm] = useState({
    nome_do_contato: '',
    email_do_contato: '',
    nome: '',
    cnpj: '',
    razao_social: '',
    endereco: {
      rua: '',
      cep: '',
      estado: '',
      bairro: '',
      cidade: '',
      numero: '',
      complemento: '',
    },
  });

  useEffect(() => {
    const newData = {
      nome_do_contato: data?.nome_do_contato,
      email_do_contato: data?.email_do_contato,
      nome: data?.nome,
      cnpj: data?.cnpj,
      razao_social: data?.razao_social,
      endereco: data?.endereco,
      faixa: data?.faixa_contratada,
    };
    !preview && setForm(newData);
  }, [data]);

  const handleChange = (key, value) => {
    setChanged(true);
    let newForm = form;
    if (key.includes('endereco')) {
      key = key.split('.')[1];
      newForm = { ...form, endereco: { ...form.endereco, [key]: value } };
    } else {
      newForm = { ...form, [key]: value };
    }
    setForm(newForm);
    setCurrentData(newForm);
  };

  const fillAddress = async () => {
    const address = await cepMask(form.endereco.cep);
    setForm({
      ...form,
      endereco: {
        ...form.endereco,
        cep: address.cep,
        rua: address.logradouro,
        bairro: address.bairro,
        cidade: address.localidade,
        estado: address.uf,
      },
    });
  };

  return (
    <S.Container>
      <C.HeaderStep>
        <div className="left">
          <div className="title">{title}</div>
          {tooltip && (
            <Tooltip title={tooltip}>
              <HelpOutlineOutlinedIcon className="iconTooltip" />
            </Tooltip>
          )}
        </div>
        {button && (
          <DefaultButton
            children={button}
            icon={<AddCircleOutlineOutlinedIcon />}
          />
        )}
      </C.HeaderStep>

      <S.CompanyInfo>
        <S.BoxInformation className="InfoCompany">
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={6} md={6}>
              <label>
                <TextInput
                  disabled={disabled}
                  label="Nome do Contato"
                  value={form.nome_do_contato}
                  onChange={e =>
                    handleChange('nome_do_contato', e.target.value)
                  }
                  error={errors?.nome_do_contato && !form.nome_do_contato}
                />
              </label>
              <label>
                <TextInput
                  disabled={disabled}
                  label="Nome da Empresa"
                  value={form.nome}
                  onChange={e => handleChange('nome', e.target.value)}
                  error={errors?.nome && !form.nome}
                />
              </label>
            </Grid>
            <Grid item xs={6} md={6}>
              <label>
                <TextInput
                  disabled={disabled}
                  label="Email do Contato"
                  value={form.email_do_contato}
                  onChange={e =>
                    handleChange('email_do_contato', e.target.value)
                  }
                  error={errors?.email_do_contato && !form.email_do_contato}
                />
              </label>
              <label>
                <TextInput
                  disabled
                  label="CNPJ"
                  value={form.cnpj}
                  onChange={e => handleChange('cnpj', e.target.value)}
                  error={errors?.cnpj && !form.cnpj}
                />
              </label>
            </Grid>
          </Grid>
        </S.BoxInformation>

        <S.BoxInformation className="InfoCompany">
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12} md={12}>
              <label>
                <TextInput
                  disabled={disabled}
                  label="Razão Social"
                  value={form.razao_social}
                  onChange={e => handleChange('razao_social', e.target.value)}
                  error={errors?.razao_social && !form.razao_social}
                />
              </label>
            </Grid>
            <Grid
              container
              item
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            />
          </Grid>
        </S.BoxInformation>

        <S.BoxInformation className="InfoCompany">
          <Grid
            container
            item
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={6} md={6}>
              <label>
                <TextInput
                  disabled={disabled}
                  label="Cep"
                  value={form?.endereco?.cep}
                  onChange={e => handleChange('endereco.cep', e.target.value)}
                  onBlur={() => fillAddress()}
                  error={errors?.endereco?.cep && !form?.endereco?.cep}
                />
              </label>
            </Grid>
            <Grid item xs={6} md={6} style={{ marginTop: '-20px' }}>
              <label>
                <Select
                  data={brStates}
                  label="Estado"
                  value={form?.endereco?.estado}
                  handleChange={e =>
                    handleChange('endereco.estado', e.target.value)
                  }
                  error={errors?.endereco?.estado && !form?.endereco?.estado}
                  style={{
                    marginBottom: '-20px',
                  }}
                />
              </label>
            </Grid>
            <Grid item xs={12} md={12}>
              <label>
                <TextInput
                  disabled={disabled}
                  label="Endereço"
                  value={form?.endereco?.rua}
                  onChange={e => handleChange('endereco.rua', e.target.value)}
                  error={errors?.endereco?.rua && !form?.endereco?.rua}
                />
              </label>
            </Grid>

            <Grid item xs={6} md={6}>
              <label>
                <TextInput
                  disabled={disabled}
                  label="Bairro"
                  value={form?.endereco?.bairro}
                  onChange={e =>
                    handleChange('endereco.bairro', e.target.value)
                  }
                  error={errors?.endereco?.bairro && !form?.endereco?.bairro}
                />
              </label>
            </Grid>
            <Grid item xs={6} md={6}>
              <label>
                <TextInput
                  disabled={disabled}
                  label="Cidade"
                  value={form?.endereco?.cidade}
                  onChange={e =>
                    handleChange('endereco.cidade', e.target.value)
                  }
                  error={errors?.endereco?.cidade && !form?.endereco?.cidade}
                />
              </label>
            </Grid>

            <Grid item xs={6} md={6}>
              <label>
                <TextInput
                  disabled={disabled}
                  label="Número"
                  value={form?.endereco?.numero}
                  onChange={e =>
                    handleChange('endereco.numero', e.target.value)
                  }
                  error={errors?.endereco?.numero && !form?.endereco?.numero}
                />
              </label>
            </Grid>
            <Grid item xs={6} md={6}>
              <label>
                <TextInput
                  disabled={disabled}
                  label="Complemento"
                  value={form?.endereco?.complemento}
                  onChange={e =>
                    handleChange('endereco.complemento', e.target.value)
                  }
                />
              </label>
            </Grid>
          </Grid>
        </S.BoxInformation>

        <S.BoxInformation className="InfoCompany">
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12} md={12}>
              <label>
                <TextInput
                  disabled
                  label="Faixa Contratada"
                  value={form.faixa}
                  onChange={e => handleChange('faixa', e.target.value)}
                />
              </label>
            </Grid>
            <Grid
              container
              item
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            />
          </Grid>
        </S.BoxInformation>
      </S.CompanyInfo>
    </S.Container>
  );
};
