// Styles
import { useTheme } from 'styled-components';

// React
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';

// Components
import SelectInput from 'components/Inputs/Select';
import ConfirmModal from 'components/ConfirmModal';
import GhostButton from 'components/Buttons/Ghost';
import SelectEmpresa from 'components/Inputs/SelectEmpresa';
import InputAttachedFile from 'components/Inputs/InputAttachedFile2';

// Components Material UI
import { EditOutlined } from '@mui/icons-material';
import { Icon, IconButton, Modal, Grid } from '@mui/material';

// Utils
import TextInput from 'components/Inputs/TextField';
import SelectOne from 'components/Inputs/SelectOne';
import * as services from '../services';
import * as S from './styled';
import { trackEvent } from '../../../../utils/mixpanel';

const ModalAdd = ({ open, dataEdit, handleClose, setUpdated }) => {
  const theme = useTheme();
  const { user } = useSelector(state => state.auth.user);
  const companies = useSelector(state => {
    return state.companies.map(item => ({
      id: item.id,
      nome: item.value,
      filiais: item.filiais,
    }));
  });

  const [routes, setRoutes] = useState([]);

  // General States
  const [loading, setLoading] = useState(false);

  // Modal Controll and States
  const [confirmEdit, setConfirmEdit] = useState(false);
  const [confirmAdd, setConfirmAdd] = useState(false);
  const [margin, setMargin] = useState('-550px');

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setMargin('0px');
      }, 10);
    }
  }, [open]);

  const behaviorCloseModal = () => {
    setMargin('-550px');
    setTimeout(() => {
      handleClose();
    }, 380);
  };

  // User fields
  const [empresa, setEmpresa] = useState();
  const [filial, setFilial] = useState('');
  const [operacao, setOperacao] = useState('');
  const [origem, setOrigem] = useState('');
  const [destino, setDestino] = useState('');
  const [anexo, setAnexo] = useState('');
  const [observacao, setObservacao] = useState('');

  // Filials redux

  const [filiais, setFiliais] = useState([]);

  useEffect(() => {
    setFiliais(
      companies
        .find(item => item.id === empresa)
        ?.filiais.map(item => ({
          name: item.nome,
          value: item.id,
        })) || [],
    );
  }, [empresa]);

  const operations = [
    {
      id: '1',
      name: 'Primária',
      value: '1',
    },
    {
      id: '2',
      name: 'Secundária',
      value: '2',
    },
    {
      id: '3',
      name: 'Açúcar',
      value: '3',
    },
  ];

  // Fill fields if action are edit
  useEffect(() => {
    if (!dataEdit) {
      return null;
    }

    setOperacao(dataEdit.id_tipo_operacao);
    setFilial(dataEdit.filial?.id);
    setOrigem(dataEdit.origem);
    setDestino(dataEdit.destino);
    setAnexo(dataEdit.arquivo);
    setObservacao(dataEdit.observacao);
    setEmpresa(dataEdit.id_da_empresa);
  }, [dataEdit]);

  // CONFIRM ACTION (FIELDS AND REQUESTS)
  const verifyFields = () => {
    if (!empresa) {
      toast.error('Selecione um empresa');
      return;
    }

    if (!operacao) {
      toast.error('Selecione uma operação');
      return;
    }

    if (!filial) {
      toast.error('Selecione uma filial');
      return;
    }

    if (!origem) {
      toast.error('Preencha a origem do rotograma');
      return;
    }

    if (!destino) {
      toast.error('Defina o destino do rotograma');
      return;
    }

    if (!anexo) {
      toast.error('Anexe um arquivo ao rotograma');
      return;
    }

    dataEdit?.id ? setConfirmEdit(true) : setConfirmAdd(true);
  };

  const confirmEditUser = async () => {
    setLoading(true);
    const res = await services.editRoute({
      id: dataEdit.id,
      origem,
      destino,
      arquivo: anexo,
      observacao,
      id_da_filial: filial,
      id_tipo_operacao: operacao,
      id_da_empresa: empresa,
    });

    if (res.data.success) {
      behaviorCloseModal();
      setTimeout(() => {
        setUpdated(true);
        toast.success(res.data.message);
      }, 600);
    } else {
      toast.error(res.data.message);
    }

    setConfirmEdit(false);
    setLoading(false);
    trackEvent(user, `rotograma editar`);
  };

  const confirmCreateUser = async () => {
    setLoading(true);

    const res = await services.createRoute({
      origem,
      destino,
      arquivo: anexo,
      observacao,
      id_da_filial: filial,
      id_tipo_operacao: parseInt(operacao),
      id_da_empresa: empresa,
    });

    if (res.data.success) {
      behaviorCloseModal();
      setTimeout(() => {
        setUpdated(true);
        toast.success(res.data.message);
      }, 600);
    } else {
      toast.error(res.data.message);
    }

    setConfirmAdd(false);
    setLoading(false);
    trackEvent(user, `rotograma adicionar`);
  };

  // ------------------------------MONITOR CLIENT X ORIGEM X OPERAÇÃO---------------------//
  const resetAndSet = (func, value) => {
    func(value);
    setOrigem('');
    setDestino('');
  };

  useEffect(() => {
    (async () => {
      const rotas = (await services.requestRotogramaRotas()).data.data
        .filter(
          item =>
            item.id_tipo_operacao == operacao &&
            item.id_da_distribuidora == user.id_da_distribuidora,
        )
        .map(item => ({
          value: item.rota,
          text: item.rota,
        }));
      setRoutes(rotas);
    })();
  }, [empresa, operacao]);

  return (
    <>
      <Modal
        open={open}
        onClose={behaviorCloseModal}
        disableEscapeKeyDown={false}
      >
        <S.Container margin={margin}>
          <S.Header>
            <div style={{ alignItems: 'center' }}>
              {dataEdit ? (
                <>
                  <EditOutlined
                    sx={{ color: theme.palette.brand.secondary.natural }}
                    fontSize="medium"
                  />
                  <h2>Editar Rotograma</h2>
                </>
              ) : (
                <>
                  <Icon
                    sx={{ color: theme.palette.brand.secondary.natural }}
                    fontSize="medium"
                  >
                    add_circle_outline
                  </Icon>
                  <h2>Adicionar Rotograma</h2>
                </>
              )}
            </div>

            <IconButton size="small" onClick={behaviorCloseModal}>
              <Icon sx={{ color: theme.palette.words.subtitle.natural }}>
                close
              </Icon>
            </IconButton>
          </S.Header>

          <S.Main>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <S.Section>INFORMAÇÕES BÁSICAS</S.Section>
              </Grid>

              <Grid item xs={12} sm={12}>
                <S.UserArea>
                  <SelectEmpresa
                    value={empresa}
                    setValue={value => resetAndSet(setEmpresa, value)}
                    empresas={[
                      ...companies,
                      { id: '', nome: 'Selecione um empresa' },
                    ]}
                  />
                </S.UserArea>
              </Grid>

              <Grid container item xs={12} sm={12} display="flex" spacing={2}>
                <Grid item xs={6} sm={6}>
                  <SelectInput
                    required
                    handleChange={e => resetAndSet(setOperacao, e.target.value)}
                    name="operacao"
                    label="Operação"
                    data={[
                      { name: 'Selecione uma operação', value: '' },
                      ...operations,
                    ]}
                    value={operacao}
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <SelectInput
                    required
                    handleChange={e => setFilial(e.target.value)}
                    name="filial"
                    label="Filial"
                    data={[
                      { name: 'Selecione uma filial', value: '' },
                      ...filiais,
                    ]}
                    value={filial}
                  />
                </Grid>
              </Grid>

              <Grid container item xs={12} sm={12} display="flex" spacing={2}>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  onClick={
                    !empresa || !operacao
                      ? () =>
                          toast.warning(
                            'Selecione empresa e operação para escolher a origem',
                          )
                      : undefined
                  }
                >
                  <SelectOne
                    value={origem}
                    handleChange={setOrigem}
                    data={routes}
                    required
                    label="Origem"
                    disabled={!empresa || !operacao}
                    type="routes"
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  onClick={
                    !empresa || !operacao
                      ? () =>
                          toast.warning(
                            'Selecione empresa e operação para escolher o destino',
                          )
                      : undefined
                  }
                >
                  <SelectOne
                    value={destino}
                    handleChange={setDestino}
                    data={routes}
                    required
                    label="Destino"
                    disabled={!empresa || !operacao}
                    type="routes"
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12}>
                <InputAttachedFile
                  label="Anexar Arquivo"
                  inputLabel="Anexar Arquivo"
                  fileUrl={anexo}
                  setFileUrl={value => setAnexo(value)}
                  fileDir="rotograma/"
                  typeFiles="application/pdf"
                  required
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextInput
                  label="Observações"
                  placeholder="Insira sua observação"
                  value={observacao}
                  onChange={e => setObservacao(e.target.value.slice(0, 300))}
                  InputProps={{ multiline: true }}
                  multiline
                  rows={6}
                />
              </Grid>
            </Grid>
          </S.Main>

          <S.Footer>
            <div>
              <GhostButton
                customcolor={
                  theme?.palette?.semantics?.feedback?.attention?.natural
                }
                onClick={behaviorCloseModal}
                size="medium"
              >
                Cancelar
              </GhostButton>

              <GhostButton
                onClick={verifyFields}
                size="medium"
                sx={{ marginLeft: '20px' }}
              >
                {dataEdit ? 'Salvar Alterações' : 'Salvar'}
              </GhostButton>
            </div>
          </S.Footer>
        </S.Container>
      </Modal>

      {/* Confirmação de edição */}
      <ConfirmModal
        open={confirmEdit}
        handleClose={() => setConfirmEdit(false)}
        title="Deseja editar rotograma?"
        titleIcon={
          <Icon
            sx={{ color: theme.palette.brand.secondary.natural }}
            fontSize="medium"
          >
            add_circle_outline
          </Icon>
        }
        subtitle="Atenção! Verifique todos os campos antes de confirmar a operação."
        buttonText="Confirmar"
        onClick={() => confirmEditUser()}
        loading={loading}
      />

      {/* Confirmação de adição */}
      <ConfirmModal
        open={confirmAdd}
        handleClose={() => setConfirmAdd(false)}
        title="Deseja confirmar criação do rotograma?"
        titleIcon={
          <EditOutlined
            sx={{ color: theme.palette.brand.secondary.natural }}
            fontSize="medium"
          />
        }
        subtitle="Atenção! Verifique todos os campos antes de confirmar a operação."
        buttonText="Confirmar"
        onClick={() => confirmCreateUser()}
        loading={loading}
      />
    </>
  );
};

export default ModalAdd;
