import produce from 'immer';
import * as types from './types';

const INITIAL_STATE = {
  modulos: [],
  last_modulos: null,
  filials: [],
  last_filials: null,
  clients: [],
  last_clients: null,
  operations: [],
  last_operations: null,
  drivers: [],
  last_drivers: null,
  users: [],
  last_users: null,
  trucks: [],
  last_trucks: null,
  publics: [],
  last_publics: null,
  responsibles: [],
  last_responsibles: null,
  cargas: [],
  last_cargas: null,
  rotas: [],
  last_rotas: null,
  empresas: [],
  last_empresas: null,
  params: [],
  last_params: null,
  'drivers-viagens': [],
  'last_drivers-viagens': null,
  'trucks-viagens': [],
  'last_trucks-viagens': null,
};

export default function selects(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.SET_DATA: {
        if (action.payload.query.modulos)
          draft.modulos = action.payload.query.modulos;
        if (action.payload.query.last_modulos)
          draft.last_modulos = action.payload.query.last_modulos;

        if (action.payload.query.filials)
          draft.filials = action.payload.query.filials;
        if (action.payload.query.last_filials)
          draft.last_filials = action.payload.query.last_filials;

        if (action.payload.query.clients)
          draft.clients = action.payload.query.clients;
        if (action.payload.query.last_clients)
          draft.last_clients = action.payload.query.last_clients;

        if (action.payload.query.operations)
          draft.operations = action.payload.query.operations;
        if (action.payload.query.last_operations)
          draft.last_operations = action.payload.query.last_operations;

        if (action.payload.query.drivers)
          draft.drivers = action.payload.query.drivers;
        if (action.payload.query.last_drivers)
          draft.last_drivers = action.payload.query.last_drivers;

        if (action.payload.query.users)
          draft.users = action.payload.query.users;
        if (action.payload.query.last_users)
          draft.last_users = action.payload.query.last_users;

        if (action.payload.query.publics)
          draft.publics = action.payload.query.publics;
        if (action.payload.query.last_publics)
          draft.last_publics = action.payload.query.last_publics;

        if (action.payload.query.responsibles)
          draft.responsibles = action.payload.query.responsibles;
        if (action.payload.query.last_responsibles)
          draft.last_responsibles = action.payload.query.last_publics;

        if (action.payload.query.trucks)
          draft.trucks = action.payload.query.trucks;
        if (action.payload.query.last_trucks)
          draft.last_trucks = action.payload.query.last_trucks;

        if (action.payload.query.cargas)
          draft.cargas = action.payload.query.cargas;
        if (action.payload.query.last_cargas)
          draft.last_cargas = action.payload.query.last_cargas;

        if (action.payload.query.rotas)
          draft.rotas = action.payload.query.rotas;
        if (action.payload.query.last_rotas)
          draft.last_rotas = action.payload.query.last_rotas;

        if (action.payload.query.params)
          draft.params = action.payload.query.params;
        if (action.payload.query.last_params)
          draft.last_params = action.payload.query.last_params;

        if (action.payload.query.empresas)
          draft.empresas = action.payload.query.empresas;

        if (action.payload.query['drivers-viagens'])
          draft['drivers-viagens'] = action.payload.query['drivers-viagens'];
        if (action.payload.query['last_drivers-viagens'])
          draft['last_drivers-viagens'] =
            action.payload.query['last_drivers-viagens'];

        if (action.payload.query['trucks-viagens'])
          draft['trucks-viagens'] = action.payload.query['trucks-viagens'];
        if (action.payload.query['last_trucks-viagens'])
          draft['last_trucks-viagens'] =
            action.payload.query['last_trucks-viagens'];

        break;
      }
      default:
    }
  });
}
