/* eslint-disable import/order */

// Styles
import * as S from '../styled';
import { colors } from 'styles/colors';
import { useTheme } from 'styled-components';
// React
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import UnicTurma from './UnicTurma';
import TableLocal from 'components/TableLocal';
import Button from 'components/Buttons/Default';
import ConfirmModal from 'components/ConfirmModal';
import ButtonGhost from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';

// Components MUI
import { Avatar, Divider } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';

// Utils
import { columnsTurmas } from '../columns';

// Services
import * as services from '../../services';

const ListTurmas = ({
  behaviorCloseModal,
  openModal,
  data,
  setSelectedTurmas,
  setAllTurmas,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [idTurma, setIdTurma] = useState(null);
  const [selectedRow, setSelectedRow] = useState([]);
  const [openNewTab, setOpenNewTab] = useState(false);
  const [openModalTurma, setOpenModalTurma] = useState(false);

  // --------------------------------------REQUEST FUNCTIONS--------------------------------------//
  const fetchTurma = async () => {
    const turmas = await services.GetPublic();
    if (turmas.success) {
      setAllTurmas(turmas.data);
    } else {
      toast.error(turmas.message);
    }
  };

  // --------------------------------------FORMATED TABLE--------------------------------------//

  const getAcron = str => {
    const matches = str.match(/\b(\w)/g);
    const acronym = matches.join('');
    return acronym.toUpperCase();
  };

  const formatTable = arr => {
    arr.forEach(i => {
      if (i.id === 'id') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver>
              <S.Paragraph fontweight="bold" color="#494f65">
                {row.original.id}
              </S.Paragraph>
            </S.ColDriver>
          );
        };
      }

      if (i.id === 'nome') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver>
              <div className="container-img-name">
                <Avatar
                  alt={getAcron(row.original.nome)}
                  src={row.original.foto}
                  sx={{ marginRight: 1 }}
                />
                <S.Paragraph fontweight="bold" color="#494f65">
                  {row.original.nome}
                </S.Paragraph>
              </div>
            </S.ColDriver>
          );
        };
      } else if (i.id === 'tipo') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver onClick={() => navigateTO(row.original.id)}>
              <S.Paragraph fontweight="bold" color="#494f65">
                {row.original.tipoUser}
              </S.Paragraph>
            </S.ColDriver>
          );
        };
      } else if (i.id === 'ativa') {
        i.Cell = function ({ row }) {
          let colorText = '';
          let colorBg = '';

          switch (row.original.ativa) {
            case true:
              colorText = colors.greenSucces;
              colorBg = colors.greenBackground;
              break;
            case false:
              colorText = colors.redDanger;
              colorBg = colors.redBackground;
              break;
          }

          return (
            <S.ColDriver>
              <S.Paragraph className="status" color={colorText} bg={colorBg}>
                {row.original.ativa ? 'Ativo' : 'Inativo'}
              </S.Paragraph>
            </S.ColDriver>
          );
        };
      } else if (i.id === 'n_alunos') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver>
              <S.Paragraph fontweight="bold" color="#494f65">
                {row.original.n_alunos}
              </S.Paragraph>
            </S.ColDriver>
          );
        };
      }
    });
    return arr;
  };

  // --------------------------------------ACTIONS--------------------------------------//
  const navigateTO = id => {
    navigate(`/capacitacao/viewTurmas/${id}`, { id });
  };

  const handleAddTurma = () => {
    setSelectedTurmas(selectedRow);
    behaviorCloseModal();
  };

  const redirectToCreationPage = () => {
    setOpenNewTab(false);
    window.open('/capacitacao/turmas?criar=true');
    window.onfocus = () => {
      fetchTurma();
    };
  };

  // --------------------------------------RETURN--------------------------------------//
  return (
    <S.ModalTurmas
      open={openModal}
      onClose={() => {
        behaviorCloseModal();
        window.onfocus = () => null;
      }}
      disableEscapeKeyDown={false}
    >
      <S.ContainerModal>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: 10,
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <PersonAddAltOutlinedIcon htmlColor={colors.greenMain} />
            <h1 style={{ marginLeft: '10px' }}>Adicionar Turmas</h1>
          </div>
          <IconButton
            onClick={() => {
              behaviorCloseModal();
              window.onfocus = () => null;
            }}
          >
            <CloseOutlinedIcon />
          </IconButton>
        </div>
        <Divider />
        <S.TableContainer>
          <TableLocal
            pageSizes={4}
            heightEmpty="285px"
            columns={formatTable(columnsTurmas)}
            data={data}
            sortBy={[]}
            permitIsSortedOccur
            tableType
            setSelectedData={setSelectedRow}
            actions={[
              {
                name: 'turma',
                action: id => {
                  Promise.all([setIdTurma(id)]).then(() =>
                    setOpenModalTurma(true),
                  );
                },
                text: 'Ver turma',
              },
            ]}
            bulkActions={[]}
            empty={{
              image: '',
              title: 'Turmas não encontradas.',
              subtitle: '',
            }}
            search={{
              inputLabel: 'Buscar Turma',
              keys: ['id', 'nome'],
            }}
          />
          {data.length <= 0 && (
            <S.AbsoluteButton>
              <DefaultButton
                startIcon={<AddCircleOutlineIcon />}
                children="Criar nova turma"
                onClick={() => setOpenNewTab(true)}
              />
            </S.AbsoluteButton>
          )}
        </S.TableContainer>
        <div
          style={{ display: 'flex', justifyContent: 'flex-end', padding: 15 }}
        >
          <ButtonGhost
            onClick={() => {
              behaviorCloseModal();
              window.onfocus = () => null;
            }}
            customcolor={
              theme?.palette?.semantics?.feedback?.attention?.natural
            }
          >
            Cancelar
          </ButtonGhost>
          <div style={{ marginLeft: 5 }} />
          <ButtonGhost onClick={handleAddTurma}>Adicionar</ButtonGhost>
        </div>

        <ConfirmModal
          open={openNewTab}
          handleClose={() => setOpenNewTab(false)}
          title="Deseja continuar?"
          titleIcon={
            <NoteAddOutlinedIcon
              sx={{ color: colors.greenMain }}
              fontSize="medium"
            />
          }
          subtitle="Você será direcionado para a criação de turmas"
          buttonText="Ir para criação"
          onClick={() => redirectToCreationPage()}
        />
        {idTurma && (
          <UnicTurma
            openModal={openModalTurma}
            idturma={idTurma}
            behaviorCloseModal={() => {
              setOpenModalTurma(false);
              setIdTurma(null);
              fetchTurma();
            }}
          />
        )}
      </S.ContainerModal>
    </S.ModalTurmas>
  );
};

export default ListTurmas;
