import api from 'services/api';
import { brStates } from 'utils/brazilianStates';

export const getStatus = async () => {
  return [
    { value: 'Ativo', label: 'Ativo' },
    { value: 'Agendado', label: 'Agendado' },
    { value: 'Finalizado', label: 'Finalizado' },
    { value: 'Rascunho', label: 'Rascunho' },
  ];
};

export const getResponsaveis = async () => {
  const res = await api.get(`/select-responsibles-provider`);
  const data = res?.data?.data || [];
  return data.map(i => ({ value: i.id, label: i.nome }));
};

export const getTiposDescarga = async () => {
  const res = await api.get('/select-unloadingInfractionTypes');
  const data = res?.data?.data || [];
  return data.map(i => ({ value: i?.id, label: i?.descricao }));
};

export const getExecutores = async () => {
  const res = await api.get('/provider/select-executores');
  const data = res?.data?.rows || [];
  return data.map(i => ({ value: i?.id, label: i?.value }));
};

export const getBases = async () => {
  const res = await api.get('/provider/select-bases');
  const data = res?.data?.rows || [];
  return data.map(i => ({ value: i?.id, label: i?.nome }));
};

export const getTamanhos = async () => {
  const res = await api.get('/provider/select-tamanhos');
  const data = res?.data?.rows || [];
  return data.map(i => ({ value: i?.id, label: i?.name }));
};

export const getTiposDesvios = async () => {
  const res = await api.get('/select-infractionTypes');
  const data = res?.data?.data || [];
  return data.map(i => ({ value: i?.id, label: i?.descricao }));
};

export const getMotoristas = async () => {
  return [
    { label: 'Agregados', value: 'AGREGADOS' },
    { label: 'Próprios', value: 'PROPRIOS' },
    { label: 'Não Informado', value: -1 },
  ];
};

export const getObjetos = async () => {
  return [
    { value: 'Alimento', label: 'ALIMENTO' },
    { value: 'Bebida', label: 'BEBIDA' },
    { value: 'Celular', label: 'CELULAR' },
    { value: 'Cigarro', label: 'CIGARRO' },
  ];
};

export const getDesviosTelemetria = async () => {
  const res = await api.get('/telematics_violacao_padrao', {
    params: { categoria: ['DESVIO', 'CERCA', 'VELOCIDADE', 'JORNADA'] },
  });
  const data = res?.data?.data;
  return data.map(i => ({ value: i?.id, label: i?.tipo }));
};

export const getDesviosFadiga = async () => {
  const res = await api.get('/telematics_violacao_padrao', {
    params: { categoria: ['FADIGA'] },
  });
  const data = res?.data?.data;
  return data.map(i => ({ value: i?.id, label: i?.tipo }));
};

export const getVeiculos = async () => {
  const res = await api.get('/telematics_tipos_veiculos');
  const data = res?.data?.data || [];
  return data.map(i => ({ value: i?.id, label: i?.tipo }));
};

export const getCriticidade = async () => {
  return [
    { value: 'PRIMÁRIA', label: 'PRIMÁRIA' },
    { value: 'MODERADA', label: 'MODERADA' },
    { value: 'GRAVE', label: 'GRAVE' },
    { value: 'GRAVÍSSIMA', label: 'GRAVÍSSIMA' },
  ];
};

export const getCondicoes = async () => {
  return [
    { label: 'Pista Molhada', value: 'true' },
    { label: 'Pista Seca', value: 'false' },
    { label: 'Não Informado', value: '-1' },
  ];
};

export const getCarregamentos = async () => {
  return [
    { label: 'Carregado', value: 'true' },
    { label: 'Vazio', value: 'false' },
    { label: 'Não Informado', value: '-1' },
  ];
};

export const getCargas = async () => {
  const res = await api.get(`/select-cargas`);
  const data = res?.data?.data || [];
  return data.map(i => ({ value: i.id, label: i.nome }));
};

export const getPropriedade = async () => {
  return [
    { label: 'Próprio', value: '0' },
    { label: 'Agregado', value: '1' },
  ];
};

export const getDepartamento = async () => {
  return [
    { label: 'Coleta', value: 'COLETA' },
    { label: 'Entrega', value: 'ENTREGA' },
    { label: 'Ambos', value: 'AMBOS' },
  ];
};

export const getAgregados = async () => {
  return [
    { label: 'Agregados', value: 'SIM' },
    { label: 'Próprios', value: 'NÃO' },
  ];
};

export const getStatusMotorista = () => {
  return [
    { label: 'Ativo', value: 'ATIVO' },
    { label: 'Inativo', value: 'INATIVO' },
  ];
};

export const getStatusEmpresa = () => {
  return [
    { label: 'Ativos', value: 'ATIVO' },
    { label: 'Inativos', value: 'INATIVO' },
  ];
};

export const getDesviosTiposPadrao = async () => {
  /// const res = await api.get('/desvios/configuracoes/padroes-ativos');
  const res = await api.get('/desvios/configuracoes/padroes');
  const data = res?.data?.data || [];
  return data.map(i => ({ value: i?.id, label: i?.nome }));
};

export const getDesviosTipos = async () => {
  const res = await api.get('/desvios/configuracoes/tipos');
  const data = res?.data?.data || [];
  return data.map(i => ({
    value: i?.id,
    label: i?.nome,
    id_desvio_tipo_padrao: i?.id_desvio_tipo_padrao,
  }));
};

export const getTecnologias = async () => {
  const res = await api.get('/tecnologias');
  const data = res?.data?.data || [];
  return data.map(i => ({
    value: i?.id,
    label: i?.nome,
    ids_tipos: i?.desvio_tipos_padroes?.map(t => t.desvio_tipo_padrao.id),
  }));
};

export const getStates = () => {
  return brStates.map(i => ({ value: i.value, label: i.name }));
};

export const getTypeClientIp = () => {
  return [
    { label: 'IPIRANGA EMPRESAS', value: 'EMPRESA' },
    {
      label: 'POSTO REVENDEDOR',
      value: 'REVENDEDOR',
    },
  ];
};

export const getStatusAcaoSuspensao = async () => {
  return [
    { label: 'PENDENTE', value: 'PENDENTE' },
    { label: 'LIBERADO', value: 'LIBERADO' },
    { label: 'APROVADO', value: 'APROVADO' },
    { label: 'REPROVADO', value: 'REPROVADO' },
    { label: 'SUSPENSO', value: 'BLOQUEADO' },
  ];
};

export const getAcaoDisciplinar = async () => {
  const res = await api.get('/acoes-disciplinares');
  const data = res?.data?.data || [];
  return data.map(i => ({ value: i?.id, label: i?.nome }));
};

export const getFaixasAcoes = async () => {
  const res = await api.get('/acoes-suspensoes/faixas', {
    params: { ativo: 1 },
  });
  const data = res?.data?.data || [];
  return data.map(i => ({ value: i?.id, label: i?.titulo }));
};
