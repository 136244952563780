import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Components
import ListPage from 'pages/Motoristas/PerfilMotorista/components/ListPage';

// Services
import { trackEvent } from 'utils/mixpanel';
import { requestAcoesSuspensoes, requestCards } from './services';
import { columns } from './constants';

export const AcoesSuspensoes = () => {
  // Redux e hooks
  const params = useParams();
  const user = useSelector(state => state.auth?.user?.user);

  // General States
  const [query, setQuery] = useState(null);
  const [resetTable, setResetTable] = useState(false);
  const [loadingLines, setLoadingLines] = useState([]);

  const [selectedCard, setSelectedCard] = useState(null);

  const actions = [
    {
      title: 'Abrir em nova guia',
      function: id => handleOpenNewTab(id),
    },
  ];

  // -------------------------- REQUESTS ------------------------------------------//

  const { isFetching, data: resData } = useQuery(
    ['acoes-suspensoes', params?.id, query],
    () => query && requestAcoesSuspensoes(params?.id, query),
    {
      refetchOnWindowFocus: false,
      onSuccess: () => {
        setLoadingLines([]);
        resetTable && setResetTable(false);
      },
    },
  );

  // Atualiza cards de acordo com os filtros selecionados
  // Atualiza tabela após cards
  const { isLoading: loadingCards, data: resCards } = useQuery(
    ['cards-acoes-suspensoes', params?.id],
    () => requestCards(params?.id, {}),
    {
      refetchOnWindowFocus: false,
    },
  );

  const initial = useRef(true);
  useEffect(() => {
    if (initial.current) {
      initial.current = false;
      return;
    }

    setResetTable(true);
  }, [selectedCard]);

  // Só habilita clique no card quando nao há nada carregando
  // Verifica se card clicado há valor válido: nao nulo e maior que 0.
  const handleClickCard = type => {
    if (!loadingCards && !isFetching) {
      const cards = resCards || [];
      const card = cards.find(item => item.type === type);

      if (card.value) {
        if (type === selectedCard) setSelectedCard(null);
        else setSelectedCard(type);
      }
    }
  };

  const handleOpenNewTab = (_, item) => {
    item?.id_ultima_acao
      ? window.open(
          `/acoes-suspensoes/${item.id_motorista}?id_acao=${item?.id_ultima_acao}`,
        )
      : toast.warning('Motorista não possui ação');
  };

  return (
    <ListPage
      cards={
        resCards?.map(card => ({
          ...card,
          disabled: isFetching,
        })) || []
      }
      selectedCard={selectedCard}
      handleClickCard={handleClickCard}
      loadingCards={loadingCards}
      tableProps={{
        data: resData?.data?.data || [],
        columns,
        loading: isFetching,
        pageCount: resData?.data?.count || 0,
        visualizedKey: 'visto',
        local: false,
        actions,
        reset: resetTable,
        onClickRow: handleOpenNewTab,
        loadingSelection: loadingLines,
        setQuery: q =>
          setQuery({
            ...q,
            status:
              selectedCard && selectedCard === 'acoes_pendentes'
                ? 'PENDENTE'
                : selectedCard && selectedCard === 'acoes_finalizadas'
                ? 'LIBERADO'
                : undefined,
          }),
        searchEvent: search =>
          trackEvent(user, 'Busca Ação e Suspensão', null, search),
        sortBy: {
          id: 'pontos',
          order: 'DESC',
        },
        placeholder: `Buscar por ID`,
        empty: {
          title: 'Ops! Você não tem nenhuma ação.',
          description: 'Verifique os filtros selecionados.',
        },
      }}
    />
  );
};
