import { ArrowForwardIosOutlined } from '@mui/icons-material';

export const SUSPENSAO_ID = 4;

export const getStatus = (status, isSuspensao = false) => {
  switch (status) {
    case 'BLOQUEADO':
      return 'SUSPENSO';
    case 'LIBERADO':
      return isSuspensao ? 'LIBERADO' : 'CONCLUÍDO';
    default:
      return status;
  }
};

export const columns = [
  {
    header: '',
    width: 30,
    children: (_, item) => {
      if (item.selected)
        return <ArrowForwardIosOutlined fontSize="small" color="secondary" />;
      return ' ';
    },
  },
  {
    header: 'Id',
    id: 'id',
    type: 'number',
    sort: true,
    width: 100,
  },
  {
    header: 'Faixa',
    id: 'faixa',
    type: 'string',
    sort: true,
    conditional: [
      {
        condition: _ => true,
        style: (theme, item) => ({
          color: item.faixa_cor,
        }),
      },
    ],
  },
  {
    header: 'Ação disciplinar',
    id: 'acao_disciplinar',
    type: 'string',
  },
  {
    header: 'Data Alcance',
    id: 'data_inicio',
    type: 'date',
    sort: true,
  },
  {
    header: 'Data de Conclusão',
    id: 'data_fim',
    type: 'date',
    sort: true,
  },
  {
    header: 'Status',
    id: 'status',
    type: 'string',
    sort: true,
    width: 200,
    align: 'center',
    style: {
      textTransform: 'uppercase',
    },
    value: (value, item) =>
      getStatus(item.status, item.id_acao_disciplinar === SUSPENSAO_ID),
    conditional: [
      {
        condition: value =>
          ['APLICADO', 'LIBERADO', 'APROVADO', 'CONCLUÍDO'].includes(value),
        style: theme => ({
          color: theme.palette.semantics.feedback.success.natural,
          backgroundColor: theme.palette.semantics.feedback.success.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => value === 'PENDENTE',
        style: theme => ({
          color: theme.palette.semantics.feedback.warning.natural,
          backgroundColor: theme.palette.semantics.feedback.warning.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => value === 'REPROVADO',
        style: theme => ({
          color: theme.palette.semantics.feedback.warning.dark,
          backgroundColor: theme.palette.semantics.feedback.warning.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => ['BLOQUEADO', 'SUSPENSO'].includes(value),
        style: theme => ({
          color: theme.palette.semantics.feedback.attention.dark,
          backgroundColor: theme.palette.semantics.feedback.attention.light,
          textAlign: 'center',
        }),
      },
    ],
  },
];
