import {
  getTiposDesvios,
  getObjetos,
  getCriticidade,
  getResponsaveis,
} from 'constants/_SERVICES/provider';

// Pages
import RevisaoCliente from 'pages/Provider/RevisaoCliente';
import ExclusaoDesvio from 'pages/Provider/ExclusaoDesvio';
import CaronasAutorizadasProvider from 'pages/Provider/CaronasAutorizadas';

export default {
  '/caronas-autorizadas': {
    date: true,
    defaults: ['empresas', 'operation', 'filial', 'motoristas'],
    page: <CaronasAutorizadasProvider />,
  },

  '/revisao-cliente': {
    title: 'Revisão de Operação',
    back: -1,
    date: false,
    defaults: [],
    pageFilterName: 'filterRevisaoOperacao',
    filters: [],
    page: <RevisaoCliente />,
  },

  '/exclusao-desvio': {
    title: 'Exclusão de Desvio',
    back: -1,
    date: false,
    pageFilterName: 'filterExclusaoDesvio',
    defaults: [],
    filters: [],
    page: <ExclusaoDesvio />,
  },
};
