/* eslint-disable import/order */

// Styles
import * as S from './styled';
import colors from 'styles/colors';
import { useTheme } from 'styled-components';

// React
import ReactPlayer from 'react-player';
import { toast } from 'react-toastify';
import { memo, useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// Components
import { View } from '../../../../Guia/View';
import { ModalConfig } from '../ModalConfig';
import { Switch } from 'components/Inputs/Switch';
import ConfirmModal from 'components/ConfirmModal';
import GhostButton from 'components/Buttons/Ghost';
import TextInput from 'components/Inputs/TextField';
import SelectOne from 'components/Inputs/SelectOne';
import SelectList from 'components/Inputs/SelectList';
import DefaultButton from 'components/Buttons/Default';

// Components MUI
import { ListItem, Tooltip } from '@mui/material';
import TvOutlinedIcon from '@mui/icons-material/TvOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import PermMediaOutlinedIcon from '@mui/icons-material/PermMediaOutlined';
import NewReleasesOutlinedIcon from '@mui/icons-material/NewReleasesOutlined';
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

// Utils
import validator from './validator';
import * as services from '../services';
import { defaultForm } from './constants';

export const PageGuia = ({
  setScreen,
  page,
  setPage,
  update,
  routes,
  setSection,
  section,
  scrollView,
}) => {
  // Data States
  const [form, setForm] = useState(defaultForm);
  const theme = useTheme();

  // Control States
  const [isCapa, setIsCapa] = useState(false);
  const [changed, setChanged] = useState(false);
  const [stepItem, setStepItem] = useState(null);
  const [deleteItem, setDeleteItem] = useState(null);
  const [loading, setLoading] = useState(false);

  // Modal States
  const [stepModal, setStepModal] = useState(false);
  const [modalSave, setModalSave] = useState(false);
  const [modalBack, setModalBack] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);

  // -------------------INITIALIZE & FORMATTER-------------------//
  useEffect(() => {
    if (page) {
      const hasCapa = page?.etapas?.find(item => item?.is_capa);
      const steps = page?.etapas?.filter(item => !item?.is_capa);
      setForm({ ...page, capa: hasCapa, etapas: steps });
    }
    !page && setForm(defaultForm);
  }, [page]);

  const formatText = text => {
    if (text?.length > 20) {
      return `${text?.substring(0, 20)}...`;
    }
    return text;
  };

  const handleClose = skip => {
    if (changed && !skip) {
      setModalBack(true);
    } else {
      scrollView(page?.id_secao, page?.id, section?.id);
      setPage(null);
      setForm(defaultForm);
      setScreen(0);
      setSection(null);
      setChanged(false);
      setModalBack(false);
    }
  };

  const planByRoute = () => {
    let plans = [];
    routes?.forEach(item => {
      if (item.rota === form?.rota) {
        plans = item?.planos?.map(plan => ({
          id: plan?.id,
          value: `${plan?.titulo}`,
        }));
      }
    });

    return plans;
  };

  // -------------------FUNCTIONS-------------------//
  const handleText = (key, value) => {
    setChanged(true);
    if (key === 'rota') {
      setForm({ ...form, [key]: value, planos: null });
    } else {
      setForm({ ...form, [key]: value });
    }
  };

  const deleteStep = index => {
    setChanged(true);
    const steps = form?.etapas?.filter((item, key) => key !== index);
    setForm({ ...form, etapas: steps });
    setDeleteItem(null);
  };

  const setNewStep = (item, key) => {
    setChanged(true);
    if (key !== null) {
      const newSteps = form?.etapas ? [...form?.etapas] : [];
      newSteps.map((step, index) => {
        if (index === key) {
          newSteps[index] = { ...step, ...item };
        }
      });
      setForm({ ...form, etapas: newSteps });
    } else {
      const newSteps = form?.etapas ? [...form?.etapas] : [];
      setForm({
        ...form,
        etapas: [...newSteps, { ...item, ordem: form?.etapas?.length + 1 }],
      });
    }
  };

  const handleCheckItem = key => {
    setChanged(true);
    const newSteps = [...form?.etapas];
    newSteps.map((step, index) => {
      if (index === key) {
        newSteps[index] = { ...step, ativa: !step?.ativa };
      }
    });
    setForm({ ...form, etapas: newSteps });
  };

  const handleCheckCapa = () => {
    setChanged(true);
    setForm({ ...form, capa: { ...form?.capa, ativa: !form?.capa?.ativa } });
  };

  const handleCapaInfo = item => {
    setChanged(true);
    setForm({ ...form, capa: { ...form?.capa, ativa: true, ...item } });
  };

  const handlePreview = () => {
    if (openPreview) return;
    const hasCapa = form?.capa?.ativa ? 1 : 0;
    const hasSteps = form?.etapas?.filter(item => item?.ativa).length || 0;
    const total = hasCapa + hasSteps;
    total ? setOpenPreview(true) : toast.warn('Nenhum item inserido ou ativo');
  };

  // ----------------------SAVE-------------------------//
  const save = async () => {
    const message = validator(form);
    if (message) {
      setModalSave(false);
      toast.error(message);
      return;
    }
    setLoading(true);
    const req = form.id ? services.updateContent : services.saveContent;

    const hasCapa = form?.capa;
    const formatSteps = hasCapa
      ? [{ ...form?.capa, is_capa: 1 }, ...form?.etapas]
      : form?.etapas;

    const res = await req({
      id_secao: form?.id_secao || section?.id,
      ativa: form?.ativa || true,
      ordem: section?.guia?.length + 1 || 1,
      ...form,
      etapas: formatSteps?.map((item, index) => ({
        ...item,
        ordem: index + 1,
      })),
    });

    if (res.success) {
      toast.success(res.message);
      setModalSave(false);
      handleClose(true);
      update(res.data);
    } else {
      toast.error(res.message);
    }
    setLoading(false);
  };

  // --------------------DRAG AND DROP-------------------//

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = ({ destination, source }) => {
    if (!destination) return;
    const reordered = reorder(form?.etapas, source.index, destination.index);
    setForm({ ...form, etapas: reordered });
    setChanged(true);
  };

  const DraggableListItem = ({ item, index }) => {
    return (
      <Draggable draggableId={String(index)} index={index}>
        {provided => (
          <ListItem
            component="div"
            ref={provided.innerRef}
            {...provided.draggableProps}
          >
            <div className="lineStep" key={index}>
              <p {...provided.dragHandleProps}>
                <MenuOutlinedIcon />
              </p>

              <div className="medium">
                <div className="prevImg">
                  {item.link && (
                    <>
                      {item.tipo === 'VIDEO' && (
                        <ReactPlayer
                          width="100%"
                          height="100%"
                          controls
                          url={item.link}
                          light={item?.imagem_capa}
                        />
                      )}
                      {item.tipo === 'IMAGEM' && <img src={item.link} />}
                    </>
                  )}
                  {!item.link && (
                    <div className="noImg">
                      <PermMediaOutlinedIcon />
                      <span>Sem Mídia</span>
                    </div>
                  )}
                </div>
                <div className="infos">
                  <div className="title">
                    <span>Titulo:</span>
                    {formatText(item?.titulo)}
                  </div>
                  <div className="title">
                    <span>Descrição:</span>
                    {formatText(item?.descricao)}
                  </div>
                  <div className="title">
                    <span>Link:</span>
                    {formatText(item?.link || '-')}
                  </div>
                </div>
              </div>
              <div className="actions">
                <Switch
                  check={item?.ativa}
                  setCheckbox={() => handleCheckItem(index)}
                />
                <EditOutlinedIcon
                  htmlColor={colors.greenMain}
                  onClick={() => {
                    setStepItem(item);
                    setStepModal(true);
                  }}
                />
                <DeleteOutlineOutlinedIcon
                  htmlColor={colors.redDanger}
                  onClick={() => setDeleteItem(index)}
                />
              </div>
            </div>
          </ListItem>
        )}
      </Draggable>
    );
  };

  const DraggableList = memo(({ items, onDragEnds }) => {
    return (
      <DragDropContext onDragEnd={onDragEnds}>
        <Droppable droppableId="droppable-list">
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {items.map((item, index) => (
                <DraggableListItem
                  item={item}
                  index={index}
                  key={String(index)}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  });

  // -------------------RENDER-------------------//
  return (
    <>
      <S.Container>
        <S.Header>
          <div className="line" onClick={() => handleClose()}>
            <ArrowBackIosNewOutlinedIcon htmlColor={colors.blueInfo} />
            <div className="title">Guia:</div>
            <div className="page">{form?.titulo}</div>
          </div>
          <div className="actions">
            <button className="preview" onClick={() => handlePreview()}>
              Ver Prévia
              <TvOutlinedIcon />
            </button>
            <DefaultButton
              children="Salvar"
              onClick={() => setModalSave(true)}
            />
          </div>
        </S.Header>
        <S.Body>
          <div className="grid">
            <div className="item">
              <TextInput
                label="Nome do Guia"
                value={form?.titulo}
                required
                onChange={e =>
                  handleText('titulo', e.target.value.slice(0, 38))
                }
              />
              <span className="info">
                Nome que será exibido na tela de guias. Ex: 'Criar uma
                avaliação'
              </span>
              <div className="counter">{form?.titulo?.length || 0}/38</div>
            </div>
            <div className="item">
              <p className="label">Habilitar curtidas e comentários:</p>
              <div className="checkArea start">
                <Switch
                  check={form?.habilitar_review}
                  textOn="Habilitado"
                  textOff="Desabilitado"
                  setCheckbox={() =>
                    handleText('habilitar_review', !form.habilitar_review)
                  }
                />
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="item">
              {(page || section) && (
                <SelectOne
                  value={form?.rota}
                  handleChange={val => handleText('rota', val)}
                  data={routes?.map(item => ({
                    value: item.rota,
                    text: item.rota,
                  }))}
                  required
                  label="Selecione uma rota"
                />
              )}
              <span className="info">
                URL onde o guia deve ser exibido no primeiro acesso do usuário.
              </span>
            </div>

            <div className="item">
              <p className="label p">
                Selecione os módulos: <span className="req">*</span>
              </p>

              <div
                className={!form?.rota ? ' off' : ''}
                onClick={
                  !form?.rota
                    ? () =>
                        toast.warn('Selecione uma rota para definir os módulos')
                    : undefined
                }
              >
                <SelectList
                  label="Módulos"
                  data={planByRoute()}
                  value={form?.planos}
                  fieldName="planos"
                  handleChange={value => handleText('planos', value)}
                  selectAll
                  disabled={!form?.rota}
                />
              </div>
              <span className="info">
                Somente os usuários que possuírem os planos selecionados podem
                visualizar o guia.
              </span>
            </div>
          </div>
          <div className="grid">
            <div className="item">
              <TextInput
                label="Descrição do Guia"
                value={form?.descricao}
                multiline
                rows={3}
                required
                onChange={e =>
                  handleText('descricao', e.target.value.slice(0, 100))
                }
              />
              <div className="counter rows">
                {form?.descricao?.length || 0}/100
              </div>
            </div>
          </div>
          <S.Steps>
            <div className="etp">
              Capa:
              <Tooltip title="Primeiro item a ser exibido quando o cliente acessar a URL informada, esse elemento fica centralizado no meio da tela e possui bloqueio até que o usuário inicie a ação ou feche o item">
                <HelpOutlineOutlinedIcon />
              </Tooltip>
            </div>

            {!form?.capa && (
              <S.ButtonAdd
                className="small"
                onClick={() => {
                  setIsCapa(true);
                  setStepModal(true);
                }}
              >
                <AddCircleOutlineOutlinedIcon />
                Adicionar uma capa
              </S.ButtonAdd>
            )}

            {form?.capa && (
              <div className="lineStep">
                <PushPinOutlinedIcon />

                <div className="medium">
                  <div className="prevImg">
                    {form?.capa?.link && (
                      <>
                        {form?.capa?.tipo === 'VIDEO' && (
                          <ReactPlayer
                            width="100%"
                            height="100%"
                            controls
                            playing={false}
                            url={form?.capa?.link}
                            light={form?.capa?.imagem_capa}
                          />
                        )}
                        {form?.capa?.tipo === 'IMAGEM' && (
                          <img src={form?.capa?.link} />
                        )}
                      </>
                    )}
                    {!form?.capa?.link && (
                      <div className="noImg">
                        <PermMediaOutlinedIcon />
                        <span>Sem Mídia</span>
                      </div>
                    )}
                  </div>
                  <div className="infos">
                    <div className="title">
                      <span>Titulo:</span>
                      {formatText(form?.capa?.titulo)}
                    </div>
                    <div className="title">
                      <span>Descrição:</span>
                      {formatText(form?.capa?.descricao)}
                    </div>
                    <div className="title">
                      <span>Link:</span>
                      {formatText(form?.capa?.link || '-')}
                    </div>
                  </div>
                </div>
                <div className="actions">
                  <Switch
                    check={form?.capa?.ativa}
                    setCheckbox={() => handleCheckCapa()}
                  />
                  <EditOutlinedIcon
                    htmlColor={colors.greenMain}
                    onClick={() => {
                      setIsCapa(true);
                      setStepItem(form?.capa);
                      setStepModal(true);
                    }}
                  />
                </div>
              </div>
            )}
          </S.Steps>
          <S.Steps>
            <div className="etp">
              Etapas:
              <Tooltip title="Itens que serão exibidos no canto inferior direito da tela, não é necessário aplicar ação alguma para que o usuário possa navegar pelos outros componentes da página">
                <HelpOutlineOutlinedIcon />
              </Tooltip>
            </div>
            <DraggableList items={form?.etapas || []} onDragEnds={onDragEnd} />
          </S.Steps>
          <S.ButtonAdd onClick={() => setStepModal(true)}>
            <AddCircleOutlineOutlinedIcon />
            Adicionar novo passo
          </S.ButtonAdd>
        </S.Body>
        <S.Footer>
          <GhostButton
            children="Cancelar"
            customcolor={
              theme?.palette?.semantics?.feedback?.attention?.natural
            }
            onClick={() => handleClose()}
          />
          <GhostButton
            children="Salvar"
            onClick={() => setModalSave(true)}
            loading={loading}
          />
        </S.Footer>
      </S.Container>

      {/* Modal Área */}
      <ModalConfig
        isCapa={isCapa}
        edit={stepItem}
        open={stepModal}
        index={stepItem ? form?.etapas?.indexOf(stepItem) : null}
        handleClose={() => {
          setStepModal(false);
          setStepItem(null);
        }}
        setSteps={setNewStep}
        setCapa={handleCapaInfo}
        setIsCapa={setIsCapa}
      />
      <ConfirmModal
        open={deleteItem !== null}
        title="Tem certeza que deseja remover esse passo?"
        handleClose={() => setDeleteItem(null)}
        buttonText="Excluir"
        isNegative
        subtitle="O item será excluído e não poderá mais ser visualizado pelo usuário"
        onClick={() => deleteStep(deleteItem)}
        titleIcon={<DeleteOutlineOutlinedIcon htmlColor={colors.redDanger} />}
      />
      <ConfirmModal
        open={modalSave}
        title="Tem certeza que deseja salvar as alterações?"
        handleClose={() => setModalSave(false)}
        buttonText="Confirmar"
        subtitle="As modificações serão disponibilizadas na plataforma"
        onClick={() => save()}
        loading={loading}
        titleIcon={<StarOutlineOutlinedIcon htmlColor={colors.greenSucces} />}
      />

      <ConfirmModal
        open={modalBack}
        title="Você tem alterações não salvas!"
        handleClose={() => setModalBack(false)}
        buttonText="Sair sem salvar"
        isNegative
        subtitle="Tem certeza que deseja sair? Todos os itens não salvos serão perdidos"
        onClick={() => handleClose(true)}
        titleIcon={<NewReleasesOutlinedIcon htmlColor={colors.redDanger} />}
      />

      {openPreview && (
        <View
          guide={form}
          open={openPreview}
          handleClose={() => setOpenPreview(false)}
          preview
        />
      )}
    </>
  );
};
