import api from 'services/api';

export const getMulta = async id => {
  const { data } = await api.get(`/multas/${id}`);
  if (data.success) return data?.data;
  return null;
};

export const getMultas = async params => {
  const { data } = await api.get('/multas', { params });
  return data;
};

export const getTiposMultas = async () => {
  const { data } = await api.get('/multas/tipos');
  return data.data;
};

export const getHistoricoMultas = async params => {
  const { data } = await api.get('/multas/historico', { params });
  if (data.data.length) {
    return data.data;
  }
  return [];
};

export const getTotais = async params => {
  const { data } = await api.get('/multas/total', { params });
  if (data.data) {
    return data.data;
  }
  return {};
};

export const saveMulta = async payload => {
  const { data } = payload.id
    ? await api.put(`/multas/${payload.id}`, payload)
    : await api.post(`/multas`, payload);
  return data;
};

export const fetchSequence = async (index, query) => {
  const res = await api.get(`/multas/paginator/${index}`, { params: query });
  if (res?.data) return res.data;
  return null;
};

export const requestExcel = async query => {
  const res = await api.get('/excel/multas', { params: query });
  if (res?.data?.data?.link)
    return { link: res.data.data.link, message: res.data.message };
  return { message: res?.data?.message };
};

export const checkUpload = async data => {
  const res = await api.post(`/multas/check-upload`, data);
  return res;
};

export const createMany = async data => {
  const res = await api.post(`/multas/create-many`, data);
  return res;
};
