import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.palette.words.title.natural};
`;

export const Title = styled.div`
  width: 100%;
  margin-bottom: 0.5rem;

  font-size: 20px;
  font-weight: 800;
`;

export const ListWrapper = styled.div`
  width: 100%;
  margin: 1rem 0;
  height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const ListItem = styled.div`
  width: 100%;
  cursor: pointer;
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  margin-bottom: 8px;
  border-radius: 100px;
  background: ${({ theme }) => theme.palette.system.border};

  font-weight: 500;
  transition: all 0.15s ease-in-out;

  :hover {
    background: ${({ theme }) => theme.palette.system.divider};
  }

  &.selected {
    background: ${({ theme }) => theme.palette.brand.primary.natural};
    color: ${({ theme }) => theme.palette.system.highlight};
  }
`;
