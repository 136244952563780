/* eslint-disable import/order */

// Styles
import * as S from './styled';
import * as C from '../styled';

// React
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { Fragment, useEffect, useState } from 'react';

// Components
import Tag from '../../components/Tag';
import Search from '../../components/Search';
import Templates from '../../components/Templates';
import SliderTag from '../../components/SliderTag';
import ResponseModal from '../../components/ModalResponse';

// Components MUI
import SlideshowIcon from '@mui/icons-material/Slideshow';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';

// Services
import * as services from '../services';

// Loaders
let timer = null;
let timerRoute = null;
const charge = [0, 1, 2, 3, 4, 5, 6];
const chargeCards = [0, 1, 2];

const MeusConteudos = () => {
  // Data States
  const [tags, setTags] = useState([]);
  const [cards, setCards] = useState(chargeCards);
  const [idUser, setIdUser] = useState(null);
  const [tipoUser, setTipoUser] = useState(null);
  const [templates, setTemplates] = useState(null);
  const [filteredTemplates, setFilteredTemplates] = useState(charge);

  // Controller States
  const [inSearch, setInSearch] = useState(true);
  const [isPreview, setIsPreview] = useState(false);
  const [firstCharge, setFirstCharge] = useState(true);
  const [loadingCard, setLoadingCard] = useState(false);
  const [currentContent, setCurrentContent] = useState(null);
  const [openPreviewFull, setOpenPreviewFull] = useState(false);
  const [query, setQuery] = useState({
    card: null,
    tag: 'Todos',
    search: '',
  });

  // --------------------------------------------REQUESTS--------------------------------------------//
  const {
    isLoading: loadingContent,
    isFetching,
    refetch,
  } = useQuery(['contents-profile', 1], () => services.fetchAllContents(), {
    refetchOnWindowFocus: false,
    onSuccess: data => {
      setCards(data.cards);
      setTags(data.tags);
      setTemplates(data.data);
      setIdUser(data.aluno.id);
      setTipoUser(data.aluno.tipo);
    },
    onError: data => toast.error(data?.message),
  });

  // Controll back arrow in window when modal is opened in same tab (BETA VERSION)
  useEffect(() => {
    timerRoute = setTimeout(() => {
      if (!openPreviewFull && !firstCharge) {
        window.onpopstate = event => {
          event.preventDefault();
        };
      }
    }, 100);

    return function cleanup() {
      clearTimeout(timerRoute);
    };
  }, [openPreviewFull, firstCharge]);

  // --------------------------------------------FILTERS--------------------------------------------//
  const handleQuery = object => {
    setQuery({ ...query, ...object });
  };

  useEffect(() => {
    const temps = templates;
    let hasFilter = temps;
    let status = null;

    if (!temps || firstCharge) {
      setFilteredTemplates(charge);
      setFirstCharge(false);
      return;
    }

    // Filtro Search
    if (query.search) {
      hasFilter = hasFilter?.filter(temp => {
        if (
          temp.titulo
            .toLowerCase()
            .includes(query.search.toLocaleLowerCase()) ||
          temp.tags
            .toString()
            .toLocaleLowerCase()
            .includes(query.search.toLocaleLowerCase())
        ) {
          return temp;
        }
      });
    }

    if (query.tag !== 'Todos') {
      hasFilter = hasFilter?.filter(temp => temp.tags.includes(query.tag));
    }

    setInSearch(true);
    clearTimeout(timer);

    timer = setTimeout(() => {
      if (!query.search && query.tag === 'Todos') {
        setFilteredTemplates(null);
      } else {
        setFilteredTemplates(hasFilter);
      }
      setInSearch(false);
    }, 400);
  }, [query, templates]);

  // --------------------------------------------ACTIONS--------------------------------------------//
  const handlePerformTest = async (id, preview) => {
    const data = templates.find(temp => temp.id === id);
    setLoadingCard(true);
    if (data.status_usuario === 'REPROVADO' && !preview) {
      const res = await services.tryNewTest(data.id);
      setCurrentContent(res.data || []);
      setOpenPreviewFull(true);
    } else {
      const obj = {
        alunoId: idUser,
        tipo: tipoUser,
        percentual: data.percentual,
      };
      setIsPreview(preview);
      const res = await services.showContent(data.id, obj);
      const content = res.data.content || [];
      setCurrentContent(content);
      setOpenPreviewFull(true);
    }
    setLoadingCard(false);
  };

  // --------------------------------------------RENDER--------------------------------------------//
  return (
    <S.Container>
      <S.Header>
        <h1>Meus Conteúdos</h1>
        <Search
          value={query.search}
          setValue={value => handleQuery({ search: value })}
          placeholder="Buscar Conteúdo"
        />
      </S.Header>
      <S.Header>
        <SliderTag width="100%">
          {!isFetching &&
            tags.map((item, key) => (
              <Tag
                children={item}
                key={key}
                onClick={() => handleQuery({ tag: item })}
                active={item === query.tag}
                disabled={item === query.tag}
              />
            ))}
          {isFetching && <Tag loading />}
        </SliderTag>
      </S.Header>
      <C.CardsContainer>
        {cards.map((card, k) => (
          <Fragment key={k}>
            {!!k && <div className="bar" />}
            <C.Card isLoading={!card?.value < 0 || !card?.text}>
              <div className="iconContainer">
                {k === 0 && <WorkspacePremiumIcon />}
                {k === 1 && <AccessTimeIcon />}
                {k === 2 && <SlideshowIcon />}
              </div>
              <div className="texts">
                <h2 className="txt">
                  <span className="val">{card?.value}</span>
                  <span className="loading" />
                </h2>
                <p className="txt">
                  <span className="val">{card?.text}</span>
                  <span className="loading" />
                </p>
              </div>
            </C.Card>
          </Fragment>
        ))}
      </C.CardsContainer>

      <Templates
        templates={filteredTemplates || templates}
        query={query}
        inSearch={inSearch}
        loadingScreen={loadingContent || inSearch}
        loadingCard={loadingCard}
        forMe
        onClickNavigateEdit={id => handlePerformTest(id)}
        onClickView={id => handlePerformTest(id, true)}
      />
      {/* Modal Area */}
      {openPreviewFull && currentContent && (
        <ResponseModal
          open={openPreviewFull}
          onClose={() => {
            setCurrentContent(null);
            setIsPreview(false);
            setOpenPreviewFull(false);
          }}
          data={currentContent}
          setCurentContent={setCurrentContent}
          preview={isPreview}
          refetch={refetch}
          backPath="/capacitacao/perfil?page=conteudos"
        />
      )}
    </S.Container>
  );
};

export default MeusConteudos;
