// Styles
import { useTheme } from 'styled-components';
import { ButtonApply } from './styled';

// React
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';

// Components
import ListaCaes from './ListaCaes';
import { ModalCliente } from './ModalCliente';
import CardIndicadores from 'components/Cards/Indicador';
import { DefaultTable } from 'components/_Table/templates/default';
import { ReactComponent as SeguindoIco } from 'images/icons/seguindo.svg';
import { ReactComponent as SeguirIco } from 'images/icons/seguir.svg';
import ExcelModal from 'components/ExcelModalNew';

// Filters
import FiltersGlobal from 'components/FiltersGlobal';
import { useFetchMultipleWithCache } from 'hooks/fetchFilters';

// Components MUI
import Grid from '@mui/material/Grid';
import { AddCircleOutline } from '@mui/icons-material';

// Utils
import { trackEvent } from 'utils/mixpanel';
import { generateFileName } from 'utils/generateFileName';

// Services
import * as request from './services';

// Constants
import { columns } from './columns';
import { caeFields, fields, iconsCard } from './constants';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
} from '@mui/material';

const initialCards = [{}, {}, {}, {}, {}];

// ---------------------------------------LAYOUT PAGE--------------------------------------------//

const LayoutDescarga = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const initial = useRef(true);
  // GENERAL STATES

  const [caes, setCaes] = useState(null);
  const [query, setQuery] = useState(null);
  const [resetTable, setResetTable] = useState(false);
  const [loadingLines, setLoadingLines] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [modalCliente, setModalCliente] = useState(false);
  const [selectedLayouts, setSelectedLayouts] = useState(null);
  const [clickedGasStation, setClickedGasSation] = useState('');
  const [selectOp, setselectOp] = useState(null);
  const [openExcelModal, setOpenExcelModal] = useState(false);
  const [isCAE, setIsCAE] = useState(false);

  // REDUX
  const filter = useSelector(state => state.filter);
  const filterLayout = useSelector(state => state.filterLayout);
  const user = useSelector(state => state.auth?.user?.user);
  const selectFilters = useSelector(state => state);
  const filtersPersist = useSelector(state => {
    return state?.filter?.filters?.layout;
  });

  // Filters data
  const {
    getEmpresasLayout,
    getExecutores,
    getAprovadores,
    getBases,
    getTamanhos,
    getEstados,
  } = useFetchMultipleWithCache();

  // --------------------- REQUESTS -------------------//

  useEffect(() => {
    if (initial.current) {
      initial.current = false;
      return;
    }

    setResetTable(true);
  }, [filter, filterLayout, selectedCard]);

  const {
    refetch: fetchData,
    isLoading: loadingLayouts,
    isFetching,
    data: resData,
  } = useQuery(
    ['layout-index', query],
    () => query && request.requestLayouts(query),
    {
      refetchOnWindowFocus: false,
      onSuccess: () => resetTable && setResetTable(false),
    },
  );

  const {
    refetch: fetchCards,
    isLoading: loadingCards,
    data: resCards,
  } = useQuery(
    ['layout-cards', { ...filter, ...filterLayout }],
    () => request.requestCards({ ...filter, ...filterLayout }),
    {
      refetchOnWindowFocus: false,
    },
  );

  const handleClickCard = type => {
    if (type === selectedCard) setSelectedCard(null);
    else setSelectedCard(type);
  };

  const handleSeguir = async posto => {
    const idPosto = posto.id;
    const seguindo = posto.seguindo;
    let res;
    if (!seguindo) res = await request.seguirPosto(idPosto);
    else res = await request.desseguirPosto(idPosto);

    if (res?.status == 200) {
      if (res.data?.message) toast.success(res.data.message);
      await fetchData();
      return;
    }
    // Erro req
    if (res.data?.message) toast.error(res.data.message);
  };

  const renderCards = () => {
    const cards = resCards?.data?.data || initialCards;
    return (
      <Grid item>
        <Grid container spacing={2}>
          {cards.map((item, k) => {
            let md = 4;
            if (12 / cards.length < 4) md = 12 / cards.length;
            return (
              <Grid
                item
                md={md}
                key={k}
                style={{ fontSize: '13px', fontWeight: 600 }}
              >
                <CardIndicadores
                  value={item.ids?.length}
                  icon={iconsCard[k]}
                  text={item.text}
                  border={`1px solid ${theme.palette.brand.primary.light}4D`}
                  handleClick={() => handleClickCard(item.type)}
                  selected={selectedCard === item.type}
                  disabled={isFetching}
                  loading={loadingCards}
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    );
  };

  // ---------------------Actions Options-----------------------------//

  const handleOpenOptionsExport = event => {
    setselectOp(event.currentTarget);
  };

  const handleClose = () => {
    setselectOp(null);
  };

  const handleOpenNewTab = ids => {
    return window.open(`/layout/${ids}`);
  };

  const handleNavigate = ids => {
    navigate(`/layout/${ids}`);
  };

  const handleApproveLayouts = async id => {
    trackEvent(user, 'LAYOUTS: APROVAR LAYOUTS');

    if (selectedLayouts?.length > 0) {
      id = selectedLayouts;
    }

    let ids = typeof id === 'object' ? id : [Number(id)];
    setLoadingLines(ids);
    const res = await request.approveLayouts(ids);
    setLoadingLines([]);

    if (res.success) {
      toast.success(res.message);
      fetchData();
      fetchCards();
    } else {
      toast.error(formatedMessage(res.message));
    }
  };

  const handleDeleteLayouts = async id => {
    trackEvent(user, 'LAYOUTS: DELETAR LAYOUT');

    let ids = typeof id === 'object' ? id : [Number(id)];
    setLoadingLines(ids);
    const res = await request.deleteLayouts(ids);
    setLoadingLines([]);

    if (res.success) {
      toast.success(res.message);
      fetchData();
      fetchCards();
    } else {
      toast.error(formatedMessage(res.message));
    }
  };

  const handleReativarLayouts = async id => {
    let ids = typeof id === 'object' ? id : [Number(id)];
    setLoadingLines(ids);
    const res = await request.reativarLayouts(ids);
    setLoadingLines([]);

    if (res.success) {
      toast.success(res.message);
      fetchData();
      fetchCards();
    } else {
      toast.error(formatedMessage(res.message));
    }
  };

  const formatedMessage = message => {
    const fm = message?.split(':');
    const postos = fm[1]?.split(',');
    if (postos) {
      return (
        <>
          <div>{fm[0].toString()}:</div>
          <ul style={{ marginTop: '10px' }}>
            {postos.map((i, k) => (
              <li key={k} style={{ listStyle: 'disc', marginLeft: '20px' }}>
                {i}
              </li>
            ))}
          </ul>
        </>
      );
    }
    return <div>{message}:</div>;
  };

  const actions = [
    {
      title: 'Ver Layout',
      function: id => navigate(`/layout/${id}`),
    },
    {
      title: 'Imprimir',
      function: id => {
        trackEvent(user, 'LAYOUTS: IMPRESSÃO LAYOUT');
        navigate(`/layout/pdf/${id}`);
      },
    },
    {
      title: 'Aprovar',
      function: id => handleApproveLayouts(id),
    },
    {
      title: 'Reativar',
      function: id => handleReativarLayouts(id),
      visible: item => item.status === 'DELETADO',
    },
    {
      title: 'Deletar',
      function: id => handleDeleteLayouts(id),
      visible: item => item.status !== 'DELETADO',
    },
    {
      title: 'Abrir em nova guia',
      function: id => handleOpenNewTab(id),
    },
    {
      title: 'Ver CAES',
      function: id => openCaeModal(id),
      visible: item => item.cae.length > 0,
    },
  ];

  // ---------------------COLUMNS-----------------------------//
  const formatCol = () => {
    const cols = columns.map(i => {
      if (i.id === 'status') {
        return { ...i, onClick: (_, item) => openCaeModal(item.id) };
      }
      if (i.id === 'nome') {
        return { ...i, onClick: (_, item) => handleNavigate(item.id) };
      }
      if (i.id === 'seguindo') {
        return {
          ...i,
          children: (value, item) => (
            <div>
              {item.seguindo ? (
                <SeguindoIco
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    handleSeguir(item);
                  }}
                />
              ) : (
                <SeguirIco
                  onClick={() => {
                    handleSeguir(item);
                  }}
                  style={{ cursor: 'pointer' }}
                />
              )}
            </div>
          ),
        };
      }
      return i;
    });
    return cols;
  };

  // ---------------------FILTERS-----------------------------//
  const filtersData = [
    {
      filterName: 'client',
      label: 'Cliente',
      options:
        selectFilters?.selects?.clients?.map(i => {
          return { label: i.nome, value: i.id };
        }) || [],
    },
    {
      filterName: 'empresas',
      label: 'Empresas',
      options: getEmpresasLayout?.data || [],
    },
    {
      filterName: 'executor',
      label: 'Executores',
      options: getExecutores?.data || [],
    },
    {
      filterName: 'aprovador',
      label: 'Aprovadores',
      options: getAprovadores?.data || [],
    },
    {
      filterName: 'base',
      label: 'Bases',
      options: getBases?.data || [],
    },
    {
      filterName: 'estado',
      label: 'Estado',
      options: getEstados?.data || [],
    },
    {
      filterName: 'tmc',
      label: 'Tamanho Max',
      options: getTamanhos?.data || [],
    },
  ];

  // ------------------------RENDER CAE MODAL----------------------------------//
  const openCaeModal = id => {
    const data = resData.data.rows;
    for (const i in data) {
      if (data[i].id === id && data[i].cae.length > 0) {
        setCaes(data[i].cae);
        setClickedGasSation(data[i].nome);
        return;
      }
    }
  };

  const open = Boolean(selectOp);
  const id = open ? 'simple-popover' : undefined;

  // ------------*------------------------Excel--------------------------------------------//
  const handleRequestExcelLayout = async () => {
    // const formatedDate = formatNameDate(new Date());
    // const res = await request.requestExcel({
    //   ...query,
    //   excelFields: fields,
    //   ids: selectedLayouts,
    // });
    // if (res.data && res.data.data.excel)
    //   ExportToExcel({
    //     excel: res.data.data.excel,
    //     name: `layouts_${formatedDate}`,
    //   });
    setIsCAE(false);
    setOpenExcelModal(true);
  };

  const handleRequestExcelCae = async () => {
    setIsCAE(true);
    setOpenExcelModal(true);
    // const formatedDate = formatNameDate(new Date());

    // const resCae = await request.requestCaeExcel({
    //   ...query,
    //   excelFields: caeFields,
    //   ids: selectedLayouts,
    // });
    // if (resCae.data && resCae.data?.data?.excel?.length > 0)
    //   ExportToExcel({
    //     excel: resCae.data.data.excel,
    //     name: `caes_${formatedDate}`,
    //   });
  };
  // ------------*------------------------Bulk Menu--------------------------------------------//
  const bulkOptions = [
    {
      title: 'Aprovar',
      function: handleApproveLayouts,
    },
    {
      title: 'Deletar',
      function: handleDeleteLayouts,
    },
    {
      title: 'Reativar',
      function: handleReativarLayouts,
    },
    {
      title: 'Exportar Layout(Excel)',
      function: handleRequestExcelLayout,
    },
    {
      title: 'Exportar CAE (Excel)',
      function: handleRequestExcelCae,
    },
  ];
  // ------------*------------------------RENDER--------------------------------------------//
  return (
    <div>
      <Grid
        marginBottom={1}
        item
        md={12}
        display="flex"
        justifyContent="space-between"
      >
        <h1>Layout de Descarga</h1>
      </Grid>
      {openExcelModal && (
        <ExcelModal
          open={openExcelModal}
          handleClose={() => setOpenExcelModal(false)}
          query={{
            ...query,
            ...filtersPersist,
            excelFields: isCAE ? caeFields : fields,
            ids: selectedLayouts,
          }}
          file_name={generateFileName(
            window.location.pathname.split('/').pop(),
            filtersPersist,
          )}
          route={isCAE ? '/excel/cae' : '/excel/layout'}
          filtersPersis={filtersPersist}
          collumns={filtersData}
          isManualDownload
        />
      )}

      <div style={{ marginTop: 16, marginBottom: 10 }}>
        <FiltersGlobal
          hideDate
          customComponent={
            <ButtonApply
              startIcon={<AddCircleOutline />}
              onClick={() => {
                trackEvent(user, 'LAYOUTS: ADICIONAR LAYOUT');
                setModalCliente(true);
              }}
            >
              Adicionar Layout
            </ButtonApply>
          }
          refetch={fetchData}
          isFetching={isFetching}
          handleFilters={props => setQuery({ ...query, ...props })}
          handleExport={handleOpenOptionsExport}
          data={filtersData}
        />
        <Popover
          id={id}
          open={open}
          anchorEl={selectOp}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <List
            sx={{ width: '100%', minWidth: 120, bgcolor: 'background.paper' }}
            aria-label="contacts"
          >
            <ListItem disablePadding>
              <ListItemButton onClick={handleRequestExcelLayout}>
                <ListItemText primary="Layout" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={handleRequestExcelCae}>
                <ListItemText primary="Cae" />
              </ListItemButton>
            </ListItem>
          </List>{' '}
        </Popover>
      </div>

      <Grid marginBottom={3} item md={12}>
        {renderCards()}
      </Grid>

      <Grid item md={12}>
        <DefaultTable
          // persist="layout"
          data={resData?.data?.rows || []}
          columns={formatCol()}
          setSelectedRows={setSelectedLayouts}
          loading={loadingLayouts}
          pageCount={resData?.data?.total || 0}
          local={false}
          bulk={bulkOptions}
          actions={actions}
          reset={resetTable}
          // onClickRow={handleNavigate}
          loadingSelection={loadingLines}
          setQuery={q =>
            setQuery({ ...q, ...filter, ...filterLayout, card: selectedCard })
          }
          sortBy={{ id: 'updatedAt', order: 'DESC' }}
          placeholder="Buscar por Cliente ou IBM"
          empty={{
            title: 'Ops! Você não tem nenhum layout de descarga.',
            description: 'Verifique os filtros selecionados.',
          }}
        />
      </Grid>
      <ListaCaes
        caes={caes}
        handleCaes={setCaes}
        posto={clickedGasStation}
        handleOpenCae={id => navigate(`/cae/${id}`)}
      />
      <ModalCliente
        open={modalCliente}
        handleClose={() => setModalCliente(false)}
      />
    </div>
  );
};

export default LayoutDescarga;
