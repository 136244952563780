import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Ranking from 'pages/_templates/Ranking';
import { trackEvent } from 'utils/mixpanel';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { usePlans } from 'hooks/usePlans';
import { useQuery } from 'react-query';
import { requestDriversRanking } from './services';
import { columns, excelFields, fields } from './constants';
import { getRouterQueryParams } from 'utils/router-query-params';
import { useFetchMultipleWithCache } from 'hooks/fetchFilters';
import { generateFileName } from 'utils/generateFileName';

import ExcelModal from 'components/ExcelModalNew';

const RankingMotoristas = () => {
  const navigate = useNavigate();
  const { isOpLogistico, isProvider, hasTorrePlus } = usePlans();
  const user = useSelector(state => state.auth?.user?.user);
  const filter = useSelector(state =>
    isProvider ? state.filterProvider : state.filter,
  );
  const filterRanking = useSelector(state =>
    isProvider ? state.filterRankingDriverProvider : state.filterRankingDriver,
  );

  const [query, setQuery] = useState(null);
  const [podium, setPodium] = useState([]);
  const [openExcelModal, setOpenExcelModal] = useState(false);
  const selects = useSelector(state => state.selects);

  const empresas =
    selects?.empresas?.map(i => {
      return { label: i.nome, value: i.id };
    }) || [];

  const filtersPersist = useSelector(state => {
    return state?.filter?.filters?.rankings;
  });

  const {
    motoristasTipos,
    veiculos,
    filiais,
    clients,
    operacao,
    desviosTiposPadrao,
  } = useFetchMultipleWithCache();

  const order = getRouterQueryParams({ location: useLocation }).get('order');

  const fieldsTransporter = [
    {
      filterName: 'filial',
      label: 'filiais',
      filterName: 'filial',
      label: 'filiais',
      options: filiais,
    },
    {
      filterName: 'client',
      label: 'cliente',
      options: clients.data,
    },
    {
      filterName: 'desvio',
      label: 'Desvios',
      options: desviosTiposPadrao.data,
    },
    {
      filterName: 'motoristas',
      filterName: 'motoristas',
      label: 'Motorista',
      options: motoristasTipos,
    },
    {
      filterName: 'veiculos',
      label: 'Veículos',
      options: veiculos.data,
    },
    {
      filterName: 'operation',
      label: 'Operação',
      options: operacao,
    },
  ];

  let fieldsProvider = [
    {
      filterName: 'empresas',
      label: 'Empresas',
      options: empresas,
    },
    {
      filterName: 'desvio',
      label: 'Filtrar por desvios',
      options: desviosTiposPadrao.data,
    },
    {
      filterName: 'modorista',
      label: 'Motorista',
      options: motoristasTipos,
      multiple: false,
    },
    {
      filterName: 'veiculos',
      label: 'Veículos',
      options: veiculos.data,
      multiple: false,
    },
    {
      filterName: 'operation',
      label: 'Operação',
      options: operacao,
      multiple: false,
    },
  ];

  const {
    isFetching,
    isLoading,
    data: resData,
    refetch: rankingRefetch,
  } = useQuery(
    ['ranking', query],
    () => query && requestDriversRanking(query),
    {
      refetchOnWindowFocus: false,
      onSuccess: res => {
        const podium = res?.data?.podio || null;
        if (podium)
          setPodium(
            podium.map(item => ({
              nameOne: item.nome ?? item.nome_motorista,
              position: item.posicao,
              oldPosition: item.posicao_anterior
                ? item.posicao_anterior > item.posicao
                  ? 'UP'
                  : item.posicao_anterior < item.posicao
                  ? 'DOWN'
                  : 'EQUAL'
                : 'UP',
              logo: item.foto,
              value: `${item.valor ?? item.total_pontos} pontos`,
            })),
          );
      },
    },
  );

  useEffect(() => {
    setQuery({
      ...filter,
      empresas: filter.empresas ? filter.empresas.split(',') : '',
      ...filterRanking,
      desvio: filterRanking.desvio ? filterRanking.desvio.split(',') : '',
    });
  }, [filter, filterRanking]);

  // ------------------------------------- Excel ------------------------------------------//
  // const handleExportar = useCallback(async query => {
  //   trackEvent(user, 'EXPORTAR RANKING');
  //   setLoadingExcel(true);

  //   const newFields = excelFields;
  //   const formatedDate = formatNameDate(new Date());
  //   const newQuery = { ...query, excelFields: newFields };

  //   const res = await requestExcel(newQuery, isProvider);
  //   if (res.excel) {
  //     ExportToExcel({
  //       excel: res.excel,
  //       name: `ranking_motoristas_${formatedDate}`,
  //     });
  //     toast.success(res.message);
  //   } else if (res.message) toast.error(res.message);

  //   setLoadingExcel(false);
  // }, []);

  const formattedColumns = useMemo(
    () =>
      columns.map(item => {
        if (item.id === 'nome_motorista') {
          if (hasTorrePlus)
            return {
              ...item,
              subRow: {
                value: (_, item) => item?.nome_empresa,
              },
            };
          if (!isOpLogistico)
            return {
              ...item,
              subRow: {
                prefix: 'Filial:',
                value: (_, item) => item?.nome_filial,
              },
            };
        }
        return item;
      }),
    [query, isOpLogistico],
  );

  const actions = [
    {
      title: 'Ver perfil',
      function: (id, item) => {
        if (item.id_motorista) navigate(`/motoristas/${item.id_motorista}`);
        else
          toast.error('Não foi possível acessar os detalhes deste motorista.');
      },
    },
  ];

  const formatResumo = useMemo(() => {
    const _data = resData?.data?.data ?? [];

    const data = _data.reduce(
      (acc, item) => {
        acc.total_pontos += Number(item.total_pontos);
        acc.pontos_km += Number(item.pontos_km);
        acc.pontos_hora += Number(item.pontos_hora);
        acc.total_kms += Number(item.total_kms);
        acc.total_horas += Number(item.total_horas);
        acc.quantidade += Number(item.quantidade);
        acc.total_desvios_graves += Number(item.total_desvios_graves);

        return acc;
      },
      {
        total_pontos: 0,
        pontos_km: 0,
        pontos_hora: 0,
        total_kms: 0,
        total_horas: 0,
        quantidade: 0,
        total_desvios_graves: 0,
      },
    );

    return fields.map(item => {
      const val = data[item.id] || '';
      return {
        ...item,
        value: item.value ? item.value(val) : val,
      };
    });
  }, [resData, fields]);

  return (
    <>
      {openExcelModal && (
        <ExcelModal
          open={openExcelModal}
          handleClose={() => setOpenExcelModal(false)}
          excelFields={excelFields}
          query={{
            ...query,
          }}
          file_name={generateFileName(
            window.location.pathname.split('/').pop(),
            filtersPersist,
          )}
          route={
            isProvider
              ? '/excel-provider/motoristas_ranking'
              : '/excel/motoristas_ranking'
          }
          filtersPersis={filtersPersist}
          collumns={isProvider ? fieldsProvider : fieldsTransporter}
          isManualDownload
        />
      )}
      <Ranking
        title="Ranking"
        handleFilters={val => setQuery({ ...val })}
        headerInfo=""
        handleRefetch={() => rankingRefetch()}
        headerActions={() => {
          trackEvent(user, 'Exportar Planilha Ranking Motoristas');
          setOpenExcelModal(true);
        }}
        podium={podium}
        loading={isFetching}
        tableProps={{
          local: true,
          loading: isLoading,
          data: resData?.data?.data
            ? resData.data.data.map(item => ({
                ...item,
                media: resData.data.media,
              }))
            : [],
          columns: formattedColumns,
          ...(hasTorrePlus ? { actions } : {}),
          searchKeys: ['nome'],
          placeholder: 'Buscar por Motorista',
          sortBy: { id: 'posicao', order: order ?? 'ASC' },
          empty: {
            image: 'motorista.png',
            title: 'Ops! Não foram encontrados motoristas para essa busca.',
            description:
              'Altere os filtros ou faça o cadastro do novo motorista!',
          },
        }}
        resumo={formatResumo}
      />
    </>
  );
};

export default RankingMotoristas;
