import React, { memo } from 'react';
import * as S from './styled';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { usePlans } from 'hooks/usePlans';
import { Close } from '@mui/icons-material';
import { Tooltip as MuiTooltip } from '@mui/material';
import { getCriticidade } from 'utils/helpers';
import { useNavigate } from 'react-router-dom';

export const Item = memo(
  ({
    name,
    infractions,
    address,
    active = false,
    onClick,
    nome_empresa,
    risco,
    placa,
    idDeslocamento,
  }) => {
    const navigate = useNavigate();

    const { isProvider, hasTorrePlus, hasTorre } = usePlans();

    const isTorreSafety = hasTorrePlus || hasTorre;

    return (
      <S.Container
        active={active}
        disabled={!infractions}
        onClick={() => !!infractions && onClick()}
        style={
          risco
            ? {
                backgroundColor: `${getCriticidade(risco)}0D`,
                border: `1px solid ${getCriticidade(risco)}`,
              }
            : {}
        }
      >
        <div
          className="container-content"
          style={{
            marginBottom: 2,
            marginTop: 2,
          }}
        >
          {isTorreSafety ? (
            <div>{nome_empresa}</div>
          ) : (
            <MuiTooltip title={name}>
              <div className="name">{name}</div>
            </MuiTooltip>
          )}
          {infractions ? (
            <div className="desvios">
              {infractions} desvios cometidos{' '}
              {active ? <Close /> : <KeyboardArrowRightIcon />}
            </div>
          ) : (
            <div className="desvios disabled">
              Nenhum desvio cometido <KeyboardArrowRightIcon />
            </div>
          )}
        </div>

        {isTorreSafety && (
          <>
            <MuiTooltip title={name}>
              <div className="name">
                {name}
                {placa ? (
                  <div className="plate">{`Placa: ${placa}`}</div>
                ) : (
                  <></>
                )}
              </div>
            </MuiTooltip>
          </>
        )}
        {isTorreSafety && (
          <div className="footer-card2">
            <div
              className="deslocamentos-link"
              onClick={() =>
                navigate(`/desvios/deslocamentos/${idDeslocamento}`)
              }
            >
              Ver deslocamento
            </div>
            <div className="rodovia-description">{address}</div>
          </div>
        )}
        {!isProvider && (
          <div className="address">
            <p>
              <span>Última localização: </span>
              {address}
            </p>
          </div>
        )}
      </S.Container>
    );
  },
);
