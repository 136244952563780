import React, { useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';
import { Container, Legenda, ContainerGraph, TooltipContainer } from './styled';
import GraphSkeleton from './GraphSkeleton';
import EmptyState from './EmptyState';

const CustomTick = ({ x, y, payload, width }) => {
  const maxCharsPerLine = 15; // Máximo de caracteres por linha.
  const lineHeight = 20; // Altura de cada linha de texto.
  const spacingFromTop = 20; // Espaço inicial do topo para a primeira linha.

  const splitText = text => {
    let lines = [];
    let startIndex = 0;

    while (startIndex < text.length) {
      let endIndex = Math.min(text.length, startIndex + maxCharsPerLine);
      // Verifica se o próximo caractere após o endIndex é um espaço, indicando o fim de uma palavra
      // Se não for espaço (ou seja, está no meio de uma palavra), ajusta o endIndex para o último espaço encontrado
      if (endIndex < text.length && text[endIndex] !== ' ') {
        let lastSpaceIndex = text.lastIndexOf(' ', endIndex);
        if (lastSpaceIndex > startIndex) {
          endIndex = lastSpaceIndex;
        }
      }

      let line = text.substring(startIndex, endIndex);
      lines.push(line);

      // Se for necessário adicionar '...' para indicar continuação
      if (lines.length === 2 && endIndex < text.length) {
        lines[1] = `${line.trim().slice(0, -3)}...`;
        break;
      }

      startIndex = endIndex + (text[endIndex] === ' ' ? 1 : 0); // Pula o espaço se o corte terminou nele
    }

    return lines;
  };

  const lines = splitText(payload.value);

  return (
    <g transform={`translate(${x},${y})`}>
      {lines.map((line, index) => (
        <text
          x={0}
          y={spacingFromTop + index * lineHeight} // Ajusta o espaçamento vertical para cada linha.
          textAnchor="middle"
          fill="#666"
          key={`tick-${index}`}
        >
          {line}
        </text>
      ))}
    </g>
  );
};

const CustomTooltip = ({ active, payload, currentTab }) => {
  if (active && payload && payload.length) {
    const { payload: currentPayload } = payload[0];
    return (
      <TooltipContainer>
        <p className="title">{`${currentPayload.name || 'Não informado'}: ${
          currentPayload.value || ''
        }`}</p>
      </TooltipContainer>
    );
  }

  return null;
};

const BarGraph = ({
  title = '',
  leftHeaderComponent,
  data = [],
  metaValue = 0,
  isLoading,
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getBarCategoryGap = () => {
    // Aumentando o espaço entre os conjuntos de barras
    if (windowWidth > 1200) return 100; // Maior separação para telas grandes
    if (windowWidth > 768) return 60; // Maior separação para telas médias
    return 20; // Separção para telas pequenas
  };

  return (
    <Container>
      <div className="container-filters">
        <div>
          <h1 className="graphtitle">{title}</h1>
          {leftHeaderComponent && leftHeaderComponent}
        </div>
        {/* <Legenda>
          <span className="circle" />
          <span className="text">Período anterior</span>
          <span className="circle2" color="#4B5166" />
          <span className="text">Período atual</span>
        </Legenda> */}
      </div>

      {!data || data.length === 0 ? (
        <ResponsiveContainer width="100%" height="100%">
          <EmptyState
            title="Nenhum dado retornado"
            subtitle="Ainda não há dados suficientes para esta busca. Favor verificar seus filtros."
          />
        </ResponsiveContainer>
      ) : (
        <ResponsiveContainer width="100%" height="100%">
          {isLoading ? (
            <GraphSkeleton />
          ) : (
            <BarChart
              barSize={18}
              height={300}
              data={data}
              barGap={6}
              barCategoryGap={getBarCategoryGap()}
              margin={{
                top: 5,
                right: 30,
                left: 20,
              }}
            >
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey={value => value?.name || 'Não informado'}
                height={50}
                tick={<CustomTick />}
                interval={data.length > 20 ? 'preserveStartEnd' : 0}
              />

              <YAxis />

              <Tooltip content={<CustomTooltip />} />

              <Bar dataKey="value" fill="#4B5166" radius={4} name="Atual" />

              {metaValue && (
                <ReferenceLine
                  y={metaValue}
                  label={{
                    value: `Meta ${metaValue}`,
                    position: 'insideBottomRight',
                    fill: 'red',
                    style: {
                      fontSize: 12,
                      fontWeight: 'bold',
                    },
                  }}
                  stroke="red"
                  strokeDasharray="3 3"
                />
              )}
            </BarChart>
          )}
        </ResponsiveContainer>
      )}
    </Container>
  );
};

export default BarGraph;
