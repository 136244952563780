/* eslint-disable prettier/prettier */
/* eslint-disable import/order */

// Styles
import * as S from './styled';
import { useTheme } from 'styled-components';

// React
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import React, { useState, useMemo } from 'react';
import { Icon } from 'components/IconsOnisys';

// Components MUI
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Collapse from '@mui/material/Collapse';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ListItemButton from '@mui/material/ListItemButton';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import PlayCircleFilledOutlinedIcon from '@mui/icons-material/PlayCircleFilledOutlined';
import { ReactComponent as CustomerService } from 'images/icons/sidebar/customer_service.svg';

// Assets
import GenericLogo from 'images/icons/sidebar/marca-onisys-pos.svg';

const PersistentDrawerLeft = ({ sidebarState, onClose, onOpenSidebar }) => {
  const theme = useTheme();

  const { pageMap, currentPlan } = useSelector(state => state.pageMap);
  const { user } = useSelector(state => state.auth?.user);
  const contract = user?.provider
    ? user?.distribuidora?.contrato
    : user?.filial?.empresa?.contrato;

  const paginasArr = pageMap
    ?.filter(plugin => plugin.id_do_ambiente === currentPlan && plugin.active)
    ?.map(plugin => plugin.paginas);

  const paginas = _.flatten(paginasArr)?.sort(
    (a, b) => a.posicao_sidebar - b.posicao_sidebar,
  );

  // Garante que o item aparecerá apenas uma vez na sidebar
  const ids = [];
  const sidebarContent = paginas?.filter(item => {
    if (
      item.is_sidebar &&
      !ids.includes(item.id) &&
      item.restricao_nivel >= user.nivel
    ) {
      ids.push(item.id);
      return true;
    }
    return false;
  });

  const [open, setOpen] = useState('');
  const [isHover, setIshover] = useState(null);
  const [selected, setSelected] = useState(false);
  const [selectedItem, setSelectedItem] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(false);
  const [subItemSelected, setSubItemSelected] = useState(null);

  const navigate = useNavigate();

  const handleNavigate = url => {
    onClose();
    navigate(url);
  };

  const resetSidebar = () => {
    setSelected(false);
    setSelectedIndex(0);
    setSelectedItem(false);
  };

  const sidebarContentGroup = useMemo(() => {
    resetSidebar();

    const result = [];
    const groups = sidebarContent?.reduce((group, items) => {
      const { secao } = items;
      const sessoes = secao.split('/');
      if (sessoes.length === 2) {
        group[sessoes[0]] = group[sessoes[0]] ?? {};
        group[sessoes[0]][sessoes[1]] = group[sessoes[0]][sessoes[1]] ?? [];
        group[sessoes[0]][sessoes[1]].push(items);
        return group;
      }
      group[sessoes[0]] = group[sessoes[0]] ?? [];
      group[sessoes[0]].push(items);
      return group;
    }, {});
    const keys = Object?.keys(groups);
    keys.map(key => {
      if (Array.isArray(groups[key])) result.push(groups[key]);
      else {
        const _keys = Object.keys(groups[key]);
        if (_keys.length === 1) {
          const itens = groups[key][_keys[0]].map(item => {
            const newItem = { ...item, secao: item.secao.split('/')[0] };
            return newItem;
          });
          result.push(itens);
          return;
        }
        const itens = [];
        _keys.map(_key => {
          itens.push(groups[key][_key]);
        });
        result.push(itens);
      }
    });
    return result;
  }, [pageMap, currentPlan]);

  const capitalizeFirstLetter = str => {
    return str
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
      .split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' e ');
  };

  const getColorHeader = () => {
    if (true) return theme.palette.system.system;
  };

  const chooseRoute = () => {
    switch (currentPlan) {
      case 2:
        handleNavigate('/conteudos');
        break;
      case 1:
        handleNavigate('/');
        break;
    }
  };

  const handleClick = pagina => {
    if (open !== pagina) setOpen(pagina);
    else setOpen('');
  };

  return (
    <S.Container
      onMouseOver={() => {
        onOpenSidebar();
      }}
    >
      <S.Drawer variant="permanent" open={sidebarState}>
        <S.DrawerHeader color={getColorHeader(currentPlan)} open={sidebarState}>
          <div
            onClick={() => chooseRoute()}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
              textAlign: !sidebarState
                ? 'center'
                : selected
                ? 'center'
                : 'start',
              marginBottom: 20,
              marginTop: 20,
            }}
          >
            {contract?.is_whitelabel ? (
              <>
                {user?.foto === null ? (
                  <Avatar
                    sx={{
                      bgcolor: theme.palette.brand.primary.natural,
                    }}
                  >
                    {user?.nome[0]}
                  </Avatar>
                ) : (
                  <Avatar src={user?.foto} />
                )}
              </>
            ) : (
              <Avatar
                sx={{
                  bgcolor: 'transparent',
                }}
                src={GenericLogo}
              >
                {user?.nome[0]}
              </Avatar>
            )}

            {sidebarState && (
              <S.Title>
                {!selected && (
                  <>
                    {user?.nome.length > 34
                      ? capitalizeFirstLetter(user?.nome.slice(0, 34))
                      : capitalizeFirstLetter(user?.nome)}
                  </>
                )}
                {!selected && (
                  <div className="subtitle">
                    {user.provider
                      ? user?.distribuidora?.nome.length > 34
                        ? `${capitalizeFirstLetter(
                            user?.distribuidora?.nome.length.slice(0, 34),
                          )}...`
                        : capitalizeFirstLetter(user?.distribuidora?.nome)
                      : user?.filial?.empresa?.nome.length > 34
                      ? capitalizeFirstLetter(
                          user?.filial?.empresa?.nome.slice(0, 34),
                        )
                      : capitalizeFirstLetter(user?.filial?.empresa?.nome)}
                  </div>
                )}
              </S.Title>
            )}
          </div>
        </S.DrawerHeader>

        <S.ListContainer>
          <S.ListItemOne sidebarState={sidebarState} isSelected={selected}>
            <List sx={{ flex: 1 }}>
              {sidebarContentGroup.map((item, index) => (
                <S.HtmlTooltip
                  open={selected && isHover === index}
                  key={index}
                  title={
                    <>
                      {Array.isArray(item[0])
                        ? capitalizeFirstLetter(
                            item[0][0]?.secao.split('/')[0].toLowerCase(),
                          )
                        : capitalizeFirstLetter(item[0]?.secao.toLowerCase())}
                    </>
                  }
                >
                  <ListItemButton
                    onMouseOut={() => setIshover(null)}
                    onMouseOver={() => {
                      setIshover(index);
                    }}
                    onClick={() => {
                      if (item.length > 1) {
                        setSelectedItem(item);
                        setSelectedIndex(index);
                        setSelected(true);
                      } else {
                        setSelected(false);
                        setSelectedIndex(index);
                        handleNavigate(item[0].pagina);
                      }
                    }}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderLeft: `${
                        index === selectedIndex ? '4px' : '0px'
                      } solid ${theme.palette.brand.secondary.dark}`,

                      '&.Mui-selected': {
                        backgroundColor: '#EDF2F9',
                      },
                      '&.Mui-focusVisible': {
                        backgroundColor: '#EDF2F9',
                      },
                      ':hover': {
                        backgroundColor: '#EDF2F9',
                      },
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 30,
                        paddingTop: 3,
                        paddingBottom: 3,
                        marginRight:
                          item[0].icone === 'icone_acidentes' ? 5 : 0,
                      }}
                    >
                      <Icon
                        style={{
                          color:
                            index === selectedIndex
                              ? theme.palette.brand.secondary.dark
                              : theme.palette.words.subtitle.natural,
                        }}
                        name={
                          Array.isArray(item[0])
                            ? item[0][0].icone
                            : item[0].icone
                        }
                      />
                    </div>
                    {sidebarState && !selected && (
                      <ListItemText
                        sx={{
                          color: theme.palette.words.subtitle.natural,
                          marginLeft: 1,
                          fontFamily: 'Texta',
                        }}
                        primary={
                          Array.isArray(item[0])
                            ? item[0][0]?.descricao
                            : item[0]?.descricao
                        }
                      />
                    )}

                    {sidebarState && !selected && item.length > 1 && (
                      <div>
                        <Icon
                          name="icone_arrow_right"
                          style={{ color: theme.palette.brand.primary.light }}
                        />
                      </div>
                    )}
                  </ListItemButton>
                </S.HtmlTooltip>
              ))}
            </List>
          </S.ListItemOne>
          <S.ListItemTwo sidebarState={sidebarState} isSelected={selected}>
            <div>
              <div className="btn-voltar" onClick={() => setSelected(false)}>
                {`<`} Voltar
              </div>
              {sidebarState && (
                <ListItemButton
                  sx={{
                    color: theme.palette.brand.primary.natural,
                    fontSize: 20,
                    fontWeight: 'bold',
                    fontFamily: 'Texta',
                  }}
                >
                  <span>
                    {selectedItem && Array.isArray(selectedItem[0])
                      ? capitalizeFirstLetter(
                          selectedItem[0][0]?.secao
                            .split('/')[0]
                            .toLowerCase() || '',
                        )
                      : capitalizeFirstLetter(
                          selectedItem[0]?.secao.toLowerCase() || '',
                        )}
                  </span>
                </ListItemButton>
              )}
            </div>
            <div>
              {selectedItem &&
                selectedItem.map((item, idx) =>
                  Array.isArray(item) ? (
                    <React.Fragment key={idx}>
                      <ListItemButton onClick={() => handleClick(idx)}>
                        <ListItemText
                          className="title-section"
                          primary={capitalizeFirstLetter(
                            item[0].secao.split('/')[1].toLowerCase(),
                          )}
                        />
                        {open === idx ? <ExpandLess /> : <ExpandMore />}
                      </ListItemButton>
                      <Collapse in={open === idx} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          {item.map((i, k) => (
                            <ListItemButton
                              selected={subItemSelected === `${idx}${k}`}
                              sx={{
                                height: 54,
                                color: theme.palette.words.subtitle.natural,
                              }}
                              key={k}
                              onClick={() => {
                                setSubItemSelected(`${idx}${k}`);
                                handleNavigate(i.pagina);
                              }}
                            >
                              {i?.descricao.length > 20
                                ? `${i?.descricao.slice(0, 24)}...`
                                : i?.descricao}
                            </ListItemButton>
                          ))}
                        </List>
                      </Collapse>
                    </React.Fragment>
                  ) : (
                    <ListItemButton
                      selected={subItemSelected === idx}
                      sx={{
                        height: 54,
                        color: theme.palette.words.subtitle.natural,
                      }}
                      key={idx}
                      onClick={() => {
                        setSubItemSelected(idx);
                        handleNavigate(item.pagina);
                      }}
                    >
                      <Tooltip title={item?.descricao}>
                        {item?.descricao.length > 20
                          ? `${item?.descricao.slice(0, 24)}...`
                          : item?.descricao}
                      </Tooltip>
                    </ListItemButton>
                  ),
                )}
            </div>
          </S.ListItemTwo>
        </S.ListContainer>
        {window.innerHeight > 530 && (
          <>
            <Divider sx={{ marginLeft: 2, marginRight: 2, marginBottom: 2 }} />

            <NavLink to="/catalogo" style={{ cursor: 'pointer' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: sidebarState ? 'flex-start' : 'center',
                  paddingLeft: sidebarState ? 20 : 0,
                  alignItems: 'center',
                  paddingBottom: 10,
                  color: theme.palette.brand.secondary.natural,
                }}
              >
                <PlayCircleFilledOutlinedIcon />
                {sidebarState && !selected && (
                  <label style={{ cursor: 'pointer', marginLeft: 10 }}>
                    Videos Tutoriais
                  </label>
                )}
              </div>
            </NavLink>

            <NavLink
              to="https://onisys.movidesk.com/form/8134/"
              target="_blank"
              style={{ cursor: 'pointer' }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: sidebarState ? 'flex-start' : 'center',
                  paddingLeft: sidebarState ? 22 : 0,
                  alignItems: 'center',
                  paddingBottom: 10,

                  color: theme.palette.brand.secondary.natural,
                }}
              >
                <CustomerService />
                {sidebarState && !selected && (
                  <label style={{ cursor: 'pointer', marginLeft: 10 }}>
                    Abrir Chamado
                  </label>
                )}
              </div>
            </NavLink>
          </>
        )}
      </S.Drawer>
    </S.Container>
  );
};

export default PersistentDrawerLeft;
