/* eslint-disable import/order */

// Styles
import * as S from './styled';
import { useTheme } from 'styled-components';

// React
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { usePlans } from 'hooks/usePlans';

// Components
import Card from 'components/Cards/Indicador';
import ExcelModal from 'components/ExcelModal';
import StorePhoneModal from './StorePhoneModal';
import InfoCard from 'components/Cards/InfoCard';
import GhostButton from 'components/Buttons/Ghost';
import ConfirmModal from 'components/ConfirmModal';
import ModalAddMotorista from './ModalAddMotorista';

// Filters
import FiltersGlobal from 'components/FiltersGlobal';

import { Grid } from '@mui/material';
import {
  SaveAlt,
  EditOutlined,
  DeleteOutline,
  AddCircleOutline,
} from '@mui/icons-material';

// Utils
import { trackEvent } from 'utils/mixpanel';
import { formatNameDate } from 'utils/dates';
import ExportToExcel from 'utils/exportToCvs';

// Assets
import { ReactComponent as file } from 'images/icons/sidebar/file.svg';

// Services
import {
  changeDriverStatus,
  requestDriverCard,
  requestExcel,
  storePhone,
  getDrivers,
  getDriversTorre,
  requestFaixaContratada,
  requestDriverCardTorre,
} from './services';

// Constants
import {
  infoMessage,
  cardsMotoristas,
  columnsMotoristas,
  fields,
  resetExcelFields,
  columnsMotoristasTorre,
} from './constants';
import { DefaultTable } from 'components/_Table/templates/default';

const sortBy = [
  {
    id: 'status',
    desc: false,
  },
];

const Motoristas = () => {
  const theme = useTheme();
  const { hasTorrePlus } = usePlans();

  const [maxFaixa, setMaxFaixa] = useState(false);
  const [ativosFaixa, setAtivosFaixa] = useState(false);
  const navigate = useNavigate();
  const filter = useSelector(state => state.filterProvider);
  const filterDriver = useSelector(state => state.filterDriverProvider);
  const filterSelects = useSelector(state => state.selects);
  const { user } = useSelector(state => state?.auth?.user);
  let isConvidado = false;
  if (+user?.nivel === 4) isConvidado = true;
  const [openAddMotorista, setOpenAddMotorista] = useState(false);

  const [drivers, setDrivers] = useState(null);

  const [data, setData] = useState(null);
  const [cardsMotors, setCardsMotors] = useState(cardsMotoristas);
  const [triggerUpdate, setTriggerUpdate] = useState(true);
  const [loading, setLoading] = useState(true);

  const [openExcelModal, setOpenExcelModal] = useState(false);
  const [excelFields, setExcelFields] = useState(fields);
  const [loadingExcel, setLoadingExcel] = useState(false);

  const [idConfirmChangeStatus, setIdConfirmChangeStatus] = useState(false);
  const [idStoreCellPhone, setIdStoreCellPhone] = useState(null);
  const [loadingCards, setLoadingCards] = useState(false);

  // Adicionar e Editar Motoristas
  const [editDriver, setEditDriver] = useState(null);

  // Query
  const [query, setQuery] = useState(null);

  const filtersData = [
    {
      filterName: 'empresas',
      label: 'Empresas',
      options:
        filterSelects?.empresas?.map(i => {
          return { label: i.nome, value: i.id };
        }) || [],
    },
    {
      filterName: 'agregado',
      label: 'Agregado',
      options: [
        { label: 'Agregados', value: 'SIM' },
        { label: 'Próprios', value: 'NÃO' },
      ],
    },
    {
      filterName: 'status',
      label: 'Status',
      options: [
        { label: 'Ativo', value: 'ATIVO' },
        { label: 'Inativo', value: 'INATIVO' },
      ],
    },
  ];

  useEffect(() => {
    const getAllDrivers = async () => {
      setLoading(true);
      const res = await getDrivers();
      if (res.data.success) {
        setDrivers(res.data.data);
      }
      setTriggerUpdate(false);
    };

    const getAllDriversTorre = async () => {
      setLoading(true);
      const res = await getDriversTorre(filter);
      if (res.data.success) {
        setDrivers(res.data.data);
      }
      setTriggerUpdate(false);
    };

    if (triggerUpdate) {
      hasTorrePlus ? getAllDriversTorre() : getAllDrivers();
    }
  }, [triggerUpdate]);

  useEffect(async () => {
    setLoadingCards(true);

    const newData = drivers?.filter(driver => {
      const res =
        (!query?.empresas?.length ||
          query.empresas.some(e => e == driver.id_da_empresa)) &&
        (!query?.agregado?.length ||
          query.agregado.some(a => a === driver.agregado)) &&
        (!query?.status?.length || query.status.some(s => s === driver.status));
      return res;
    });

    const filters = { ...filter, ...filterDriver };
    let dataCardSelected;

    if (!hasTorrePlus) {
      const res = await requestDriverCard(filters);

      const newCardsMotors = cardsMotors.map(card => {
        if (card.name === 'desvios') {
          const cardData = newData?.filter(item =>
            res.data.data.includes(item.id),
          );
          card.value = res.data.data ? res.data.data.length : newData.length;
          if (card?.selected) dataCardSelected = cardData;
        }
        return card;
      });
      setCardsMotors(newCardsMotors);
      setLoadingCards(false);
    } else {
      let tmpCards = cardsMotoristas;

      const res = await requestDriverCardTorre(filters);
      tmpCards[0].value = `${
        res?.data?.data?.motoristasAtivos || 0
      }/${maxFaixa}`;
      tmpCards[1].value = res?.data?.data?.motoristasSemDesvio || 0;

      setCardsMotors(tmpCards);
    }
    // Atualiza data com newData ou dataCardSelected se algum card for selecionado
    if (dataCardSelected) setData(dataCardSelected);
    else setData(newData);
    setTimeout(() => setLoading(false), 1000);
  }, [query, filter, filterDriver, cardsMotors[0].selected, drivers]);

  const fetchFaixa = async () => {
    setLoadingCards(true);
    const faixaContratada = await requestFaixaContratada();
    if (faixaContratada.data.success) {
      const motAtivos = faixaContratada.data.ativos
        ? faixaContratada.data.ativos
        : drivers.filter(item => item.status === 'ATIVO').length;
      const cardInit = cardsMotors.map(card => {
        if (card.name === 'limite') {
          card.value = `${motAtivos}/${faixaContratada.data.data.faixa.max}`;
        }
        return card;
      });
      setAtivosFaixa(faixaContratada.data.ativos);
      setMaxFaixa(faixaContratada.data.data.faixa.max);
      setCardsMotors(cardInit);
    } else toast.error(faixaContratada.data.message);

    setLoadingCards(false);
  };

  useEffect(() => {
    fetchFaixa();
  }, [drivers]);

  const handleRequestExcel = async () => {
    setLoadingExcel(true);

    if (!hasTorrePlus)
      excelFields.push({
        label: 'Empresa',
        value: 'empresa.nome',
        selected: true,
        default: false,
      });
    const newFields = excelFields.filter(item => item?.selected === true);

    if (hasTorrePlus)
      newFields = newFields.filter(
        item => item?.value !== 'distribuidora.nome',
      );

    const formatedDate = formatNameDate(new Date());

    const newQuery = { ...filter, ...filterDriver, excelFields: newFields };

    const res = await requestExcel(newQuery);
    if (res.data && res.data?.data?.excel)
      ExportToExcel({
        excel: res.data.data.excel,
        name: `motoristas_${formatedDate}`,
      });
    else if (res.data.message) toast.error(res.data?.message);

    setExcelFields(resetExcelFields(fields));
    setLoadingExcel(false);
    setOpenExcelModal(false);
  };

  const handleClickCards = name => {
    const newCards = cardsMotors.map(card => {
      if (name === card?.name) {
        if (card?.selected !== undefined) {
          card.selected = !card?.selected;
        }
      } else if (card?.selected) card.selected = false;
      return card;
    });
    setCardsMotors(newCards);
  };

  const handleVer = id => {
    trackEvent(user, 'MOTORISTA: VER PERFIL');
    if (id) navigate(`/motoristas/${id}`);
    else toast.error('Não foi possível acessar os detalhes deste motorista.');
  };

  const handleEditar = id => {
    trackEvent(user, 'MOTORISTA: EDITAR');
    const driverEdit = drivers.find(driver => String(driver.id) === String(id));
    if (driverEdit) setEditDriver(driverEdit);
    setOpenAddMotorista(true);
  };

  const handleHistorico = id => {
    trackEvent(user, 'MOTORISTA: visualizar histórico de alterações');
    navigate(`/logs/driver/${id}`);
  };

  const handleSetDesativarAtivar = driverId => {
    const driverEdit = drivers.find(
      driver => String(driver.id) === String(driverId),
    );
    if (!driverEdit.celular && driverEdit.status === 'INATIVO')
      setIdStoreCellPhone(driverId);
    else setIdConfirmChangeStatus({ id: driverId, status: driverEdit.status });
    trackEvent(
      user,
      `MOTORISTA:${
        driverEdit.status === 'ATIVO' ? 'desativar' : 'ativar'
      }  motorista`,
    );

    setIdConfirmChangeStatus({ id: driverId, status: driverEdit.status });
  };

  const handleDesativarAtivar = async driverId => {
    trackEvent(user, 'MOTORISTA: desativar motorista');
    const res = await changeDriverStatus(driverId);
    if (res && res.data?.success) {
      toast.success(res.data.message);
      setTriggerUpdate(true);
    } else if (res && res.data?.message) toast.error(res.data.message);
    else
      toast.error(
        'Erro ao ativar/desativar motorista, tente mais tarde. Caso persista procure o nosso time de suporte',
      );
    setIdConfirmChangeStatus(null);
  };

  const handleStorePhone = async data => {
    setLoading(true);

    const res = await storePhone(data);
    if (res && res.data?.success) {
      toast.success(res.data.message);
      setTriggerUpdate(true);
    } else if (res && res.data?.message) toast.error(res.data.message);
    else
      toast.error(
        'Erro ao salvar celular do motorista, tente novamente mais tarde. Caso persista procure o nosso time de suporte',
      );

    setIdConfirmChangeStatus(null);
    setIdStoreCellPhone(null);
    setLoading(false);
  };

  const PopoverAdicionar = (
    <S.AdicinarDiv>
      <button
        onClick={() => {
          trackEvent(user, 'MOTORISTA:  Adicionar novo');
          setOpenAddMotorista(!openAddMotorista);
        }}
      >
        Adicionar novo
      </button>

      <button
        onClick={() => {
          trackEvent(user, 'MOTORISTA: Adicionar em massa');
          navigate('/motoristas/add-motoristas');
        }}
      >
        Adicionar em massa
      </button>
    </S.AdicinarDiv>
  );

  const renderHeader = () => {
    return (
      <>
        <S.TitleWrapper>
          <h1>Lista de Motoristas</h1>
        </S.TitleWrapper>

        <div style={{ marginTop: 16, marginBottom: 10 }}>
          <FiltersGlobal
            hideDate
            refetch={() => setTriggerUpdate(true)}
            // isFetching={isFetching}
            handleFilters={props => setQuery({ ...query, ...props })}
            handleExport={() => {
              trackEvent(user, 'MOTORISTA: EXPORTAR');
              setOpenExcelModal(true);
            }}
            data={filtersData}
          />
        </div>

        <div style={{ padding: '0px 0px' }}>
          <InfoCard message={infoMessage} key={1} />
        </div>
      </>
    );
  };

  const renderCards = () => {
    return (
      <Grid container spacing={2} marginBottom="25px">
        {cardsMotors?.map(card => {
          return (
            <Grid item key={card.name} xs={12} md={4} xl={4}>
              <Card
                value={card.value}
                icon={card.icon}
                text={card.text}
                handleClick={() => handleClickCards(card.name)}
                selected={card?.selected}
                loading={loadingCards}
              />
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const renderTableDrivers = () => {
    const actions = [
      { title: 'Ver perfil', function: handleVer },
      {
        title: hasTorrePlus ? 'Visializar detalhes' : 'Editar',
        function: handleEditar,
      },
    ];
    if (!hasTorrePlus)
      actions.push(
        {
          title: 'Histórico alterações',
          function: handleHistorico,
        },
        {
          title: 'Ativar/Desativar',
          function: handleSetDesativarAtivar,
        },
      );
    return (
      <DefaultTable
        data={data || []}
        columns={hasTorrePlus ? columnsMotoristasTorre : columnsMotoristas}
        actions={actions}
        searchKeys={['nome', 'funcao', 'filial.nome']}
        loading={loading}
        placeholder="Buscar por nome, função ou filial"
        sortBy={{ id: 'status', order: 'ASC' }}
        searchEvent={search =>
          trackEvent(user, 'Busca Gestão de Motoristas', null, search)
        }
      />
    );
  };

  return (
    <>
      <S.Main>
        {renderHeader()}
        {renderCards()}
        <ModalAddMotorista
          open={Boolean(openAddMotorista)}
          dataEdit={editDriver}
          disabledEdit={hasTorrePlus}
          handleClose={() => setOpenAddMotorista(false)}
          setTriggerUpdate={setTriggerUpdate}
        />
        {renderTableDrivers()}
      </S.Main>

      {
        /* Confirmação de ativacao / desativacao */
        idConfirmChangeStatus && (
          <ConfirmModal
            open={Boolean(idConfirmChangeStatus)}
            handleClose={() => setIdConfirmChangeStatus(null)}
            title={
              idConfirmChangeStatus.status === 'ATIVO'
                ? 'Deseja desativar este motorista?'
                : 'Deseja reativar este motorista?'
            }
            titleIcon={
              idConfirmChangeStatus.status === 'ATIVO' ? (
                <DeleteOutline
                  sx={{
                    color: theme.palette.semantics.feedback.attention.natural,
                  }}
                  fontSize="medium"
                />
              ) : (
                <EditOutlined
                  sx={{ color: theme.palette.brand.secondary.natural }}
                  fontSize="medium"
                />
              )
            }
            subtitle="Motoristas inativos não tem novas avaliações geradas e serão removidos das turmas associadas."
            buttonText="Confirmar"
            onClick={() => handleDesativarAtivar(idConfirmChangeStatus.id)}
            loading={loading}
          />
        )
      }

      {openExcelModal && (
        <ExcelModal
          onClick={handleRequestExcel}
          open={openExcelModal}
          handleClose={() => setOpenExcelModal(false)}
          title="Selecionar os campos de Excel"
          titleIcon={file}
          subtitle="Selecionar abaixo:"
          setData={setExcelFields}
          data={excelFields}
          loading={loadingExcel}
        />
      )}

      {idStoreCellPhone && (
        <StorePhoneModal
          id={idStoreCellPhone}
          handleConfirm={handleStorePhone}
          handleClose={() => {
            setIdConfirmChangeStatus(null);
            setIdStoreCellPhone(null);
          }}
          loading={loading}
        />
      )}
    </>
  );
};

export default Motoristas;
