import { useSelector } from 'react-redux';

const getIndicator = (
  id_indicador,
  id_distribuidora,
  id_empresa,
  paramsConst = null,
) => {
  // id_distribuidora e id_empresa deve receber do objeto específico.
  // Não omitir valor quando existe. Tratativa de prioridade já é feita na função
  // Ex: desvio de id_distribuidora = 1 e id_empresa = 3

  // params pode ser passado como constante ou é consultado do redux
  // Passar por parametro para evitar renderizações desnecesssarias
  let params = paramsConst;
  if (!params) {
    params = useSelector(state => state.selects.params);
  }

  if (!params) return null;
  const indicador = params.find(item => item.id == id_indicador);
  if (!indicador) return null;
  // Inicializa valor padrao
  let valor = indicador.valor_padrao;
  // inicializa param para sobrepor ordem de prioridade
  let param = null;
  // Busca primeiro parametro da distribuidora passada
  if (id_distribuidora) {
    param = indicador.parametrizacoes.find(
      item => item.id_distribuidora === id_distribuidora,
    );
  }
  // Se nao tem parametrizacao de distribuidora, busca da empresa
  if (id_empresa && !param) {
    param = indicador.parametrizacoes.find(
      item => item.id_empresa === id_empresa,
    );
  }

  if (param) valor = param.valor;
  return valor;
};

const indicadorRevisaoExclusao = {
  GRAVÍSSIMA: 47,
  GRAVE: 48,
  MODERADA: 49,
  PRIMÁRIA: 50,
};

const indicadorAnexoExclusao = {
  GRAVÍSSIMA: 61,
  GRAVE: 62,
  MODERADA: 63,
  PRIMÁRIA: 64,
};

// Funcao
export { getIndicator };

// Constantes
export { indicadorRevisaoExclusao, indicadorAnexoExclusao };
