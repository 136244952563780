import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const Main = styled.div`
  display: flex;
`;

export const Header = styled.div`
  h1 {
    ${({ theme }) => css`
      font-size: 24px;
      color: ${theme.palette.brand.primary.natural};
      font-weight: 900;
      letter-spacing: 1.1px;
    `}
  }
`;

export const BoxGraphs = styled.div`
  margin-top: 20px;
  position: relative;
`;
