import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Componentes
import Tabs from 'components/Tabs';
import FiltersGlobal from 'components/FiltersGlobal';
import { History } from '../../../components/Graphs/History';
import BarGraph2 from 'components/Graphs/NewBarGraph';
import { CustomizedSelect } from 'components/SelectCustom';

// Service
import { useFetchMultipleWithCache } from 'hooks/fetchFilters';
import { toast } from 'react-toastify/dist';
import { Grid } from '@mui/material';
import { trackEvent } from 'utils/mixpanel';
import { requestGraphDescarga } from '../services';
import * as S from './styled';

const ptsdTabs = [
  { value: 'tudo', label: 'Tudo' },
  { value: 'filial', label: 'Por filial' },
  { value: 'cliente', label: 'Por cliente' },
];

const historicoTabs = [
  { value: 'status', label: 'Avaliações por status' },
  { value: 'desvios', label: 'Avaliações sem desvios' },
  { value: 'atrasadas', label: 'Avaliações atrasadas' },
  { value: 'drivers', label: 'Motoristas avaliados' },
  { value: 'discharge', label: 'Horas avaliadas' },
];

const top10Tabs = [
  { value: 'justificativas', label: 'Justificativas de exclusão' },
  { value: 'responsaveis', label: 'Responsáveis' },
];

const GraficosAvalicoesDescarga = () => {
  const user = useSelector(state => {
    return state.auth?.user?.user;
  });
  const filter = useSelector(state => {
    return state?.filter?.filters?.['estatisticas-avaliacoes-descarga'];
  });

  const [ptsLoading, setPtsLoading] = useState(true);
  const [historicoLoading, setHistoricoLoading] = useState(true);
  const [tempLoading, setTempLoading] = useState(true);
  const [top10Loading, setTop10Loading] = useState(true);

  const [ptsHour, setPtsHour] = useState([]);
  const [ptsHourAxis, setPtsHourAxis] = useState([]);
  const [ptsdTab, setPtsdTab] = useState('tudo');

  const [historico, setHistorico] = useState([]);
  const [historicoAxis, setHistoricoAxis] = useState([]);
  const [historicoTab, setHistoricoTab] = useState('status');

  const [tempMedio, setTempMedio] = useState(null);
  const [tempMedioAxis, setTempMedioAxis] = useState(['month']);

  const [top10, setTop10] = useState([]);
  const [top10Tab, setTop10Tab] = useState('justificativas');

  // Selects dos filtros
  const { filiais, clients } = useFetchMultipleWithCache();

  const fetchPtsHour = async query => {
    setPtsLoading(true);
    const res = await requestGraphDescarga('1', query);
    if (res.data?.data) {
      setPtsHour(res.data.data.data);
      setPtsHourAxis(res.data.data.series);
    } else if (res.data?.message) toast.error(res.data.message);
    setPtsLoading(false);
  };

  const fetchHistory = async query => {
    setHistoricoLoading(true);
    const res = await requestGraphDescarga('2', query);
    if (res.data?.data) {
      setHistorico(res.data.data.historico);
      setHistoricoAxis(res.data.data.series);
    } else if (res.data?.message) toast.error(res.data.message);
    setHistoricoLoading(false);
  };

  const fetchTempMedio = async query => {
    setTempLoading(true);
    const res = await requestGraphDescarga('3', query);
    if (res.data?.data) {
      setTempMedio(res.data.data.data);
      setTempMedioAxis(res.data.data.series);
    } else if (res.data?.message) toast.error(res.data.message);
    setTempLoading(false);
  };

  const fetchTop10 = async query => {
    setTop10Loading(true);
    const res = await requestGraphDescarga('4', query);
    if (res.data?.data) {
      setTop10(res.data.data.data);
    } else if (res.data?.message) toast.error(res.data.message);
    setTop10Loading(false);
  };

  useEffect(() => {
    const query = { ...filter, tab_option: ptsdTab };
    fetchPtsHour(query);
  }, [filter, ptsdTab]);

  useEffect(() => {
    const query = { ...filter, tab_option: historicoTab };
    fetchHistory(query);
  }, [filter, historicoTab]);

  useEffect(() => {
    fetchTempMedio(filter);
  }, [filter]);

  useEffect(() => {
    const query = { ...filter, tab_option: top10Tab };
    fetchTop10(query);
  }, [filter, top10Tab]);

  // Filtros utilizados
  const filterList = [
    {
      filterName: 'filial',
      label: 'Filiais',
      options: filiais || [],
    },
    {
      filterName: 'client',
      label: 'Clientes',
      options: clients.data || [],
    },
  ];

  const renderFilters = () => {
    return (
      <FiltersGlobal
        hideRefleshButton
        hideExportButton
        persistDate
        customComponent={<h1>Estatísticas: Avaliações Descarga</h1>}
        defaultDate={filter}
        data={filterList}
      />
    );
  };

  const renderPhGraph = () => {
    const tabs = (
      <Tabs
        value={ptsdTab}
        items={ptsdTabs}
        onChange={(e, value) => {
          trackEvent(user, `GRÁFICO AVALIAÇÕES PTS/DESCARGA: ${value}`);
          setPtsdTab(value);
        }}
      />
    );

    return (
      <S.BoxGraphs>
        <History
          titulo="Histórico de ptos/descarga"
          data={ptsHour}
          series={ptsHourAxis}
          series_divs={['pontos', 'descarga']}
          loading={ptsLoading}
          leftHeaderComponent={tabs}
          initialDate={filter?.initialDate}
          finalDate={filter?.finalDate}
        />
      </S.BoxGraphs>
    );
  };

  const renderHistoryGraph = () => {
    const tabs = (
      <CustomizedSelect
        size="small"
        items={historicoTabs}
        selected={historicoTab}
        handelSelect={value => {
          trackEvent(user, `GRÁFICO AVALIAÇÕES HISTÓRICO: ${value}`);
          setHistoricoTab(value);
        }}
      />
    );
    return (
      <S.BoxGraphs>
        <History
          titulo="Histórico"
          data={historico}
          series={historicoAxis}
          calcDistinct={
            historicoTab === 'drivers' &&
            historicoAxis.includes('Motoristas avaliados')
          }
          loading={historicoLoading}
          leftHeaderComponent={tabs}
          initialDate={filter?.initialDate}
          finalDate={filter?.finalDate}
        />
      </S.BoxGraphs>
    );
  };

  const renderTempMedioGraph = () => {
    return (
      <S.BoxGraphs>
        <History
          titulo="Tempo médio (dias)"
          data={tempMedio}
          series={tempMedioAxis}
          loading={tempLoading}
          // leftHeaderComponent={tabs}
          initialDate={filter?.initialDate}
          finalDate={filter?.finalDate}
          hidePeriods
          initialPeriod="month"
        />
      </S.BoxGraphs>
    );
  };

  const renderTop10Graph = () => {
    const tabs = (
      <Tabs
        value={top10Tab}
        items={top10Tabs}
        onChange={(e, value) => {
          trackEvent(user, `GRÁFICO AVALIAÇÕES DESCARGA TOP 10: ${value}`);
          setTop10Tab(value);
        }}
      />
    );

    return (
      <S.BoxGraphs>
        <BarGraph2
          title="Top 10"
          leftHeaderComponent={tabs}
          currentTab={top10Tab}
          data={top10}
          tabsItems={top10Tabs}
          isLoading={top10Loading}
          handleTabClick={newValue => setTop10Tab(newValue)}
        />
      </S.BoxGraphs>
    );
  };

  const renderMain = () => (
    <>
      <S.Container>
        <S.Header>{renderFilters()}</S.Header>
        <S.Main>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              {renderPhGraph()}
            </Grid>

            <Grid item xs={15} md={12}>
              {renderHistoryGraph()}
            </Grid>

            <Grid item xs={12} md={12}>
              {renderTempMedioGraph()}
            </Grid>

            <Grid item xs={12} md={12}>
              {renderTop10Graph()}
            </Grid>
          </Grid>
        </S.Main>
      </S.Container>
    </>
  );

  return renderMain();
};

export default GraficosAvalicoesDescarga;
