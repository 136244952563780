// Styleds

// React
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState, useRef } from 'react';
import { useTheme } from 'styled-components';
import { updateProfilePhoto } from 'store/modules/header/actions';

// Material UI
import { Grid } from '@mui/material';
import LocalSeeOutlinedIcon from '@mui/icons-material/LocalSeeOutlined';

// Components
import Loading from 'components/Loading';
import Select from 'components/Inputs/Select';
import GhostButton from 'components/Buttons/Ghost';
import TextInput from 'components/Inputs/TextField';
import DefaultButtonPopover from 'components/Buttons/DefaultPopover';

// Utils
import { firestorage } from 'utils/firebase';
import { cepMask, celMask } from 'utils/masks';
import { brStates } from 'utils/brazilianStates';

// Services(Api)

// Images & Svgs
import OnisysLogo from 'images/logoheader.svg';
import DefaultButton from 'components/Buttons/Default';
import * as api from './services';
import UpgradeModal from './UpgradeModal';
import * as C from '../styled';
import * as S from './styled';

// TODO RETORNO DE IMAGENS P/ EXIBIÇÃO
// TODO FUNÇÃO DO CONTATO line 388
// TODO IMAGEM DO CONTATO line 382

export const PerfilEmpresa = () => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const { currentPlan } = useSelector(state => {
    return state.pageMap;
  });

  // Verfiy user level
  const {
    user: { user },
  } = useSelector(state => state.auth);

  const [level, setLevel] = useState(0);
  useEffect(() => {
    const lv = user.nivel;
    setLevel(lv);
    if (lv < 2) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [user]);

  // General States & Ref for controll
  const [request, setRequest] = useState(null);
  const [upgradeModal, setUpgradeModal] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(true);
  const inputFile = useRef(null);

  // Form States
  const [contatoImage, setContatoImage] = useState('');
  const [logo, setLogo] = useState('');
  const [razaoSocial, setRazaoSocial] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [plano, setPlano] = useState('');
  const [faixa, setFaixa] = useState('');
  const [estadual, setEstadual] = useState('');
  const [municipal, setMunicipal] = useState('');
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [telefone, setTelefone] = useState('');
  const [endereco, setEndereco] = useState('');
  const [bairro, setBairro] = useState('');
  const [cidade, setCidade] = useState('');
  const [estado, setEstado] = useState('');
  const [cep, setCep] = useState('');
  const [numero, setNumero] = useState('');
  const [complemento, setComplemento] = useState('');

  // Fill Inputs
  useEffect(() => {
    requestInfos(true);
  }, []);

  const requestInfos = async newData => {
    let data = null;
    if (request && !newData) {
      data = request;
    } else if (newData) {
      if (user.provider) {
        data = await api.requireProvider(user.id_da_distribuidora);
        setRequest(data);
      } else {
        data = await api.requireInfos();
        setRequest(data);
      }
    } else {
      return false;
    }

    // Fromatação p/ exibição do plano
    const { planos } = data;
    if (planos) {
      for (const i in planos) {
        if (planos[i].id === currentPlan) {
          setPlano(planos[i].descricao);
        }
      }
    }

    setLogo(data.logo_url ?? '');
    setRazaoSocial(data.razao_social ?? '');
    setCnpj(data.cnpj ?? '');
    setFaixa(data.faixa_contratada ?? '');
    setEstadual(data.inscricao_estadual ?? '');
    setMunicipal(data.inscricao_municipal ?? '');
    setNome(data.nome_do_contato ?? '');
    setEmail(data.email_do_contato ?? '');
    setTelefone(data.telefone_do_contato ?? '');
    setEndereco(data.endereco?.rua ?? '');
    setBairro(data.endereco?.bairro ?? '');
    setCidade(data.endereco?.cidade ?? '');
    setEstado(data.endereco?.estado ?? '');
    setCep(data.endereco?.cep ?? '');
    setNumero(data.endereco?.numero ?? '');
    setComplemento(data.endereco?.complemento ?? '');
    setMotoristas(data.motoristas_ativos ?? '');
    setMaxMoto(data.max_contrato ?? '');

    // Após preencher todos os states
    setLoading(false);
  };

  // Plain Area (Infos)
  const [motoristas, setMotoristas] = useState(0);
  const [maxMoto, setMaxMoto] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [colorBar, setColorbar] = useState('');

  useEffect(() => {
    calcPercentage();
  }, [motoristas, maxMoto]);

  const calcPercentage = () => {
    let calc = parseInt((motoristas * 100) / maxMoto);
    if (calc > 100) calc = 100;
    setPercentage(calc);

    if (calc <= 60) {
      setColorbar(
        'linear-gradient(-45deg, #1BC5BD, #26d0d6, #1BC5BD, #23A6D5, #1BC5BD)',
      );
    } else if (calc > 60 && calc < 80) {
      setColorbar(
        'linear-gradient(-45deg, #ff8e01, #ea9c3c, #ba6d10, #f98807, #f7ac5b)',
      );
    } else if (calc > 80) {
      setColorbar(
        'linear-gradient(-45deg, #f64e60, #f2717e, #e0162a, #f2525f, #c4212c)',
      );
    }
  };

  // Change Logo (single update)
  const updateFirebaseLogo = async () => {
    if (level !== 1) {
      return toast.error('Você não tem permissão para essa operação.');
    }

    const file = inputFile.current.files[0];
    if (file) {
      try {
        setLoading(true);
        const dh = new Date().toDateString();
        if (!file) return toast.error('Não foi possível enviar sua imagem');

        const uploadTask = firestorage
          .ref(`/logos/${dh}_${file.name}`)
          .put(file);
        uploadTask.on(
          'state_changed',
          snapShot => {},
          err => {
            console.log('Err', err);
          },
          () => {
            firestorage
              .ref(`/logos/`)
              .child(`${dh}_${file.name}`)
              .getDownloadURL()
              .then(fireBaseUrl => {
                // dispatch(updatePhotoProfile({ profile_photo: fireBaseUrl }));
                dispatch(
                  updateProfilePhoto({
                    profile_photo: fireBaseUrl,
                  }),
                );
                updateDdLogo(fireBaseUrl);
              });
          },
        );
      } catch (error) {
        toast.error('Não foi possível enviar sua imagem');
      }
      setLoading(false);
    }
  };

  const updateDdLogo = async fireBaseUrl => {
    let res = {};
    if (user.provider) {
      res = await api.updateProvider(user.id_da_distribuidora, {
        ...request,
        logo_url: fireBaseUrl,
      });
    } else {
      res = await api.updateInfos({ logo_url: fireBaseUrl });
    }
    res.success ? toast.success(res.message) : toast.error(res.message);
    requestInfos(true);
  };

  // Change All infos
  const updateInfos = async () => {
    const newData = {};
    newData.razao_social = razaoSocial;
    newData.cnpj = cnpj;
    newData.inscricao_estadual = estadual;
    newData.inscricao_municipal = municipal;
    newData.nome_do_contato = nome;
    newData.email_do_contato = email;
    newData.telefone_do_contato = telefone;
    newData.endereco = {
      rua: endereco,
      bairro,
      cidade,
      estado,
      cep,
      numero,
      complemento,
    };

    let res = {};
    if (user.provider) {
      res = await api.updateProvider(user.id_da_distribuidora, {
        ...request,
        ...newData,
      });
    } else {
      res = await api.updateInfos(newData);
    }

    if (res.success) {
      toast.success(res.message);
      requestInfos(true);
    } else {
      toast.error(res.message);
    }
  };

  // Fill new address when cep toggle
  const fillAddress = async cep => {
    const address = await cepMask(cep);
    setCep(address.cep);
    setEndereco(address.logradouro);
    setBairro(address.bairro);
    setCidade(address.localidade);
    setEstado(address.uf);
  };

  return (
    <S.Container>
      {loading && (
        <div className="boxLoading">
          <Loading />
        </div>
      )}
      {!loading && (
        <>
          <C.TitleArea>
            <span className="textArea">Perfil e Empresa</span>
          </C.TitleArea>
          <S.BoxArea>
            <S.UserArea logo={logo}>
              <div className="topArea">
                <div className="ring--imageArea">
                  <div className="image">
                    {logo && <img src={logo} />}
                    {!logo && <span>{razaoSocial[0] ?? ''}</span>}
                  </div>
                </div>
                {!disabled && (
                  <label className="changePhotoArea">
                    <LocalSeeOutlinedIcon htmlColor="#757575" />
                    <input
                      type="file"
                      multiple={false}
                      ref={inputFile}
                      onChange={updateFirebaseLogo}
                    />
                  </label>
                )}
              </div>
              <div className="bottomArea">{razaoSocial}</div>
            </S.UserArea>

            {plano && (
              <S.PlainArea percentage={percentage} colorBar={colorBar}>
                <div className="topPlain">
                  <div className="leftArea">
                    <span className="plainTitle">Plano</span>
                    <div className="plainCircle">
                      <span>{plano}</span>
                    </div>
                  </div>
                  <div className="rightArea">
                    <img src={OnisysLogo} alt="Onisys" />
                  </div>
                </div>
                <div className="bottomPlain">
                  <div className="rowText">
                    <div className="textLeft">
                      <span className="textFaixas">Faixas</span>
                      <span className="numberFaixas">
                        {motoristas} / {maxMoto}
                      </span>
                    </div>
                    <div className="textRight">{percentage}%</div>
                  </div>
                  <div className="rowBar">
                    <span className="emptyBar" />
                    <span className="completedBar" />
                  </div>
                </div>
                <DefaultButton
                  size="medium"
                  sx={{ width: '100%', marginTop: '20px' }}
                  onClick={() => setUpgradeModal(true)}
                >
                  FAZER UPGRADE DO PLANO
                </DefaultButton>
              </S.PlainArea>
            )}

            <S.CompanyInfo>
              <S.TitleSection>Informações da Empresa</S.TitleSection>
              <S.BoxInformation className="InfoCompany" logo={logo}>
                <Grid display="flex">
                  <div className="image">
                    {logo && <img src={logo} />}
                    {!logo && <span>{razaoSocial[0] ?? ''}</span>}
                  </div>
                  <div className="infoName">
                    <span className="nameItem">Nome</span>
                    <span className="nameCustom">{razaoSocial}</span>
                  </div>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                  marginTop={1}
                >
                  <Grid item xs={6} md={6}>
                    <label>
                      <TextInput
                        disabled={disabled}
                        label="Razão Social"
                        value={razaoSocial}
                        onChange={e => setRazaoSocial(e.target.value)}
                      />
                    </label>
                    <label>
                      <TextInput
                        disabled={disabled}
                        label="Inscrição Estadual"
                        value={estadual}
                        onChange={e => setEstadual(e.target.value)}
                      />
                    </label>
                    <label>
                      <TextInput
                        disabled
                        label="Plano Contratado"
                        value={plano}
                        onChange={e => setPlano(e.target.value)}
                      />
                    </label>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <label>
                      <TextInput
                        disabled
                        label="CNPJ"
                        value={cnpj}
                        onChange={e => setCnpj(e.target.value)}
                      />
                    </label>
                    <label>
                      <TextInput
                        disabled={disabled}
                        label="Inscrição Municipal"
                        value={municipal}
                        onChange={e => setMunicipal(e.target.value)}
                      />
                    </label>
                    <label>
                      <TextInput
                        disabled
                        label="Faixa Contratada"
                        value={faixa}
                        onChange={e => setFaixa(e.target.value)}
                      />
                    </label>
                  </Grid>
                </Grid>
              </S.BoxInformation>

              <S.TitleSection>Informações do Contato</S.TitleSection>
              <S.BoxInformation className="InfoCompany">
                <Grid display="flex">
                  <div className="image green">
                    {contatoImage && <img src={contatoImage} />}{' '}
                    {/* Necessario alteração no back para retornar essa imagem */}
                    {!contatoImage && <span>{nome[0] ?? ''}</span>}
                  </div>
                  <div className="infoName">
                    <span className="nameCustom">{nome}</span>
                    {/* <span className="nameItem">XXXXXXXXXXXX</span>   Necessario alteração no back para retorno a função */}
                  </div>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                  marginTop={1}
                >
                  <Grid item xs={12} md={12}>
                    <label>
                      <TextInput
                        disabled={disabled}
                        label="Nome"
                        value={nome}
                        onChange={e => setNome(e.target.value)}
                      />
                    </label>
                  </Grid>
                  <Grid
                    container
                    item
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item xs={6} md={6}>
                      <label>
                        <TextInput
                          disabled={disabled}
                          label="Email"
                          value={email}
                          onChange={e => setEmail(e.target.value)}
                        />
                      </label>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <label>
                        <TextInput
                          disabled={disabled}
                          label="Telefone"
                          value={telefone}
                          onChange={e => setTelefone(celMask(e.target.value))}
                        />
                      </label>
                    </Grid>
                  </Grid>
                </Grid>
              </S.BoxInformation>

              <S.TitleSection>Endereço</S.TitleSection>
              <S.BoxInformation className="InfoCompany">
                <Grid
                  container
                  item
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs={12} md={12}>
                    <label>
                      <TextInput
                        disabled={disabled}
                        label="Endereço"
                        value={endereco}
                        onChange={e => setEndereco(e.target.value)}
                      />
                    </label>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <label>
                      <TextInput
                        disabled={disabled}
                        label="Cep"
                        value={cep}
                        onChange={e => setCep(e.target.value)}
                        onBlur={() => fillAddress(cep)}
                      />
                    </label>
                  </Grid>
                  <Grid item xs={6} md={6} style={{ marginTop: '-20px' }}>
                    <label>
                      <Select
                        disabled={disabled}
                        data={brStates}
                        value={estado}
                        label="Estado"
                        handleChange={e => setEstado(e.target.value)}
                        style={{
                          marginBottom: '-20px',
                        }}
                      />
                    </label>
                  </Grid>

                  <Grid item xs={6} md={6}>
                    <label>
                      <TextInput
                        disabled={disabled}
                        label="Bairro"
                        value={bairro}
                        onChange={e => setBairro(e.target.value)}
                      />
                    </label>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <label>
                      <TextInput
                        disabled={disabled}
                        label="Cidade"
                        value={cidade}
                        onChange={e => setCidade(e.target.value)}
                      />
                    </label>
                  </Grid>

                  <Grid item xs={6} md={6}>
                    <label>
                      <TextInput
                        disabled={disabled}
                        label="Número"
                        value={numero}
                        onChange={e => setNumero(e.target.value)}
                      />
                    </label>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <label>
                      <TextInput
                        disabled={disabled}
                        label="Complemento"
                        value={complemento}
                        onChange={e => setComplemento(e.target.value)}
                      />
                    </label>
                  </Grid>
                </Grid>
              </S.BoxInformation>

              <S.Footer>
                {!disabled && (
                  <>
                    <GhostButton
                      customcolor={
                        theme?.palette?.semantics?.feedback?.attention?.natural
                      }
                      size="medium"
                      onClick={() => requestInfos(false)}
                    >
                      Cancelar
                    </GhostButton>

                    <GhostButton
                      size="medium"
                      sx={{ marginLeft: '10px' }}
                      onClick={updateInfos}
                    >
                      Salvar Alterações
                    </GhostButton>
                  </>
                )}
              </S.Footer>
            </S.CompanyInfo>
          </S.BoxArea>
          {upgradeModal && (
            <UpgradeModal
              open={upgradeModal}
              handleClose={() => setUpgradeModal(false)}
            />
          )}
        </>
      )}
    </S.Container>
  );
};
