import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { Skeleton } from '@mui/material';

// styles
import * as S from './styled';

const getDataMax = datamax => {
  return datamax > 100
    ? Math.ceil(datamax / 100) * 100
    : datamax > 10
    ? Math.ceil(datamax / 10) * 10
    : datamax > 5
    ? Math.ceil(datamax / 5) * 5
    : 5;
};

const CustomTick = props => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="middle"
        fill="#939aab"
        transform="rotate(0)"
      >
        <tspan x={0} dy="20">
          {payload.value}
        </tspan>
      </text>
    </g>
  );
};

export const LineGraph = ({ data = [], height = 240, loading }) => {
  return (
    <S.Container>
      <div className="legend-container">
        <S.Legend>
          <div className="point" />
          Média Geral
        </S.Legend>
        <S.Legend>
          <div className="point primary" />
          Usuário
        </S.Legend>
      </div>
      <ResponsiveContainer width="100%" height={height}>
        {loading ? (
          <Skeleton
            style={{ marginTop: '1rem' }}
            height={height}
            animation="wave"
            variant="rounded"
          />
        ) : (
          <LineChart
            height={height}
            data={data}
            margin={{ top: 20, right: 30, left: 10 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="nome"
              height={70}
              tick={<CustomTick />}
              interval={0}
            />
            <YAxis domain={[0, getDataMax]} />
            <Tooltip />

            <Line
              type="linear"
              dataKey="quantidade"
              name="Valor"
              stroke="#0C12F2"
              dot={props => {
                const { index, payload } = props;
                const isLastDot = index === data.length - 1;
                return (
                  <circle
                    key={index}
                    cx={props.cx}
                    cy={props.cy}
                    r={5}
                    fill={isLastDot ? 'none' : '#0C12F2'} // Se for o último ponto, não preenche
                    stroke={isLastDot ? '#0C12F2' : 'none'} // Se for o último ponto, define a cor da borda
                    strokeWidth={isLastDot ? 2 : 0} // Se for o último ponto, define a largura da borda
                  />
                );
              }}
            />

            <Line
              type="linear"
              dataKey="media"
              name="Media"
              stroke="#939aab"
              strokeDasharray={['5 5']}
              dot={props => {
                const { index, payload } = props;
                const isLastDot = index === 11; // Verifica se é o último ponto
                return (
                  <circle
                    key={index}
                    cx={props.cx}
                    cy={props.cy}
                    r={5}
                    fill={isLastDot ? 'none' : '#939aab'}
                    stroke={isLastDot ? '#939aab' : 'none'}
                    strokeWidth={isLastDot ? 2 : 0}
                  />
                );
              }}
            />
          </LineChart>
        )}
      </ResponsiveContainer>
    </S.Container>
  );
};
