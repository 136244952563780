// React
import { useEffect, useState } from 'react';

// Styles
import { useTheme } from 'styled-components';

// Components
import Icon from 'components/Icons';
import Loading from 'components/Loading';
import GhostButton from 'components/Buttons/Ghost';

// Components MI
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import TextInput from '../../TextField';
import * as S from './styled';

const ModalFile = ({
  data, // Itens a serem exibidos no modal
  activeItem, // Item que é exibido ao inicializar modal
  setData, // Formatação do objeto que compõe o modal
  open, // Determina abertura
  setOpen, // Controle abertura e fechamento do modal
  loading, // Aguarda o carregamento para exibição das imagens
  automaticSave, // Verifica se o modal deve ser async
  addInDatabase, // Retorna o array com os itens que deve sem inseridos no banco
  haveLegend, // Determina se a legenda será exibida no modal
  multiple,

  newImagesByModal,
  editNew,
  editOld,
  setAddImages,
}) => {
  const theme = useTheme();
  // General States
  const [margin, setMargin] = useState(0);
  const [currentItem, setCurrentItem] = useState(0);

  // --------------Configure Slide and Postion----------------------//
  useEffect(() => {
    const total = data.length;
    const totalMargin = 540 * total - 540;

    if (activeItem) {
      setMargin(activeItem * 540);
      calcPosition(totalMargin / margin);
    }
  }, []);

  const handleNext = () => {
    const total = data.length;
    const totalMargin = 540 * total - 540;
    if (margin < totalMargin) {
      setMargin(margin + 540);
      calcPosition(margin + 540);
    }
  };

  const handleBack = () => {
    if (margin > 0) {
      setMargin(margin - 540);
      calcPosition(margin - 540);
    }
  };

  const calcPosition = currentPos => {
    const pos = currentPos / 540;
    setCurrentItem(pos);
  };

  // --------------------Controll Input text-------------------------//
  const [legend, setLegend] = useState('');
  useEffect(() => {
    toggleData(data);
  }, [currentItem]);

  const toggleLegend = value => {
    const copyData = [...data];
    copyData[currentItem].legenda = value.slice(0, 100);
    setData(copyData);
    toggleData(copyData);
  };

  const toggleData = archive => {
    if (data[0]) {
      setLegend(archive[currentItem].legenda);
    }
  };

  const handleSave = () => {
    if (newImagesByModal) {
      const verifyItem = () => {
        if (multiple) {
          setAddImages([...editNew, ...editOld]);
        } else {
          setAddImages(editNew);
        }
      };

      Promise.all([verifyItem()]).then(() => {
        addInDatabase();
      });
    } else {
      addInDatabase();
    }
    setOpen();
  };

  return (
    <S.ModalType open={open}>
      <S.Background>
        <S.Header>
          <S.LeftHeader>
            <ImageOutlinedIcon
              htmlColor={theme.palette.brand.secondary.natural}
            />
            <S.Title>
              {`Editar Fotos `}
              {!loading && (
                <span>{`( ${currentItem + 1} de ${data.length} )`}</span>
              )}
            </S.Title>
          </S.LeftHeader>

          <span
            onClick={() => {
              setOpen();
              if (newImagesByModal) {
                setAddImages(editOld);
              }
            }}
            style={{ cursor: 'pointer' }}
          >
            <Icon
              name="close"
              size={25}
              color={theme.palette.brand.primary.light}
            />
          </span>
        </S.Header>
        <S.ImagesArea>
          {!loading && (
            <>
              <div className="arrow back" onClick={() => handleBack()}>
                <ArrowBackIosNewOutlinedIcon
                  htmlColor={theme.palette.brand.primary.light}
                />
              </div>

              <S.Slider width={data.length} margin={margin}>
                {typeof data === 'object' &&
                  data.map((imagem, idx) => (
                    <S.Sliders key={idx}>
                      <embed src={imagem.imagem_url} draggable={false} />
                    </S.Sliders>
                  ))}
              </S.Slider>

              <div className="arrow next" onClick={() => handleNext()}>
                <ArrowForwardIosOutlinedIcon
                  htmlColor={theme.palette.brand.primary.light}
                />
              </div>
            </>
          )}
          {loading && (
            <div>
              <Loading />
            </div>
          )}
        </S.ImagesArea>
        <S.LegendArea haveLegend={haveLegend}>
          {haveLegend && (
            <TextInput
              placeholder="Insira sua Legenda"
              value={legend || ''}
              disabled={loading}
              onChange={e => toggleLegend(e.target.value)}
              noAsterisk
            />
          )}
        </S.LegendArea>
        <S.ButtonsArea>
          <GhostButton
            children="Voltar"
            customcolor={
              theme?.palette?.semantics?.feedback?.attention?.natural
            }
            onClick={() => {
              setOpen();
              if (automaticSave) {
                setData(null);
              }
              if (newImagesByModal) {
                setAddImages(editOld);
              }
            }}
          />
          <GhostButton
            style={{
              marginLeft: '10px',
            }}
            children={automaticSave ? 'Salvar' : 'Concluir'}
            onClick={() => handleSave()}
          />
        </S.ButtonsArea>
      </S.Background>
    </S.ModalType>
  );
};

export default ModalFile;
