import styled, { css } from 'styled-components';

export const ModalContent = styled.div`
  ${({ theme }) => css`
    position: absolute;
    width: 100%;
    max-width: 590px;
    background-color: ${theme.palette.system.overlay};
    border: 0px 5px 20px ${theme.palette.system.divider};
    border-radius: 8px;
    padding: 25px 25px;
    margin: 0 auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `}
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  color: ${({ theme }) => theme.palette.words.title.natural};

  h3 {
    font-size: 24px;
    font-weight: 500;
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 1rem;
  margin-top: 1rem;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
