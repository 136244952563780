import {
  getExecutores,
  getAprovadores,
  getBases,
  getTamanhos,
  getStates,
  getClients,
  getTypeClientIp,
  getEmpresasLayout,
} from 'constants/_SERVICES/user';

// Pages
import LayoutDescarga from 'pages/LayoutDescarga';
import ConfigLayout from 'pages/LayoutDescarga/Config';
import CaeDetail from 'pages/LayoutDescarga/Cae/Detalhe';
import LayoutPdf from 'pages/LayoutDescarga/Config/ModelPdf';

export default {
  '/layout/criar': {
    back: '/layout',
    title: 'Criar Layout',
    status: true,
    defaults: [],
    page: <ConfigLayout />,
  },

  '/layout': {
    header: {},
    pageFilterName: 'filterLayout',
    title: 'Layout de Descarga',
    defaults: [],
    back: -1,
    page: <LayoutDescarga />,
  },

  '/layout/:id': {
    back: -1,
    title: 'Layout',
    id: true,
    status: true,
    defaults: [],
    page: <ConfigLayout />,
  },

  '/layout/pdf/:id': {
    title: 'Impressão Layout',
    id: true,
    defaults: [],
    page: <LayoutPdf />,
  },

  '/cae/:id': {
    back: -1,
    title: 'Cae',
    id: true,
    defaults: [],
    page: <CaeDetail />,
  },
};
