// Styles
import { useTheme } from 'styled-components';
// React
import React, { useState, useEffect, useMemo } from 'react';

// Components
import GhostButton from 'components/Buttons/Ghost';
import SelectInput from 'components/Inputs/Select';

// Components MUI
import List from '@mui/material/List';
import { Popover } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import FormGroup from '@mui/material/FormGroup';
import ListItemText from '@mui/material/ListItemText';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ListItemButton from '@mui/material/ListItemButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import * as S from './styled';

// Utils
import { selectClients } from './services';
import { getTurmas } from '../services';

const Filters = ({
  id,
  open,
  onClose,
  anchorEl,
  data = [],
  data_bk = [],
  setUsersFilters,
  fetch,
  tipoUser,
}) => {
  const [openCollapseTurma, setOpenCollapseTurma] = React.useState(false);
  const [openCollapseTipo, setOpenCollapseTipo] = React.useState(false);
  const [openCollapseCliente, setOpenCollapseCliente] = React.useState(false);
  const theme = useTheme();

  // opcoes filters
  const [typeGestor, setTypeGestor] = useState(false);
  const [typeDriver, setTypeDriver] = useState(false);
  const [cliente, setCliente] = useState(null);
  const [turma, setTurma] = useState(null);
  const [total, setTotal] = useState(0);

  const [allClientes, setAllClientes] = useState([]);
  const [allTurmas, setAllTurmas] = useState([]);

  const fetchClientes = async () => {
    let res = await selectClients();
    res = res.data.map(item => {
      return { name: item.nome, value: item.distribuidora.id };
    });
    setAllClientes(res || []);
  };

  const fetchTurmas = async () => {
    let res = await getTurmas();
    res = res.data.data.map(item => {
      return { name: item.nome, value: item.id };
    });
    setAllTurmas(res);
  };

  useEffect(() => {
    fetchClientes();
    fetchTurmas();
  }, []);

  const filterArray = () => {
    const filterClientes = data_bk.filter(item =>
      item?.clients?.includes(cliente),
    );
    const filterTurmas = data_bk.filter(item =>
      item?.nomes_turmas?.includes(turma),
    );

    const userType = typeDriver && !typeGestor ? 'Motorista' : 'Usuário';
    const filterUserType = data_bk.filter(item => item?.tipo === userType);

    const allArr = [filterClientes, filterTurmas, filterUserType];
    const result = Array.prototype.concat(...allArr);
    setUsersFilters(result);
    onClose();
  };

  const clearFilters = () => {
    setCliente(null);
    setTurma(null);
    setTotal(0);
    setTypeDriver(false);
    setTypeGestor(false);
    setUsersFilters(data_bk);
    onClose();
  };

  const handleChangeCliente = val => {
    setCliente(val);
    setTotal(p => p++);
  };

  const handleChangeTurma = val => {
    setTurma(val);
    setTotal(p => p++);
  };

  return (
    <div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <S.Main>
          <S.Header>
            <h2>Filtros</h2>
            <S.ClearButton onClick={clearFilters}>Limpar filtros</S.ClearButton>
          </S.Header>

          <S.SelectsContainer>
            <List>
              <ListItemButton
                onClick={() => {
                  setOpenCollapseTurma(!openCollapseTurma);
                  setOpenCollapseCliente(false);
                  setOpenCollapseTipo(false);
                }}
              >
                <ListItemText primary="Filtrar por Turma" />
                {openCollapseTurma ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={openCollapseTurma} timeout="auto" unmountOnExit>
                <div style={{ marginLeft: 0 }}>
                  <SelectInput
                    required
                    name="Turmas"
                    value={turma}
                    handleChange={e => handleChangeTurma(e.target.value)}
                    label="Turmas"
                    data={allTurmas}
                  />
                </div>
              </Collapse>
              <ListItemButton
                onClick={() => {
                  setOpenCollapseTipo(!openCollapseTipo);
                  setOpenCollapseCliente(false);
                  setOpenCollapseTurma(false);
                }}
              >
                <ListItemText primary="Filtrar por Tipo" />
                {openCollapseTipo ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={openCollapseTipo} timeout="auto" unmountOnExit>
                <div style={{ marginLeft: 25 }}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={typeDriver}
                          disableRipple
                          edge="start"
                          onChange={() => {
                            setTypeGestor(false);
                            setTypeDriver(true);
                          }}
                        />
                      }
                      label="Motorista"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={typeGestor}
                          disableRipple
                          edge="start"
                          onChange={() => {
                            setTypeDriver(false);
                            setTypeGestor(true);
                          }}
                        />
                      }
                      label="Gestor"
                    />
                  </FormGroup>
                </div>
              </Collapse>
              {tipoUser?.para_motoristas ? (
                <ListItemButton
                  onClick={() => {
                    setOpenCollapseCliente(!openCollapseCliente);
                    setOpenCollapseTipo(false);
                    setOpenCollapseTurma(false);
                  }}
                >
                  <ListItemText primary="Filtrar por Cliente" />
                  {openCollapseCliente ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
              ) : (
                <></>
              )}
              <Collapse in={openCollapseCliente} timeout="auto" unmountOnExit>
                <div style={{ marginLeft: 0 }}>
                  <SelectInput
                    value={cliente}
                    handleChange={e => handleChangeCliente(e.target.value)}
                    name="Cliente"
                    label="Cliente"
                    data={allClientes}
                  />
                </div>
              </Collapse>
            </List>
          </S.SelectsContainer>

          <S.Footer>
            <GhostButton
              customcolor={
                theme?.palette?.semantics?.feedback?.attention?.natural
              }
              onClick={onClose}
              size="medium"
              id="cancelar"
            >
              Cancelar
            </GhostButton>

            <GhostButton onClick={filterArray} size="medium" id="aplicar">
              Aplicar
            </GhostButton>
          </S.Footer>
        </S.Main>
      </Popover>
    </div>
  );
};

export default Filters;
