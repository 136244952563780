import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  color: ${({ active }) => (active ? '#f9fbfd' : '#4B5166')};
  background: ${({ active }) => (active ? '#4B5166' : '#f9fbfd')};
  border-radius: 4px;
  width: 100%;
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 1px solid #939aab4d;
  overflow: hidden;
  justify-content: center;
  flex-direction: column;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  .footer-card2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .deslocamentos-link {
      text-decoration: underline;
      text-align: start;
      font: normal normal medium 16px/20px Texta;
      letter-spacing: 0px;
      color: #ff8040;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }
    .rodovia-description {
      letter-spacing: 0px;
      color: #4b5166;
    }
  }
  .name {
    text-align: left;
    font-size: 18px;
    font-weight: 900;
    max-width: 10vw;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .name > span {
    font-size: 14px;
    font-weight: 400;
    margin-left: 5px;
  }
  .plate {
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    max-width: 10vw;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .divider {
    min-width: 100%;
    border-bottom: 1px solid #939aab;
    opacity: 0.3;
  }
  .container-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 3px;
    padding-bottom: 3px;
  }
  .address {
    text-align: left;
    font-size: 14px;
    font-weight: 400;

    span {
      font-size: 14px;
      font-weight: 900;
    }
  }
  .desvios {
    display: flex;
    justify-content: center;
    text-align: right;
    text-decoration: underline;
    font-size: 16px;
    font-style: mendium;
    letter-spacing: 0px;
    cursor: default;
    color: #ff8040;
  }
  .desvios:hover {
    color: #ff8040;
  }
  .desvios:active {
    color: #ff8040;
  }
  .desvios.disabled {
    text-decoration: none;
    color: #939aab;
  }
`;
