import { formatNewHour } from 'utils/dates';

const styledCutom = theme => ({
  backgroundColor: `${theme?.palette?.semantics?.feedback?.warning?.natural}22`,
  color: theme?.palette?.semantics?.feedback?.warning?.natural,
  borderRadius: 6,
  textAlign: 'center',
  paddingLeft: 15,
  paddingRight: 15,
});

export const getStatusColor = (status, theme) => {
  switch (status) {
    case 'ABERTO':
      return <div style={{ ...styledCutom(theme) }}>{status}</div>;
    case 'PENDENTE':
      return <div style={{ ...styledCutom(theme) }}>{status}</div>;
  }
};

export const InfoWindow = ({
  index,
  color,
  item,
  hasTorrePlus,
  isProvider,
  theme,
}) => (
  <div
    style={{
      fontFamily: 'Texta',
      background: '#fff',
      color: '#4b5166',
    }}
  >
    <svg width="12" height="12" viewBox="0 0 24 30.581">
      <path
        d="M12,0A12.092,12.092,0,0,0,0,12.154c0,8.69,10.8,17.778,11.263,18.159a1.147,1.147,0,0,0,1.474,0C13.2,29.931,24,20.843,24,12.154A12.092,12.092,0,0,0,12,0m0,18.035a5.881,5.881,0,1,1,5.806-5.881A5.852,5.852,0,0,1,12,18.035"
        fill={color}
      />
    </svg>
    <span
      style={{
        fontSize: '14px',
        fontWeight: 900,
        color,
        textTransform: 'capitalize',
      }}
    >
      {String(item.criticidade).toLocaleLowerCase()}
    </span>
    <br />
    <span style={{ fontSize: '16px', fontWeight: 900 }}>{item.desvio}</span>
    <br />
    <div style={{ display: 'flex' }}>
      {getStatusColor(item?.status?.toUpperCase(), theme)}
    </div>
    <span style={{ fontSize: '14px', fontWeight: 400 }}>
      Empresa: <span style={{ fontWeight: 900 }}>{item?.nome}</span>
      {hasTorrePlus && (
        <div>
          Motorista:{' '}
          <span style={{ fontWeight: 900 }}>{item?.nome_motorista}</span>
        </div>
      )}
      <br />
      <div
        style={{
          margin: '4px 0',
          width: '100%',
          borderBottom: '1px solid #939aab',
        }}
      />
      <span style={{ fontSize: '14px', fontWeight: 400 }}>
        {formatNewHour(item.data_desvio)}
      </span>
      <br />
      <span style={{ fontSize: '14px', fontWeight: 400 }}>{item.endereco}</span>
    </span>
    <br />
    <br />
    {(hasTorrePlus || !isProvider) && (
      <button
        style={{
          border: 'none',
          background: 'inherit',
          padding: '4px',
          font: 'normal normal 14px/16px Texta',
          color: '#ff8040',
        }}
        id={`btn-remover-${index}`}
        value={index}
        type="button"
        onClick={() => window.open(`/desvios/${item.id}`)}
      >
        Resolver
      </button>
    )}
  </div>
);

export const InfoWindowLastPosition = ({ color, item }) => (
  <div
    style={{
      fontFamily: 'Texta',
      background: '#fff',
      color: '#4b5166',
    }}
  >
    <svg width="12" height="12" viewBox="0 0 24 30.581">
      <path
        d="M12,0A12.092,12.092,0,0,0,0,12.154c0,8.69,10.8,17.778,11.263,18.159a1.147,1.147,0,0,0,1.474,0C13.2,29.931,24,20.843,24,12.154A12.092,12.092,0,0,0,12,0m0,18.035a5.881,5.881,0,1,1,5.806-5.881A5.852,5.852,0,0,1,12,18.035"
        fill={color}
      />
    </svg>
    <span
      style={{
        fontSize: '14px',
        fontWeight: 900,
        color,
        textTransform: 'capitalize',
      }}
    >
      Última localização
    </span>
    <br />
    <span style={{ fontSize: '14px', fontWeight: 400 }}>
      Motorista: <span style={{ fontWeight: 900 }}>{item.name}</span>
      <br />
      <div
        style={{
          margin: '4px 0',
          width: '100%',
          borderBottom: '1px solid #939aab',
        }}
      />
      <span style={{ fontSize: '14px', fontWeight: 400 }}>
        {formatNewHour(item?.ultima_localizacao?.data)}
      </span>
      <br />
      <span style={{ fontSize: '14px', fontWeight: 400 }}>
        {item?.ultima_localizacao?.latitude},
        {item?.ultima_localizacao?.longitude}
      </span>
    </span>
    <br />
    <br />
  </div>
);
