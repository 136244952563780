import React from 'react';
import { useLocation } from 'react-router-dom';

import { Divider } from '@mui/material';
import Tabs from 'components/Tabs';
import Podium from 'components/Podium';
import InfoCard from 'components/Cards/InfoCard';
import Header from './components/Header';
import Resumo from './components/Resumo';
import { DefaultTable } from 'components/_Table/templates/default';
import FiltersGlobal from 'components/FiltersGlobal';
import { useFetchMultipleWithCache } from 'hooks/fetchFilters';
import { useSelector } from 'react-redux';
import { usePlans } from 'hooks/usePlans';

const Ranking = ({
  title,
  headerInfo,
  headerActions,
  headerTabs = null,
  podium = [],
  resumo = null,
  loading = false,
  infoMessage = null,
  tableProps = {},
  handleRefetch = () => {},
  handleFilters = () => {},
}) => {
  const {
    tiposDesvios,
    motoristasTipos,
    veiculos,
    filiais,
    clients,
    operacao,
    desviosTiposPadrao,
  } = useFetchMultipleWithCache();

  const selects = useSelector(state => state.selects);
  const { isProvider } = usePlans();
  const location = useLocation();

  const empresas =
    selects?.empresas?.map(i => {
      return { label: i.nome, value: i.id };
    }) || [];

  const fields = [
    {
      filterName: 'filial',
      label: 'filiais',
      filterName: 'filial',
      label: 'filiais',
      options: filiais,
    },
    {
      filterName: 'client',
      label: 'cliente',
      options: clients.data,
    },
    {
      filterName: 'desvio',
      label: 'Desvios',
      options: desviosTiposPadrao.data,
    },
    {
      filterName: 'motoristas',
      filterName: 'motoristas',
      label: 'Motorista',
      options: motoristasTipos,
    },
    {
      filterName: 'veiculos',
      label: 'Veículos',
      options: veiculos.data,
    },
    {
      filterName: 'operation',
      label: 'Operação',
      options: operacao,
    },
  ];

  let fieldsProvider = [
    {
      filterName: 'empresas',
      label: 'Empresas',
      options: empresas,
    },
    {
      filterName: 'desvio',
      label: 'Filtrar por desvios',
      options: desviosTiposPadrao.data,
    },
    {
      filterName: 'modorista',
      label: 'Motorista',
      options: motoristasTipos,
      multiple: false,
    },
    {
      filterName: 'veiculos',
      label: 'Veículos',
      options: veiculos.data,
      multiple: false,
    },
    {
      filterName: 'operation',
      label: 'Operação',
      options: operacao,
      multiple: false,
    },
  ];

  const renderFilters = () => {
    if (location.pathname !== '/empresas/ranking') {
      fieldsProvider = fieldsProvider.filter(
        item => item.filterName !== 'operacao',
      );
    }

    fieldsProvider;
    return (
      <FiltersGlobal
        persistDate
        handleExport={headerActions}
        data={isProvider ? fieldsProvider : fields}
        isFetching={loading}
        handleRefetch={handleRefetch}
        handleFilters={handleFilters}
      />
    );
  };

  return (
    <>
      <Header title={title} info={headerInfo} />
      {renderFilters()}
      {headerTabs && (
        <>
          <Tabs {...headerTabs} />
          <Divider />
        </>
      )}

      {infoMessage ? <InfoCard message={infoMessage} key="info" /> : <br />}

      <Podium data={podium} loading={loading} />
      <br />
      <DefaultTable {...tableProps} />

      {resumo && <Resumo data={resumo} />}
    </>
  );
};

export default Ranking;
