import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Componentes
import CustomBarChart from './Graphs/BarGraph';
import CustomLineChart from './Graphs/LineGraph';

import FiltersGlobal from 'components/FiltersGlobal';
import { useFetchMultipleWithCache } from 'hooks/fetchFilters';

// Service
import { toast } from 'react-toastify/dist';
import { Grid } from '@mui/material';
import { requestLayout, requestSelectsOptions } from '../services';
import * as S from './styled';
import { usePlans } from 'hooks/usePlans';
import { trackEvent } from 'utils/mixpanel';

const GraficosLayout = () => {
  const { isProvider } = usePlans();
  const { clients } = useFetchMultipleWithCache();

  const user = useSelector(state => {
    return state.auth?.user?.user;
  });

  const selects = useSelector(state => state.selects);

  const [filter, setFilter] = useState({});

  const [historico, setHistorico] = useState(null);
  const [tipoTab, setTipoTab] = useState('tipo');
  const [historicoAxis, setHistoricoAxis] = useState(['month']);
  const [historicoLoading, setHistoricoLoading] = useState(true);

  const [tempMedio, setTempMedio] = useState(null);
  const [tempMedioAxis, setTempMedioAxis] = useState(['month']);
  const [temploading, setTempLoading] = useState(true);

  const [tipo, setTipo] = useState(null);
  const [tipoLoading, setTipoLoading] = useState(true);

  const [status, setStatus] = useState(null);
  const [statusLoading, setStatusLoading] = useState(true);

  const [posto, setPosto] = useState(null);
  const [postoLoading, setPostoLoading] = useState(true);

  const [optionsFilters, setOptionsFilters] = useState({});

  const fetchSelectOptions = async () => {
    const res = await requestSelectsOptions();
    setOptionsFilters(res.data);
  };

  const fetchHistorico = async query => {
    setHistoricoLoading(true);
    const res = await requestLayout(
      isProvider ? '/provider/estatisticas-layout/' : '/estatisticas-layout/',
      '1',
      query,
    );
    if (res.data?.data) {
      setHistorico(res.data.data.caes);
      setHistoricoAxis(res.data.data.series);
    } else if (res.data?.message) toast.error(res.data.message);
    setHistoricoLoading(false);
  };

  const fetchTempMedio = async query => {
    setTempLoading(true);
    const res = await requestLayout(
      isProvider ? '/provider/estatisticas-layout/' : '/estatisticas-layout/',
      '2',
      query,
    );
    if (res.data?.data) {
      setTempMedio(res.data.data.data);
      setTempMedioAxis(res.data.data.series);
    } else if (res.data?.message) toast.error(res.data.message);
    setTempLoading(false);
  };

  const fetchTipo = async query => {
    setTipoLoading(true);
    const res = await requestLayout(
      isProvider ? '/provider/estatisticas-layout/' : '/estatisticas-layout/',
      '3',
      query,
    );
    if (res.data?.data) setTipo(res.data.data);
    else if (res.data?.message) toast.error(res.data.message);
    setTipoLoading(false);
  };

  const fetchStatus = async query => {
    setStatusLoading(true);
    const res = await requestLayout(
      isProvider ? '/provider/estatisticas-layout/' : '/estatisticas-layout/',
      '4',
      query,
    );
    if (res.data?.data) setStatus(res.data.data);
    else if (res.data?.message) toast.error(res.data.message);
    setStatusLoading(false);
  };

  const fetchPosto = async query => {
    setPostoLoading(true);
    const res = await requestLayout(
      isProvider ? '/provider/estatisticas-layout/' : '/estatisticas-layout/',
      '5',
      query,
    );
    if (res.data?.data) setPosto(res.data.data);
    else if (res.data?.message) toast.error(res.data.message);
    setPostoLoading(false);
  };

  useEffect(() => {
    fetchTipo({ ...filter, tip_tab: tipoTab });
  }, [filter, tipoTab]);

  useEffect(() => {
    fetchSelectOptions();
    fetchTempMedio(filter);
    fetchHistorico({ ...filter, tip_tab: 'geral' });
    fetchStatus(filter);
    fetchPosto(filter);
  }, [filter]);

  const renderHistoryGraph = () => (
    <div style={{ marginTop: '20px', position: 'relative' }}>
      <CustomLineChart
        data={historico || []}
        title="Histórico de CAEs abertas"
        loading={historicoLoading}
      />
    </div>
  );

  const renderTempMedioGraph = () => (
    <div style={{ marginTop: '20px', position: 'relative' }}>
      <CustomLineChart
        data={tempMedio}
        title="Histórico tempo médio conclusão (dias)"
        loading={temploading}
        dataKey="Tempo"
      />
    </div>
  );

  const renderTipoGraph = () => (
    <div style={{ marginTop: '20px' }}>
      <CustomBarChart
        title="Estatísticas"
        data={tipo}
        loading={tipoLoading}
        tabs={[
          { value: 'tipo', label: 'CAEs por tipo' },
          { value: 'base', label: 'CAEs por base' },
        ]}
        handleTabs={value => {
          trackEvent(user, `GRÁFICO LAYOUT DESCARGA: ${value}`);
          setTipoTab(value);
        }}
      />
    </div>
  );

  const renderStatusGraph = () => (
    <div style={{ marginTop: '20px' }}>
      <CustomBarChart
        title="CAEs por status"
        data={status}
        loading={statusLoading}
      />
    </div>
  );

  const renderPostoGraph = () => (
    <div style={{ marginTop: '20px' }}>
      <CustomBarChart
        title="Posto por status"
        data={posto}
        loading={postoLoading}
      />
    </div>
  );

  const empresas =
    selects?.empresas?.map(i => {
      return { label: i.nome, value: i.id };
    }) || [];

  const client =
    selects?.clients?.map(i => {
      return { label: i.nome, value: i.id };
    }) || [];

  const filtersDefault = [
    {
      filterName: 'client',
      label: 'Clientes',
      options: client || [],
    },
  ];

  const filtersDefaultProvider = [
    {
      filterName: 'typeClient',
      label: 'Tipo de Cliente',
      options:
        [
          {
            value: 'EMPRESA',
            label: 'Empresa',
          },
          {
            value: 'REVENDEDOR',
            label: 'Revendedor',
          },
        ] || [],
    },
    {
      filterName: 'typeCae',
      label: 'Tipo de Cae',
      options: optionsFilters?.tipos_cae || [],
    },
    {
      filterName: 'empresas',
      label: 'Empresa',
      options: empresas || [],
    },
    {
      filterName: 'estado',
      label: 'Estado',
      options: optionsFilters?.estado || [],
    },
  ];

  const renderMain = () => (
    <>
      <S.Container>
        <S.Main>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} xl={6}>
              {renderStatusGraph()}
            </Grid>

            <Grid item xs={12} md={6} xl={6}>
              {renderPostoGraph()}
            </Grid>

            <Grid item xs={12} md={12} xl={12} marginTop={2}>
              <FiltersGlobal
                data={isProvider ? filtersDefaultProvider : filtersDefault}
                handleFilters={setFilter}
                hideRefleshButton
                hideExportButton
                customComponent={
                  <S.Title>Estatísticas: Layouts de descarga</S.Title>
                }
              />
            </Grid>
            <Grid item xs={12} md={12} xl={12}>
              {renderTipoGraph()}
            </Grid>

            <Grid item xs={12} md={12} xl={12}>
              {renderHistoryGraph()}
            </Grid>

            <Grid item xs={12} md={12} xl={12}>
              {renderTempMedioGraph()}
            </Grid>
          </Grid>
        </S.Main>
      </S.Container>
    </>
  );

  return renderMain();
};

export default GraficosLayout;
