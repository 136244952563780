import React, { useRef, useState } from 'react';
import Loading from 'components/Loading';
import BorderColorIcon from '@mui/icons-material/BorderColorOutlined';
import { ReactComponent as PerfilIcon } from 'images/icons/perfil.svg';

import { firestorage } from 'utils/firebase';

import * as S from './styled';

export const Avatar = ({ link, loading, handleChange }) => {
  const inputFile = useRef(null);
  const [isSending, setIsSending] = useState(false);

  // Change Photo
  // Atenção o upload da foto nao garante a finalização do usuario
  // Isso pode gerar upload desnecessario de imagens no firebase
  const updateUserImage = async () => {
    const file = inputFile.current.files[0];
    if (file) {
      try {
        setIsSending(true);
        const dh = new Date().toDateString();
        if (!file) return toast.error('Não foi possível enviar sua imagem');

        const uploadTask = firestorage
          .ref(`/usuarios/${dh}_${file.name}`)
          .put(file);
        uploadTask.on(
          'state_changed',
          snapShot => {},
          err => {
            console.log('Err', err);
          },
          () => {
            firestorage
              .ref(`/usuarios/`)
              .child(`${dh}_${file.name}`)
              .getDownloadURL()
              .then(fireBaseUrl => {
                handleChange(fireBaseUrl);
              });
          },
        );
      } catch (error) {
        toast.error('Não foi possível enviar sua imagem');
      }
      setIsSending(false);
    }
  };

  return (
    <S.Container>
      <S.Circle>
        {loading || isSending ? (
          <Loading />
        ) : link ? (
          <img src={link || 'images/icons/perfil.svg'} alt="Avatar" />
        ) : (
          <PerfilIcon />
        )}
      </S.Circle>
      {handleChange instanceof Function && (
        <S.EditButton>
          <label>
            <input
              type="file"
              multiple={false}
              ref={inputFile}
              onChange={updateUserImage}
              disabled={loading}
            />
            <BorderColorIcon fontSize="8px" />
          </label>
        </S.EditButton>
      )}
    </S.Container>
  );
};
