import styled from 'styled-components';
import { SnackbarContent, CircularProgress } from '@mui/material'; // Certifique-se de que esta importação está correta.

export const SnackContainer = styled.div`
  background-color: #ffffff; /* Cor de fundo personalizada (laranja) */
  color: #fff; /* Cor do texto */
  padding: 16px;
  border-radius: 8px;
  font-size: 1rem;
  width: 400px;
  height: 96px; /* Ajuste de altura */
  display: flex;
  align-items: center; /* Alinha o conteúdo ao centro */
  border-bottom: 6px solid #ff3d03;
  position: fixed; /* Fixa o SnackContainer na tela */
  top: 16px; /* Distância do topo da tela */
  right: 12px; /* Distância da lateral direita da tela */
  z-index: 9999; /* Garante que o container esteja acima de outros elementos */
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
`;

export const LoadingAndTextContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  widht: 340px;
  flex-direction: row;
  margin-bottom: 0; /* Removi margem extra */
  justify-content: space-between; /* Para alinhar o texto e o CircularProgress */
  gap: 16px; /* Espaçamento entre o texto e o CircularProgress */
  padding-right: 16px; /* Adiciona um espaçamento à direita */
`;

export const StyledCircularProgress = styled(CircularProgress)`
  color: #939aab; /* Cor do círculo de preenchimento */
`;

export const Texto = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 25.2px;
  color: #424449;
  align: center;
`;

export const BotaoFechar = styled.button`
  padding-bottom: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 10px;
  transition: color 0.3s ease;
  width: 10px;
  height: 10px;
  margin-top: -25px; /* Ajuste o valor conforme necessário */

  &:focus {
    outline: none;
  }

  &:hover img {
    opacity: 0.7;
  }
`;
