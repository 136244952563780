import { useEffect, useState } from 'react';
import * as S from './styled';
import * as api from './services';

// Components
import Tabs from 'components/TabsSlider';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import SelectMultiple from './components/SelectMultiple';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Switch } from 'components/Inputs/Switch';
import Loading from 'components/Loading';
import { toast } from 'react-toastify';

export const Notificacoes = () => {
  // Notifications
  const [allNotificationsPermissions, setAllNotificationsPermissions] =
    useState([]);
  const [tabs, setTabs] = useState([]);
  const [currentTab, setCurrentTab] = useState(null);
  const [currentSelection, setCurrentSelection] = useState([]);
  const [loading, setLoading] = useState(false);

  const [switchStates, setSwitchStates] = useState({});

  const populateSwitchStates = permissions => {
    const newSwitchStates = {};
    Object.keys(permissions).forEach(key => {
      const notifications = permissions[key];
      notifications.forEach(notification => {
        newSwitchStates[notification.id] = {
          valor_plataforma: notification.valor_plataforma === 1,
          valor_push: notification.valor_push === 1,
          valor_email: notification.valor_email === 1,
          valor_som: notification.som === 1,
          ids_formularios: notification.ids_formularios || [],
          ids_desvios_tipos: notification.ids_desvios_tipos || [],
        };
      });
    });
    setSwitchStates(newSwitchStates);
  };

  const fetchPermissionsNotifications = async () => {
    setLoading(true);
    const res = await api.getNotifications();
    setAllNotificationsPermissions(res?.data);

    const t = Object.keys(res?.data || []).map(item => ({
      label: item,
      value: item,
    }));
    setCurrentTab(t[0]?.value);
    setTabs(t);

    setCurrentSelection(res?.data[t[0]?.value]);
    populateSwitchStates(res?.data);

    setLoading(false);
  };

  useEffect(() => {
    fetchPermissionsNotifications();
  }, []);

  useEffect(() => {
    if (currentTab) {
      setCurrentSelection(allNotificationsPermissions[currentTab]);
      populateSwitchStates({
        [currentTab]: allNotificationsPermissions[currentTab],
      });
    }
  }, [currentTab]);

  const handlePageTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleRemoveDesvio = async (notificationId, desvioId) => {
    setSwitchStates(prevState => ({
      ...prevState,
      [notificationId]: {
        ...prevState[notificationId],
        ids_desvios_tipos: prevState[notificationId].ids_desvios_tipos.filter(
          id => id !== desvioId,
        ),
      },
    }));

    const updatedDesvios = switchStates[
      notificationId
    ].ids_desvios_tipos.filter(id => id !== desvioId);

    const dataToSend = {
      id: notificationId,
      ...switchStates[notificationId],
      ids_desvios_tipos: updatedDesvios,
    };

    try {
      const res = await api.updateNotificationPermission(dataToSend);
      if (res?.success) {
        console.log('Desvio removido com sucesso:', res.message);
      }
    } catch (error) {
      console.error('Erro ao remover desvio:', error);
    }
  };

  const handleSwitchChange = async (id, type, value, notification) => {
    // Atualizando o estado local para refletir no front-end
    // Atualizando o estado local para refletir no front-end
    setSwitchStates(prevState => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        [type]: value,
        // Garantindo que os valores existentes sejam preservados
        valor_plataforma:
          type === 'valor_plataforma'
            ? value
            : prevState[id]?.valor_plataforma ??
              notification?.valor_plataforma ??
              false,
        valor_push:
          type === 'valor_push'
            ? value
            : prevState[id]?.valor_push ?? notification?.valor_push ?? false,
        valor_email:
          type === 'valor_email'
            ? value
            : prevState[id]?.valor_email ?? notification?.valor_email ?? false,
        valor_som:
          type === 'valor_som'
            ? value
            : prevState[id]?.valor_som ?? notification?.som ?? false,
        ids_formularios:
          type === 'ids_formularios'
            ? value
            : prevState[id]?.ids_formularios ??
              notification?.ids_formularios ??
              [],
        ids_desvios_tipos:
          type === 'ids_desvios_tipos'
            ? value
            : prevState[id]?.ids_desvios_tipos ??
              notification?.ids_desvios_tipos ??
              [],
        // Garantindo que os valores existentes sejam preservados
        valor_plataforma:
          type === 'valor_plataforma'
            ? value
            : prevState[id]?.valor_plataforma ??
              notification?.valor_plataforma ??
              false,
        valor_push:
          type === 'valor_push'
            ? value
            : prevState[id]?.valor_push ?? notification?.valor_push ?? false,
        valor_email:
          type === 'valor_email'
            ? value
            : prevState[id]?.valor_email ?? notification?.valor_email ?? false,
        valor_som:
          type === 'valor_som'
            ? value
            : prevState[id]?.valor_som ?? notification?.som ?? false,
        ids_formularios:
          type === 'ids_formularios'
            ? value
            : prevState[id]?.ids_formularios ??
              notification?.ids_formularios ??
              [],
        ids_desvios_tipos:
          type === 'ids_desvios_tipos'
            ? value
            : prevState[id]?.ids_desvios_tipos ??
              notification?.ids_desvios_tipos ??
              [],
      },
    }));

    // Preparando os dados para enviar para a API
    const dataToSend = {
      id,
      plataforma:
        type === 'valor_plataforma'
          ? value
          : switchStates[id]?.valor_plataforma ??
            notification?.valor_plataforma ??
            false,
      push:
        type === 'valor_push'
          ? value
          : switchStates[id]?.valor_push ?? notification?.valor_push ?? false,
      email:
        type === 'valor_email'
          ? value
          : switchStates[id]?.valor_email ?? notification?.valor_email ?? false,
      som:
        type === 'valor_som'
          ? value
          : switchStates[id]?.valor_som ?? notification?.som ?? false,
      ids_formularios:
        type === 'ids_formularios'
          ? value
          : switchStates[id]?.ids_formularios ??
            notification?.ids_formularios ??
            [],
      ids_desvios_tipos:
        type === 'ids_desvios_tipos'
          ? value
          : switchStates[id]?.ids_desvios_tipos ??
            notification?.ids_desvios_tipos ??
            [],
    };

    // Atualizando a notificação no back-end
    if (dataToSend) {
      const currentNot = currentSelection.find(cs => cs.id === id);

      if (currentNot) {
        currentNot.valor_plataforma = dataToSend.plataforma;
        currentNot.valor_push = dataToSend.push;
        currentNot.valor_email = dataToSend.email;
        currentNot.som = dataToSend.som;

        try {
          const res = await api.updateNotificationPermission(dataToSend);
          if (res?.success) {
            toast.success(res.message);
          }
        } catch (error) {
          console.error('Erro ao atualizar permissões de notificação', error);
        }
      } else {
        console.error(
          `Não foi possível encontrar a notificação com id ${id} no currentSelection.`,
        );
      }
    }
  };

  const getDefaultValueAll = notification => {
    return Boolean(
      (!notification.plataforma || notification.valor_plataforma) &&
        (!notification.push || notification.valor_push) &&
        (!notification.email || notification.valor_email),
    );
  };

  const getDefaultValue = value => {
    return value === null || value === undefined ? false : Boolean(value);
  };

  const hexToRGBA = (hex, opacity) => {
    hex = hex.replace('#', '');

    if (hex.length === 3) {
      hex = hex
        .split('')
        .map(char => char + char)
        .join('');
    }

    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };

  if (loading) return <Loading />;

  return (
    <S.Container>
      <Tabs
        value={currentTab}
        items={tabs}
        onChange={handlePageTab}
        width="700px"
      />
      <div className="boxBody">
        {currentSelection &&
          currentSelection.map((i, k) => (
            <Accordion
              key={k}
              sx={{
                boxShadow: 'none',
                border: 'none',
                backgroundColor: 'transparent',
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div className="notifyItem" key={k}>
                  <div className="notifyControls">
                    <Switch
                      check={getDefaultValueAll(i)}
                      setCheckbox={value =>
                        handleSwitchChange(i.id, 'all', value, i)
                      }
                      textOn=""
                      textOff=""
                    />
                    <div style={{ textAlign: 'left' }}>
                      <div className="formatTextCheck">
                        {i.titulo}{' '}
                        {i.titulo === 'Desvio Venceu' ? (
                          <div
                            style={{
                              color: i.cor,
                              background: hexToRGBA(i.cor, '0.1'),
                            }}
                            className="rotulo"
                          >
                            {i?.descricao}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <span className="notifyText">
                        {`${i.plataforma === 1 ? 'Plataforma ' : ''}`}{' '}
                        {`${i.push === 1 && i.plataforma === 1 ? '/' : ''}`}{' '}
                        {`${i.push === 1 ? 'Push ' : ''}`}
                        {`${
                          (i.push === 1 || i.plataforma === 1) && i.email === 1
                            ? '/'
                            : ''
                        }`}{' '}
                        {`${i.email === 1 ? 'E-mail ' : ''}`}
                      </span>
                    </div>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails sx={{ paddingLeft: 10 }}>
                {i?.valor_desvios_tipos && (
                  <SelectMultiple
                    label={
                      <div className="subtitle">
                        Escolha os desvios dos quais você será notificado:
                      </div>
                    }
                    value={i.ids_desvios_tipos}
                    data={i?.valor_desvios_tipos.map(item => {
                      return {
                        id: item.id,
                        value: item.titulo,
                        criticidade: item.criticidade,
                      };
                    })}
                    handleChange={values =>
                      handleSwitchChange(i.id, 'ids_desvios_tipos', values)
                    }
                  />
                )}

                <div className="tags-container">
                  {switchStates[i.id]?.ids_desvios_tipos.map(id_se => {
                    const desvio = i?.valor_desvios_tipos.find(
                      item => item.id === id_se,
                    );
                    return (
                      desvio && (
                        <div className="tags" key={desvio.id}>
                          {desvio.titulo}
                          {'  '}
                          <span
                            onClick={() => handleRemoveDesvio(i.id, desvio.id)}
                          >
                            x
                          </span>
                        </div>
                      )
                    );
                  })}
                </div>

                <div
                  className="subtitle"
                  style={{ marginTop: i?.valor_desvios_tipos ? 15 : 0 }}
                >
                  Escolha onde você irá receber notificações:
                </div>

                <div className="notifyControls space">
                  {i.plataforma === 1 && (
                    <Switch
                      check={getDefaultValue(i.valor_plataforma)}
                      setCheckbox={value =>
                        handleSwitchChange(i.id, 'valor_plataforma', value, i)
                      }
                      textOn="Notificações da plataforma Onisys."
                      textOff="Notificações da plataforma Onisys."
                    />
                  )}
                </div>

                <div className="notifyControls space">
                  {i.push === 1 && (
                    <Switch
                      check={getDefaultValue(i.valor_push)}
                      setCheckbox={value =>
                        handleSwitchChange(i.id, 'valor_push', value, i)
                      }
                      textOn="Notificações push enviadas diretamente ao seu dispositivo."
                      textOff="Notificações push enviadas diretamente ao seu dispositivo."
                    />
                  )}
                </div>

                <div className="notifyControls space">
                  {i.email === 1 && (
                    <Switch
                      check={getDefaultValue(i.valor_email)}
                      setCheckbox={value =>
                        handleSwitchChange(i.id, 'valor_email', value, i)
                      }
                      textOn="Notificações por e-mail."
                      textOff="Notificações por e-mail."
                    />
                  )}
                </div>

                <div className="space" style={{ width: '80%' }}>
                  {i?.valor_formulario && (
                    <SelectMultiple
                      label="Escolha os formulários:"
                      value={i.ids_formularios}
                      data={i?.valor_formulario.map(item => {
                        return {
                          id: item.id,
                          value: item.titulo,
                        };
                      })}
                      handleChange={values =>
                        handleSwitchChange(i.id, 'ids_formularios', values)
                      }
                    />
                  )}
                </div>
              </AccordionDetails>
            </Accordion>
          ))}
      </div>
    </S.Container>
  );
};
