import { addDays, addHours } from 'date-fns';
import { getDateGivenWeek } from 'utils/dates';

export const modalDataInformation = [
  {
    identifier: 'unavailable',
    title: 'Avaliações não Disponíveis',
    description: [
      'As avaliações indisponíveis são as que o vídeo do motorista ainda não foi disponibilizado ou não conseguimos acesso ao vídeo, consulte a coluna de status da avaliação para compreender o motivo da indisponibilidade. As avaliações são criadas no primeiro dia da semana.',
      'É possível arquivar as avaliações nesse status usando o botão de justificativa na coluna de ações caso não seja possível disponibilizar o vídeo. Caso a avaliação fique 60 dias com este status as avaliações são arquivadas de forma automática.',
    ],
  },
  {
    identifier: 'processing',
    title: 'Avaliações em Processamento',
    description: [
      'As avaliações em processamento são as que conseguimos baixar o vídeo e agora estamos processando e filtrando os desvios, normalmente o prazo para terminar o processamento é de 5 dias após o upload do vídeo.',
    ],
  },
  {
    identifier: 'pending',
    title: 'Avaliações Pendentes',
    description: [
      'As avaliações pendentes já passaram pela classificação do Onisys e já tiveram parte dos itens respondidos, agora é sua vez completar a avaliação para garantir que o motorista está seguindo todas as regras estabelecidas.',
      'Para mover a avaliação para o status de preenchida é necessário que todos os itens sejam preenchidos e que se programe uma data de feedback para o motorista, além disso os desvio com criticidade alta e média devem ser movidos para pendente.',
      'O prazo para preencher a avaliação antes que ela seja considerada atrasada é de 10 dias após a disponibilização da avaliação. Ao passar a avaliação para preenchida o sistema verifica se há alguma avaliação do motorista com os status anteriores e preenche seus itens de forma automática.',
      'É possível arquivar as avaliações nesse status caso as seguintes regras sejam respeitadas: Não há desvios com criticidade alta e média e o motorista já tenha pelo menos uma avaliação preenchida.  Caso a avaliação fique 45 dias com este status as avaliações são arquivadas de forma automática.',
    ],
  },
  {
    identifier: 'filled',
    title: 'Avaliações Preenchidas',
    description: [
      'As avaliações preenchidas estão aguardando a assinatura do motorista, neste status ainda é possível editar os itens da avaliação. Após o motorista assinar a avaliação os desvios são finalizados de forma automática.',
      'O motorista pode contestar a avaliação caso não concorde com algum item apontado.',
      'O prazo para assinar a avaliação antes que ela seja considerada atrasada é dia 10 dias após o preenchimento.',
      'É possível arquivar as avaliações nesse status caso as seguintes regras sejam respeitadas: Não há desvios com criticidade alta e média e o motorista já tenha pelo menos uma avaliação preenchida.  Caso a avaliação fique 45 dias com este status as avaliações são arquivadas de forma automática.',
    ],
  },
  {
    identifier: 'subscribed',
    title: 'Avaliações Assinadas',
    description: [
      'As avaliações assinadas pelos motoristas não podem mais ser editadas, neste status o motorista já concordou com os desvios e orientações apontados.',
    ],
  },
  {
    identifier: 'archived',
    title: 'Avaliações Arquivadas',
    description: [
      'Aqui ficam armazenadas as avaliações que foram arquivadas durante o processo, caso você queira recuperá-las basta usar o botão para recuperar a avaliação, daí a avaliação volta para o status em que ela foi arquivada. Não é possível editar o formulário neste status.',
    ],
  },
];

//-------------------------------------------
export const columnsUnavailable2 = [
  {
    header: 'Id',
    id: 'id',
    type: 'number',
    sort: true,
    width: 100,
  },
  {
    header: 'Motorista/Filial',
    id: 'motorista.nome',
    type: 'string',
    sort: true,
    subRow: {
      prefix: 'FIlial:',
      value: (_, item) => item?.filial?.nome,
    },
  },
  {
    header: 'Semana',
    id: 'semana',
    type: 'string',
    sort: true,
    subRow: {
      value: value => getDateGivenWeek(value),
    },
  },
  {
    header: 'Ultimo Upload',
    id: 'data_upload',
    type: 'date',
    sort: true,
    align: 'center',
  },
  {
    header: 'Status',
    id: 'log',
    type: 'string',
    sort: true,
    align: 'center',
    conditional: [
      {
        condition: value => !!value,
        style: theme => ({
          color: theme.palette.semantics.feedback.attention.natural,
          backgroundColor: theme.palette.semantics.feedback.attention.light,
          textAlign: 'center',
        }),
      },
    ],
  },
];

export const columnsProcessing2 = [
  {
    header: 'Id',
    id: 'id',
    type: 'number',
    sort: true,
    width: 100,
  },
  {
    header: 'Motorista/Filial',
    id: 'motorista.nome',
    type: 'string',
    sort: true,
    tooltip: true,
    subRow: {
      prefix: 'FIlial:',
      value: (_, item) => item?.filial?.nome,
    },
  },
  {
    header: 'Placa',
    id: 'caminhao.placa',
    type: 'string',
    sort: true,
    style: {
      textTransform: 'uppercase',
    },
  },
  {
    header: 'Data',
    id: 'data_avaliacao',
    type: 'date',
    sort: true,
    value: value => addHours(new Date(value), 3),
    subRow: {
      prefix: 'Semana:',
      value: (_, item) => item?.semana,
    },
  },
  {
    header: 'Semana',
    id: 'semana',
    type: 'string',
    sort: true,
    width: 200,
    subRow: {
      value: value => getDateGivenWeek(value),
    },
  },
  {
    header: 'Previsão',
    id: 'data_upload',
    type: 'date',
    align: 'center',
    value: value => (value ? addDays(new Date(value), 5) : 'Sem previsão'),
    sort: true,
    conditional: [
      {
        condition: value => !!value,
        style: theme => ({
          color: theme.palette.semantics.feedback.attention.natural,
          backgroundColor: theme.palette.semantics.feedback.attention.light,
          textAlign: 'center',
        }),
      },
    ],
  },
  {
    header: 'Responsável',
    id: 'avaliador.nome',
    type: 'string',
    sort: false,
    value: () => 'onisys',
    align: 'center',
  },
  {
    header: 'Status',
    id: 'status',
    type: 'string',
    align: 'center',
    value: value => `Em ${value}`,
    sort: true, // Red
  },
];

export const columnsPending2 = [
  {
    header: 'Id',
    id: 'id',
    type: 'number',
    sort: true,
    width: 100,
  },
  {
    header: 'Motorista/Filial',
    id: 'motorista.nome',
    type: 'string',
    sort: true,
    subRow: {
      prefix: 'FIlial:',
      value: (_, item) => item?.filial?.nome,
    },
  },
  {
    header: 'Data',
    id: 'data_avaliacao',
    type: 'date',
    sort: true,
    value: value => addHours(new Date(value), 3),
    subRow: {
      prefix: 'Semana:',
      value: (_, item) => item?.semana,
    },
  },
  {
    header: 'Previsão',
    id: 'data_pendente',
    type: 'date',
    align: 'center',
    value: value => (value ? addDays(new Date(value), 10) : 'Sem previsão'),
    sort: true,
    conditional: [
      {
        condition: value => !!value,
        style: theme => ({
          color: theme.palette.semantics.feedback.attention.natural,
          backgroundColor: theme.palette.semantics.feedback.attention.light,
          textAlign: 'center',
        }),
      },
    ],
  },
  {
    header: 'Pontuação',
    id: 'pontuacao',
    type: 'number',
    sort: true,
    align: 'center',
    sufix: 'Pontos',
    conditional: [
      {
        condition: () => true,
        style: theme => ({
          color: theme.palette.semantics.feedback.warning.natural,
          textAlign: 'center',
        }),
      },
    ],
  },
  {
    header: 'Placa',
    id: 'caminhao.placa',
    type: 'string',
    sort: true,
    style: {
      textTransform: 'uppercase',
    },
  },
];

export const columnsFilled2 = [
  {
    header: 'Id',
    id: 'id',
    type: 'number',
    sort: true,
    width: 100,
  },
  {
    header: 'Motorista/Filial',
    id: 'motorista.nome',
    type: 'string',
    sort: true,
    subRow: {
      prefix: 'FIlial:',
      value: (_, item) => item?.filial?.nome,
    },
  },
  {
    header: 'Data',
    id: 'data_avaliacao',
    type: 'date',
    sort: true,
    value: value => addHours(new Date(value), 3),
    subRow: {
      prefix: 'Semana:',
      value: (_, item) => item?.semana,
    },
  },
  {
    header: 'Previsão',
    id: 'data_finalizacao',
    type: 'date',
    align: 'center',
    value: (value, _) =>
      value ? addDays(new Date(value), 10) : 'Sem previsão',
    sort: true,
    conditional: [
      {
        condition: value => !!value,
        style: theme => ({
          color: theme.palette.semantics.feedback.attention.natural,
          backgroundColor: theme.palette.semantics.feedback.attention.light,
          textAlign: 'center',
        }),
      },
    ],
  },
  {
    header: 'Pontuação',
    id: 'pontuacao',
    type: 'number',
    sort: true,
    align: 'center',
    sufix: 'Pontos',
    conditional: [
      {
        condition: () => true,
        style: theme => ({
          color: theme.palette.semantics.feedback.warning.natural,
          textAlign: 'center',
        }),
      },
    ],
  },
  {
    header: 'Placa',
    id: 'caminhao.placa',
    type: 'string',
    sort: true,
    style: {
      textTransform: 'uppercase',
    },
  },
  {
    header: 'Responsável',
    id: 'avaliador.nome',
    type: 'string',
    sort: true,
  },
];

export const columnsSigned2 = [
  {
    header: 'Id',
    id: 'id',
    type: 'number',
    sort: true,
    width: 100,
  },
  {
    header: 'Motorista/Filial',
    id: 'motorista.nome',
    type: 'string',
    sort: true,
    subRow: {
      prefix: 'FIlial:',
      value: (_, item) => item?.filial?.nome,
    },
  },
  {
    header: 'Data',
    id: 'data_avaliacao',
    type: 'date',
    sort: true,
    value: value => addHours(new Date(value), 3),
    subRow: {
      prefix: 'Semana:',
      value: (_, item) => item?.semana,
    },
  },
  {
    header: 'Pontuação',
    id: 'pontuacao',
    type: 'number',
    sort: true,
    align: 'center',
    sufix: 'Pontos',
    conditional: [
      {
        condition: () => true,
        style: theme => ({
          color: theme.palette.semantics.feedback.warning.natural,
          textAlign: 'center',
        }),
      },
    ],
  },
  {
    header: 'Placa',
    id: 'caminhao.placa',
    type: 'string',
    sort: true,
    style: {
      textTransform: 'uppercase',
    },
  },
  {
    header: 'Responsável',
    id: 'avaliador.nome',
    type: 'string',
    sort: true,
  },
];

export const columnsArchived2 = [
  {
    header: 'Id',
    id: 'id',
    type: 'number',
    sort: true,
    width: 100,
  },
  {
    header: 'Motorista/Filial',
    id: 'motorista.nome',
    type: 'string',
    sort: true,
    subRow: {
      prefix: 'FIlial:',
      value: (_, item) => item?.filial?.nome,
    },
  },
  {
    header: 'Semana',
    id: 'semana',
    type: 'string',
    sort: true,
    subRow: {
      value: (_, item) => getDateGivenWeek(item?.semana),
    },
  },
  {
    header: 'Previsão',
    id: 'data_finalizacao',
    type: 'date',
    align: 'center',
    value: () => 'Sem previsão',
    sort: true,
    conditional: [
      {
        condition: () => true,
        style: theme => ({
          color: theme.palette.semantics.feedback.attention.natural,
          backgroundColor: theme.palette.semantics.feedback.attention.light,
          textAlign: 'center',
        }),
      },
    ],
  },
  {
    header: 'Pontuação',
    id: 'pontuacao',
    type: 'number',
    sort: true,
    align: 'center',
    sufix: 'Pontos',
    conditional: [
      {
        condition: () => true,
        style: theme => ({
          color: theme.palette.semantics.feedback.warning.natural,
          textAlign: 'center',
        }),
      },
    ],
  },
  {
    header: 'Placa',
    id: 'caminhao.placa',
    type: 'string',
    sort: true,
    style: {
      textTransform: 'uppercase',
    },
  },
  {
    header: 'Responsável',
    id: 'avaliador.nome',
    type: 'string',
    sort: true,
  },
];
