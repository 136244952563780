import styled from 'styled-components';

export const Container = styled.div`
  background: #f9fbfd;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 0.5rem;
  padding: 0.25rem 0.5rem;
  border: 1px solid #939aab4d;
  overflow: hidden;
  color: #4b5166;
  font: normal normal medium 14px/20px Texta;

  .criticidade {
    display: flex;
    align-items: center;
    font: normal normal 900 14px/20px Texta;
    text-transform: capitalize;
    letter-spacing: 0px;
  }
  .tipo-desvio {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font: normal normal 900 16px/20px Texta;
    letter-spacing: 0px;
  }
`;
