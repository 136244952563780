// Styled
import { useTheme } from 'styled-components';

// React
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

// Components
import Select from 'components/Inputs/Select';
import Calendar from 'components/Inputs/Calendar';
import DataHour from 'components/Inputs/DataHour';
import ConfirmModal from 'components/ConfirmModal';
import GhostButton from 'components/Buttons/Ghost';
import TextInput from 'components/Inputs/TextField';
import DropZoneCustom from 'components/Inputs/DropZoneCustom';
import InputAttachedFile from 'components/Inputs/InputAttachedFile2';

// Components MUI
import { Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { WarningAmber } from '@mui/icons-material';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import * as S from './styled';

// Utils
import { objetos, howToColor } from './constants';
import * as services from '../../services';

export const ModalDesvio = ({
  open,
  onClose,
  infos,
  evaluation,
  setRecharge,
  responseQuestions,
  obj,
  initialDate,
  finalDate,
}) => {
  const theme = useTheme();
  // Redux
  const selects = useSelector(state => {
    return state.selects;
  });

  const listaResponsaveis =
    selects.users?.map(item => {
      return { value: item.id, name: item.nome };
    }) || [];

  const tipo_desvio = infos?.questao.desvio_tipo;
  const [objeto, setObjeto] = useState('');
  const [outro, setOutro] = useState('');
  const [data_desvio, setDataDesvio] = useState(evaluation.hora_inicial);
  const [data_previsao, setDataPrevisao] = useState('');
  const [pda, setPda] = useState('');
  const [responsavel, setResponsavel] = useState('');
  const [evidencia, setEvidencia] = useState(null);
  const [video_evidencia, setVideoEvidencia] = useState(null);
  const [arquivo, setArquivo] = useState('');
  const [obs, setObs] = useState('');

  const [confirmModal, setConfirmModal] = useState(false);

  const addImage = item => {
    if (item?.[0].video) setVideoEvidencia(item[0].imagem_url);
    else setEvidencia(item?.[0].imagem_url);
  };

  const deleteImage = () => {
    setEvidencia(null);
  };

  const confirmCreateDesvio = async () => {
    if (!evidencia && !video_evidencia) {
      toast.error('Insira uma imagem para o desvio');
      setConfirmModal(false);
      return;
    }

    if (!data_desvio) {
      toast.error('Defina a data do desvio');
      setConfirmModal(false);
      return;
    }

    if (!data_previsao) {
      toast.error('Defina a previsão de conclusão do desvio');
      setConfirmModal(false);
      return;
    }

    if (!pda) {
      toast.error('Defina um plano de ação para o desvio');
      setConfirmModal(false);
      return;
    }

    if (!responsavel) {
      toast.error('Defina um responsável para o desvio');
      setConfirmModal(false);
      return;
    }

    const data = {
      semana: evaluation.semana,
      imagem: evidencia,
      video: video_evidencia,
      filial: evaluation.filial.nome,
      motorista: evaluation.motorista.nome,
      tipo_desvio: infos?.questao.desvio_tipo?.titulo,
      criticidade: infos?.questao.desvio_tipo?.criticidade,
      objeto,
      outro,
      data_desvio,
      data_previsao,
      plano_acao: pda,
      arquivo,
      responsavel,
      observacao: obs,
      pergunta: infos?.questao?.pergunta,
      id_tipo_desvio_padrao: infos?.questao.desvio_tipo?.id_desvio_tipo_padrao,
      id_do_usuario: responsavel,
      id_da_avaliacao: evaluation.id,
      id_da_questao: infos?.questao.id,
      id_do_motorista: evaluation.motorista.id,
      id_do_caminhao: evaluation.id_do_caminhao,
      id_da_distribuidora: evaluation.id_da_distribuidora,
      pontos: infos?.questao?.desvio_tipo?.pontos,
      categoria: 'DESCARGA',
    };

    setConfirmModal(false);
    const res = await services.createNewInfraction(data);
    if (res.success) {
      toast.success(res.message);

      Promise.all([
        responseQuestions(obj.id, obj.answer, obj.oldAnswer),
        setRecharge(true),
      ]).then(() => {
        onClose();
      });
    } else {
      toast.error(res.message);
    }
  };

  return (
    <S.ModalMui open={open} onClose={onClose}>
      <S.Container>
        <S.Wrapper>
          <S.Header>
            <div className="titleModal">
              <NoteAltOutlinedIcon
                htmlColor={theme.palette.brand.secondary.natural}
              />
              <span className="text">Criar Novo Desvio</span>
            </div>
            <div className="closeArea">
              <CloseIcon
                onClick={() => onClose()}
                style={{ cursor: 'pointer' }}
              />
            </div>
          </S.Header>
        </S.Wrapper>
        <Divider />

        <S.Wrapper>
          <S.Body>
            {/* Left */}
            <S.RightContent>
              <div className="content-two-grid">
                <div className="boxFlex">
                  <S.StyledLogoWrapper>
                    <S.StyledLogo backgroundImage={evaluation.motorista.foto}>
                      {!evaluation.motorista.foto && (
                        <h2>{evaluation.motorista.nome[0]}</h2>
                      )}
                    </S.StyledLogo>
                  </S.StyledLogoWrapper>

                  <S.StyledTextWrapper>
                    <h1>{evaluation.motorista.nome.toLowerCase()}</h1>
                    <p>Filial: {evaluation.motorista.filial?.nome}</p>
                  </S.StyledTextWrapper>
                </div>
                <TextInput label="Semana" value={evaluation.semana} disabled />
              </div>

              <div className="content-two-grid">
                <TextInput
                  label="Tipo de desvio"
                  value={infos?.questao.desvio_tipo?.titulo}
                  disabled
                />

                <TextInput
                  id="criticidade"
                  label="Criticidade"
                  value={infos?.questao.desvio_tipo?.criticidade}
                  InputProps={{ readOnly: true }}
                  variant="filled"
                  startIcon={
                    <WarningAmber
                      sx={{
                        color: howToColor(
                          infos?.questao.desvio_tipo?.criticidade,
                          theme,
                        ),
                      }}
                    />
                  }
                  fillcolor={howToColor(
                    infos?.questao.desvio_tipo?.criticidade,
                    theme,
                  )}
                />
              </div>

              <div className="leftBoxArea">
                <DropZoneCustom
                  data={
                    evidencia
                      ? { imagem_url: evidencia, legenda: '', video: false }
                      : video_evidencia
                      ? {
                          imagem_url: video_evidencia,
                          legenda: '',
                          video: true,
                        }
                      : null
                  }
                  multiple={false}
                  fileDir="desvios/imagens"
                  addFunction={addImage}
                  deleteFunction={deleteImage}
                  automaticSave
                  haveLegend={false}
                  label="Adicionar Imagens"
                  titleButton="Adicionar Imagens"
                  required
                  unicSize="170px"
                  noTopButton
                  imageVideoTypes
                />
              </div>

              <div className="leftBoxArea">
                <TextInput
                  InputProps={{ multiline: true }}
                  rows={3}
                  label="Observação"
                  value={obs}
                  onChange={e => setObs(e.target.value)}
                />
              </div>
            </S.RightContent>

            {/* RIGHT */}
            <S.LeftContent>
              <div className="content-two-grid">
                {(tipo_desvio.desvio_tipo_padrao.sigla === 'OSC' ||
                  tipo_desvio.desvio_tipo_padrao.sigla === 'ICO') && (
                  <Select
                    value={objeto || ''}
                    data={objetos}
                    handleChange={value => setObjeto(value.target.value)}
                    label="Objeto"
                    required
                  />
                )}
                {objeto === 'OUTRO' && (
                  <TextInput
                    label="Outro"
                    value={outro}
                    onChange={e => setOutro(e.target.value)}
                    required
                  />
                )}
              </div>
              <div className="content-two-grid one">
                <DataHour
                  value={data_desvio}
                  onChange={newValue => setDataDesvio(newValue)}
                  label="Data Desvio"
                  required
                  minDate={initialDate}
                  maxDate={finalDate}
                />
                <Calendar
                  value={new Date(data_previsao)}
                  onChange={e => setDataPrevisao(e)}
                  futureDate
                  pastDate={false}
                  label="Previsão de conclusão"
                  style={{ maxWidth: '100%' }}
                  required
                />
              </div>
              <div className="content-one-grid">
                <TextInput
                  InputProps={{ multiline: true }}
                  rows={2}
                  label="Plano de ação"
                  value={pda}
                  onChange={e => setPda(e.target.value)}
                  required
                />
              </div>

              <div className="leftBoxArea">
                <InputAttachedFile
                  idInput="file-attch-modal-desvios"
                  label="Anexar Arquivo"
                  inputLabel="Anexar Arquivo"
                  fileUrl={arquivo}
                  setFileUrl={value => setArquivo(value)}
                  fileDir="desvios/arquivos"
                />
              </div>

              <div className="content-one-grid">
                <Select
                  value={responsavel || ''}
                  data={listaResponsaveis}
                  handleChange={value => setResponsavel(value.target.value)}
                  label="Responsável"
                  required
                />
              </div>

              <div className="buttonArea">
                <GhostButton
                  customcolor={
                    theme?.palette?.semantics?.feedback?.attention?.natural
                  }
                  children="Voltar"
                  onClick={() => onClose()}
                />
                <GhostButton
                  children="Criar novo desvio"
                  style={{ marginLeft: '10px' }}
                  onClick={() => setConfirmModal(true)}
                />
              </div>
            </S.LeftContent>
          </S.Body>
        </S.Wrapper>

        <ConfirmModal
          open={confirmModal}
          handleClose={() => setConfirmModal(false)}
          title="Tem certeza que deseja criar desvio?"
          subtitle="O desvio criado será anexado na avaliação"
          buttonText="Confirmar"
          onClick={() => confirmCreateDesvio()}
        />
      </S.Container>
    </S.ModalMui>
  );
};
