import styled, { css } from 'styled-components';
import Button from '@mui/material/Button';

export const ButtonApply = styled(Button)`
  && {
    ${({ theme }) => css`
      height: 32px;
      background: #ff8040;
      border-radius: 4px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px 16px;
      gap: 4px;
      font-family: 'Texta';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 25px;
      color: #ffffff;
      text-transform: none;
      margin-right: 8px;
    `}
  }
`;
