import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { InputWrapper, StyledInput } from './styled';

export const Input = props => {
  return (
    <InputWrapper>
      <SearchIcon />
      <StyledInput {...props} />
    </InputWrapper>
  );
};
