import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { useTheme } from 'styled-components';

import { Grid } from '@mui/material';
import {
  InfoOutlined,
  NavigateBeforeRounded,
  NavigateNextRounded,
  WarningAmber,
} from '@mui/icons-material';
import AlertModal from 'components/AlertModal';
import TextInput from 'components/Inputs/TextField';
import { Select as SelectMotorista } from 'components/Inputs/SelectMotorista/templates/select';
import DropZoneCustom from 'components/Inputs/DropZoneCustom';
import ImageViolacao from '../../components/ImageViolacao';
import MapDesvio from '../../components/MapDesvio';
import { salvarDesvio } from '../../services';
import {
  getCriticidadeColor,
  getGrupoDesvio,
  getTipoDesvio,
} from '../../constants';

import { formatNewHourClean } from 'utils/dates';
import { usePlans } from 'hooks/usePlans';
import * as S from '../styled';

const Info = ({ infraction, refetch, formData, setFormData }) => {
  const imgLastPosition = require(`images/pin-motorista.png`);
  const imgCurrentPosistion = require(`images/icone-local.png`);

  const theme = useTheme();
  const [view, setView] = useState('video');
  const [confirmMotorista, setConfirmMotorista] = useState(null);
  const [loadingMotorista, setLoadingMotorista] = useState(false);
  const { isOpLogistico, isProvider, hasConfigAcaoSuspensao, hasTorrePlus } =
    usePlans();
  const hasImage = infraction?.motorista?.foto
    ? infraction.motorista.foto
    : false;

  const tipoDesvio = getTipoDesvio(infraction);
  const grupoDesvio = getGrupoDesvio(infraction);

  const addImage = item => {
    setFormData(prev => {
      return { ...prev, arquivo_evidencia: item?.[0]?.imagem_url || null };
    });
  };

  const deleteImage = item => {
    setFormData(prev => {
      return { ...prev, arquivo_evidencia: null };
    });
  };

  const editImage = item => {
    setFormData(prev => {
      return { ...prev, arquivo_evidencia: item?.[0].imagem_url };
    });
  };

  const handleAtribuirMotorista = async id_motorista => {
    setLoadingMotorista(true);

    const payload = { id_motorista };
    const res = await salvarDesvio([infraction.id], payload);
    if (res.data?.success) {
      refetch();
      toast.success(res.data?.message);
    } else if (res.data?.message) toast.error(res.data.message);

    setConfirmMotorista(null);
    setLoadingMotorista(false);
  };

  const renderMapa = () => {
    return (
      <Grid
        item
        xs={12}
        sm={12}
        display="flex"
        justifyContent="center"
        position="relative"
      >
        {(infraction?.avaliacao?.caminho_cloud ||
          infraction?.desvio_info?.video ||
          infraction?.desvio_info?.imagem ||
          infraction?.desvio_info?.frame_pos) &&
        view === 'video' ? (
          <>
            <ImageViolacao
              video={
                infraction?.avaliacao?.caminho_cloud ??
                infraction?.desvio_info?.video
              }
              imagem={infraction?.desvio_info?.imagem}
              frame_pos={infraction?.desvio_info?.frame_pos}
            />
            <S.AbsoluteIconButton
              sx={{
                right: -16,
              }}
              onClick={() => setView('mapa')}
            >
              <NavigateNextRounded />
            </S.AbsoluteIconButton>
          </>
        ) : (
          <>
            {infraction.latitude && view === 'mapa' && (
              <S.AbsoluteIconButton
                sx={{
                  left: -16,
                }}
                onClick={() => setView('video')}
              >
                <NavigateBeforeRounded />
              </S.AbsoluteIconButton>
            )}

            <MapDesvio
              position={{
                lat: parseFloat(infraction.latitude),
                lng: parseFloat(infraction.longitude),
              }}
              otherPosition={
                infraction.latitude_anterior
                  ? {
                      lat: parseFloat(infraction.latitude_anterior),
                      lng: parseFloat(infraction.longitude_anterior),
                    }
                  : null
              }
            />
          </>
        )}
      </Grid>
    );
  };

  const getEndereco = useCallback(infraction => {
    if (infraction?.endereco)
      return `${infraction.endereco} (lat: ${infraction.latitude}, lng: ${infraction.longitude})`;
    return infraction?.latitude
      ? `lat: ${infraction.latitude}, lng: ${infraction.longitude}`
      : 'Não informado';
  }, []);

  const getLastEndereco = useCallback(infraction => {
    if (infraction?.endereco)
      return `${infraction.endereco} ${
        infraction.latitude_anterior
          ? `(lat: ${infraction.latitude_anterior}, lng: ${infraction.longitude_anterior})`
          : ''
      }`;
    return infraction?.latitude_anterior
      ? `lat: ${infraction.latitude_anterior}, lng: ${infraction.longitude_anterior}`
      : 'Não informado';
  }, []);

  return (
    <S.ColumnWrapper>
      <Grid container columnSpacing={2} rowSpacing={1}>
        {!infraction?.latitude &&
        !infraction?.avaliacao?.caminho_cloud &&
        !infraction?.desvio_info?.video &&
        !infraction?.desvio_info?.imagem ? (
          <Grid item xs={12} sm={12}>
            <DropZoneCustom
              data={
                formData.arquivo_evidencia
                  ? {
                      imagem_url: formData.arquivo_evidencia,
                      legenda: '',
                      video: false,
                    }
                  : null
              }
              multiple={false}
              fileDir="desvios/evidencias"
              addFunction={addImage}
              deleteFunction={deleteImage}
              editFunction={editImage}
              automaticSave={false}
              noTopButton
              unicSize="200px"
            />
          </Grid>
        ) : (
          <Grid
            item
            xs={12}
            sm={12}
            display="flex"
            justifyContent="center"
            position="relative"
          >
            {infraction?.latitude ? (
              renderMapa()
            ) : (
              <ImageViolacao
                video={
                  infraction?.avaliacao?.caminho_cloud ??
                  infraction?.desvio_info?.video
                }
                imagem={infraction?.desvio_info?.imagem}
                frame_pos={infraction?.desvio_info?.frame_pos}
              />
            )}
          </Grid>
        )}

        <Grid item xs={12} sm={12}>
          <S.DriverContainer>
            {!infraction.id_motorista &&
            (!isProvider || isOpLogistico || hasTorrePlus) ? (
              <div className="info">
                <S.StyledTextWrapper>
                  <h2>Motorista não identificado</h2>
                  {infraction.status === 'ABERTO' && (
                    <SelectMotorista
                      onSelect={motorista => setConfirmMotorista(motorista)}
                    />
                  )}
                </S.StyledTextWrapper>
              </div>
            ) : (
              <div>
                <div className="info">
                  <S.StyledLogoWrapper>
                    <S.StyledLogo backgroundImage={hasImage}>
                      {!hasImage && (
                        <h2>
                          {!isProvider || isOpLogistico
                            ? infraction.motorista?.nome[0]
                            : infraction.empresa?.nome[0]}
                        </h2>
                      )}
                    </S.StyledLogo>
                  </S.StyledLogoWrapper>

                  <S.StyledTextWrapper>
                    <h3>
                      {!isProvider || isOpLogistico || hasTorrePlus
                        ? (infraction.motorista?.nome).toLowerCase()
                        : infraction.empresa?.nome?.toLowerCase()}
                    </h3>
                    {hasTorrePlus ? (
                      <p>
                        {`Empresa: ${
                          infraction?.empresa?.nome
                            ? infraction.empresa?.nome?.toLowerCase()
                            : '-'
                        }`}
                      </p>
                    ) : (
                      <p>
                        {isOpLogistico && !infraction.empresa
                          ? 'Desvio próprio'
                          : `Filial: ${
                              infraction.motorista?.filial?.nome ||
                              infraction.caminhao?.filial?.nome ||
                              'Não definida'
                            }`}
                      </p>
                    )}
                  </S.StyledTextWrapper>
                </div>
                {infraction?.desvio_info?.log_atribuicao_motorista && (
                  <div className="atr-info">
                    <InfoOutlined fontSize="14px" />
                    <span>
                      {
                        infraction?.desvio_info?.log_atribuicao_motorista
                          .descricao
                      }
                    </span>
                  </div>
                )}
              </div>
            )}
            {hasConfigAcaoSuspensao && (
              <div className="card">
                PONTOS AÇÕES E SUSPENSÕES
                <br />
                <span>{infraction?.pontuacao_atual || '0'}</span>
              </div>
            )}
          </S.DriverContainer>
        </Grid>

        <Grid item xs={12} sm={8}>
          <TextInput
            id="desvio"
            label="Desvio"
            value={infraction.desvio_tipo?.titulo ?? '-'}
            inputProps={{ readOnly: true }}
            variant="filled"
            title={`${infraction.desvio_tipo?.titulo}${
              infraction.desvio_tipo?.desvio_tipo_padrao
                ? `\n (${infraction.desvio_tipo.desvio_tipo_padrao.categoria}) ${infraction.desvio_tipo.desvio_tipo_padrao.titulo}`
                : ''
            }`}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextInput
            id="data_desvio"
            label="Data do desvio"
            value={formatNewHourClean(infraction.data_desvio, true)}
            inputProps={{ readOnly: true }}
            variant="filled"
          />
        </Grid>

        {(infraction.valor || infraction.valor === 0) && (
          <>
            <Grid item xs={12} sm={6} md={8}>
              <TextInput
                id="velocidade"
                label="Velocidade"
                value={`${infraction.valor} km/h`}
                inputProps={{ readOnly: true }}
                variant="filled"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextInput
                id="velocidade_via"
                label="Limite de velocidade"
                value={
                  infraction.desvio_tipo?.limiar || infraction.velocidade_via
                    ? `${
                        infraction.desvio_tipo?.limiar ||
                        infraction.velocidade_via
                      } km/h`
                    : 'Não informado'
                }
                inputProps={{ readOnly: true }}
                variant="filled"
              />
            </Grid>
          </>
        )}

        <Grid item xs={12} sm={6} md={8}>
          <TextInput
            id="pontos"
            label="Pontuação"
            value={`${infraction.pontos} pontos`}
            inputProps={{ readOnly: true }}
            variant="filled"
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <TextInput
            id="criticidade"
            label="Criticidade"
            value={infraction.criticidade}
            inputProps={{ readOnly: true }}
            variant="filled"
            startIcon={
              <WarningAmber
                sx={{
                  color: getCriticidadeColor(infraction.criticidade, theme),
                }}
              />
            }
            fillcolor={getCriticidadeColor(infraction.criticidade, theme)}
          />
        </Grid>

        {tipoDesvio != 'MANUAL' && (
          <>
            <Grid item xs={12} sm={8}>
              <TextInput
                id="veiculo"
                label="Tipo de Veículo"
                value={
                  infraction.caminhao?.tipo_veiculo?.tipo ?? 'Não Informado'
                }
                inputProps={{ readOnly: true }}
                variant="filled"
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextInput
                id="placa"
                label="Placa"
                value={infraction.caminhao?.placa}
                inputProps={{ readOnly: true }}
                variant="filled"
              />
            </Grid>

            <Grid item xs={12} sm={8}>
              <TextInput
                id="filial_frota"
                label="Filial da frota"
                value={infraction.caminhao?.filial?.nome}
                inputProps={{ readOnly: true }}
                variant="filled"
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextInput
                id="id_frota"
                label="ID da frota"
                value={infraction.caminhao?.frota || 'Não Informado'}
                inputProps={{ readOnly: true }}
                variant="filled"
              />
            </Grid>
          </>
        )}

        {tipoDesvio === 'CERCA' && (
          <Grid item xs={12} sm={12}>
            <TextInput
              id="cerca"
              label="Título da cerca eletrônica"
              value={infraction.tipo_desvio?.titulo ?? '-'}
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>
        )}

        {(infraction?.endereco || infraction?.latitude) && (
          <Grid item xs={12} sm={12}>
            <TextInput
              startIcon={
                <img
                  src={imgCurrentPosistion}
                  alt="icon localizacao"
                  height={30}
                  width={24}
                />
              }
              id="local"
              label="Localização"
              value={getEndereco(infraction)}
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>
        )}

        <Grid item xs={12} sm={12}>
          <TextInput
            startIcon={
              <img
                src={imgLastPosition}
                alt="icon ultima localizacao"
                height={36}
                width={36}
              />
            }
            id="endereco"
            label="Última localização antes do desvio"
            value={getLastEndereco(infraction)}
            inputProps={{ readOnly: true }}
            variant="filled"
          />
        </Grid>

        {(typeof infraction.carregado === 'boolean' ||
          typeof infraction.pista_molhada === 'boolean') && (
          <Grid item xs={12} sm={12}>
            <TextInput
              id="condicoes"
              label="Condições"
              value={`${
                infraction.carregado
                  ? 'Veículo carregado; '
                  : infraction.carregado === false
                  ? 'Veículo vazio; '
                  : 'Não Informado; '
              }${
                infraction.pista_molhada
                  ? 'Pista molhada;'
                  : infraction.pista_molhada === false
                  ? 'Pista seca;'
                  : ''
              }`}
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>
        )}

        {(infraction.status === 'DELETADO' ||
          infraction.aprovado_distribuidora !== null) && (
          <Grid item xs={12} sm={12}>
            <TextInput
              id="motivoexclusao"
              label="Motivo da exclusão"
              value={infraction.desvio_info?.motivo_exclusao}
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>
        )}
      </Grid>
      {confirmMotorista && (
        <AlertModal
          open={!!confirmMotorista}
          handleClose={() => setConfirmMotorista(null)}
          title="Essa ação é irreversível!"
          loading={loadingMotorista}
          backButtonText="Cancelar"
          subtitle={
            <p>
              Deseja mesmo atribuir o motorista{' '}
              <span>{String(confirmMotorista?.nome || '').toLowerCase()}</span>
              ?<br />
              Essa ação não poderá ser desfeita.
            </p>
          }
          buttonText="Continuar"
          onClick={() => handleAtribuirMotorista(confirmMotorista.value)}
        />
      )}
    </S.ColumnWrapper>
  );
};

export default Info;
