import styled from 'styled-components';
import colors from 'styles/colors';

export const Container = styled.div`
  .boxLoading {
    position: relative;
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }
`;
export const GridCustom = styled.div`
  display: flex;
  flex-direction: column;
  background: ${props => (props.background ? props.background : '#edf2f9')};
  border: 1px solid ${props => (props.border ? props.border : '#939aab4d')};
  border-radius: 4px;
  padding: ${props => (props.padding ? props.padding : 0)}px;
`;

export const Criticidade = styled.div`
  width: 150px;
  padding: 4px;
  display: flex;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  background-color: ${props => `${props.color}33`};
  margin-bottom: 14px;
  p {
    color: ${props => props.color};
    font: normal normal bold 14px/14px Texta;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  min-width: '100%';
  .boxHeader {
    display: flex;
    overflow-x: auto;

    .headerItem {
      background-color: #fff;
      color: ${colors.greyTitle};
      opacity: 0.8;
      font-weight: 600;
      font-size: 16px;
      padding: 10px 40px;
      border: 1px solid #939aab4d;
      border-bottom: none;
      border-right: none;
      transition: all ease 0.5s;
      cursor: pointer;
      box-shadow: 0px -1px 10px #939aab4d;
    }

    .headerItem:last-child {
      border-right: 1px solid #939aab4d;
      border-top-right-radius: 5px;
    }

    .headerItem:first-child {
      border-top-left-radius: 5px;
    }

    .active {
      background-color: #efefef;
    }
  }

  .boxBody {
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    .subtitle {
      text-align: left;
      font: normal normal 900 13px/14px Texta;
      letter-spacing: 0px;
      color: #656e8c;
      text-transform: uppercase;
      opacity: 1;
    }

    .switchTitle {
      text-align: left;
      font: normal normal medium 14px/20px Texta;
      letter-spacing: 0px;
      color: #656e8c;
      opacity: 1;
      margin-top: 20px;
      margin-bottom: 16px;
    }
  }

  .cliente {
    border: 1px solid #939aab4d;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

export const ClienteTitle = styled.div`
  margin-top: 41px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  p {
    margin-right: 10px;
    text-align: left;
    font: normal normal 900 13px/14px Texta;
    letter-spacing: 0px;
    color: #656e8c;
    text-transform: uppercase;
    opacity: 1;
  }
`;

export const Footer = styled.div`
  margin-top: 15px;
  margin-right: 25px;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: end;
`;
