import React from 'react';
import { Grid } from '@mui/material';
import Card from 'components/Cards/Indicador';

const Cards = ({
  cards = [],
  selectedCard,
  disableCards,
  loadingCards,
  handleClickCard,
}) => {
  const nCards = cards.length;

  // Tamanho dinamico dos cards
  // lg: tela > 1200px
  // md: tela > 800px
  let lg = 12 / nCards;
  if (lg > 4) lg = 4; // tamanho nim = 1/3
  else if (lg < 2.4) lg = 2.4; // tamanho max = 1/5

  // Tela pequena
  const xs = lg >= 4 ? lg : 4;

  return (
    <Grid
      container
      spacing={2}
      sx={{ marginTop: '15px', marginBottom: '15px' }}
    >
      {loadingCards ? (
        <Card loading={loadingCards} />
      ) : (
        cards.map(card => {
          return (
            <Grid item xs={xs} md={lg}>
              <Card
                key={card.type}
                value={card.value}
                icon={card.icon}
                text={card.text}
                smalltype={card.smalltype}
                handleClick={() => {
                  if (handleClickCard instanceof Function)
                    handleClickCard(card.type);
                }}
                selected={selectedCard === card.type}
                disabled={disableCards || card.disabled}
                loading={card.loading}
              />
            </Grid>
          );
        })
      )}
    </Grid>
  );
};

export default Cards;
