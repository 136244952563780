import styled, { css } from 'styled-components';

export const TitleWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  margin-bottom: 10px;
`;

export const Paragraph = styled.p`
  ${({ theme, ...props }) => css`
    font-size: ${props.fontsize ? props.fontsize : '16px'};
    color: ${props.color ? props.color : theme.palette.brand.primary.light};
    font-weight: ${props.fontweight ? props.fontweight : 400};
    min-width: ${props.minwidth};
    text-align: ${props.center ? 'center' : 'start'};

    &.status {
      background-color: ${theme.palette.semantics.feedback.warning.light};
      color: ${theme.palette.semantics.feedback.warning.natural};
      border-radius: 10px;
    }
  `}
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const ColDriver = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    text-transform: capitalize;

    h1 {
      font-size: 14px;
      font-weight: 600;
      color: ${theme.palette.brand.primary.natural};
    }
  `}
`;
