import styled, { keyframes } from 'styled-components';
import colors from 'styles/colors';

export const VideoCounter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  border-radius: 5px;

  width: 100%;
  height: 500px;
  position: relative;

  label {
    font-weight: bold;
  }

  .video-progress {
    background-image: linear-gradient(
      to right,
      rgba(0, 0, 55, 20) 0%,
      rgba(0, 0, 10, 20) 20%,
      rgba(0, 0, 40, 20) 80%,
      rgba(0, 0, 55, 20) 100%
    );
    border-radius: 4px;
    color: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    animation: placeholderShimmer 3s linear infinite forwards;

    @keyframes placeholderShimmer {
      0% {
        background-position: -100rem 0;
      }

      100% {
        background-position: 70rem 0;
      }
    }

    .bar {
      width: 500px;
      background: #fff;
      border-radius: 8px;
      height: 8px;
      margin-top: 10px;
    }
    .progress {
      background: #ff8040;
      border-radius: 8px;
      height: 8px;
    }
  }
`;

export const VideoUploading = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${props => (props.isError ? colors.redDanger : colors.grey)};
  border-radius: 4px;
  color: ${props => (props.isError ? colors.redDanger : colors.grey)};
  padding: 15px;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  user-select: none;
  width: 100%;
  cursor: pointer;
  transition: all 0.1s ease-in-out;

  .MuiSvgIcon-root {
    transition: all 0.1s ease-in-out;
    color: ${props => (props.isError ? colors.redDanger : colors.grey)};
  }

  &:hover {
    background-color: ${colors.greenMainBackground};
    border: 2px solid ${colors.greenMain};
    color: ${colors.greenMain};

    .MuiSvgIcon-root {
      color: ${colors.greenMain};
    }
  }

  input {
    display: none;
  }

  label {
    margin-left: 10px;
    font-weight: bold;
    cursor: pointer;
  }
`;

export const Container = styled.div`
  width: 100%;
  padding: 5px 40px;
`;

export const MediaArchive = styled.div`
  display: flex;
  justify-content: center;
  padding: 5px 40px;
  width: 100%;
`;

export const ContentButton = styled.div`
  padding: 0px 40px;
  width: 100%;
`;

export const Label = styled.label`
  font-weight: 400;
  color: ${colors.greySubtitle};
`;
