import React from 'react';
import { TextField, InputAdornment, FormHelperText } from '@mui/material';
import { useTheme } from 'styled-components';
import { InputWrapper } from './styled';

const TextInput = React.forwardRef(
  (
    {
      value = '',
      onChange = null,
      label,
      placeholder,
      name,
      required,
      message,
      isValid = false,
      disabled = false,
      id,
      startIcon,
      endIcon,
      labelIcon = null,
      fillcolor,
      style,
      noAsterisk,
      color = 'secondary',
      ...props
    },
    ref,
  ) => {
    const theme = useTheme();
    return (
      <InputWrapper fillcolor={fillcolor}>
        <p
          style={{
            color: !props.error
              ? theme.palette.words.subtitle.natural
              : theme.palette.semantics.feedback.attention.natural,
            font: 'normal medium 14px/20px Texta',
          }}
        >
          {label}
          {labelIcon}
          {!noAsterisk && (
            <span
              style={{
                fontSize: '20px',
                fontWeight: 500,
                color: required
                  ? theme.palette.semantics.feedback.attention.dark
                  : theme.palette.system.transparent,
              }}
            >
              *
            </span>
          )}
          {}
        </p>
        <TextField
          {...props}
          ref={ref}
          color={color}
          style={{
            width: '100%',
            background: theme.palette.system.highlight,
            ...style,
          }}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          name={name}
          disabled={disabled === true}
          id={id}
          InputProps={{
            ...props.InputProps,
            startAdornment: startIcon ? (
              <InputAdornment position="end">{startIcon}</InputAdornment>
            ) : (
              ''
            ),
            endAdornment: endIcon ? (
              <InputAdornment position="end">{endIcon}</InputAdornment>
            ) : (
              ''
            ),
          }}
          sx={{
            '.MuiOutlinedInput-notchedOutline': {
              border: disabled
                ? 'none'
                : `2px solid ${theme.palette.brand.primary.light}`,
            },
            '.MuiOutlinedInput-notchedOutline.error': {
              border: `2px solid ${theme.palette.semantics.feedback.attention.natural}`,
            },
            '.MuiInputBase-input.MuiOutlinedInput-input': {
              color: theme.palette.words.subtitle.natural,
              font: 'normal normal 16px/23px Texta',
              opacity: 1,
            },
            '.Mui-disabled': {
              backgroundColor: theme.palette.semantics.feedback.unknown.light,
              borderRadius: '5px',
            },
          }}
        />
        {props?.inputProps?.maxLength && (
          <FormHelperText style={{ width: '100%', textAlign: 'right' }}>
            {`${value?.length || 0}/${props?.inputProps?.maxLength} caracteres`}
          </FormHelperText>
        )}
        {message && (
          <span
            style={{
              color: theme.palette.semantics.feedback.attention.dark,
              margin: '10px 0',
            }}
          >
            {message}
          </span>
        )}
        {isValid && (
          <div
            style={{
              display: isValid ? 'block' : 'none',
              color: theme.palette.semantics.feedback.attention.dark,
              textTransform: 'capitalize',
              margin: '10px 0',
            }}
          >
            {isValid}
          </div>
        )}
      </InputWrapper>
    );
  },
);

export default TextInput;
