import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { endOfDay, format, startOfMonth, subHours, subMonths } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import * as S from './styled';
import { requestDriversHistorico } from './services';
import FiltersGlobal from 'components/FiltersGlobal';
import { History } from 'components/Graphs/History';
import { CustomizedSelect } from 'components/SelectCustom';
import { usePlans } from 'hooks/usePlans';

const getData = data => {
  if (data?.length)
    return data?.map(item => {
      const { ano, mes, x } = item;
      const date = new Date(ano, mes - 1, 1);
      const formatDate = format(date, 'MMM yyyy', { locale: ptBR });
      return {
        Valor: Number(x) ? Number(x) : 0,
        name: formatDate.charAt(0).toUpperCase() + formatDate.slice(1),
      };
    });

  return [];
};

export const Historico = () => {
  const { hasMonitoramento } = usePlans();
  const [filter, setFilter] = useState({
    finalDate: endOfDay(new Date()),
    initialDate: startOfMonth(subMonths(new Date(), 11)),
    label: 'motoristas_avaliados',
  });

  const { isFetching, data } = useQuery(
    ['empresas-historico', filter],
    () =>
      filter &&
      requestDriversHistorico({
        ...filter,
        initialDate: subHours(filter.initialDate, 3),
        finalDate: subHours(filter.finalDate, 3),
      }),
    {
      refetchOnWindowFocus: false,
    },
  );

  const selectItens = useMemo(() => {
    const itens = [
      { value: 'motoristas_avaliados', label: 'Motoristas avaliados' },
      { value: 'atendimento_com_sla', label: 'Atendimentos com SLA' },
    ];
    if (hasMonitoramento)
      itens.splice(1, 0, {
        value: 'horas_inspecionadas',
        label: 'Horas inspecionadas',
      });

    return itens;
  }, [hasMonitoramento]);

  const selects = useSelector(state => state.selects);
  const empresasFilter =
    selects?.empresas?.map(i => {
      return { label: i.nome, value: i.id };
    }) || [];

  // Filtros utilizados
  const filterList = [
    {
      filterName: 'empresas',
      label: 'Empresas',
      options: empresasFilter || [],
    },
  ];

  const select = (
    <CustomizedSelect
      size="small"
      items={selectItens}
      selected={filter.label}
      handelSelect={value => {
        setFilter(filter => ({ ...filter, label: value }));
      }}
    />
  );

  const headerComponent = (
    <S.Header>
      <h1>Estatíticas</h1>
      <FiltersGlobal
        hideExportButton
        hideRefleshButton
        customComponent={select}
        isFetching={isFetching}
        handleFilters={filter => {
          setFilter(state => ({ ...state, ...filter, label: state.label }));
        }}
        datePickerType="fixed"
        defaultDate={{
          initialDate: filter?.initialDate,
          finalDate: filter?.finalDate,
        }}
        data={filterList}
      />
    </S.Header>
  );

  return (
    <>
      <History
        titulo=""
        data={data || []}
        series={['Valor']}
        series_divs={[]}
        loading={isFetching}
        hidePeriods
        formatRenderData={getData}
        headerComponent={headerComponent}
        initialDate={filter?.initialDate}
        finalDate={filter?.finalDate}
      />
    </>
  );
};
