import CircleIcon from '@mui/icons-material/Circle';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Tooltip } from '@mui/material';
import * as S from './styled';
import { useTheme } from 'styled-components';

export const categorias = [
  { value: 'geral', label: 'Geral' },
  { value: 'cabine', label: 'Cabine', plan: 140 },
  { value: 'telemetria', label: 'Telemetria', plan: 100 },
  { value: 'fadiga', label: 'Fadiga', plan: 110 },
  { value: 'manual', label: 'Manual' },
  { value: 'descarga', label: 'Descarga', plan: 140 },
];

const sharedCols = {
  posicao: {
    header: 'Pos',
    id: 'posicao',
    accessor: d => (d.posicao ? parseInt(d.posicao) : '-'),
    sort: true,
    width: 60,
    align: 'center',
    children: (value, item) => {
      const theme = useTheme();

      if (!item.posicao_anterior || item.posicao_anterior > item.posicao)
        return (
          <S.TableName center className="position">
            <Tooltip
              title={`Posição anterior: ${item.posicao_anterior}`}
              arrow
              placement="top"
            >
              <ArrowDropUpIcon
                htmlColor={theme.palette.semantics.feedback.success.natural}
              />
            </Tooltip>
            <span>{item.posicao}</span>
          </S.TableName>
        );
      if (item.posicao_anterior < item.posicao)
        return (
          <S.TableName center className="position">
            <Tooltip
              title={`Posição anterior: ${item.posicao_anterior}`}
              arrow
              placement="top"
            >
              <ArrowDropDownIcon
                htmlColor={theme.palette.semantics.feedback.attention.natural}
              />
            </Tooltip>
            <span>{item.posicao}</span>
          </S.TableName>
        );
      if (item.posicao_anterior == item.posicao)
        return (
          <S.TableName center className="position">
            <CircleIcon
              className="svg"
              htmlColor={theme.palette.semantics.feedback.information.natural}
            />
            <span>{item.posicao}</span>
          </S.TableName>
        );
    },
  },
  nome_empresa: {
    header: 'Empresa',
    id: 'nome_empresa',
    sort: true,
    tooltip: true,
    type: 'string',
    picture: 'foto',
    width: 300,
  },
  pontos_km: {
    header: 'Pontos/Km',
    id: 'pontos_km',
    sort: true,
    type: 'number',
    align: 'center',
    value: value =>
      Number(value).toLocaleString(undefined, {
        maximumFractionDigits: 2,
      }),
    conditional: [
      {
        condition: (value, item) => Number(item.pontos_km) > Number(item.media),
        style: theme => ({
          backgroundColor: theme.palette.semantics.feedback.attention.light,
          color: theme.palette.semantics.feedback.attention.natural,
          textAlign: 'center',
        }),
      },
      {
        condition: (value, item) =>
          Number(item.pontos_km) <= Number(item.media),
        style: theme => ({
          backgroundColor: theme.palette.semantics.feedback.success.light,
          color: theme.palette.semantics.feedback.success.natural,
          textAlign: 'center',
        }),
      },
    ],
  },
  total_kms: {
    header: 'Km',
    id: 'total_kms',
    sort: true,
    align: 'center',
    value: (value, item) => item.total_kms_formatado ?? '-',
    children: val => (
      <S.TableName style={{ textTransform: 'uppercase' }}>{val}</S.TableName>
    ),
  },
  total_pontos: {
    header: 'Pontos',
    id: 'total_pontos',
    sort: true,
    align: 'center',
    value: (value, item) => item.total_pontos_formatado ?? '-',
    children: val => (
      <S.TableName style={{ textTransform: 'uppercase' }}>{val}</S.TableName>
    ),
  },
  quantidade: {
    header: 'QTD Desvios',
    id: 'quantidade',
    sort: true,
    type: 'number',
    align: 'center',
  },
  total_desvios_graves: {
    header: 'Desvios Graves/Gravíssimos',
    id: 'total_desvios_graves',
    sort: true,
    type: 'number',
    align: 'center',
  },
  fator_risco: {
    header: 'Fator de Risco',
    id: 'fator_risco',
    sort: true,
    type: 'string',
    align: 'center',
  },
};

export const columns = {
  geral: [
    sharedCols.posicao,
    sharedCols.nome_empresa,
    {
      header: 'Total de pontos',
      id: 'total_pontos',
      sort: true,
      type: 'number',
      align: 'center',
      value: (value, item) => item.total_pontos_formatado ?? '-',
      conditional: [
        {
          condition: (value, item) =>
            Number(item.total_pontos) > Number(item.media),
          style: theme => ({
            backgroundColor: theme.palette.semantics.feedback.attention.light,
            color: theme.palette.semantics.feedback.attention.natural,
            textAlign: 'center',
          }),
        },
        {
          condition: (value, item) =>
            Number(item.total_pontos) <= Number(item.media),
          style: theme => ({
            backgroundColor: theme.palette.semantics.feedback.success.light,
            color: theme.palette.semantics.feedback.success.natural,
            textAlign: 'center',
          }),
        },
      ],
    },
    {
      header: 'Motoristas',
      id: 'motoristas',
      sort: true,
      type: 'number',
      align: 'center',
      value: value =>
        value
          ? Number(value).toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })
          : '-',
    },
    {
      header: 'Deslocamento',
      id: 'total_kms',
      sort: true,
      align: 'center',
      plans: [100],
      value: (value, item) => item.total_kms_formatado ?? '-',
      children: val => (
        <S.TableName style={{ textTransform: 'uppercase' }}>{val}</S.TableName>
      ),
    },
    {
      header: 'Horas',
      id: 'total_horas',
      sort: true,
      type: 'number',
      align: 'center',
      plans: [140],
    },
    {
      header: 'Ptos/Km',
      id: 'pontos_km',
      sort: true,
      align: 'center',
      plans: [100],
      value: (value, item) => item.pontos_km_formatado ?? '-',
      children: val => {
        return (
          <S.TableName style={{ textTransform: 'uppercase' }}>
            {val}
          </S.TableName>
        );
      },
    },
    {
      header: 'Ptos/Hr',
      id: 'pontos_hora',
      sort: true,
      align: 'center',
      plans: [140],
      value: (value, item) => item.pontos_hora_formatado ?? '-',
      children: val => (
        <S.TableName style={{ textTransform: 'uppercase' }}>{val}</S.TableName>
      ),
    },
    {
      header: 'Percentual Desvios finalizados no prazo',
      id: 'finalizados_no_prazo',
      sort: true,
      type: 'number',
      align: 'center',
      width: 200,
      value: value => `${value}%`,
      subRow: {
        prefix: 'Desvios excluidos:',
        value: (_, item) => `${item?.desvios_deletados}%`,
      },
    },
  ],
  cabine: [
    sharedCols.posicao,
    sharedCols.nome_empresa,
    {
      header: 'Pontos/Hora',
      id: 'pontos_hora',
      sort: true,
      type: 'number',
      align: 'center',
      tabs: ['cabine'],
      value: value => Math.floor(Number(value) * 100) / 100,
      conditional: [
        {
          condition: (value, item) =>
            Number(item.pontos_hora) > Number(item.media),
          style: theme => ({
            backgroundColor: theme.palette.semantics.feedback.attention.light,
            color: theme.palette.semantics.feedback.attention.natural,
            textAlign: 'center',
          }),
        },
        {
          condition: (value, item) =>
            Number(item.pontos_hora) <= Number(item.media),
          style: theme => ({
            backgroundColor: theme.palette.semantics.feedback.success.light,
            color: theme.palette.semantics.feedback.success.natural,
            textAlign: 'center',
          }),
        },
      ],
    },
    {
      header: 'Hrs Avaliadas',
      id: 'total_horas',
      sort: true,
      type: 'number',
      align: 'center',
      tabs: ['cabine'],
    },
    sharedCols.total_pontos,
    {
      header: 'Motoristas avaliados',
      id: 'total_motoristas_avaliados',
      sort: true,
      type: 'number',
      align: 'center',
      value: value => `${value}`,
    },
    sharedCols.quantidade,
    sharedCols.total_desvios_graves,
    {
      header: 'Disponibilização de horas',
      id: 'total_disponibilizacao_horas',
      sort: true,
      type: 'number',
      align: 'center',
      value: value => `${value}%`,
    },
    {
      header: 'Prazo de disponibilização',
      id: 'total_prazo_disponibilizacao',
      sort: true,
      type: 'number',
      align: 'center',
      value: value => `${value}%`,
    },
    sharedCols.fator_risco,
  ],
  telemetria: [
    sharedCols.posicao,
    sharedCols.nome_empresa,
    sharedCols.pontos_km,
    sharedCols.total_kms,
    sharedCols.total_pontos,
    {
      header: 'Viagens',
      id: 'total_viagens',
      sort: true,
      type: 'number',
      align: 'center',
    },
    sharedCols.quantidade,
    sharedCols.total_desvios_graves,
    {
      header: 'Pontos Telemetria',
      id: 'total_pontos_telemetria',
      sort: true,
      type: 'number',
      align: 'center',
    },
    {
      header: 'Pontos Cerca Eletrônica',
      id: 'total_pontos_cerca',
      sort: true,
      type: 'number',
      align: 'center',
    },
    {
      header: 'Pontos Excesso de Velocidade',
      id: 'total_pontos_velocidade',
      sort: true,
      type: 'number',
      align: 'center',
    },
    sharedCols.fator_risco,
  ],
  fadiga: [
    sharedCols.posicao,
    sharedCols.nome_empresa,
    sharedCols.pontos_km,
    sharedCols.total_kms,
    sharedCols.total_pontos,
    sharedCols.quantidade,
    sharedCols.total_desvios_graves,
    {
      header: 'Pontos Fadiga',
      id: 'total_pontos_fadiga',
      sort: true,
      type: 'number',
      align: 'center',
    },
    {
      header: 'Pontos Colisão',
      id: 'total_pontos_colisao',
      sort: true,
      type: 'number',
      align: 'center',
    },
    {
      header: 'Pontos Conduta',
      id: 'total_pontos_conduta',
      sort: true,
      type: 'number',
      align: 'center',
    },
    sharedCols.fator_risco,
  ],
  manual: [
    sharedCols.posicao,
    sharedCols.nome_empresa,
    {
      header: 'Total de pontos',
      id: 'total_pontos',
      sort: true,
      type: 'number',
      align: 'center',
      value: value =>
        Number(value).toLocaleString(undefined, {
          maximumFractionDigits: 2,
        }),
      conditional: [
        {
          condition: (value, item) =>
            Number(item.total_pontos) > Number(item.media),
          style: theme => ({
            backgroundColor: theme.palette.semantics.feedback.attention.light,
            color: theme.palette.semantics.feedback.attention.natural,
            textAlign: 'center',
          }),
        },
        {
          condition: (value, item) =>
            Number(item.total_pontos) <= Number(item.media),
          style: theme => ({
            backgroundColor: theme.palette.semantics.feedback.success.light,
            color: theme.palette.semantics.feedback.success.natural,
            textAlign: 'center',
          }),
        },
      ],
    },
    sharedCols.quantidade,
    sharedCols.total_desvios_graves,
    sharedCols.fator_risco,
  ],
  descarga: [
    sharedCols.posicao,
    sharedCols.nome_empresa,
    {
      header: 'Pontos/Descarga',
      id: 'pontos_descarga',
      sort: true,
      type: 'number',
      align: 'center',
      value: value =>
        Number(value).toLocaleString(undefined, {
          maximumFractionDigits: 2,
        }),
      conditional: [
        {
          condition: (value, item) =>
            Number(item.pontos_descarga) > Number(item.media),
          style: theme => ({
            backgroundColor: theme.palette.semantics.feedback.attention.light,
            color: theme.palette.semantics.feedback.attention.natural,
            textAlign: 'center',
          }),
        },
        {
          condition: (value, item) =>
            Number(item.pontos_descarga) <= Number(item.media),
          style: theme => ({
            backgroundColor: theme.palette.semantics.feedback.success.light,
            color: theme.palette.semantics.feedback.success.natural,
            textAlign: 'center',
          }),
        },
      ],
    },
    sharedCols.quantidade,
    sharedCols.total_desvios_graves,
    sharedCols.fator_risco,
  ],
};

const sharedFields = {
  pontos_km: {
    id: 'pontos_km',
    title: 'Pontos/km',
    type: 'number',
  },
  total_kms: {
    id: 'total_kms',
    title: 'Quilômetros',
  },
  total_pontos: {
    id: 'total_pontos',
    title: 'Pontos',
    type: 'number',
  },
  quantidade: {
    id: 'quantidade',
    title: 'Qtd desvios',
    type: 'number',
  },
  total_desvios_graves: {
    id: 'total_desvios_graves',
    title: 'Desvios graves/gravíssimos',
    type: 'number',
  },
  fator_risco: {
    id: 'fator_risco',
    title: 'Principal Fator de Risco',
  },
};

export const fields = {
  geral: [
    {
      id: 'total_pontos_formatado',
      title: 'Total de pontos',
    },
    {
      id: 'motoristas_avaliados',
      title: 'Motoristas',
      type: 'number',
    },
    {
      id: 'total_kms',
      title: 'Deslocamento',
      plans: [100],
    },
    {
      id: 'total_horas',
      title: 'Horas',
      plans: [140],
      type: 'number',
    },
    {
      id: 'pontos_km',
      title: 'Ptos/Km',
      type: 'number',
      plans: [100],
    },
    {
      id: 'pontos_hora',
      title: 'Ptos/Hr',
      type: 'number',
      plans: [140],
    },
    {
      id: 'finalizados_no_prazo',
      title: 'Percentual Desvios finalizados no prazo',
      value: value => `${Number(value)}%`,
    },
    {
      id: 'desvios_deletados',
      title: 'Desvios excluidos',
      value: value => `${Number(value)}%`,
    },
    sharedFields.fator_risco,
  ],
  cabine: [
    {
      id: 'pontos_hora_cabine',
      title: 'Pontos/hora',
      type: 'number',
    },
    {
      id: 'total_horas',
      title: 'Horas avaliadas',
      type: 'number',
    },
    sharedFields.total_pontos,
    {
      id: 'total_motoristas_avaliados',
      title: 'Motoristas avaliados',
      value: value => `${Number(value)}`,
    },
    sharedFields.quantidade,
    sharedFields.total_desvios_graves,
    {
      id: 'total_disponibilizacao_horas',
      title: 'Disponibilização de horas',
      value: value => `${Number(value)}%`,
    },
    {
      id: 'total_prazo_disponibilizacao',
      title: 'Prazo de disponibilização',
      value: value => `${Number(value)}%`,
    },
    sharedFields.fator_risco,
  ],
  telemetria: [
    sharedFields.pontos_km,
    sharedFields.total_kms,
    sharedFields.total_pontos,
    {
      id: 'total_viagens',
      title: 'Viagens',
      type: 'number',
    },
    sharedFields.quantidade,
    sharedFields.total_desvios_graves,
    {
      id: 'total_pontos_telemetria',
      title: 'Pontos telemetria',
      type: 'number',
    },
    {
      id: 'total_pontos_cerca',
      title: 'Pontos cerca',
      type: 'number',
    },
    {
      id: 'total_pontos_velocidade',
      title: 'Pontos excesso velocidade',
      type: 'number',
    },
    sharedFields.fator_risco,
  ],
  fadiga: [
    sharedFields.pontos_km,
    sharedFields.total_kms,
    sharedFields.total_pontos,
    sharedFields.quantidade,
    sharedFields.total_desvios_graves,
    {
      id: 'total_pontos_fadiga',
      title: 'Pontos fadiga',
      type: 'number',
    },
    {
      id: 'total_pontos_colisao',
      title: 'Pontos colisão',
      type: 'number',
    },
    {
      id: 'total_pontos_conduta',
      title: 'Pontos conduta',
      type: 'number',
    },
    sharedFields.fator_risco,
  ],
  manual: [
    {
      id: 'total_pontos',
      title: 'Total de pontos',
      type: 'number',
    },
    sharedFields.quantidade,
    sharedFields.total_desvios_graves,
    sharedFields.fator_risco,
  ],
  descarga: [
    {
      id: 'pontos_descarga',
      title: 'Pontos/descarga',
      type: 'number',
    },
    sharedFields.quantidade,
    sharedFields.total_desvios_graves,
    sharedFields.fator_risco,
  ],
};

export const getExcelFields = tab => [
  {
    value: 'posicao',
    label: 'Posição',
  },
  {
    value: 'nome_empresa',
    label: 'Empresa',
  },
  ...fields[tab]
    .map(field => ({
      label: field.title,
      value: field.id,
    }))
    .slice(0, fields[tab].length - 1),
];
