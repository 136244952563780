import styled from 'styled-components';
import colors from 'styles/colors';

const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 0px;
  right: 0px;
  width: 550px;
  height: 100%;
  background-color: ${colors.white};
  padding: 0px 30px;
  margin-right: ${props => props.margin};
  transition: all ease 0.5s;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 70px;
  border-bottom: 1px solid #939aab4d;

  div {
    display: flex;
    flex-direction: row;
    h2 {
      margin-left: 10px;
    }
  }
`;

const Main = styled.div`
  width: 100%;
  height: 100%;
  padding: 5px 15px;
  overflow-y: scroll;
  margin-bottom: 5px;

  label {
    display: flex;
    align-items: center;
    cursor: pointer;

    input {
      display: none;
    }

    .imgArea {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 2px solid ${colors.greenMain};
      padding: 3px;
      display: flex;
      align-items: center;
      justify-content: center;

      .borderImage {
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          border-radius: 100px;
        }
      }

      .empty {
        background-color: ${colors.greyBorder};
        width: 100%;
        height: 100%;
        border-radius: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 900;
        color: ${colors.greyTitle};
      }
    }

    .textArea {
      display: flex;
      align-items: center;
      color: ${colors.greySubtitle};
      margin-left: 10px;

      span {
        margin-left: 5px;
      }
    }
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  align-items: center;
  justify-content: end;
  width: 100%;
  height: 80px;
  border-top: 1px solid #939aab4d;
`;

const Section = styled.h3`
  font: normal normal 900 13px/50px Texta;
  justify-content: center;
  color: ${colors.greySubtitle};
`;

export { Container, Header, Main, Footer, Section };
