import styled from 'styled-components';
import colors from 'styles/colors';

export const Cotainer = styled.div`
  margin-bottom: 50px;
`;

export const Title = styled.div`
  font-size: 25px;
  color: ${colors.greyTitle};
  font-weight: 600;

  &::first-letter {
    text-transform: uppercase;
  }
`;

export const Description = styled.div`
  font-size: 18px;
  color: ${colors.greySubtitle};
  font-weight: 500;
  margin: 15px 0px;
`;

export const ContainerMedia = styled.div`
  width: 100%;
  height: 500px;
  background-color: #000;
  position: relative;

  .react-player {
    video::-webkit-media-controls-timeline {
      display: ${props => (props.free ? 'block' : 'none')};
    }
  }

  .cap {
    width: 100%;
    height: 100%;
    background-color: ${props => (!props.play ? '#000032cc' : 'transparent')};
    position: absolute;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    transition: all 0.3s ease-in-out;

    .MuiSvgIcon-root {
      width: 100px;
      height: 100px;
      color: ${colors.greenMain};
      visibility: ${props => (props.play ? 'hidden' : 'visible')};
      transition: all 0.1s ease-in-out;
    }
  }

  .error {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: ${colors.black};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 3;

    .MuiSvgIcon-root {
      width: 100px;
      height: 100px;
      color: ${colors.greenMain};
      visibility: ${props => (props.play ? 'hidden' : 'visible')};
      transition: all 0.1s ease-in-out;
    }

    span {
      font-size: 18px;
      color: ${colors.white};
      font-weight: 500;
      margin-top: 15px;
    }
  }
`;

export const ContainerPDF = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 550px;
  position: relative;
  overflow: hidden;
  position: relative;

  .error {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: ${colors.black};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 3;

    .MuiSvgIcon-root {
      width: 100px;
      height: 100px;
      color: ${colors.greenMain};
      visibility: ${props => (props.play ? 'hidden' : 'visible')};
      transition: all 0.1s ease-in-out;
    }

    span {
      font-size: 18px;
      color: ${colors.white};
      font-weight: 500;
      margin-top: 15px;
    }
  }
`;

export const Button = styled.div`
  width: 50px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${props => (props.view ? 'pointer' : 'not-allowed')};
  opacity: ${props => (props.view ? 1 : 0)};
  transition: all 0.3s ease-in-out;
`;

export const Controls = styled.div`
  width: 100%;
  /* position: absolute; */
  bottom: 0px;
  /* margin-bottom: -30px; */
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-size: 14px;
    font-weight: bold;
    color: ${colors.greySubtitle};
  }
`;

export const MediaImage = styled.img`
  width: 100%;
  height: 500px;
  object-fit: cover;
  object-position: center;
`;

export const MediaText = styled.div`
  padding: 0px 15px;
`;

export const BoxLoading = styled.div``;
